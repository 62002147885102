import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { getMainColor, getFontColor, CloseSide } from "../../store/theme";
import { useDispatch, useSelector } from "react-redux";
import {
  BoxTooltipTitle,
  TooltipMobileAction,
} from "../ToolTipsFolder/ToolTips";
import ToolSvg from "../Svgs/ToolTipSvg";

function BrandColor() {
  let { color: FromLocalSt, fontColor } = useSelector(
    (state) => state.themeslice
  );
  let dispatch = useDispatch();

  let [color, setColor] = useState(FromLocalSt);
  let [colorFont, setColorFont] = useState(fontColor);

  const colorTimeoutRef = useRef(null);
  const fontColorTimeoutRef = useRef(null);

  const debouncedSetColor = useCallback(() => {
    dispatch(getMainColor(color));
  }, [color, dispatch]);

  const debouncedSetFontColor = useCallback(() => {
    dispatch(getFontColor(colorFont));
  }, [colorFont, dispatch]);

  useEffect(() => {
    if (colorTimeoutRef.current) {
      clearTimeout(colorTimeoutRef.current);
    }

    colorTimeoutRef.current = setTimeout(debouncedSetColor, 500); // Adjust delay as needed

    return () => clearTimeout(colorTimeoutRef.current);
  }, [color, dispatch]);

  useEffect(() => {
    if (fontColorTimeoutRef.current) {
      clearTimeout(fontColorTimeoutRef.current);
    }

    fontColorTimeoutRef.current = setTimeout(debouncedSetFontColor, 500); // Adjust delay as needed

    return () => clearTimeout(fontColorTimeoutRef.current);
  }, [colorFont, dispatch]);

  const ChoseColor = (e) => {
    setColor(e); // Update state immediately for UI feedback
  };

  const ChoseColorFont = (e) => {
    setColorFont(e); // Update state immediately for UI feedback
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Initial width
  const handleResize = () => {
    setTimeout(() => {
      setWindowWidth(window.innerWidth);
    }, 100); // 100ms delay, you can adjust this as needed
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Fragment>
      <h2 className="accordion-header">
        <div className="accordion-button">
          <text className="text-acco">Colors</text>
          {windowWidth ? (
            <TooltipMobileAction
              backgroundColor={"rgba(27, 31, 41)"}
              title="Add your branding to the screen by customizing the colors of
banners, tickers and display names"
              placement="top"
            >
              <ToolSvg />
            </TooltipMobileAction>
          ) : (
            <BoxTooltipTitle
              backgroundColor={"rgba(27, 31, 41)"}
              title="Add your branding to the screen by customizing the colors of
banners, tickers and display names"
              placement="top"
            >
              <ToolSvg />
            </BoxTooltipTitle>
          )}
          <div
            className="close "
            style={{
              alignSelf: "flex-end",
              width: "100%",
              textAlign: "right",
              marginTop: "-3px",
            }}
            onClick={() => dispatch(CloseSide(false))}
          >
            <i
              class="fa-solid fa-xmark"
              style={{
                fontSize: "21px ",
                cursor: "pointer",
              }}
            ></i>
          </div>
        </div>
      </h2>
      <div className="accordion-collapse collapse show">
        <div className="accordion-body">
          <div className="waraper-color-box mt-3">
            <div>
              <p className="title">Background</p>
              <div className="box-color">
                <input
                  type="color"
                  value={color}
                  className="chose-color"
                  onChange={(e) => ChoseColor(e.target.value)}
                />
                <input
                  type="text"
                  value={color}
                  readOnly
                  maxLength={7}
                  className="input-color form-control"
                />
              </div>
            </div>
            <div>
              <p className="title">Font</p>
              <div className="box-color">
                <input
                  type="color"
                  value={colorFont}
                  className="chose-color"
                  onChange={(e) => ChoseColorFont(e.target.value)}
                />
                <input
                  type="text"
                  value={colorFont}
                  readOnly
                  maxLength={7}
                  className="input-color form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default BrandColor;
