import React, { useState } from "react";
import "./ViwerMobileCover.scss";
import img1 from "../../assits/img-mobile.jpg";
import { TooltipMobileAction } from "../ToolTipsFolder/ToolTips";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ToggleChatBox } from "../../store/theme";
function ViwerMobileCover() {
  let { data } = useSelector((state) => state.persistData.chatMeesages);

  // let { ticker } = useSelector((state) => state.themeslice);
  let { ticker, title } = useSelector((state) => state.persistData.banners);
  let { BoxChat } = useSelector((state) => state.themeslice);

  let [state, setState] = useState(false);
  let dispatch = useDispatch();
  const closeChat = (e) => {
    e.preventDefault();
    setState(BoxChat);
    dispatch(ToggleChatBox(!BoxChat));
  };

  return (
    <div className="viwer-mobile">
      <div className="box-gear-top">
        <div className="viwer-number">
          <i className="fa-solid fa-user" />
          <span>5</span>
        </div>
        <TooltipMobileAction
          status={{ true: true }}
          classNeed={"HandelTool"}
          title={
            <ul className="box-mobile-toltip list-unstyled">
              <li>
                <i
                  className="fa-regular fa-user"
                  style={{ fontSize: "15px" }}
                />
                <span>User login</span>
              </li>
              <li>
                <i
                  className="fa-solid fa-video-slash"
                  style={{ fontSize: "15px" }}
                />
                <span>Enable audio only</span>
              </li>
              <li>
                <i className="fa-solid fa-share" style={{ fontSize: "15px" }} />
                <span>Share room</span>
              </li>
              {!BoxChat ? (
                <li onClick={(e) => closeChat(e)}>
                  {/* <i class="fa-solid fa-comment-slash" onClick={(e) => closeChat(e)}></i> */}
                  <i
                    className="fa-solid fa-comment"
                    style={{ fontSize: "15px" }}
                  />
                  <span>Show Chat</span>
                </li>
              ) : (
                <li onClick={(e) => closeChat(e)}>
                  {/* <i class="fa-solid fa-comment-slash" onClick={(e) => closeChat(e)}></i> */}
                  <i
                    className="fa-solid fa-comment-slash"
                    style={{ fontSize: "15px" }}
                  />
                  <span>Hide Chat</span>
                </li>
              )}
            </ul>
          }
        >
          <i className="fa-solid fa-gear"></i>
        </TooltipMobileAction>
      </div>
      {/**<div className="fading-overlay"></div> */}

      <div
        className="commints"
        style={{
          bottom: ticker ? (title ? "88px " : "35px") : title ? "65px" : "5px",
          left: BoxChat ? "-9px" : "-1000px",
          transition: "all 0.3s ease",
        }}
      >
        {data &&
          data
            .slice()
            .reverse()
            .map((e, index) => {
              return (
                <div className="meesage-box" key={index}>
                  <div className="box-title">
                    <text className="name">{e.username}</text>
                    <text className="message">{e.title}</text>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
}

export default ViwerMobileCover;
