import { createSlice } from "@reduxjs/toolkit";
import imgfacebook from "../assits/facebook.png";
import imginsta from "../assits//instagram.png";
const initialState = {
  listOfSocial: [
    {
      id: 1,
      title: "FaceBook page",
      status: false,
      icon: imgfacebook,
      destinationKey: "Facebook",
      braodcastKey: "b6e5 mbsmr",
      url: "rtmps://live-api-s.facebook.com:443/rtmp/",
      aspectratio: "Landsacpe",
    },
    // {
    //   id: 2,
    //   title: "Instagram Channel",
    //   status: true,
    //   icon: imginsta,
    //   destinationKey: "Instagram",
    //   braodcastKey: "b6e5 mbsmr",
    //   url: "rtmps://edgetee-upload-dub4-1.xx.fbcdn.net:443/rtmp/",
    //   aspectratio: "Portrait",
    // },
    // {
    //   id: 3,
    //   title: "Youtube Channel",
    //   status: true,
    //   icon: imginsta,
    //   destinationKey: "Youtube",
    //   braodcastKey: "b6e5 mbsmr",
    //   url: "rtmp://a.rtmp.youtube.com/live2/",
    //   aspectratio: "Portrait",
    // },
  ],
  loading: false,
  error: null, // Initialize error as null or an Error object
};

const socialSlice = createSlice({
  name: "social",
  initialState,
  reducers: {
    toggleSocialStatus(state, action) {
      const socialItem = state.listOfSocial.find(
        (item) => item.id === action.payload.id
      );
      if (socialItem) {
        socialItem.status = !socialItem.status;
      }
    },
    ManageAddSocial: (state, action) => {
      state.listOfSocial = [...state.listOfSocial, action.payload];
    },

    ManageDeleteSocial: (state, action) => {
      state.listOfSocial = state.listOfSocial.filter(
        (e) => e.id !== action.payload
      );
    },

    ManageReorderSocial: (state, action) => {
      state.listOfSocial = action.payload;
    },

    ManageEditSocial: (state, action) => {
      const { id, values } = action.payload; // Destructure id and values from payload
      const index = state.listOfSocial.findIndex((item) => item.id === id);

      if (index !== -1) {
        state.listOfSocial[index] = {
          ...state.listOfSocial[index],
          ...values,
        };
      } else {
        console.error(`Social with id ${id} not found`);
      }
    },
  },
  extraReducers: (builder) => {},
});

export default socialSlice.reducer;

export let {
  toggleSocialStatus,
  ManageAddSocial,
  ManageDeleteSocial,
  ManageReorderSocial,
  ManageEditSocial,
} = socialSlice.actions;
