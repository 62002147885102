import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MangeStart } from "../../../store/theme";

function LeaveConfirmation({ setOpenLeaveConf }) {
  const path = useNavigate();
  const dispatch = useDispatch();
  let HandleLeave = (e) => {
    e.preventDefault();
    dispatch(MangeStart(true));
    path("/");
  };
  return (
    <div className="modal-control">
      <div className="box-icon" onClick={() => setOpenLeaveConf(false)}>
        <i className="fa-solid fa-xmark" />
      </div>
      <form className="form-wrap">
        <h2>Leave Studio</h2>
        <p>Are you sure you want to leave the studio?</p>
        <button
          className="danger"
          onClick={(e) => {
            HandleLeave(e);
          }}
        >
          <span>Leave</span>
        </button>
      </form>
    </div>
  );
}

export default LeaveConfirmation;
