import React from "react";

function ScreenSvg({ backgroundColor, main }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="250"
      zoomAndPan="magnify"
      viewBox="0 0 187.5 187.499992"
      height="250"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="3f1152d4e7">
          <path
            d="M 0 0 L 187 0 L 187 187 L 0 187 Z M 0 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="fd09875ad5">
          <path
            d="M 29.921875 0 L 157.082031 0 C 165.015625 0 172.625 3.152344 178.238281 8.761719 C 183.847656 14.375 187 21.984375 187 29.921875 L 187 157.082031 C 187 165.015625 183.847656 172.625 178.238281 178.238281 C 172.625 183.847656 165.015625 187 157.082031 187 L 29.921875 187 C 21.984375 187 14.375 183.847656 8.761719 178.238281 C 3.152344 172.625 0 165.015625 0 157.082031 L 0 29.921875 C 0 21.984375 3.152344 14.375 8.761719 8.761719 C 14.375 3.152344 21.984375 0 29.921875 0 Z M 29.921875 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="32580c9a81">
          <path
            d="M 85 141 L 103 141 L 103 158.398438 L 85 158.398438 Z M 85 141 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="c1f0d2c7bc">
          <path
            d="M 56 154 L 132 154 L 132 158.398438 L 56 158.398438 Z M 56 154 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="bcca4e0657">
          <path
            d="M 12.507812 127 L 174.824219 127 L 174.824219 146 L 12.507812 146 Z M 12.507812 127 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="9f0872ba38">
          <path
            d="M 12.507812 28.246094 L 174.824219 28.246094 L 174.824219 132 L 12.507812 132 Z M 12.507812 28.246094 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#3f1152d4e7)">
        <g clip-path="url(#fd09875ad5)">
          <path
            fill={backgroundColor}
            d="M 0 0 L 187 0 L 187 187 L 0 187 Z M 0 0 "
            fill-opacity="1"
            fill-rule="nonzero"
          />
        </g>
      </g>
      <g clip-path="url(#32580c9a81)">
        <path
          fill={main}
          d="M 100.425781 158.398438 L 87.238281 158.398438 C 86.082031 158.398438 85.148438 157.46875 85.148438 156.320312 L 85.148438 143.53125 C 85.148438 142.382812 86.082031 141.453125 87.238281 141.453125 L 100.425781 141.453125 C 101.578125 141.453125 102.511719 142.382812 102.511719 143.53125 L 102.511719 156.320312 C 102.511719 157.46875 101.578125 158.398438 100.425781 158.398438 Z M 89.324219 154.242188 L 98.335938 154.242188 L 98.335938 145.609375 L 89.324219 145.609375 Z M 89.324219 154.242188 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill={main}
        d="M 93.789062 86.117188 C 82.996094 86.117188 74.210938 77.375 74.210938 66.632812 C 74.210938 55.886719 82.996094 47.148438 93.789062 47.148438 C 104.585938 47.148438 113.371094 55.886719 113.371094 66.632812 C 113.371094 77.375 104.585938 86.117188 93.789062 86.117188 Z M 93.789062 51.300781 C 85.296875 51.300781 78.386719 58.179688 78.386719 66.628906 C 78.386719 75.082031 85.296875 81.960938 93.789062 81.960938 C 102.28125 81.960938 109.191406 75.082031 109.191406 66.628906 C 109.191406 58.179688 102.28125 51.300781 93.789062 51.300781 Z M 93.789062 51.300781 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={main}
        d="M 122.730469 112.605469 C 121.980469 112.605469 121.257812 112.203125 120.882812 111.5 C 115.515625 101.457031 105.085938 95.21875 93.671875 95.21875 C 82.253906 95.21875 71.824219 101.457031 66.457031 111.5 C 65.914062 112.515625 64.652344 112.898438 63.632812 112.359375 C 62.617188 111.824219 62.230469 110.5625 62.769531 109.550781 C 68.863281 98.148438 80.703125 91.0625 93.671875 91.0625 C 106.636719 91.0625 118.476562 98.148438 124.570312 109.550781 C 125.113281 110.5625 124.726562 111.824219 123.707031 112.359375 C 123.394531 112.527344 123.058594 112.605469 122.730469 112.605469 Z M 122.730469 112.605469 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <g clip-path="url(#c1f0d2c7bc)">
        <path
          fill={main}
          d="M 129.234375 158.398438 L 58.425781 158.398438 C 57.273438 158.398438 56.335938 157.46875 56.335938 156.320312 C 56.335938 155.171875 57.273438 154.242188 58.425781 154.242188 L 129.234375 154.242188 C 130.390625 154.242188 131.324219 155.171875 131.324219 156.320312 C 131.324219 157.46875 130.390625 158.398438 129.234375 158.398438 Z M 129.234375 158.398438 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#bcca4e0657)">
        <path
          fill={main}
          d="M 164.101562 145.609375 L 23.238281 145.609375 C 17.351562 145.609375 12.5625 140.84375 12.5625 134.988281 L 12.5625 129.425781 C 12.5625 128.277344 13.496094 127.347656 14.652344 127.347656 L 172.691406 127.347656 C 173.84375 127.347656 174.777344 128.277344 174.777344 129.425781 L 174.777344 134.988281 C 174.777344 140.84375 169.988281 145.609375 164.105469 145.609375 Z M 16.738281 131.503906 L 16.738281 134.988281 C 16.738281 138.554688 19.652344 141.453125 23.238281 141.453125 L 164.101562 141.453125 C 167.683594 141.453125 170.601562 138.554688 170.601562 134.988281 L 170.601562 131.503906 Z M 16.738281 131.503906 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#9f0872ba38)">
        <path
          fill={main}
          d="M 172.691406 131.503906 L 14.652344 131.503906 C 13.496094 131.503906 12.5625 130.574219 12.5625 129.425781 L 12.5625 38.867188 C 12.5625 33.011719 17.351562 28.246094 23.238281 28.246094 L 164.101562 28.246094 C 169.988281 28.246094 174.777344 33.011719 174.777344 38.867188 L 174.777344 129.425781 C 174.777344 130.574219 173.839844 131.503906 172.6875 131.503906 Z M 16.738281 127.347656 L 170.601562 127.347656 L 170.601562 38.867188 C 170.601562 35.304688 167.6875 32.402344 164.105469 32.402344 L 23.238281 32.402344 C 19.652344 32.402344 16.738281 35.304688 16.738281 38.867188 Z M 16.738281 127.347656 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  );
}

export default ScreenSvg;
