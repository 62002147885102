import { createSlice } from "@reduxjs/toolkit";
import img1 from "../assits/img-mobile.jpg";
import img2 from "../assits/img-mobile_2.jpg";
import { randomPassword } from "../RandomID";

const initialState = {
  backgrounds: [
    {
      id: randomPassword(2),
      landscape: { name: "test landscape", src: img1, type: "img" },
      portrait: { name: "test portrait ", src: img2, type: "img" },
    },
  ],
  currentBackground: {
    id: randomPassword(2),
    landscape: { name: "test landscape", src: img1, type: "img" },
    portrait: { name: "test portrait ", src: img2, type: "img" },
  },
  loading: false,
  error: null, // Initialize error as null or an Error object
};

const backgroundSlice = createSlice({
  name: "background",
  initialState,
  reducers: {
    addBackground(state, action) {
      state.backgrounds.push(action.payload);
    },
    getBackGruond: (state, action) => {
      state.currentBackground = action.payload;
    },
    ManageDeleteBackground: (state, action) => {
      state.backgrounds = state.backgrounds.filter(
        (e) => e.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {},
});

export default backgroundSlice.reducer;

export let { addBackground, getBackGruond, ManageDeleteBackground } =
  backgroundSlice.actions;
