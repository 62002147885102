import React from "react";

function BluringThreeSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="250"
      zoomAndPan="magnify"
      viewBox="0 0 187.5 187.499992"
      height="250"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="f9b8951d8d">
          <path
            d="M 0 0 L 187 0 L 187 187 L 0 187 Z M 0 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="29cdee1fb4">
          <path
            d="M 29.921875 0 L 157.082031 0 C 165.015625 0 172.625 3.152344 178.238281 8.761719 C 183.847656 14.375 187 21.984375 187 29.921875 L 187 157.082031 C 187 165.015625 183.847656 172.625 178.238281 178.238281 C 172.625 183.847656 165.015625 187 157.082031 187 L 29.921875 187 C 21.984375 187 14.375 183.847656 8.761719 178.238281 C 3.152344 172.625 0 165.015625 0 157.082031 L 0 29.921875 C 0 21.984375 3.152344 14.375 8.761719 8.761719 C 14.375 3.152344 21.984375 0 29.921875 0 Z M 29.921875 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="e5d0e9a778">
          <path
            d="M 102.078125 51.671875 L 107.316406 51.671875 L 107.316406 56.90625 L 102.078125 56.90625 Z M 102.078125 51.671875 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="584bf9b506">
          <path
            d="M 77 65.125 L 110 65.125 L 110 100 L 77 100 Z M 77 65.125 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="1ef3b72245">
          <path
            d="M 63.261719 99 L 123.851562 99 L 123.851562 131.695312 L 63.261719 131.695312 Z M 63.261719 99 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="db7b199351">
          <path
            d="M 79.175781 39 L 84.414062 39 L 84.414062 44.238281 L 79.175781 44.238281 Z M 79.175781 39 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="0ba1eb0084">
          <path
            d="M 102.078125 39 L 107.316406 39 L 107.316406 44.238281 L 102.078125 44.238281 Z M 102.078125 39 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="403d1238b3">
          <path
            d="M 55.527344 68.539062 L 60.761719 68.539062 L 60.761719 73.773438 L 55.527344 73.773438 Z M 55.527344 68.539062 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="477cd539cf">
          <path
            d="M 33.554688 112.753906 L 38.789062 112.753906 L 38.789062 117.992188 L 33.554688 117.992188 Z M 33.554688 112.753906 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="84a1bb6ce3">
          <path
            d="M 79.175781 51.671875 L 84.414062 51.671875 L 84.414062 56.90625 L 79.175781 56.90625 Z M 79.175781 51.671875 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="e5168c9371">
          <path
            d="M 125.730469 54.144531 L 130.964844 54.144531 L 130.964844 59.382812 L 125.730469 59.382812 Z M 125.730469 54.144531 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="f642071d83">
          <path
            d="M 125.730469 68.539062 L 130.964844 68.539062 L 130.964844 73.773438 L 125.730469 73.773438 Z M 125.730469 68.539062 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="16d26a40b9">
          <path
            d="M 145.402344 126.539062 L 150.640625 126.539062 L 150.640625 131.773438 L 145.402344 131.773438 Z M 145.402344 126.539062 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="6c9860d94d">
          <path
            d="M 33.554688 91.886719 L 38.789062 91.886719 L 38.789062 97.125 L 33.554688 97.125 Z M 33.554688 91.886719 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="f7bb6afe33">
          <path
            d="M 147.878906 91.886719 L 153.113281 91.886719 L 153.113281 97.125 L 147.878906 97.125 Z M 147.878906 91.886719 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="bc56e24446">
          <path
            d="M 79.941406 150.046875 L 85.175781 150.046875 L 85.175781 155.28125 L 79.941406 155.28125 Z M 79.941406 150.046875 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="dec918ec2c">
          <path
            d="M 98.132812 150.046875 L 103.367188 150.046875 L 103.367188 155.28125 L 98.132812 155.28125 Z M 98.132812 150.046875 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="e6b59c0f88">
          <path
            d="M 56.890625 140.09375 L 62.125 140.09375 L 62.125 145.328125 L 56.890625 145.328125 Z M 56.890625 140.09375 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="ea3de1526b">
          <path
            d="M 125.519531 140.09375 L 130.757812 140.09375 L 130.757812 145.328125 L 125.519531 145.328125 Z M 125.519531 140.09375 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="057012f6da">
          <path
            d="M 40.976562 129.015625 L 46.214844 129.015625 L 46.214844 134.25 L 40.976562 134.25 Z M 40.976562 129.015625 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="94aecca2ff">
          <path
            d="M 148.421875 110.28125 L 153.660156 110.28125 L 153.660156 115.515625 L 148.421875 115.515625 Z M 148.421875 110.28125 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="d266950674">
          <path
            d="M 36.027344 71.011719 L 41.265625 71.011719 L 41.265625 76.25 L 36.027344 76.25 Z M 36.027344 71.011719 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="3656f46ba5">
          <path
            d="M 55.527344 54.144531 L 60.761719 54.144531 L 60.761719 59.382812 L 55.527344 59.382812 Z M 55.527344 54.144531 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="c9be31ff27">
          <path
            d="M 144.890625 71.011719 L 150.128906 71.011719 L 150.128906 76.25 L 144.890625 76.25 Z M 144.890625 71.011719 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#f9b8951d8d)">
        <g clip-path="url(#29cdee1fb4)">
          <path
            fill="#d4e3fd"
            d="M 0 0 L 187 0 L 187 187 L 0 187 Z M 0 0 "
            fill-opacity="1"
            fill-rule="nonzero"
          />
        </g>
      </g>
      <g clip-path="url(#e5d0e9a778)">
        <path
          fill="#000000"
          d="M 107.316406 54.289062 C 107.316406 54.636719 107.25 54.96875 107.117188 55.289062 C 106.984375 55.613281 106.792969 55.894531 106.546875 56.140625 C 106.304688 56.386719 106.019531 56.574219 105.699219 56.707031 C 105.378906 56.839844 105.042969 56.90625 104.699219 56.90625 C 104.351562 56.90625 104.015625 56.839844 103.695312 56.707031 C 103.375 56.574219 103.089844 56.386719 102.847656 56.140625 C 102.601562 55.894531 102.410156 55.613281 102.277344 55.289062 C 102.144531 54.96875 102.078125 54.636719 102.078125 54.289062 C 102.078125 53.941406 102.144531 53.609375 102.277344 53.289062 C 102.410156 52.964844 102.601562 52.683594 102.847656 52.4375 C 103.089844 52.191406 103.375 52.003906 103.695312 51.871094 C 104.015625 51.738281 104.351562 51.671875 104.699219 51.671875 C 105.042969 51.671875 105.378906 51.738281 105.699219 51.871094 C 106.019531 52.003906 106.304688 52.191406 106.546875 52.4375 C 106.792969 52.683594 106.984375 52.964844 107.117188 53.289062 C 107.25 53.609375 107.316406 53.941406 107.316406 54.289062 Z M 107.316406 54.289062 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#584bf9b506)">
        <path
          fill="#000000"
          d="M 93.558594 99.65625 C 102.457031 99.65625 109.699219 90.195312 109.699219 81.285156 C 109.699219 72.375 102.457031 65.125 93.558594 65.125 C 84.65625 65.125 77.414062 72.375 77.414062 81.285156 C 77.417969 90.195312 84.65625 99.65625 93.558594 99.65625 Z M 93.558594 99.65625 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#1ef3b72245)">
        <path
          fill="#000000"
          d="M 105.675781 99.820312 C 102.542969 102.210938 98.636719 103.628906 94.40625 103.628906 L 92.707031 103.628906 C 88.476562 103.628906 84.570312 102.207031 81.4375 99.820312 C 71.214844 101.476562 63.410156 110.347656 63.410156 121.050781 C 63.410156 126.929688 76.90625 131.695312 93.558594 131.695312 C 110.207031 131.695312 123.707031 126.929688 123.707031 121.050781 C 123.707031 110.347656 115.898438 101.476562 105.675781 99.820312 Z M 105.675781 99.820312 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#db7b199351)">
        <path
          fill="#000000"
          d="M 84.414062 41.621094 C 84.414062 41.96875 84.347656 42.300781 84.214844 42.621094 C 84.082031 42.941406 83.890625 43.226562 83.644531 43.472656 C 83.402344 43.714844 83.117188 43.90625 82.796875 44.039062 C 82.476562 44.171875 82.140625 44.238281 81.796875 44.238281 C 81.449219 44.238281 81.113281 44.171875 80.792969 44.039062 C 80.472656 43.90625 80.1875 43.714844 79.945312 43.472656 C 79.699219 43.226562 79.507812 42.941406 79.375 42.621094 C 79.242188 42.300781 79.175781 41.96875 79.175781 41.621094 C 79.175781 41.273438 79.242188 40.9375 79.375 40.617188 C 79.507812 40.296875 79.699219 40.015625 79.945312 39.769531 C 80.1875 39.523438 80.472656 39.335938 80.792969 39.199219 C 81.113281 39.066406 81.449219 39 81.796875 39 C 82.140625 39 82.476562 39.066406 82.796875 39.199219 C 83.117188 39.335938 83.402344 39.523438 83.644531 39.769531 C 83.890625 40.015625 84.082031 40.296875 84.214844 40.617188 C 84.347656 40.9375 84.414062 41.273438 84.414062 41.621094 Z M 84.414062 41.621094 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#0ba1eb0084)">
        <path
          fill="#000000"
          d="M 107.316406 41.621094 C 107.316406 41.96875 107.25 42.300781 107.117188 42.621094 C 106.984375 42.941406 106.792969 43.226562 106.546875 43.472656 C 106.304688 43.714844 106.019531 43.90625 105.699219 44.039062 C 105.378906 44.171875 105.042969 44.238281 104.699219 44.238281 C 104.351562 44.238281 104.015625 44.171875 103.695312 44.039062 C 103.375 43.90625 103.089844 43.714844 102.847656 43.472656 C 102.601562 43.226562 102.410156 42.941406 102.277344 42.621094 C 102.144531 42.300781 102.078125 41.96875 102.078125 41.621094 C 102.078125 41.273438 102.144531 40.9375 102.277344 40.617188 C 102.410156 40.296875 102.601562 40.015625 102.847656 39.769531 C 103.089844 39.523438 103.375 39.335938 103.695312 39.199219 C 104.015625 39.066406 104.351562 39 104.699219 39 C 105.042969 39 105.378906 39.066406 105.699219 39.199219 C 106.019531 39.335938 106.304688 39.523438 106.546875 39.769531 C 106.792969 40.015625 106.984375 40.296875 107.117188 40.617188 C 107.25 40.9375 107.316406 41.273438 107.316406 41.621094 Z M 107.316406 41.621094 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#403d1238b3)">
        <path
          fill="#000000"
          d="M 60.761719 71.15625 C 60.761719 71.503906 60.695312 71.835938 60.5625 72.160156 C 60.429688 72.480469 60.242188 72.761719 59.996094 73.007812 C 59.75 73.253906 59.46875 73.441406 59.148438 73.574219 C 58.824219 73.707031 58.492188 73.773438 58.144531 73.773438 C 57.796875 73.773438 57.464844 73.707031 57.144531 73.574219 C 56.824219 73.441406 56.539062 73.253906 56.292969 73.007812 C 56.046875 72.761719 55.859375 72.480469 55.726562 72.160156 C 55.59375 71.835938 55.527344 71.503906 55.527344 71.15625 C 55.527344 70.808594 55.59375 70.476562 55.726562 70.15625 C 55.859375 69.835938 56.046875 69.550781 56.292969 69.304688 C 56.539062 69.058594 56.824219 68.871094 57.144531 68.738281 C 57.464844 68.605469 57.796875 68.539062 58.144531 68.539062 C 58.492188 68.539062 58.824219 68.605469 59.148438 68.738281 C 59.46875 68.871094 59.75 69.058594 59.996094 69.304688 C 60.242188 69.550781 60.429688 69.835938 60.5625 70.15625 C 60.695312 70.476562 60.761719 70.808594 60.761719 71.15625 Z M 60.761719 71.15625 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#477cd539cf)">
        <path
          fill="#000000"
          d="M 38.789062 115.375 C 38.789062 115.71875 38.722656 116.054688 38.589844 116.375 C 38.457031 116.695312 38.269531 116.980469 38.023438 117.222656 C 37.777344 117.46875 37.492188 117.660156 37.171875 117.792969 C 36.851562 117.925781 36.519531 117.992188 36.171875 117.992188 C 35.824219 117.992188 35.488281 117.925781 35.167969 117.792969 C 34.847656 117.660156 34.566406 117.46875 34.320312 117.222656 C 34.074219 116.980469 33.886719 116.695312 33.753906 116.375 C 33.621094 116.054688 33.554688 115.71875 33.554688 115.375 C 33.554688 115.027344 33.621094 114.691406 33.753906 114.371094 C 33.886719 114.050781 34.074219 113.765625 34.320312 113.523438 C 34.566406 113.277344 34.847656 113.085938 35.167969 112.953125 C 35.488281 112.820312 35.824219 112.753906 36.171875 112.753906 C 36.519531 112.753906 36.851562 112.820312 37.171875 112.953125 C 37.492188 113.085938 37.777344 113.277344 38.023438 113.523438 C 38.269531 113.765625 38.457031 114.050781 38.589844 114.371094 C 38.722656 114.691406 38.789062 115.027344 38.789062 115.375 Z M 38.789062 115.375 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#84a1bb6ce3)">
        <path
          fill="#000000"
          d="M 84.414062 54.289062 C 84.414062 54.636719 84.347656 54.96875 84.214844 55.289062 C 84.082031 55.613281 83.890625 55.894531 83.644531 56.140625 C 83.402344 56.386719 83.117188 56.574219 82.796875 56.707031 C 82.476562 56.839844 82.140625 56.90625 81.796875 56.90625 C 81.449219 56.90625 81.113281 56.839844 80.792969 56.707031 C 80.472656 56.574219 80.1875 56.386719 79.945312 56.140625 C 79.699219 55.894531 79.507812 55.613281 79.375 55.289062 C 79.242188 54.96875 79.175781 54.636719 79.175781 54.289062 C 79.175781 53.941406 79.242188 53.609375 79.375 53.289062 C 79.507812 52.964844 79.699219 52.683594 79.945312 52.4375 C 80.1875 52.191406 80.472656 52.003906 80.792969 51.871094 C 81.113281 51.738281 81.449219 51.671875 81.796875 51.671875 C 82.140625 51.671875 82.476562 51.738281 82.796875 51.871094 C 83.117188 52.003906 83.402344 52.191406 83.644531 52.4375 C 83.890625 52.683594 84.082031 52.964844 84.214844 53.289062 C 84.347656 53.609375 84.414062 53.941406 84.414062 54.289062 Z M 84.414062 54.289062 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#e5168c9371)">
        <path
          fill="#000000"
          d="M 130.964844 56.765625 C 130.964844 57.113281 130.898438 57.445312 130.765625 57.765625 C 130.632812 58.085938 130.445312 58.371094 130.199219 58.617188 C 129.953125 58.859375 129.671875 59.050781 129.347656 59.183594 C 129.027344 59.316406 128.695312 59.382812 128.347656 59.382812 C 128 59.382812 127.667969 59.316406 127.34375 59.183594 C 127.023438 59.050781 126.742188 58.859375 126.496094 58.617188 C 126.25 58.371094 126.0625 58.085938 125.929688 57.765625 C 125.796875 57.445312 125.730469 57.113281 125.730469 56.765625 C 125.730469 56.417969 125.796875 56.082031 125.929688 55.761719 C 126.0625 55.441406 126.25 55.160156 126.496094 54.914062 C 126.742188 54.667969 127.023438 54.476562 127.34375 54.34375 C 127.667969 54.210938 128 54.144531 128.347656 54.144531 C 128.695312 54.144531 129.027344 54.210938 129.347656 54.34375 C 129.671875 54.476562 129.953125 54.667969 130.199219 54.914062 C 130.445312 55.160156 130.632812 55.441406 130.765625 55.761719 C 130.898438 56.082031 130.964844 56.417969 130.964844 56.765625 Z M 130.964844 56.765625 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#f642071d83)">
        <path
          fill="#000000"
          d="M 130.964844 71.15625 C 130.964844 71.503906 130.898438 71.835938 130.765625 72.160156 C 130.632812 72.480469 130.445312 72.761719 130.199219 73.007812 C 129.953125 73.253906 129.671875 73.441406 129.347656 73.574219 C 129.027344 73.707031 128.695312 73.773438 128.347656 73.773438 C 128 73.773438 127.667969 73.707031 127.34375 73.574219 C 127.023438 73.441406 126.742188 73.253906 126.496094 73.007812 C 126.25 72.761719 126.0625 72.480469 125.929688 72.160156 C 125.796875 71.835938 125.730469 71.503906 125.730469 71.15625 C 125.730469 70.808594 125.796875 70.476562 125.929688 70.15625 C 126.0625 69.835938 126.25 69.550781 126.496094 69.304688 C 126.742188 69.058594 127.023438 68.871094 127.34375 68.738281 C 127.667969 68.605469 128 68.539062 128.347656 68.539062 C 128.695312 68.539062 129.027344 68.605469 129.347656 68.738281 C 129.671875 68.871094 129.953125 69.058594 130.199219 69.304688 C 130.445312 69.550781 130.632812 69.835938 130.765625 70.15625 C 130.898438 70.476562 130.964844 70.808594 130.964844 71.15625 Z M 130.964844 71.15625 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#16d26a40b9)">
        <path
          fill="#000000"
          d="M 150.640625 129.15625 C 150.640625 129.503906 150.574219 129.839844 150.441406 130.160156 C 150.308594 130.480469 150.117188 130.761719 149.875 131.007812 C 149.628906 131.253906 149.34375 131.441406 149.023438 131.574219 C 148.703125 131.707031 148.367188 131.773438 148.023438 131.773438 C 147.675781 131.773438 147.339844 131.707031 147.019531 131.574219 C 146.699219 131.441406 146.417969 131.253906 146.171875 131.007812 C 145.925781 130.761719 145.734375 130.480469 145.601562 130.160156 C 145.46875 129.839844 145.402344 129.503906 145.402344 129.15625 C 145.402344 128.808594 145.46875 128.476562 145.601562 128.15625 C 145.734375 127.835938 145.925781 127.550781 146.171875 127.304688 C 146.417969 127.058594 146.699219 126.871094 147.019531 126.738281 C 147.339844 126.605469 147.675781 126.539062 148.023438 126.539062 C 148.367188 126.539062 148.703125 126.605469 149.023438 126.738281 C 149.34375 126.871094 149.628906 127.058594 149.875 127.304688 C 150.117188 127.550781 150.308594 127.835938 150.441406 128.15625 C 150.574219 128.476562 150.640625 128.808594 150.640625 129.15625 Z M 150.640625 129.15625 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#6c9860d94d)">
        <path
          fill="#000000"
          d="M 38.789062 94.503906 C 38.789062 94.851562 38.722656 95.1875 38.589844 95.507812 C 38.457031 95.828125 38.269531 96.109375 38.023438 96.355469 C 37.777344 96.601562 37.492188 96.792969 37.171875 96.925781 C 36.851562 97.058594 36.519531 97.125 36.171875 97.125 C 35.824219 97.125 35.488281 97.058594 35.167969 96.925781 C 34.847656 96.792969 34.566406 96.601562 34.320312 96.355469 C 34.074219 96.109375 33.886719 95.828125 33.753906 95.507812 C 33.621094 95.1875 33.554688 94.851562 33.554688 94.503906 C 33.554688 94.15625 33.621094 93.824219 33.753906 93.503906 C 33.886719 93.183594 34.074219 92.898438 34.320312 92.652344 C 34.566406 92.410156 34.847656 92.21875 35.167969 92.085938 C 35.488281 91.953125 35.824219 91.886719 36.171875 91.886719 C 36.519531 91.886719 36.851562 91.953125 37.171875 92.085938 C 37.492188 92.21875 37.777344 92.410156 38.023438 92.652344 C 38.269531 92.898438 38.457031 93.183594 38.589844 93.503906 C 38.722656 93.824219 38.789062 94.15625 38.789062 94.503906 Z M 38.789062 94.503906 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#f7bb6afe33)">
        <path
          fill="#000000"
          d="M 153.113281 94.503906 C 153.113281 94.851562 153.046875 95.1875 152.914062 95.507812 C 152.78125 95.828125 152.59375 96.109375 152.347656 96.355469 C 152.101562 96.601562 151.820312 96.792969 151.5 96.925781 C 151.179688 97.058594 150.84375 97.125 150.496094 97.125 C 150.148438 97.125 149.816406 97.058594 149.496094 96.925781 C 149.175781 96.792969 148.890625 96.601562 148.644531 96.355469 C 148.398438 96.109375 148.210938 95.828125 148.078125 95.507812 C 147.945312 95.1875 147.878906 94.851562 147.878906 94.503906 C 147.878906 94.15625 147.945312 93.824219 148.078125 93.503906 C 148.210938 93.183594 148.398438 92.898438 148.644531 92.652344 C 148.890625 92.410156 149.175781 92.21875 149.496094 92.085938 C 149.816406 91.953125 150.148438 91.886719 150.496094 91.886719 C 150.84375 91.886719 151.179688 91.953125 151.5 92.085938 C 151.820312 92.21875 152.101562 92.410156 152.347656 92.652344 C 152.59375 92.898438 152.78125 93.183594 152.914062 93.503906 C 153.046875 93.824219 153.113281 94.15625 153.113281 94.503906 Z M 153.113281 94.503906 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#bc56e24446)">
        <path
          fill="#000000"
          d="M 85.175781 152.664062 C 85.175781 153.011719 85.109375 153.34375 84.976562 153.664062 C 84.84375 153.984375 84.65625 154.269531 84.410156 154.515625 C 84.164062 154.761719 83.882812 154.949219 83.5625 155.082031 C 83.242188 155.214844 82.90625 155.28125 82.558594 155.28125 C 82.210938 155.28125 81.878906 155.214844 81.558594 155.082031 C 81.238281 154.949219 80.953125 154.761719 80.707031 154.515625 C 80.460938 154.269531 80.273438 153.984375 80.140625 153.664062 C 80.007812 153.34375 79.941406 153.011719 79.941406 152.664062 C 79.941406 152.316406 80.007812 151.980469 80.140625 151.660156 C 80.273438 151.339844 80.460938 151.058594 80.707031 150.8125 C 80.953125 150.566406 81.238281 150.378906 81.558594 150.246094 C 81.878906 150.113281 82.210938 150.046875 82.558594 150.046875 C 82.90625 150.046875 83.242188 150.113281 83.5625 150.246094 C 83.882812 150.378906 84.164062 150.566406 84.410156 150.8125 C 84.65625 151.058594 84.84375 151.339844 84.976562 151.660156 C 85.109375 151.980469 85.175781 152.316406 85.175781 152.664062 Z M 85.175781 152.664062 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#dec918ec2c)">
        <path
          fill="#000000"
          d="M 103.367188 152.664062 C 103.367188 153.011719 103.300781 153.34375 103.167969 153.664062 C 103.035156 153.984375 102.847656 154.269531 102.601562 154.515625 C 102.355469 154.761719 102.074219 154.949219 101.753906 155.082031 C 101.433594 155.214844 101.097656 155.28125 100.75 155.28125 C 100.402344 155.28125 100.070312 155.214844 99.75 155.082031 C 99.429688 154.949219 99.144531 154.761719 98.898438 154.515625 C 98.652344 154.269531 98.464844 153.984375 98.332031 153.664062 C 98.199219 153.34375 98.132812 153.011719 98.132812 152.664062 C 98.132812 152.316406 98.199219 151.980469 98.332031 151.660156 C 98.464844 151.339844 98.652344 151.058594 98.898438 150.8125 C 99.144531 150.566406 99.429688 150.378906 99.75 150.246094 C 100.070312 150.113281 100.402344 150.046875 100.75 150.046875 C 101.097656 150.046875 101.433594 150.113281 101.753906 150.246094 C 102.074219 150.378906 102.355469 150.566406 102.601562 150.8125 C 102.847656 151.058594 103.035156 151.339844 103.167969 151.660156 C 103.300781 151.980469 103.367188 152.316406 103.367188 152.664062 Z M 103.367188 152.664062 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#e6b59c0f88)">
        <path
          fill="#000000"
          d="M 62.125 142.710938 C 62.125 143.058594 62.058594 143.390625 61.925781 143.710938 C 61.792969 144.035156 61.605469 144.316406 61.359375 144.5625 C 61.113281 144.808594 60.832031 144.996094 60.511719 145.128906 C 60.191406 145.261719 59.855469 145.328125 59.507812 145.328125 C 59.160156 145.328125 58.828125 145.261719 58.507812 145.128906 C 58.1875 144.996094 57.902344 144.808594 57.65625 144.5625 C 57.410156 144.316406 57.222656 144.035156 57.089844 143.710938 C 56.957031 143.390625 56.890625 143.058594 56.890625 142.710938 C 56.890625 142.363281 56.957031 142.03125 57.089844 141.710938 C 57.222656 141.386719 57.410156 141.105469 57.65625 140.859375 C 57.902344 140.613281 58.1875 140.425781 58.507812 140.292969 C 58.828125 140.160156 59.160156 140.09375 59.507812 140.09375 C 59.855469 140.09375 60.191406 140.160156 60.511719 140.292969 C 60.832031 140.425781 61.113281 140.613281 61.359375 140.859375 C 61.605469 141.105469 61.792969 141.386719 61.925781 141.710938 C 62.058594 142.03125 62.125 142.363281 62.125 142.710938 Z M 62.125 142.710938 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#ea3de1526b)">
        <path
          fill="#000000"
          d="M 130.757812 142.710938 C 130.757812 143.058594 130.691406 143.390625 130.558594 143.710938 C 130.425781 144.035156 130.234375 144.316406 129.988281 144.5625 C 129.746094 144.808594 129.460938 144.996094 129.140625 145.128906 C 128.820312 145.261719 128.484375 145.328125 128.136719 145.328125 C 127.792969 145.328125 127.457031 145.261719 127.136719 145.128906 C 126.816406 144.996094 126.53125 144.808594 126.289062 144.5625 C 126.042969 144.316406 125.851562 144.035156 125.71875 143.710938 C 125.585938 143.390625 125.519531 143.058594 125.519531 142.710938 C 125.519531 142.363281 125.585938 142.03125 125.71875 141.710938 C 125.851562 141.386719 126.042969 141.105469 126.289062 140.859375 C 126.53125 140.613281 126.816406 140.425781 127.136719 140.292969 C 127.457031 140.160156 127.792969 140.09375 128.136719 140.09375 C 128.484375 140.09375 128.820312 140.160156 129.140625 140.292969 C 129.460938 140.425781 129.746094 140.613281 129.988281 140.859375 C 130.234375 141.105469 130.425781 141.386719 130.558594 141.710938 C 130.691406 142.03125 130.757812 142.363281 130.757812 142.710938 Z M 130.757812 142.710938 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#057012f6da)">
        <path
          fill="#000000"
          d="M 46.214844 131.632812 C 46.214844 131.980469 46.148438 132.3125 46.015625 132.632812 C 45.882812 132.953125 45.691406 133.238281 45.449219 133.484375 C 45.203125 133.730469 44.917969 133.917969 44.597656 134.050781 C 44.277344 134.183594 43.945312 134.25 43.597656 134.25 C 43.25 134.25 42.914062 134.183594 42.59375 134.050781 C 42.273438 133.917969 41.992188 133.730469 41.746094 133.484375 C 41.5 133.238281 41.308594 132.953125 41.175781 132.632812 C 41.042969 132.3125 40.976562 131.980469 40.976562 131.632812 C 40.976562 131.285156 41.042969 130.953125 41.175781 130.628906 C 41.308594 130.308594 41.5 130.027344 41.746094 129.78125 C 41.992188 129.535156 42.273438 129.347656 42.59375 129.214844 C 42.914062 129.082031 43.25 129.015625 43.597656 129.015625 C 43.945312 129.015625 44.277344 129.082031 44.597656 129.214844 C 44.917969 129.347656 45.203125 129.535156 45.449219 129.78125 C 45.691406 130.027344 45.882812 130.308594 46.015625 130.628906 C 46.148438 130.953125 46.214844 131.285156 46.214844 131.632812 Z M 46.214844 131.632812 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#94aecca2ff)">
        <path
          fill="#000000"
          d="M 153.660156 112.898438 C 153.660156 113.246094 153.59375 113.578125 153.460938 113.898438 C 153.328125 114.222656 153.136719 114.503906 152.890625 114.75 C 152.648438 114.996094 152.363281 115.183594 152.042969 115.316406 C 151.722656 115.449219 151.386719 115.515625 151.039062 115.515625 C 150.695312 115.515625 150.359375 115.449219 150.039062 115.316406 C 149.71875 115.183594 149.433594 114.996094 149.191406 114.75 C 148.945312 114.503906 148.753906 114.222656 148.621094 113.898438 C 148.488281 113.578125 148.421875 113.246094 148.421875 112.898438 C 148.421875 112.550781 148.488281 112.21875 148.621094 111.894531 C 148.753906 111.574219 148.945312 111.292969 149.191406 111.046875 C 149.433594 110.800781 149.71875 110.613281 150.039062 110.480469 C 150.359375 110.347656 150.695312 110.28125 151.039062 110.28125 C 151.386719 110.28125 151.722656 110.347656 152.042969 110.480469 C 152.363281 110.613281 152.648438 110.800781 152.890625 111.046875 C 153.136719 111.292969 153.328125 111.574219 153.460938 111.894531 C 153.59375 112.21875 153.660156 112.550781 153.660156 112.898438 Z M 153.660156 112.898438 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#d266950674)">
        <path
          fill="#000000"
          d="M 41.265625 73.632812 C 41.265625 73.980469 41.199219 74.3125 41.066406 74.632812 C 40.933594 74.953125 40.742188 75.238281 40.496094 75.484375 C 40.253906 75.726562 39.96875 75.917969 39.648438 76.050781 C 39.328125 76.183594 38.992188 76.25 38.644531 76.25 C 38.300781 76.25 37.964844 76.183594 37.644531 76.050781 C 37.324219 75.917969 37.039062 75.726562 36.796875 75.484375 C 36.550781 75.238281 36.359375 74.953125 36.226562 74.632812 C 36.09375 74.3125 36.027344 73.980469 36.027344 73.632812 C 36.027344 73.285156 36.09375 72.949219 36.226562 72.628906 C 36.359375 72.308594 36.550781 72.027344 36.796875 71.78125 C 37.039062 71.535156 37.324219 71.347656 37.644531 71.214844 C 37.964844 71.082031 38.300781 71.011719 38.644531 71.011719 C 38.992188 71.011719 39.328125 71.082031 39.648438 71.214844 C 39.96875 71.347656 40.253906 71.535156 40.496094 71.78125 C 40.742188 72.027344 40.933594 72.308594 41.066406 72.628906 C 41.199219 72.949219 41.265625 73.285156 41.265625 73.632812 Z M 41.265625 73.632812 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#3656f46ba5)">
        <path
          fill="#000000"
          d="M 60.761719 56.765625 C 60.761719 57.113281 60.695312 57.445312 60.5625 57.765625 C 60.429688 58.085938 60.242188 58.371094 59.996094 58.617188 C 59.75 58.859375 59.46875 59.050781 59.148438 59.183594 C 58.824219 59.316406 58.492188 59.382812 58.144531 59.382812 C 57.796875 59.382812 57.464844 59.316406 57.144531 59.183594 C 56.824219 59.050781 56.539062 58.859375 56.292969 58.617188 C 56.046875 58.371094 55.859375 58.085938 55.726562 57.765625 C 55.59375 57.445312 55.527344 57.113281 55.527344 56.765625 C 55.527344 56.417969 55.59375 56.082031 55.726562 55.761719 C 55.859375 55.441406 56.046875 55.160156 56.292969 54.914062 C 56.539062 54.667969 56.824219 54.476562 57.144531 54.34375 C 57.464844 54.210938 57.796875 54.144531 58.144531 54.144531 C 58.492188 54.144531 58.824219 54.210938 59.148438 54.34375 C 59.46875 54.476562 59.75 54.667969 59.996094 54.914062 C 60.242188 55.160156 60.429688 55.441406 60.5625 55.761719 C 60.695312 56.082031 60.761719 56.417969 60.761719 56.765625 Z M 60.761719 56.765625 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#c9be31ff27)">
        <path
          fill="#000000"
          d="M 150.128906 73.632812 C 150.128906 73.980469 150.0625 74.3125 149.929688 74.632812 C 149.796875 74.953125 149.605469 75.238281 149.359375 75.484375 C 149.117188 75.726562 148.832031 75.917969 148.511719 76.050781 C 148.191406 76.183594 147.855469 76.25 147.507812 76.25 C 147.164062 76.25 146.828125 76.183594 146.507812 76.050781 C 146.1875 75.917969 145.902344 75.726562 145.660156 75.484375 C 145.414062 75.238281 145.222656 74.953125 145.089844 74.632812 C 144.957031 74.3125 144.890625 73.980469 144.890625 73.632812 C 144.890625 73.285156 144.957031 72.949219 145.089844 72.628906 C 145.222656 72.308594 145.414062 72.027344 145.660156 71.78125 C 145.902344 71.535156 146.1875 71.347656 146.507812 71.214844 C 146.828125 71.082031 147.164062 71.011719 147.507812 71.011719 C 147.855469 71.011719 148.191406 71.082031 148.511719 71.214844 C 148.832031 71.347656 149.117188 71.535156 149.359375 71.78125 C 149.605469 72.027344 149.796875 72.308594 149.929688 72.628906 C 150.0625 72.949219 150.128906 73.285156 150.128906 73.632812 Z M 150.128906 73.632812 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  );
}

export default BluringThreeSvg;
