import React from "react";
import { useDispatch } from "react-redux";
import { ToggleAutoMute } from "../../store/theme";
import { useSelector } from "react-redux";

function BoxAutoMute() {
  let dispatch = useDispatch();
  return (
    <div
      className="box-auto-mute"
      onClick={() => dispatch(ToggleAutoMute(false))}
    >
      <div className="Title-box">
        <p>
          <i class="fa-solid fa-volume-xmark"></i>
          Audio Muted
        </p>
        <p>Click here to unmute</p>
      </div>
    </div>
  );
}

export default BoxAutoMute;
