import React, { useState } from "react";
import {
  BoxTooltipTitle,
  TooltipMobileAction,
} from "../../ToolTipsFolder/ToolTips";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import ToolSvg from "../../Svgs/ToolTipSvg";

function General() {
  let [checkState, setCheckState] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Initial width

  return (
    <>
      <div className="quality-record">
        <div className="box-chack">
          <div className="wraper-check">
            <input
              defaultChecked={false}
              type="checkbox"
              id="Hide"
              className="form-check"
            />
            <label htmlFor="Hide">Hide viewers count</label>

            {windowWidth <= 575 ? (
              <TooltipMobileAction
                backgroundColor={"rgba(27, 31, 41)"}
                title="Only viewers, moderators and hosts will be able to see the count."
                placement="top"
              >
                <ToolSvg />
              </TooltipMobileAction>
            ) : (
              <BoxTooltipTitle
                title="Only viewers, moderators and hosts will be able to see the count."
                arrow
                placement="top"
              >
                <ToolSvg />
              </BoxTooltipTitle>
            )}
          </div>
        </div>

        <div className="box-chack">
          <div className="wraper-check">
            <input
              defaultChecked={true}
              type="checkbox"
              id="Adapt"
              className="form-check"
            />
            <label htmlFor="Adapt">Adapt layouts to mobile</label>

            {windowWidth <= 575 ? (
              <TooltipMobileAction
                backgroundColor={"rgba(27, 31, 41)"}
                title="When disabled, viewers on mobile will see the same landscape screen on laptop."
                placement="top"
              >
                <ToolSvg />
              </TooltipMobileAction>
            ) : (
              <BoxTooltipTitle
                title="When disabled, viewers on mobile will see the same landscape screen on laptop."
                arrow
                placement="top"
              >
                <ToolSvg />
              </BoxTooltipTitle>
            )}
          </div>
        </div>

        <div className="box-chack">
          <div className="wraper-check">
            <input
              defaultChecked={false}
              type="checkbox"
              id="viewers"
              className="form-check"
              onChange={(e) => setCheckState(e.target.checked)}
            />
            <label htmlFor="viewers">Allow viewers to raise hands</label>

            {windowWidth <= 575 ? (
              <TooltipMobileAction
                backgroundColor={"rgba(27, 31, 41)"}
                title="Enable this option if you want your viewers to be able to request joining the stream?"
                placement="top"
              >
                <ToolSvg />
              </TooltipMobileAction>
            ) : (
              <BoxTooltipTitle
                title="Enable this option if you want your viewers to be able to request joining the stream?"
                arrow
                placement="top"
              >
                <ToolSvg />
              </BoxTooltipTitle>
            )}
          </div>
        </div>
        <div>
          <FormControl>
            <RadioGroup
              row
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
                marginLeft: "30px",
                marginTop: "8px",
              }}
            >
              <FormControlLabel
                sx={{ ".MuiTypography-root": { fontSize: "1.5rem" } }}
                value="audioVideo" // Value for audio & video selection
                control={<Radio disabled={!checkState} />}
                label="Audio and video"
              />
              <FormControlLabel
                sx={{ ".MuiTypography-root": { fontSize: "1.5rem" } }}
                value="videoOnly" // Value for video only selection
                control={<Radio disabled={!checkState} color="primary" />}
                label="Video Only"
              />
              <FormControlLabel
                sx={{ ".MuiTypography-root": { fontSize: "1.5rem" } }}
                value="audioOnly"
                control={<Radio disabled={!checkState} />}
                label="Audio Only"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      {/* <div className="header">
        <button className="btn btn-primary">
          <text>Save</text>
        </button>
      </div> */}
    </>
  );
}

export default General;
