import React, { useState } from "react";
import "./SendMessageBox.scss";
import { TooltipMobileAction } from "../ToolTipsFolder/ToolTips";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { formatTime } from "../../formatTime";
import { addMessage } from "../../store/chatSlice";
import { ToggleChatBox } from "../../store/theme";
import { Dialog } from "@mui/material";
import CoinsPopUp from "../CoinsPopUp/CoinsPopUp";

function SendMessageBox() {
  let [inp, setInp] = useState();
  let dispatch = useDispatch();
  let AddDate = (e) => {
    e.preventDefault();
    if (inp) {
      const currentTimestamp = new Date().getTime(); // Get current timestamp in milliseconds
      const formattedTime = formatTime(currentTimestamp);
      dispatch(
        addMessage({
          title: inp,
          main: true,
          username: "mayar",
          time: formattedTime,
        })
      );
      setInp("");
    }
  };

  let { BoxChat, ShowCoins } = useSelector((state) => state.themeslice);
  const closeChat = (e) => {
    e.preventDefault();
    dispatch(ToggleChatBox(!BoxChat));
  };
  let { ticker } = useSelector((state) => state.persistData.banners);

  let [openPop, setOpenPop] = useState(false);
  return (
    <>
      <form
        className="box-write-bottom"
        style={{ bottom: ticker ? "34px" : "15px" }}
      >
        <button
          type="submit"
          onClick={(e) => AddDate(e)}
          className="icon-send"
          style={{ position: "absolute", left: "20px" }}
        >
          <i className={`fa-solid fa-play   ${inp && "active"}`} />
        </button>
        <input
          type="text"
          placeholder="Add comment"
          value={inp}
          onChange={(e) => setInp(e.target.value)}
        />

        {ShowCoins && (
          <div className="wrapper-hand" onClick={() => setOpenPop(true)}>
            <i class="fa-solid fa-dollar-sign"></i>
          </div>
        )}
        {/* <i class="fa-solid fa-comment-slash" onClick={(e) => closeChat(e)}></i> */}
        <TooltipMobileAction
          classNeed={"HandelTool"}
          title={
            <ul className="box-mobile-toltip list-unstyled">
              <li>
                <i
                  className="fa-solid fa-microphone"
                  style={{ fontSize: "15px" }}
                />
                <span>Request mic</span>
              </li>
              <li>
                <i className="fa-solid fa-video" style={{ fontSize: "14px" }} />
                <span>Request cam</span>
              </li>
            </ul>
          }
        >
          <i className="fa-solid fa-hand" />
        </TooltipMobileAction>
        <i
          className="fa-solid fa-heart ms-1 me-2"
          style={{ color: "#cb4545" }}
        />
      </form>

      <Dialog maxWidth="xs" open={openPop} onClose={() => setOpenPop(false)}>
        <CoinsPopUp Close={() => setOpenPop(false)} />
      </Dialog>
    </>
  );
}

export default SendMessageBox;
