import React from "react";
import { useDispatch } from "react-redux";
import {
  ManageControlStatus,
  ManageSideBarSize,
  ManageViwerTapMode,
  ToggleAutoMute,
  ToggleShowCoins,
  manageShowCover,
} from "../../store/theme";
import { useSelector } from "react-redux";
import { manageUsers } from "../../store/usersSlice";

function Test() {
  let { Users } = useSelector((state) => state.users);
  let dispatch = useDispatch();
  const ToggleStatus = (e) => {
    // if (e === "Host") {
    //   const mainUser = Users.find((user) => user.main) || Users[0];
    //   const updatedUsers = Users.map((user) => ({
    //     ...user,
    //     main: user.id === mainUser.id,
    //   }));
    //   dispatch(manageUsers(updatedUsers));
    // }
    dispatch(ManageControlStatus(e));
  };

  let ToggleModeViwer = (e) => {
    dispatch(ManageViwerTapMode(e));
  };
  let ShowVocer = (e) => {
    dispatch(manageShowCover({ status: true, type: e }));
  };
  let ToggleMute = (e) => {
    dispatch(ToggleAutoMute(true));
  };
  return (
    <div className="box-icon h-100 w-100 p-3 wrapper-side">
      <div
        className="d-flex"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <p
          style={{ fontSize: "1.6rem", cursor: "pointer" }}
          onClick={(e) => ToggleStatus("Moderator")}
        >
          Moderator
        </p>
        <p
          style={{ fontSize: "1.6rem", cursor: "pointer" }}
          onClick={(e) => ToggleStatus("Host")}
        >
          Host
        </p>
        <p
          style={{ fontSize: "1.6rem", cursor: "pointer" }}
          onClick={(e) => {
            ToggleStatus("Viewer");
          }}
        >
          Viewer
        </p>
      </div>

      <p
        style={{ fontSize: "1.6rem", cursor: "pointer" }}
        onClick={(e) => {
          dispatch(ToggleShowCoins(false));
        }}
      >
        Hidden Coins Icon
      </p>

      <hr style={{ background: "black", height: "2px" }} />
      <br />
      <p
        style={{ fontSize: "1.6rem", cursor: "pointer" }}
        onClick={(e) => ToggleModeViwer("quiz")}
      >
        Quiz Mode
      </p>
      <p
        style={{ fontSize: "1.6rem", cursor: "pointer" }}
        onClick={(e) => ToggleModeViwer("basic")}
      >
        Basic Mode
      </p>
      <p
        style={{ fontSize: "1.6rem", cursor: "pointer" }}
        onClick={() => ShowVocer("event")}
      >
        Show Cover
      </p>
      <p
        style={{ fontSize: "1.6rem", cursor: "pointer" }}
        onClick={() => ShowVocer("loading")}
      >
        Show Loading Cover
      </p>
      <p style={{ fontSize: "1.6rem", cursor: "pointer" }} onClick={ToggleMute}>
        Audio Mute
      </p>
    </div>
  );
}

export default Test;
