import React, { useEffect, useState } from "react";
import "./Mainpage.scss";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import SettingBar from "../../components/SettingBar/SettingBar";
import { useSelector } from "react-redux";
import UnderStage from "../../components/GuestsContainer/UnderStage";
import MobileBox from "../../components/MobileScreen/MobileBox";
import BoxVideo from "../../components/DisplayBox/BoxVideo";
import Layout from "../../components/Layout/Layout";
import { notifysuccess } from "../../Notification";

function MainPage() {
  let { status, sideBarStatus } = useSelector((state) => state.themeslice);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth || "100vw");

  useEffect(() => {
    const updateDimensions = () => {
      // Adding a slight delay to ensure correct dimensions are captured
      setTimeout(() => {
        const newHeight = window.innerHeight;
        const newWidth = window.innerWidth;
        setInnerHeight(newHeight);
        setInnerWidth("100vw");
        // notifysuccess(`Width: ${newWidth}px, Height: ${newHeight}px`);
      }, 100); // 100ms delay, you can adjust this value if necessary
    };

    updateDimensions(); // Call initially on component mount

    window.addEventListener("resize", updateDimensions); // Listen for resize

    return () => {
      window.removeEventListener("resize", updateDimensions); // Cleanup on unmount
    };
  }, []);

  console.log("sideBarStatus", sideBarStatus);

  return (
    <div
      className="Box-Project"
      style={{
        height: innerHeight,
        maxWidth: innerWidth,
        // width: innerWidth - 1,
      }}
    >
      {status !== "Viewer" && <Navbar />}
      <div className="main-box" style={{ gap: sideBarStatus ? "0px" : "3px" }}>
        {status !== "Viewer" && (
          <div className="box-new-layout d-none d-sm-flex open ">
            <div className="box-btns">
              <Layout />
            </div>
          </div>
        )}
        <div className="box-show row">
          <div
            className={`col-sm-12 box-wraperrr ${
              status === "Viewer" && "viwer-box"
            }`}
          >
            <BoxVideo />
            {status !== "Viewer" && (
              <div className="understage d-none d-sm-flex">
                <UnderStage />
              </div>
            )}
            {status !== "Viewer" && <MobileBox />}
          </div>
        </div>

        <Sidebar />
      </div>
      <SettingBar />
    </div>
  );
}

export default MainPage;
