import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import imgfacebook from "../../assits/facebook.png";
import imginsta from "../../assits//instagram.png";
import imgtiktok from "../../assits/tiktok.png";
import imgyoutube from "../../assits/youtube.png";
import img2 from "../../assits/Perfogram_Logo_TransparentBG.png";
import useClickOutside from "../../useClickOutside";
import { BoxTooltipTitle } from "../ToolTipsFolder/ToolTips";

function Navbar() {
  let [toggle, setToggle] = useState(false);
  let [value, setValue] = useState({ title: "Go Live", value: "Live", id: 1 });
  let listOfValue = [
    { title: "Go Live", value: "Live", id: 1 },
    { title: "Start Recording", value: "Recording", id: 2 },
  ];

  let domNode = useClickOutside(() => {
    setToggle(false);
  });

  let streamList = [
    { value: "FaceBookStream", img: imgfacebook },
    { value: "InstaStream", img: imginsta },
    { value: "YouTubeStream", img: imgyoutube },
    { value: "TiktokStream", img: imgtiktok },
    { value: "FaceBookStream", img: imgfacebook },
    { value: "InstaStream", img: imginsta },
    { value: "YouTubeStream", img: imgyoutube },
  ];

  let [toggleShow, setToggleShow] = useState(false);

  let ToggleSocial = useClickOutside(() => {
    setToggleShow(false);
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Initial width
  const [windowHeight, setWindowHeight] = useState(window.innerHeight); // Initial height

  const handleResize = () => {
    setTimeout(() => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }, 100); // 100ms delay, you can adjust this as needed
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderStreamList = () => {
    if (windowWidth <= 575) {
      // Show 3 items for small screens
      return streamList.slice(0, 3);
    } else if (
      windowWidth > 575 &&
      windowWidth <= 900 &&
      windowHeight > 200 &&
      windowHeight <= 500
    ) {
      // Show 4 items if width is between 575 and 900 and height is between 200 and 500
      return streamList.slice(0, 4);
    } else {
      // Show all items for larger screens
      return streamList;
    }
  };

  const renderDropdownStreamList = () => {
    if (windowWidth <= 575) {
      // Show the remaining items if there are more than 3 items
      return streamList.length > 3 ? streamList.slice(3) : [];
    } else if (
      windowWidth > 575 &&
      windowWidth <= 900 &&
      windowHeight > 200 &&
      windowHeight <= 500
    ) {
      // Show the remaining items if there are more than 4 items
      return streamList.length > 4 ? streamList.slice(4) : [];
    } else {
      // No additional dropdown items for larger screens
      return [];
    }
  };

  return (
    <div className="mian-nav">
      <div className="header-inner">
        <div className="img-list">
          <div className="img-box">
            <img src={img2} alt="..." />
          </div>
          <div className="list-icons">
            <div className="list-icons-two" ref={ToggleSocial}>
              {renderStreamList().map((e, index) => (
                <div key={index}>
                  <BoxTooltipTitle
                    backgroundColor={"rgba(27, 31, 41)"}
                    title={e.value}
                    placement="bottom"
                  >
                    <div className="img-icons">
                      <img src={e.img} alt="..." />
                    </div>
                  </BoxTooltipTitle>
                </div>
              ))}
              {renderDropdownStreamList().length > 0 && (
                <>
                  <div
                    className="seoncd-list"
                    style={{
                      height: toggleShow ? "100px" : "0px",
                      padding: toggleShow ? "4px 0px 4px" : "0px",
                      border: toggleShow ? "1px solid gray" : "none",
                    }}
                  >
                    {renderDropdownStreamList().map((e, index) => (
                      <BoxTooltipTitle
                        backgroundColor={"rgba(27, 31, 41)"}
                        title={e.value}
                        placement="bottom"
                      >
                        <div className="img-icons">
                          <img src={e.img} alt="..." />
                        </div>
                      </BoxTooltipTitle>
                    ))}
                  </div>
                  <div
                    className="more-streams"
                    style={{ paddingTop: "1.85px" }}
                    onClick={() => setToggleShow(!toggleShow)}
                  >
                    <BoxTooltipTitle
                      backgroundColor={"rgba(27, 31, 41)"}
                      title="View More Streams"
                      placement="bottom"
                    >
                      <i
                        className="fa-solid fa-circle-plus"
                        style={{ fontSize: "14px" }}
                      ></i>
                    </BoxTooltipTitle>
                  </div>
                </>
              )}
            </div>
            <div className="seoncd list"></div>
          </div>
        </div>
        <div className="details">
          <div className="title">
            <BoxTooltipTitle
              backgroundColor={"rgba(27, 31, 41)"}
              title="Number of viewers currently watching
Show duration elapsed"
              placement="bottom"
            >
              <div className="box-details">
                <i className="fa-solid fa-users" />
                <span>1.2k</span>
              </div>
            </BoxTooltipTitle>
            <div className="box-details">
              <i className="fa-regular fa-clock"></i>
              <span>18:23:00</span>
            </div>
            <div className="box-state">
              <span>{value?.value}</span>
            </div>
          </div>
          <div className="select-box" ref={domNode}>
            <span>{value?.title}</span>
            <i
              className="fa-solid fa-chevron-down"
              onClick={() => setToggle(!toggle)}
            ></i>
            <div
              className="box-menu"
              style={{ height: toggle ? "55px" : "0px" }}
            >
              {listOfValue &&
                listOfValue.map((e) => (
                  <span
                    key={e.id}
                    onClick={(item) => {
                      item.preventDefault();
                      item.stopPropagation();
                      setValue(e);
                      setToggle(false);
                    }}
                  >
                    {e.title}
                  </span>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
