import React, { Fragment, useState } from "react";
import "./MobileBox.scss";
import PrivateChat from "../ChatComponent/PrivateChat";
import { useDispatch, useSelector } from "react-redux";
import { ManageSettingBox, MangeStart } from "../../store/theme";
import {
  CamStatus,
  MicStatus,
  StopCameraMic,
  handleToggleCam,
  handleToggleMic,
} from "../../store/usersSlice.js";
import { BoxTooltipTitle, TooltipBoxAction } from "../ToolTipsFolder/ToolTips";
import Banners from "../BannersTap/Banners";
import Brand from "../BrandTap/Brand";
import UploadVideos from "../VideosTap/UploadVideos";
import Graphics from "../GraphicsTap/Graphics";
import Viwers from "../ViewerTap/Viwers";
import UnderStage from "../GuestsContainer/UnderStage";
import Layout from "../Layout/Layout";
import RequestsTap from "../RequestsTap/RequestsTap";
import Test from "../ViewerTap/Test";
import QuestionTap from "../QuestionTap/QuestionTap";
import PollsTap from "../PollsTap/PollsTap";
import AllActionTap from "../AllActionTap/AllActionTap";
import SocialMediaTap from "../SocialMediaTap/SocialMediaTap";
import { handleToggleCamMY, handleToggleMicMY } from "../../store/mySlice.js";
import { Dialog } from "@mui/material";
import InvitePeople from "../SettingBar/SettingModal/InvitePeople.jsx";
import ScreenSvg from "../Svgs/ScreenSvg.jsx";
import touchimg from "../../assits/touchImg.svg";
import touchimg2 from "../../assits/touchImg2.svg";
import touchimg3 from "../../assits/touch-press-click-svgrepo-com.svg";
import ManyUsers from "../Svgs/ManyUsers.jsx";
import BrandCat from "../Svgs/BrandCat.jsx";
import ShareCat from "../Svgs/ShareCat.jsx";
import BannerSvg from "../Svgs/BannerSvg.jsx";
import VideoSvg from "../Svgs/VideoSvg.jsx";
import ImageSvg from "../Svgs/ImageSvg.jsx";
import QuestionSvg from "../Svgs/QuestionSvg.jsx";
import PollSvg from "../Svgs/PollSvg.jsx";
import TouchICon from "../Svgs/TouchICon.jsx";
function MobileBox() {
  let [activeBox, setActiveBox] = useState("Guests");
  let [activeCat, setActiveCat] = useState("screen");

  let { settingBox, status } = useSelector((state) => state.themeslice);
  let { myData } = useSelector((state) => state.persistData.myData);
  let [openInvite, setOpenInvite] = useState(false);

  let { mic, stopMicCam, cam } = useSelector((state) => state.users);

  let dispatch = useDispatch();

  const HandleMicClick = (id, state) => {
    dispatch(handleToggleMicMY({ id, state }));
    dispatch(handleToggleMic({ id, state }));
  };

  const HandleCamClick = (id, state) => {
    dispatch(handleToggleCamMY({ id, state }));
    dispatch(handleToggleCam({ id, state }));
  };

  return (
    <div className="mobile-box d-block d-sm-none">
      <div
        className="list-actions"
        style={{
          border:
            status === "Host" ? "none" : "1px solid rgba(27, 31, 41, 0.2)",
        }}
      >
        <div
          className="d-flex w-100 "
          style={{
            marginBottom: "8px",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {status !== "Host" && (
            <div
              onClick={(e) => {
                setActiveCat("screen");
                setActiveBox("Guests");
              }}
              className={`box-  ${activeCat === "screen" && "active"}`}
            >
              <ScreenSvg
                backgroundColor={
                  activeCat === "screen" ? "rgb(10, 76, 199)" : "#fff"
                }
                main={activeCat === "screen" ? "#fff" : "#777"}
              />
            </div>
          )}

          {status !== "Host" && (
            <div
              onClick={(e) => {
                setActiveCat("touch");
                setActiveBox("Banners");
              }}
              className={`box-  ${activeCat === "touch" && "active"}`}
            >
              <TouchICon
                backgroundColor={
                  activeCat === "touch" ? "rgb(10, 76, 199)" : "#fff"
                }
                main={activeCat === "touch" ? "#fff" : "#777"}
              />
            </div>
          )}

          {status !== "Host" && (
            <div
              onClick={(e) => {
                setActiveCat("mainusers");
                setActiveBox("Chat");
              }}
              className={`box  ${activeCat === "mainusers" && "active"}`}
            >
              {" "}
              <ManyUsers
                backgroundColor={
                  activeCat === "mainusers" ? "rgb(10, 76, 199)" : "#fff"
                }
                main={activeCat === "mainusers" ? "#fff" : "#777"}
              />
            </div>
          )}
          {status !== "Host" && (
            <div
              onClick={(e) => {
                setActiveCat("brandcat");
                setActiveBox("Brand");
              }}
              className={`box  ${activeCat === "brandcat" && "active"}`}
            >
              <BrandCat
                backgroundColor={
                  activeCat === "brandcat" ? "rgb(10, 76, 199)" : "#fff"
                }
                main={activeCat === "brandcat" ? "#fff" : "#777"}
                toggle={activeCat === "brandcat" ? "#fff" : "#fff"}
              />
            </div>
          )}

          {status !== "Host" && (
            <div
              onClick={(e) => {
                setActiveCat("sharecat");
                setActiveBox("SocialMedia");
              }}
              className={`box  ${activeCat === "sharecat" && "active"}`}
            >
              <ShareCat
                backgroundColor={
                  activeCat === "sharecat" ? "rgb(10, 76, 199)" : "#fff"
                }
                main={activeCat === "sharecat" ? "#fff" : "#777"}
              />
            </div>
          )}

          {status === "Host" && (
            <div
              className="list-actions"
              style={{
                borderBottom:
                  status === "Host" && activeBox === "Chat"
                    ? "none"
                    : "1px solid rgba(27, 31, 41, 0.2)",
              }}
            >
              <div
                className="d-flex w-100 "
                style={{
                  marginBottom: "8px",
                  justifyContent: "space-around",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  onClick={(e) => {
                    setActiveCat("screen");
                    setActiveBox("Guests");
                  }}
                  className={`box-  ${activeCat === "screen" && "active"}`}
                >
                  <ScreenSvg
                    backgroundColor={
                      activeCat === "screen" ? "rgb(10, 76, 199)" : "#fff"
                    }
                    main={activeCat === "screen" ? "#fff" : "#777"}
                  />
                </div>

                <div
                  onClick={(e) => {
                    setActiveCat("");
                    setActiveBox("Chat");
                  }}
                  className={`container-icon ${
                    activeBox === "Chat" ? "active" : ""
                  }`}
                >
                  <i
                    className="fa-regular fa-comment-dots"
                    style={{ fontSize: "27px" }}
                  />
                </div>
                <div
                  onClick={(e) => {
                    setActiveCat("");
                    setActiveBox("Viewer");
                  }}
                  className={`container-icon ${
                    activeBox === "Viewer" ? "active" : ""
                  }`}
                >
                  <i
                    className="fa-solid fa-users"
                    style={{ fontSize: "27px" }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {activeCat === "touch" && (
        <div className="list-actions">
          <div
            className="d-flex w-100 "
            style={{
              marginBottom: "8px",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div onClick={(e) => setActiveBox("Banners")}>
              <BannerSvg
                sidebar
                backgroundColor={
                  activeBox === "Banners"
                    ? "rgb(10, 76, 199)"
                    : "rgb(79, 84, 97)"
                }
              />
            </div>
            <div onClick={(e) => setActiveBox("Videos")}>
              <VideoSvg
                color={activeBox === "Videos" ? "active" : "#575d68"}
                miancolor={
                  activeBox === "Videos" ? "#ffffff" : "rgb(79, 84, 97)"
                }
                backgroundColor={
                  activeBox === "Videos"
                    ? "rgb(10, 76, 199)"
                    : "rgb(79, 84, 97)"
                }
              />
            </div>
            <div onClick={(e) => setActiveBox("Graphics")}>
              <ImageSvg
                backgroundColor={
                  activeBox === "Graphics"
                    ? "rgb(10, 76, 199)"
                    : "rgb(79, 84, 97)"
                }
              />
            </div>
            <div onClick={(e) => setActiveBox("Questions")}>
              <QuestionSvg
                backgroundColor={
                  activeBox === "Questions"
                    ? "rgb(10, 76, 199)"
                    : "rgb(79, 84, 97)"
                }
              />
            </div>
            <div onClick={(e) => setActiveBox("Polls")}>
              <PollSvg
                backgroundColor={
                  activeBox === "Polls" ? "rgb(10, 76, 199)" : "rgb(79, 84, 97)"
                }
              />
            </div>
            <div
              className="f-blod"
              style={{
                borderRight: "3px solid gray",
                width: "2px",
                height: "100%",
                minHeight: "30px",

                //                 border-right: ;
                // width: 2px;
                // height: 100%;
                // min-height: 30px;
              }}
            ></div>

            <div
              onClick={(e) => setActiveBox("AllAction")}
              className={`container-icon ${
                activeBox === "AllAction" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-border-all"></i>
            </div>
          </div>
        </div>
      )}

      {activeCat === "mainusers" && (
        <div className="list-actions">
          <div
            className="d-flex w-100 "
            style={{
              marginBottom: "8px",
              justifyContent: "space-evenly",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div
              onClick={(e) => setActiveBox("Chat")}
              className={`container-icon ${
                activeBox === "Chat" ? "active" : ""
              }`}
            >
              <i
                className="fa-regular fa-comment-dots"
                style={{ fontSize: "27px" }}
              />
            </div>
            <div
              onClick={(e) => setActiveBox("Viewer")}
              className={`container-icon ${
                activeBox === "Viewer" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-users" style={{ fontSize: "27px" }} />
            </div>
            <div
              onClick={(e) => setActiveBox("Requests")}
              className={`container-icon ${
                activeBox === "Requests" ? "active" : ""
              }`}
            >
              <i className="fa-solid fa-hand" style={{ fontSize: "27px" }} />
            </div>
          </div>
        </div>
      )}

      <Fragment>
        <div className="box-result">
          <div className="wrapper-result">
            <div className="box-content">
              {activeBox === "Banners" ? (
                <Banners />
              ) : activeBox === "Brand" ? (
                <Brand />
              ) : activeBox === "Videos" ? (
                <UploadVideos />
              ) : activeBox === "Graphics" ? (
                <Graphics />
              ) : activeBox === "Chat" ? (
                <PrivateChat />
              ) : activeBox === "Viewer" ? (
                <Viwers />
              ) : activeBox === "Requests" ? (
                <RequestsTap />
              ) : activeBox === "Questions" ? (
                <QuestionTap />
              ) : activeBox === "Polls" ? (
                <PollsTap />
              ) : activeBox === "AllAction" ? (
                <AllActionTap />
              ) : activeBox === "SocialMedia" ? (
                <SocialMediaTap />
              ) : activeBox === "Test" ? (
                <Test />
              ) : activeBox === "Guests" ? (
                <div className="mobil-understage">
                  <div className="box-btns ">
                    <Layout />
                  </div>
                  <div className="understage">
                    <UnderStage />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="action-settings">
          <div className="box-action">
            {/*Box For Mic */}
            {myData && myData.activeMic ? (
              <div
                className="box-icon"
                onClick={(e) => {
                  HandleMicClick(myData.id, false);
                }}
              >
                <BoxTooltipTitle
                  backgroundColor={"rgba(27, 31, 41)"}
                  title="Mute Microphone"
                  placement="top"
                >
                  <i className="fa-solid fa-microphone" />
                </BoxTooltipTitle>
                <span>Mute</span>
              </div>
            ) : (
              <div
                className="box-icon active"
                onClick={(e) => {
                  HandleMicClick(myData.id, true);
                }}
              >
                <BoxTooltipTitle
                  backgroundColor={"rgba(27, 31, 41)"}
                  title="Unmute Microphone"
                  placement="top"
                >
                  <i className="fa-solid fa-microphone-slash" />
                </BoxTooltipTitle>
                <span>Unmute</span>
              </div>
            )}

            {/*Box For cam */}

            {myData && myData.activeCam ? (
              <div
                className="box-icon"
                onClick={(e) => {
                  HandleCamClick(myData.id, false);
                }}
              >
                <BoxTooltipTitle
                  backgroundColor={"rgba(27, 31, 41)"}
                  title="Turn off Camera"
                  placement="top"
                >
                  <i className="fa-solid fa-video" />
                </BoxTooltipTitle>
                <span>Stop Cam</span>
              </div>
            ) : (
              <div
                className="box-icon active"
                onClick={(e) => {
                  HandleCamClick(myData.id, true);
                }}
              >
                <BoxTooltipTitle
                  backgroundColor={"rgba(27, 31, 41)"}
                  title="Turn on Camera"
                  placement="top"
                >
                  <i className="fa-solid fa-video-slash" />
                </BoxTooltipTitle>
                <span>Start Cam</span>
              </div>
            )}

            <div
              className="box-icon"
              onClick={() => dispatch(ManageSettingBox(true))}
            >
              <i className="fa-solid fa-gear" />
              <span>Settings</span>
            </div>

            <TooltipBoxAction
              title={
                <ul className="box-action-toltip list-unstyled">
                  {/***   <li
                    className=""
                    onClick={() => dispatch(ManageSettingBox(true))}
                  >
                    <i className="fa-solid fa-gear" /> <span>Settings</span>
                  </li>
                  <li
                    className=""
                    onClick={() => {
                      setActiveBox("Viewer");
                    }}
                  >
                    <i className="fa-solid fa-users" /> <span>Viewer</span>
                  </li> */}
                  <li className="" onClick={() => setOpenInvite(true)}>
                    <i class="fa-solid fa-share"></i>
                    <span>Share</span>
                  </li>
                  <li className="" onClick={() => dispatch(MangeStart(true))}>
                    <i
                      className="fa-solid fa-circle-xmark"
                      style={{ color: "red" }}
                    />
                    <span>Leave Studio</span>
                  </li>
                  <li className="" onClick={() => setActiveBox("Test")}>
                    <i class="fa-solid fa-share"></i>
                    <span>Test</span>
                  </li>
                </ul>
              }
              status={settingBox || openInvite || activeBox === "Test"}
            >
              <div className="box-icon">
                <i className="fa-solid fa-ellipsis" />
                <span>More</span>
                <label>2</label>
              </div>
            </TooltipBoxAction>
            {/*
             <div className="box-icon">
              <i className="fa-solid fa-user-plus" />
              <span>Invite</span>
            </div>
        */}
            {/*
            <div className="box-icon">
              <i
                className="fa-solid fa-circle-xmark"
                style={{ color: "red" }}
              />
              <span style={{ fontSize: "1.07rem", marginTop: "5px" }}>
                Leave Studio
              </span>
            </div>
    */}
          </div>
        </div>
      </Fragment>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={openInvite}
        onClose={() => setOpenInvite(false)}
      >
        <InvitePeople setOpenInvite={setOpenInvite} />
      </Dialog>
    </div>
  );
}

export default MobileBox;
