import React, { Fragment, useEffect, useState } from "react";
import "./UnderStage.scss";
import vi1 from "../../assits/presenter.jpg";
// import vi1 from "../../assits/videoBack.mp4";
import { BoxTooltipTitle, TooltipBoxAction } from "../ToolTipsFolder/ToolTips";
import { Box, Dialog, Slider, Stack } from "@mui/material";
import EditName from "./Modal/EditName";
import EditAvatar from "./Modal/EditAvatar";
import { useDispatch, useSelector } from "react-redux";
import { getLayout } from "../../store/theme";

import { handleToggleCamMic, handleToggleMic } from "../../store/usersSlice.js";
import KickFromStudion from "./Modal/KickFromStudion";
import BanFromStudion from "./Modal/BanFromStudion";
import { manageUsers } from "../../store/usersSlice";
import {
  handleToggleCamMicMY,
  handleToggleMicMY,
} from "../../store/mySlice.js";
import { VolumeDown, VolumeUp } from "@mui/icons-material";
import hightValum from "../../assits/hight-volume.png";
import midValum from "../../assits/mid-volume.png";
import lowtValum from "../../assits/low-volume.png";
import noValum from "../../assits/mute.png";
import { DndProvider, useDrag, useDragLayer, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { isMobile } from "react-device-detect";
import { TouchBackend } from "react-dnd-touch-backend";
import { usePreview } from "react-dnd-preview";

function UnderStage() {
  let { Users, mic, stopMicCam, cam } = useSelector((state) => state.users);
  let { myData } = useSelector((state) => state.persistData.myData);
  let { status, sideBarStatus } = useSelector((state) => state.themeslice);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let [manage, setManage] = useState("");
  let ManageModal = (e) => {
    setManage(e);
    if (e) {
      handleOpen();
    }
  };

  const [value, setValue] = useState(100);

  const getImageSrc = (value) => {
    if (value <= 0) return noValum;
    if (value >= 1 && value <= 35) return lowtValum;
    if (value >= 36 && value <= 70) return midValum;
    if (value >= 71) return hightValum;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //function for mange stop cam and mic from toolTip
  let MangeStopMicCam = (id, state) => {
    dispatch(handleToggleCamMic({ id, state }));
    dispatch(handleToggleCamMicMY({ id, state }));
  };

  //function for add to stage or remove
  const handleStateLogic = (id, type, state) => {
    let updatedData;
    let potentialMainUser;

    let usersInStage = Users.filter((e) => e.inStage === true);
    if (type) {
      updatedData = Users.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            inStage: type,
            main: usersInStage.length === 0 ? true : false,
          }; // Toggle inStage
        }
        return item; // Return unchanged for other objects
      });
    } else {
      if (state) {
        updatedData = Users.map((item) => {
          if (item.id === id) {
            // Update clicked element: inStage and main to false
            return { ...item, inStage: type, main: false };
          } else if (item.inStage === true && item.id !== id) {
            // Store a potential main user (only for the first eligible user)
            if (!potentialMainUser) {
              potentialMainUser = item;
            }
            // Return unchanged for now
            return item;
          } else {
            // No change needed for other users
            return item;
          }
        });

        // Update main to true for the potential main user (if found)
        if (potentialMainUser) {
          updatedData = updatedData.map((item) =>
            item.id === potentialMainUser.id ? { ...item, main: true } : item
          );
        }
      } else {
        updatedData = Users.map((item) => {
          if (item.id === id) {
            return { ...item, inStage: false };
          } else {
            return item;
          }
        });
      }
    }
    potentialMainUser = false;
    dispatch(manageUsers(updatedData));
  };

  //function for set user main  or not
  const handleActiveLogic = (id, state) => {
    const updatedData = Users.map((item) => {
      return {
        ...item,
        main: item.id === id ? state : false,
        inStage: item.id === id ? state : item.inStage,
      }; // Toggle inStage
    });
    // setData(updatedData);
    // dispatch(getLayout("Single"));
    dispatch(manageUsers(updatedData));
  };

  let dispatch = useDispatch();

  const HandleMicClick = (id, state) => {
    if (id === myData.id) {
      dispatch(handleToggleMicMY({ id, state }));
    }
    dispatch(handleToggleMic({ id, state }));
  };

  const RemoveAssist = (IDD) => {
    console.log("IDDIDIDI", IDD);
    const videoIndex = Users.findIndex((ele) => ele.id === IDD);

    if (videoIndex !== -1) {
      // Video found, toggle inStage property
      let updateValue = Users.filter((ele) => ele.id !== IDD);

      // Get the first item that has inStage: true
      const firstInStageIndex = updateValue.findIndex(
        (ele) => ele.inStage === true
      );

      if (firstInStageIndex !== -1) {
        // Create a new array with the updated main property for the first inStage item
        updateValue = updateValue.map((user, index) =>
          index === firstInStageIndex ? { ...user, main: true } : user
        );
      }

      // Dispatch the updated Users array
      dispatch(manageUsers(updateValue));
    }
  };

  const filteredUsers =
    status === "Host"
      ? Users.filter((user) => user.main).length > 0
        ? Users.filter((user) => user.main)
        : [Users[0]]
      : Users;

  const checkIfTrue = (e) => {
    if (e.length === 1) {
      if (e[0].main) {
        return true;
      } else {
        return false;
      }
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Initial width
  const [windowHeight, setWindowHeight] = useState(window.innerHeight); // Initial width

  useEffect(() => {
    const updateDimensions = () => {
      // Adding a slight delay to ensure correct dimensions are captured
      setTimeout(() => {
        const newHeight = window.innerHeight;
        const newWidth = window.innerWidth;
        setWindowWidth(newWidth);
        setWindowHeight(newHeight);
      }, 50); // 100ms delay, you can adjust this value if necessary
    };

    updateDimensions(); // Call initially on component mount

    window.addEventListener("resize", updateDimensions); // Listen for resize

    return () => {
      window.removeEventListener("resize", updateDimensions); // Cleanup on unmount
    };
  }, []);

  const getBoxDimensions = () => {
    if (
      windowWidth >= 576 &&
      windowWidth <= 804 &&
      windowHeight >= 100 &&
      windowHeight <= 500 &&
      !sideBarStatus
    ) {
      return { width: "100px", height: "60px" };
    } else {
      if (windowWidth >= 576 && windowWidth <= 900 && !sideBarStatus) {
        return { width: "120px", height: "67.5px" };
      }
      return { width: "166px", height: "94px" };
    }
  };

  const SortableUserItem = ({ index, user, moveUser, children, style }) => {
    const [{ isDragging }, ref, preview] = useDrag({
      type: "ItemType",
      item: { index, src: user.src, data: user },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      options: {
        preview: true, // Ensure that the preview is enabled for touch devices
      },
    });

    // Configure the drop logic
    const [{ isOver }, drop] = useDrop({
      accept: "ItemType",
      drop: (item) => {
        if (item.index !== index) {
          moveUser(item.index, index, item);
          item.index = index; // Update the dragged item's index
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    });

    // Combine refs for drag and drop
    const combinedRef = (node) => ref(drop(node));

    return (
      <div
        className={`box-user ${isDragging ? "dragging" : ""} ${
          isOver ? "drag-over" : ""
        }`} // Add class based on isOver
        ref={combinedRef} // Combine drag and drop refs
        style={{
          ...style,
          opacity: isDragging ? 0.5 : 1, // Visual feedback for drag effect
          cursor: isDragging ? "grabbing" : "grab", // Change the cursor when dragging
          border: isOver ? "2px solid blue" : "none", // Add border when dragging over
        }}
      >
        {children}
      </div>
    );
  };

  const moveUser = (fromIndex, toIndex, data) => {
    console.log("datadatadata", data);
    if (fromIndex === toIndex) return;

    // Clone the current Users array
    const updatedUsers = Array.from(Users);

    // Find the user IDs at fromIndex and toIndex in usersInStage
    const fromUserId = Users[fromIndex].id;
    const toUserId = Users[toIndex].id;

    // Find the corresponding indexes in the main Users array by comparing ID
    const fromUserIndex = updatedUsers.findIndex(
      (user) => user.id === fromUserId
    );
    const toUserIndex = updatedUsers.findIndex((user) => user.id === toUserId);

    // Swap the items in the main Users array if both indexes are valid
    if (fromUserIndex !== -1 && toUserIndex !== -1) {
      // Create new objects for the users being swapped to avoid mutation
      const fromUser = { ...updatedUsers[fromUserIndex] };
      const toUser = { ...updatedUsers[toUserIndex] };

      // Check if the first item has `main: true`
      const isMain = fromUser.main;

      // Swap the users
      updatedUsers[fromUserIndex] = toUser;
      updatedUsers[toUserIndex] = fromUser;

      // If the first item had `main: true`, give it to the second item
      if (isMain && updatedUsers[fromUserIndex].inStage) {
        updatedUsers[toUserIndex].main = false;
        updatedUsers[fromUserIndex].main = true;
      }

      // Dispatch only the updated list
      dispatch(manageUsers(updatedUsers));
    }
  };

  // const moveUser = (fromIndex, toIndex, data) => {
  //   console.log("datadatadata", data);
  //   if (fromIndex === toIndex) return;

  //   // Clone the current Users array
  //   const updatedUsers = Array.from(Users);

  //   // Find the user IDs at fromIndex and toIndex in usersInStage
  //   const fromUserId = Users[fromIndex].id;
  //   const toUserId = Users[toIndex].id;

  //   // Find the corresponding indexes in the main Users array by comparing ID
  //   const fromUserIndex = updatedUsers.findIndex(
  //     (user) => user.id === fromUserId
  //   );
  //   const toUserIndex = updatedUsers.findIndex((user) => user.id === toUserId);

  //   // Swap the items in the main Users array if both indexes are valid
  //   if (fromUserIndex !== -1 && toUserIndex !== -1) {
  //     // Create new objects for the users being swapped to avoid mutation
  //     const fromUser = { ...updatedUsers[fromUserIndex] };
  //     const toUser = { ...updatedUsers[toUserIndex] };

  //     // Check if the first item has `main: true`
  //     const isMain = fromUser.main;

  //     // Swap the users
  //     updatedUsers[fromUserIndex] = toUser;
  //     updatedUsers[toUserIndex] = fromUser;

  //     // If the first item had `main: true`, give it to the second item
  //     if (isMain) {
  //       console.log("isMainisMain", toUserIndex);
  //       console.log("fromUserIndexfromUserIndex", fromUserIndex);
  //       updatedUsers[toUserIndex].main = false;
  //       updatedUsers[fromUserIndex].main = true;
  //     }

  //     // Dispatch only the updated list
  //     dispatch(manageUsers(updatedUsers));
  //   }
  // };

  const CustomDragPreview = ({ width, height }) => {
    const { display, itemType, item, style } = usePreview();
    if (!display) {
      return null;
    }

    return (
      <div
        style={{
          ...style,
          backgroundColor: "rgba( 0,0, 0, 0.1)",
          opacity: 0.4,
          zIndex: 9999999,
          width: width,
          height: height,
        }}
      >
        {/* Custom preview content goes here */}
        <img
          src={item.src}
          style={{
            width: width,
            height: height,
            opacity: 0.2,
            backgroundColor: "rgba( 0,0, 0, 0.1)",
            zIndex: -1,
          }}
          alt="Preview"
        />
      </div>
    );
  };

  return (
    <DndProvider
      backend={isMobile ? TouchBackend : HTML5Backend}
      options={{ enableMouseEvents: true }}
    >
      <div className="wrpaer-understage">
        <div
          className="box-conatiner-2 d-flex "
          style={{
            justifyContent:
              filteredUsers.length === 1 ? "flex-start" : "space-around",
          }}
        >
          {/*Box Fro user details and image */}
          {filteredUsers &&
            filteredUsers.map((e, index) => {
              const { width, height } = getBoxDimensions();
              return (
                <SortableUserItem
                  key={e.id}
                  index={index}
                  user={e}
                  moveUser={moveUser}
                  style={{
                    width: width,
                    height: height,
                  }}
                >
                  <>
                    {isMobile && (
                      <CustomDragPreview width={width} height={height} />
                    )}

                    {e.activeCam ? (
                      <div className="box-img">
                        <img
                          src={e.src ? e.src : vi1}
                          alt="..."
                          style={{ objectFit: e.isAssits ? "cover" : "" }}
                        />
                      </div>
                    ) : (
                      <div className="box-avatar">
                        <div className="wraper-avatar">
                          <svg
                            focusable={false}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="#4F5461"
                            className="styled__StyledAccountCircle-sc-a7234v-4 dBFZjM"
                          >
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
                            <path d="M0 0h24v24H0z" fill="none" />
                          </svg>
                        </div>
                      </div>
                    )}

                    <div className={`title ${e.inStage && "Remove"}`}>
                      {/* <i className="fa-solid fa-user" /> */}
                      <span>{e.name}</span>
                    </div>

                    <div className="big-control">
                      <div
                        className={`box-user-avatar ${e.main && "mainActive"}`}
                        onClick={() => handleActiveLogic(e.id, true)}
                      >
                        <div className="avatar-icon">
                          <div className={`wrapper`}>
                            <i class="fa-solid fa-thumbtack"></i>
                          </div>
                        </div>
                      </div>

                      <div
                        className="control"
                        style={
                          e.isAssits && e.type !== "ImgList"
                            ? {
                                flex: "auto",
                                maxWidth: "60%",
                                padding: "0px 5px 0px 3px",
                              }
                            : {}
                        }
                      >
                        {e.isAssits && e.type === "ImgList" ? (
                          ""
                        ) : e.isAssits ? (
                          <Box sx={{ width: "100%" }}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              paddingLeft={"5px"}
                              sx={{
                                "& .MuiSlider-root": {
                                  padding: "0px",
                                },
                              }}
                            >
                              <Slider
                                size="medium"
                                defaultValue={70}
                                aria-label="Small"
                                valueLabelDisplay="auto"
                                onChange={handleChange}
                                sx={{
                                  color: "gray",
                                  padding: "0",
                                  marginBottom: "0px",
                                  margin: "0px",
                                  width: "80%",
                                  marginRight: "9px",
                                  "& .MuiSlider-thumb": {
                                    width: "15px",
                                    height: "15px",
                                    background: "#fff",
                                    border: "1px solid gray",
                                  },
                                }}
                              />
                              {
                                <img
                                  src={getImageSrc(value)}
                                  alt="..."
                                  style={{
                                    width: "16px",
                                    marginLeft: "4px",
                                    marginRight: "3px",
                                  }}
                                />
                              }
                            </Stack>
                          </Box>
                        ) : (
                          <BoxTooltipTitle
                            placement="top"
                            backgroundColor="rgb(27, 31, 41)"
                            title={
                              <span className="titleWithButtons">Mute mic</span>
                            }
                          >
                            {/* <i className="fa-solid fa-microphone-slash" /> */}
                            {e.activeMic ? (
                              <i
                                className="fa-solid fa-microphone"
                                onClick={() => HandleMicClick(e.id, false)}
                              />
                            ) : (
                              <i
                                className="fa-solid fa-microphone-slash"
                                onClick={() => HandleMicClick(e.id, true)}
                              />
                            )}
                            {/*
                        e.main ? (
                        mic ? (
                          <i
                            className="fa-solid fa-microphone"
                            onClick={(tt) => {
                              ToggleMic(index, e.main);
                            }}
                          />
                        ) : (
                          <i
                            className="fa-solid fa-microphone-slash"
                            onClick={(tt) => {
                              ToggleMic(index, e.main);
                            }}
                          />
                        )
                      ) : statusMic && statusMic.some((e) => e === index) ? (
                        <i
                          className="fa-solid fa-microphone-slash"
                          onClick={(tt) => {
                            ToggleMic(index, e.main);
                          }}
                        />
                      ) : (
                        <i
                          className="fa-solid fa-microphone"
                          onClick={(tt) => {
                            ToggleMic(index, e.main);
                          }}
                        />
                      )
                      */}
                          </BoxTooltipTitle>
                        )}
                        {/*Box For Actions Like Edit name and avatar */}
                        <TooltipBoxAction
                          status={open}
                          placement={"top"}
                          title={
                            <ul className="box-action-toltip list-unstyled">
                              <li
                                className=""
                                onClick={(e) => {
                                  ManageModal("Editname");
                                }}
                              >
                                <i className="fa-solid fa-pen" />
                                <span>Edit name </span>
                              </li>

                              {myData && myData.id === e.id ? (
                                <Fragment>
                                  <li
                                    className=""
                                    onClick={(e) => {
                                      ManageModal("EditAvatar");
                                    }}
                                  >
                                    <i className="fa-solid fa-circle-user" />
                                    <span>Edit audio avatar</span>
                                  </li>

                                  <li
                                    className=""
                                    onClick={(z) => {
                                      MangeStopMicCam(e.id, false);
                                    }}
                                  >
                                    <i className="fa-solid fa-trash" />
                                    <span>Stop mic/cam</span>
                                  </li>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  <li className="">
                                    <i className="fa-solid fa-gear" />
                                    <span>Edit settings</span>
                                  </li>

                                  <li
                                    className=""
                                    onClick={(e) => {
                                      ManageModal("Kick");
                                    }}
                                  >
                                    <i className="fa-solid fa-circle-xmark" />
                                    <span>Remove from studio</span>
                                  </li>

                                  {/* <li
                                  className=""
                                  style={{ color: "rgb(191, 31, 2)" }}
                                  onClick={(e) => {
                                    ManageModal("Ban");
                                  }}
                                >
                                  <i
                                    style={{ color: "rgb(191, 31, 2)" }}
                                    className="fa-solid fa-ban"
                                  />
                                  <span style={{ color: "rgb(191, 31, 2)" }}>
                                    Ban from studio
                                  </span>
                                </li> */}
                                </Fragment>
                              )}
                            </ul>
                          }
                        >
                          <i className="fa-solid fa-ellipsis-vertical" />
                        </TooltipBoxAction>
                      </div>
                    </div>

                    {/*Add To Stage Button */}
                    {e.inStage ? (
                      <div
                        className="AddBtn Remove"
                        onClick={(z) =>
                          e.isAssits
                            ? RemoveAssist(e.id)
                            : handleStateLogic(e.id, false, e.main)
                        }
                      >
                        <text>{e.isAssits ? "Hide" : "Remove"}</text>
                      </div>
                    ) : (
                      <div
                        className="AddBtn"
                        onClick={(z) => handleStateLogic(e.id, true, e.main)}
                      >
                        <text>Add to stage</text>
                      </div>
                    )}
                  </>
                </SortableUserItem>
              );
            })}
          {status === "Host" && (
            <div
              className="box-label"
              style={{
                border: checkIfTrue(filteredUsers)
                  ? "1px solid rgb(20, 97, 225)"
                  : "1px solid rgb(79, 84, 97)",
              }}
            >
              <div
                className="box-icon"
                style={{
                  background: checkIfTrue(filteredUsers)
                    ? " rgb(20, 97, 225)"
                    : " rgb(79, 84, 97)",
                }}
              >
                {checkIfTrue(filteredUsers) ? (
                  <i class="fa-solid fa-image-portrait"></i>
                ) : (
                  <i class="fa-solid fa-door-open"></i>
                )}
              </div>
              <div className="box-title">
                <p className="header">
                  {checkIfTrue(filteredUsers)
                    ? "You're in the show!"
                    : "You're backstage"}
                </p>
                <p className="title">
                  {checkIfTrue(filteredUsers)
                    ? "Everyone can see and hear you"
                    : "Only the host can see you. The host may add you to the stage at any time. Be ready!"}
                </p>
              </div>
            </div>
          )}
          <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
            <div className="modal-box">
              {manage === "Editname" ? (
                <EditName Close={handleClose} />
              ) : manage === "Kick" ? (
                <KickFromStudion Close={handleClose} />
              ) : manage === "Ban" ? (
                <BanFromStudion Close={handleClose} />
              ) : (
                <EditAvatar Close={handleClose} />
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </DndProvider>
  );
}

export default UnderStage;
