import React from "react";

const QuestionSvg = ({ miancolor, backgroundColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      zoomAndPan="magnify"
      viewBox="0 0 67.5 56.249996"
      height="75"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="3338446569">
          <path
            d="M 0.199219 0 L 66.808594 0 L 66.808594 55.511719 L 0.199219 55.511719 Z M 0.199219 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="5e790a3ea5">
          <path
            d="M 7.597656 0 L 59.40625 0 C 63.496094 0 66.808594 3.3125 66.808594 7.402344 L 66.808594 48.109375 C 66.808594 52.195312 63.496094 55.511719 59.40625 55.511719 L 7.597656 55.511719 C 3.511719 55.511719 0.199219 52.195312 0.199219 48.109375 L 0.199219 7.402344 C 0.199219 3.3125 3.511719 0 7.597656 0 Z M 7.597656 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="1af8b9c105">
          <path
            d="M 21 8.726562 L 45.992188 8.726562 L 45.992188 46.472656 L 21 46.472656 Z M 21 8.726562 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#3338446569)">
        <g clip-path="url(#5e790a3ea5)">
          <path
            fill={backgroundColor}
            d="M 0.199219 0 L 66.765625 0 L 66.765625 55.511719 L 0.199219 55.511719 Z M 0.199219 0 "
            fill-opacity="1"
            fill-rule="nonzero"
          />
          <path
            stroke-linecap="butt"
            transform="matrix(0.740132, 0, 0, 0.740132, 0.197371, -0.00000123359)"
            fill="none"
            stroke-linejoin="miter"
            d="M 9.998609 0.00000166672 L 79.997778 0.00000166672 C 85.523612 0.00000166672 89.999168 4.475557 89.999168 10.001391 L 89.999168 65.001116 C 89.999168 70.521671 85.523612 75.002505 79.997778 75.002505 L 9.998609 75.002505 C 4.478053 75.002505 0.00249702 70.521671 0.00249702 65.001116 L 0.00249702 10.001391 C 0.00249702 4.475557 4.478053 0.00000166672 9.998609 0.00000166672 Z M 9.998609 0.00000166672 "
            stroke={backgroundColor}
            stroke-width="6"
            stroke-opacity="1"
            stroke-miterlimit="4"
          />
        </g>
      </g>
      <g clip-path="url(#1af8b9c105)">
        <path
          fill="#ffffff"
          d="M 33.722656 8.726562 C 25.863281 8.726562 21.40625 13.132812 21.277344 19.507812 L 21.277344 19.824219 L 28.21875 19.824219 L 28.21875 19.507812 C 28.371094 16.691406 30.316406 14.875 33.261719 14.875 C 36.179688 14.875 38.125 16.5625 38.125 18.941406 C 38.125 21.324219 37.128906 22.554688 33.824219 24.523438 C 30.292969 26.597656 28.882812 28.902344 29.214844 33 L 29.253906 34.09375 L 36.050781 34.09375 L 36.050781 33.050781 C 36.050781 30.59375 37 29.339844 40.40625 27.367188 C 44.015625 25.242188 45.882812 22.554688 45.882812 18.714844 C 45.882812 12.796875 41.046875 8.726562 33.722656 8.726562 Z M 32.800781 38.847656 C 30.195312 38.847656 28.570312 40.359375 28.570312 42.824219 C 28.570312 45.265625 30.195312 46.773438 32.800781 46.773438 C 35.402344 46.773438 37.007812 45.265625 37.007812 42.824219 C 37.007812 40.359375 35.40625 38.847656 32.800781 38.847656 Z M 32.800781 38.847656 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  );
};

export default QuestionSvg;
