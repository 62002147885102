import React, { Fragment, useEffect, useState } from "react";
import {
  BoxTooltipTitle,
  TooltipBoxAction,
  TooltipMobileAction,
} from "../ToolTipsFolder/ToolTips";
import { useDispatch, useSelector } from "react-redux";
import img1 from "../../assits/img-mobile.jpg";
import img2 from "../../assits/img-mobile_2.jpg";
import { Dialog } from "@mui/material";
import FileUpload from "../Drag&DropFile/FileUpload";
import {
  ManageDeleteBackground,
  addBackground,
  getBackGruond,
} from "../../store/backgroundSlice";
import ToolSvg from "../Svgs/ToolTipSvg";
import { randomPassword } from "../../RandomID";

function BrandBackground() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // let { cover } = useSelector((state) => state.themeslice);

  let { backgrounds } = useSelector((state) => state.backgrounds);
  console.log("backgrounds", backgrounds);
  // const [uploadedBackground, setUploadedBackground] = useState([
  //   {
  //     landscape: { name: "test landscape", src: img1, type: "img" },
  //     portrait: { name: "test portrait ", src: img2, type: "img" },
  //   },
  // ]);
  let [active, setActive] = useState(null);
  let dispatch = useDispatch();

  const handleSelectedFile = (file) => {
    // setUploadedBackground((prev) => [...prev, file]);
    dispatch(addBackground({ ...file, id: randomPassword(2) }));
  };

  let handleBackground = (e) => {
    dispatch(getBackGruond(e));
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Initial width
  const handleResize = () => {
    setTimeout(() => {
      setWindowWidth(window.innerWidth);
    }, 100); // 100ms delay, you can adjust this as needed
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Fragment>
      <h2 className="accordion-header">
        <div className="accordion-button">
          <text className="text-acco">Background</text>
          {windowWidth ? (
            <TooltipMobileAction
              backgroundColor={"rgba(27, 31, 41)"}
              title={
                <div className="toltip-title-line">
                  <span>
                    Use an image, video, or GIF as your background. Note that
                    the background may not appear in all layouts.
                  </span>{" "}
                  <br /> <br /> <span>Ideal dimensions: 1280 x 720 pixels</span>{" "}
                  <br /> <br /> <span>Maximum image file size: 2 MB</span>{" "}
                  <br /> <br /> <span>Maximum video duration: 1 minute</span>{" "}
                  <br /> <br />{" "}
                  <span>
                    Supported file formats: .png, .jpeg, .jpg, .gif, .mp4.
                  </span>{" "}
                  <br /> <br />
                  <span>
                    Video backgrounds will continuously loop without sound.
                  </span>{" "}
                  <br />{" "}
                </div>
              }
              placement="top"
            >
              <ToolSvg />
            </TooltipMobileAction>
          ) : (
            <BoxTooltipTitle
              arrow
              placement="top"
              backgroundColor={"rgba(27, 31, 41)"}
              title={
                <div className="toltip-title-line">
                  <span>
                    Use an image, video, or GIF as your background. Note that
                    the background may not appear in all layouts.
                  </span>{" "}
                  <br /> <br /> <span>Ideal dimensions: 1280 x 720 pixels</span>{" "}
                  <br /> <br /> <span>Maximum image file size: 2 MB</span>{" "}
                  <br /> <br /> <span>Maximum video duration: 1 minute</span>{" "}
                  <br /> <br />{" "}
                  <span>
                    Supported file formats: .png, .jpeg, .jpg, .gif, .mp4.
                  </span>{" "}
                  <br /> <br />
                  <span>
                    Video backgrounds will continuously loop without sound.
                  </span>{" "}
                  <br />{" "}
                </div>
              }
            >
              <ToolSvg />
            </BoxTooltipTitle>
          )}
        </div>
      </h2>

      <div className="accordion-collapse">
        <div className="accordion-body">
          <div className="BoxVideo BoxGraphic">
            <ul className="list-unstyled mb-2">
              {backgrounds &&
                backgrounds.map((e, index) => (
                  <li key={index} className="mb-2">
                    <div className="wraper-box-video">
                      <div
                        className={`layer-video ${
                          active === index && "active"
                        }`}
                        onClick={() => {
                          setActive(active === index ? "" : index);
                          handleBackground(e);
                          // dispatch(
                          //   ManageCover(
                          //     active === index ? "" : { type: "img", src: e.src }
                          //   )
                          // );
                        }}
                      >
                        <div className={`box-video `}>
                          <div className={`add-toBox `}>
                            {active !== index ? (
                              <div className="showplus">
                                <span>Show</span>
                                <i className="fa-solid fa-circle-plus" />
                              </div>
                            ) : (
                              <div className="showplus">
                                <i className="fa-solid fa-circle-plus" />
                                <span>Hide</span>
                              </div>
                            )}
                          </div>
                          <div className="liner"></div>
                          {e.landscape && e.landscape.type === "mp4" ? (
                            <Fragment>
                              <video src={e.landscape && e.landscape.src} />
                              <i className="fa-solid fa-clapperboard viv" />
                            </Fragment>
                          ) : (
                            <img
                              src={e.landscape && e.landscape.src}
                              alt={e.name}
                            />
                          )}
                          {e.portrait && e.portrait.type === "mp4" ? (
                            <Fragment>
                              <video src={e.portrait && e.portrait.src} />
                              <i className="fa-solid fa-clapperboard viv" />
                            </Fragment>
                          ) : (
                            e.portrait.src && (
                              <img
                                style={{
                                  width: "35px",
                                  height: "100%",
                                }}
                                src={e.portrait && e.portrait.src}
                                alt={e.name}
                              />
                            )
                          )}
                          {/* Display uploaded video */}
                          {/* <div className="box-title">
                            <p>{e.landscape && e.landscape.name}</p>
                            <p>{e.portrait && e.portrait.name}</p>
                          </div> */}
                          {/**  <i className="fa-solid fa-clapperboard viv" /> */}
                        </div>
                        <div
                          className="icon-edit"
                          onClick={(z) => {
                            z.preventDefault();
                            z.stopPropagation();
                            dispatch(ManageDeleteBackground(e.id));
                            console.log("background");
                          }}
                        >
                          <BoxTooltipTitle placement="top" title="Delete">
                            <div
                              className="edit"
                              style={{ top: "17px", right: "3px" }}
                            >
                              <i
                                className="fa-solid fa-trash"
                                style={{ color: active === index && "#fff" }}
                              />
                            </div>
                          </BoxTooltipTitle>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
            <div className="box-add-video">
              <div className="wrapper">
                <label htmlFor="upload" onClick={(e) => handleOpen()}>
                  <div className="box-plus">
                    <span>+</span>
                  </div>
                  <div className="title">
                    <span>Add Background</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog maxWidth="xm" open={open} onClose={handleClose}>
        <div className="modal-box">
          <FileUpload
            handleSelectedFile={handleSelectedFile}
            close={handleClose}
          />
        </div>
      </Dialog>
    </Fragment>
  );
}

export default BrandBackground;
