import React from "react";

function PollSvg({ backgroundColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      zoomAndPan="magnify"
      viewBox="0 0 67.5 56.249996"
      height="75"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="d5f9a53f65">
          <path
            d="M 0.199219 0 L 66.808594 0 L 66.808594 55.511719 L 0.199219 55.511719 Z M 0.199219 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="f0a62da139">
          <path
            d="M 7.597656 0 L 59.40625 0 C 63.496094 0 66.808594 3.3125 66.808594 7.402344 L 66.808594 48.109375 C 66.808594 52.195312 63.496094 55.511719 59.40625 55.511719 L 7.597656 55.511719 C 3.511719 55.511719 0.199219 52.195312 0.199219 48.109375 L 0.199219 7.402344 C 0.199219 3.3125 3.511719 0 7.597656 0 Z M 7.597656 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="9dc22ef5cd">
          <path
            d="M 10.640625 8.523438 L 24 8.523438 L 24 47 L 10.640625 47 Z M 10.640625 8.523438 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="920469be97">
          <path
            d="M 43 30 L 56.53125 30 L 56.53125 47 L 43 47 Z M 43 30 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#d5f9a53f65)">
        <g clip-path="url(#f0a62da139)">
          <path
            fill={backgroundColor}
            d="M 0.199219 0 L 66.765625 0 L 66.765625 55.511719 L 0.199219 55.511719 Z M 0.199219 0 "
            fill-opacity="1"
            fill-rule="nonzero"
          />
          <path
            stroke-linecap="butt"
            transform="matrix(0.740132, 0, 0, 0.740132, 0.197371, -0.00000123359)"
            fill="none"
            stroke-linejoin="miter"
            d="M 9.998609 0.00000166671 L 79.997778 0.00000166671 C 85.523612 0.00000166671 89.999168 4.475557 89.999168 10.001391 L 89.999168 65.001116 C 89.999168 70.521671 85.523612 75.002505 79.997778 75.002505 L 9.998609 75.002505 C 4.478053 75.002505 0.00249702 70.521671 0.00249702 65.001116 L 0.00249702 10.001391 C 0.00249702 4.475557 4.478053 0.00000166671 9.998609 0.00000166671 Z M 9.998609 0.00000166671 "
            stroke={backgroundColor}
            stroke-width="6"
            stroke-opacity="1"
            stroke-miterlimit="4"
          />
        </g>
      </g>
      <path
        fill="#ffffff"
        d="M 39.082031 46.984375 L 27.863281 46.984375 C 27.40625 46.984375 27.03125 46.609375 27.03125 46.179688 L 27.03125 21.621094 C 27.03125 21.191406 27.40625 20.816406 27.863281 20.816406 L 39.082031 20.816406 C 39.539062 20.816406 39.914062 21.191406 39.914062 21.621094 L 39.914062 46.207031 C 39.914062 46.609375 39.539062 46.984375 39.082031 46.984375 Z M 39.082031 46.984375 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <g clip-path="url(#9dc22ef5cd)">
        <path
          fill="#ffffff"
          d="M 22.691406 46.984375 L 11.472656 46.984375 C 11.015625 46.984375 10.640625 46.609375 10.640625 46.179688 L 10.640625 9.355469 C 10.640625 8.902344 11.015625 8.527344 11.472656 8.527344 L 22.691406 8.527344 C 23.148438 8.527344 23.496094 8.902344 23.496094 9.355469 L 23.496094 46.179688 C 23.46875 46.609375 23.148438 46.984375 22.691406 46.984375 Z M 22.691406 46.984375 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#920469be97)">
        <path
          fill="#ffffff"
          d="M 55.527344 46.984375 L 44.304688 46.984375 C 43.851562 46.984375 43.476562 46.609375 43.476562 46.179688 L 43.476562 31.210938 C 43.476562 30.78125 43.851562 30.40625 44.304688 30.40625 L 55.527344 30.40625 C 55.980469 30.40625 56.355469 30.78125 56.355469 31.210938 L 56.355469 46.207031 C 56.355469 46.609375 55.980469 46.984375 55.527344 46.984375 Z M 55.527344 46.984375 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
    </svg>
  );
}

export default PollSvg;
