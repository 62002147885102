import React from "react";

function BluringTwoSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="250"
      zoomAndPan="magnify"
      viewBox="0 0 187.5 187.499992"
      height="250"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="5ea99f3586">
          <path
            d="M 0 0 L 187 0 L 187 187 L 0 187 Z M 0 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="88a73682a2">
          <path
            d="M 29.921875 0 L 157.082031 0 C 165.015625 0 172.625 3.152344 178.238281 8.761719 C 183.847656 14.375 187 21.984375 187 29.921875 L 187 157.082031 C 187 165.015625 183.847656 172.625 178.238281 178.238281 C 172.625 183.847656 165.015625 187 157.082031 187 L 29.921875 187 C 21.984375 187 14.375 183.847656 8.761719 178.238281 C 3.152344 172.625 0 165.015625 0 157.082031 L 0 29.921875 C 0 21.984375 3.152344 14.375 8.761719 8.761719 C 14.375 3.152344 21.984375 0 29.921875 0 Z M 29.921875 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="d64cda7997">
          <path
            d="M 81.929688 51.671875 L 87.167969 51.671875 L 87.167969 56.90625 L 81.929688 56.90625 Z M 81.929688 51.671875 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="b7b020eb48">
          <path
            d="M 97.128906 51.671875 L 102.367188 51.671875 L 102.367188 56.90625 L 97.128906 56.90625 Z M 97.128906 51.671875 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="9e7d4efd05">
          <path
            d="M 77 65.125 L 110 65.125 L 110 100 L 77 100 Z M 77 65.125 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="5c34dbec0a">
          <path
            d="M 63.261719 99 L 123.851562 99 L 123.851562 131.695312 L 63.261719 131.695312 Z M 63.261719 99 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="6a962e94d6">
          <path
            d="M 81.929688 39 L 87.167969 39 L 87.167969 44.238281 L 81.929688 44.238281 Z M 81.929688 39 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="ebf5a2ba94">
          <path
            d="M 97.128906 39 L 102.367188 39 L 102.367188 44.238281 L 97.128906 44.238281 Z M 97.128906 39 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="0c2b419a2f">
          <path
            d="M 48.847656 71.011719 L 54.082031 71.011719 L 54.082031 76.25 L 48.847656 76.25 Z M 48.847656 71.011719 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="4ea97cfcf8">
          <path
            d="M 49.464844 98.074219 L 54.703125 98.074219 L 54.703125 103.3125 L 49.464844 103.3125 Z M 49.464844 98.074219 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="9a5a757d7f">
          <path
            d="M 48.847656 85.40625 L 54.082031 85.40625 L 54.082031 90.640625 L 48.847656 90.640625 Z M 48.847656 85.40625 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="d2dc3cd24a">
          <path
            d="M 50.085938 126.914062 L 55.320312 126.914062 L 55.320312 132.152344 L 50.085938 132.152344 Z M 50.085938 126.914062 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="45e12981c7">
          <path
            d="M 49.464844 114.246094 L 54.703125 114.246094 L 54.703125 119.480469 L 49.464844 119.480469 Z M 49.464844 114.246094 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="cf2b1f534e">
          <path
            d="M 65.378906 58.003906 L 70.613281 58.003906 L 70.613281 63.242188 L 65.378906 63.242188 Z M 65.378906 58.003906 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="76d566bac3">
          <path
            d="M 64.757812 45.335938 L 69.996094 45.335938 L 69.996094 50.574219 L 64.757812 50.574219 Z M 64.757812 45.335938 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="e1a575e633">
          <path
            d="M 115.285156 58.003906 L 120.523438 58.003906 L 120.523438 63.242188 L 115.285156 63.242188 Z M 115.285156 58.003906 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="07b81400d9">
          <path
            d="M 115.285156 45.335938 L 120.523438 45.335938 L 120.523438 50.574219 L 115.285156 50.574219 Z M 115.285156 45.335938 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="f37ad73549">
          <path
            d="M 131.089844 71.011719 L 136.324219 71.011719 L 136.324219 76.25 L 131.089844 76.25 Z M 131.089844 71.011719 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="79aef9edc3">
          <path
            d="M 131.707031 98.074219 L 136.945312 98.074219 L 136.945312 103.3125 L 131.707031 103.3125 Z M 131.707031 98.074219 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="e752260a6b">
          <path
            d="M 131.089844 85.40625 L 136.324219 85.40625 L 136.324219 90.640625 L 131.089844 90.640625 Z M 131.089844 85.40625 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="9e1e2a2e56">
          <path
            d="M 132.328125 126.914062 L 137.5625 126.914062 L 137.5625 132.152344 L 132.328125 132.152344 Z M 132.328125 126.914062 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="6394793c06">
          <path
            d="M 131.707031 114.246094 L 136.945312 114.246094 L 136.945312 119.480469 L 131.707031 119.480469 Z M 131.707031 114.246094 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="f78a53e89e">
          <path
            d="M 33.554688 77.496094 L 38.789062 77.496094 L 38.789062 82.730469 L 33.554688 82.730469 Z M 33.554688 77.496094 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="802b9a4f9e">
          <path
            d="M 33.554688 105.304688 L 38.789062 105.304688 L 38.789062 110.539062 L 33.554688 110.539062 Z M 33.554688 105.304688 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="4c99623174">
          <path
            d="M 33.554688 91.886719 L 38.789062 91.886719 L 38.789062 97.125 L 33.554688 97.125 Z M 33.554688 91.886719 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="9b6ce5a150">
          <path
            d="M 147.878906 77.496094 L 153.113281 77.496094 L 153.113281 82.730469 L 147.878906 82.730469 Z M 147.878906 77.496094 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="0d9a91ce09">
          <path
            d="M 147.675781 104.554688 L 152.910156 104.554688 L 152.910156 109.792969 L 147.675781 109.792969 Z M 147.675781 104.554688 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="63647218ae">
          <path
            d="M 147.878906 91.886719 L 153.113281 91.886719 L 153.113281 97.125 L 147.878906 97.125 Z M 147.878906 91.886719 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="e57c933132">
          <path
            d="M 82.550781 157.015625 L 87.785156 157.015625 L 87.785156 162.253906 L 82.550781 162.253906 Z M 82.550781 157.015625 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="5ae710362b">
          <path
            d="M 98.75 157.015625 L 103.988281 157.015625 L 103.988281 162.253906 L 98.75 162.253906 Z M 98.75 157.015625 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="4630d94b1c">
          <path
            d="M 82.933594 144.347656 L 88.167969 144.347656 L 88.167969 149.582031 L 82.933594 149.582031 Z M 82.933594 144.347656 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="18fb8eb9de">
          <path
            d="M 98.132812 144.347656 L 103.367188 144.347656 L 103.367188 149.582031 L 98.132812 149.582031 Z M 98.132812 144.347656 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="0f4437048e">
          <path
            d="M 69.707031 154.542969 L 74.945312 154.542969 L 74.945312 159.777344 L 69.707031 159.777344 Z M 69.707031 154.542969 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="8d641af51b">
          <path
            d="M 115.925781 154.542969 L 121.160156 154.542969 L 121.160156 159.777344 L 115.925781 159.777344 Z M 115.925781 154.542969 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="25e210d03d">
          <path
            d="M 59.808594 140.09375 L 65.042969 140.09375 L 65.042969 145.328125 L 59.808594 145.328125 Z M 59.808594 140.09375 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="405f1b622a">
          <path
            d="M 127.636719 140.09375 L 132.875 140.09375 L 132.875 145.328125 L 127.636719 145.328125 Z M 127.636719 140.09375 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="765cb47f71">
          <path
            d="M 58.027344 150.683594 L 63.261719 150.683594 L 63.261719 155.917969 L 58.027344 155.917969 Z M 58.027344 150.683594 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="c3c813092f">
          <path
            d="M 147.878906 119.488281 L 153.113281 119.488281 L 153.113281 124.726562 L 147.878906 124.726562 Z M 147.878906 119.488281 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="ed26406703">
          <path
            d="M 34.171875 117.015625 L 39.410156 117.015625 L 39.410156 122.25 L 34.171875 122.25 Z M 34.171875 117.015625 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="d2525bef14">
          <path
            d="M 114.539062 142.566406 L 119.773438 142.566406 L 119.773438 147.804688 L 114.539062 147.804688 Z M 114.539062 142.566406 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="c866a5a194">
          <path
            d="M 127.636719 150.683594 L 132.875 150.683594 L 132.875 155.917969 L 127.636719 155.917969 Z M 127.636719 150.683594 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="6eacb13695">
          <path
            d="M 71.371094 142.566406 L 76.605469 142.566406 L 76.605469 147.804688 L 71.371094 147.804688 Z M 71.371094 142.566406 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#5ea99f3586)">
        <g clip-path="url(#88a73682a2)">
          <path
            fill="#d4e3fd"
            d="M 0 0 L 187 0 L 187 187 L 0 187 Z M 0 0 "
            fill-opacity="1"
            fill-rule="nonzero"
          />
        </g>
      </g>
      <g clip-path="url(#d64cda7997)">
        <path
          fill="#000000"
          d="M 87.167969 54.289062 C 87.167969 54.636719 87.101562 54.96875 86.964844 55.289062 C 86.832031 55.613281 86.644531 55.894531 86.398438 56.140625 C 86.152344 56.386719 85.871094 56.574219 85.550781 56.707031 C 85.230469 56.839844 84.894531 56.90625 84.546875 56.90625 C 84.199219 56.90625 83.867188 56.839844 83.546875 56.707031 C 83.226562 56.574219 82.941406 56.386719 82.695312 56.140625 C 82.453125 55.894531 82.261719 55.613281 82.128906 55.289062 C 81.996094 54.96875 81.929688 54.636719 81.929688 54.289062 C 81.929688 53.941406 81.996094 53.609375 82.128906 53.289062 C 82.261719 52.964844 82.453125 52.683594 82.695312 52.4375 C 82.941406 52.191406 83.226562 52.003906 83.546875 51.871094 C 83.867188 51.738281 84.199219 51.671875 84.546875 51.671875 C 84.894531 51.671875 85.230469 51.738281 85.550781 51.871094 C 85.871094 52.003906 86.152344 52.191406 86.398438 52.4375 C 86.644531 52.683594 86.832031 52.964844 86.964844 53.289062 C 87.101562 53.609375 87.167969 53.941406 87.167969 54.289062 Z M 87.167969 54.289062 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#b7b020eb48)">
        <path
          fill="#000000"
          d="M 102.367188 54.289062 C 102.367188 54.636719 102.300781 54.96875 102.164062 55.289062 C 102.03125 55.613281 101.84375 55.894531 101.597656 56.140625 C 101.351562 56.386719 101.070312 56.574219 100.75 56.707031 C 100.429688 56.839844 100.09375 56.90625 99.746094 56.90625 C 99.398438 56.90625 99.066406 56.839844 98.746094 56.707031 C 98.425781 56.574219 98.140625 56.386719 97.894531 56.140625 C 97.652344 55.894531 97.460938 55.613281 97.328125 55.289062 C 97.195312 54.96875 97.128906 54.636719 97.128906 54.289062 C 97.128906 53.941406 97.195312 53.609375 97.328125 53.289062 C 97.460938 52.964844 97.652344 52.683594 97.894531 52.4375 C 98.140625 52.191406 98.425781 52.003906 98.746094 51.871094 C 99.066406 51.738281 99.398438 51.671875 99.746094 51.671875 C 100.09375 51.671875 100.429688 51.738281 100.75 51.871094 C 101.070312 52.003906 101.351562 52.191406 101.597656 52.4375 C 101.84375 52.683594 102.03125 52.964844 102.164062 53.289062 C 102.300781 53.609375 102.367188 53.941406 102.367188 54.289062 Z M 102.367188 54.289062 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#9e7d4efd05)">
        <path
          fill="#000000"
          d="M 93.558594 99.65625 C 102.457031 99.65625 109.699219 90.195312 109.699219 81.285156 C 109.699219 72.375 102.457031 65.125 93.558594 65.125 C 84.65625 65.125 77.414062 72.375 77.414062 81.285156 C 77.417969 90.195312 84.65625 99.65625 93.558594 99.65625 Z M 93.558594 99.65625 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#5c34dbec0a)">
        <path
          fill="#000000"
          d="M 105.675781 99.820312 C 102.542969 102.210938 98.636719 103.628906 94.40625 103.628906 L 92.707031 103.628906 C 88.476562 103.628906 84.570312 102.207031 81.4375 99.820312 C 71.214844 101.476562 63.410156 110.347656 63.410156 121.050781 C 63.410156 126.929688 76.90625 131.695312 93.558594 131.695312 C 110.207031 131.695312 123.707031 126.929688 123.707031 121.050781 C 123.707031 110.347656 115.898438 101.476562 105.675781 99.820312 Z M 105.675781 99.820312 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#6a962e94d6)">
        <path
          fill="#000000"
          d="M 87.167969 41.621094 C 87.167969 41.96875 87.101562 42.300781 86.964844 42.621094 C 86.832031 42.941406 86.644531 43.226562 86.398438 43.472656 C 86.152344 43.714844 85.871094 43.90625 85.550781 44.039062 C 85.230469 44.171875 84.894531 44.238281 84.546875 44.238281 C 84.199219 44.238281 83.867188 44.171875 83.546875 44.039062 C 83.226562 43.90625 82.941406 43.714844 82.695312 43.472656 C 82.453125 43.226562 82.261719 42.941406 82.128906 42.621094 C 81.996094 42.300781 81.929688 41.96875 81.929688 41.621094 C 81.929688 41.273438 81.996094 40.9375 82.128906 40.617188 C 82.261719 40.296875 82.453125 40.015625 82.695312 39.769531 C 82.941406 39.523438 83.226562 39.335938 83.546875 39.203125 C 83.867188 39.066406 84.199219 39 84.546875 39 C 84.894531 39 85.230469 39.066406 85.550781 39.203125 C 85.871094 39.335938 86.152344 39.523438 86.398438 39.769531 C 86.644531 40.015625 86.832031 40.296875 86.964844 40.617188 C 87.101562 40.9375 87.167969 41.273438 87.167969 41.621094 Z M 87.167969 41.621094 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#ebf5a2ba94)">
        <path
          fill="#000000"
          d="M 102.367188 41.621094 C 102.367188 41.96875 102.300781 42.300781 102.164062 42.621094 C 102.03125 42.941406 101.84375 43.226562 101.597656 43.472656 C 101.351562 43.714844 101.070312 43.90625 100.75 44.039062 C 100.429688 44.171875 100.09375 44.238281 99.746094 44.238281 C 99.398438 44.238281 99.066406 44.171875 98.746094 44.039062 C 98.425781 43.90625 98.140625 43.714844 97.894531 43.472656 C 97.652344 43.226562 97.460938 42.941406 97.328125 42.621094 C 97.195312 42.300781 97.128906 41.96875 97.128906 41.621094 C 97.128906 41.273438 97.195312 40.9375 97.328125 40.617188 C 97.460938 40.296875 97.652344 40.015625 97.894531 39.769531 C 98.140625 39.523438 98.425781 39.335938 98.746094 39.203125 C 99.066406 39.066406 99.398438 39 99.746094 39 C 100.09375 39 100.429688 39.066406 100.75 39.203125 C 101.070312 39.335938 101.351562 39.523438 101.597656 39.769531 C 101.84375 40.015625 102.03125 40.296875 102.164062 40.617188 C 102.300781 40.9375 102.367188 41.273438 102.367188 41.621094 Z M 102.367188 41.621094 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#0c2b419a2f)">
        <path
          fill="#000000"
          d="M 54.082031 73.632812 C 54.082031 73.980469 54.015625 74.3125 53.882812 74.632812 C 53.75 74.953125 53.5625 75.238281 53.316406 75.484375 C 53.070312 75.726562 52.789062 75.917969 52.464844 76.050781 C 52.144531 76.183594 51.8125 76.25 51.464844 76.25 C 51.117188 76.25 50.785156 76.183594 50.460938 76.050781 C 50.140625 75.917969 49.859375 75.726562 49.613281 75.484375 C 49.367188 75.238281 49.179688 74.953125 49.046875 74.632812 C 48.914062 74.3125 48.847656 73.980469 48.847656 73.632812 C 48.847656 73.285156 48.914062 72.949219 49.046875 72.628906 C 49.179688 72.308594 49.367188 72.027344 49.613281 71.78125 C 49.859375 71.535156 50.140625 71.347656 50.460938 71.214844 C 50.785156 71.082031 51.117188 71.011719 51.464844 71.011719 C 51.8125 71.011719 52.144531 71.082031 52.464844 71.214844 C 52.789062 71.347656 53.070312 71.535156 53.316406 71.78125 C 53.5625 72.027344 53.75 72.308594 53.882812 72.628906 C 54.015625 72.949219 54.082031 73.285156 54.082031 73.632812 Z M 54.082031 73.632812 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#4ea97cfcf8)">
        <path
          fill="#000000"
          d="M 54.703125 100.695312 C 54.703125 101.039062 54.636719 101.375 54.503906 101.695312 C 54.371094 102.015625 54.179688 102.300781 53.933594 102.542969 C 53.6875 102.789062 53.40625 102.980469 53.085938 103.113281 C 52.765625 103.246094 52.429688 103.3125 52.082031 103.3125 C 51.738281 103.3125 51.402344 103.246094 51.082031 103.113281 C 50.761719 102.980469 50.476562 102.789062 50.230469 102.542969 C 49.988281 102.300781 49.796875 102.015625 49.664062 101.695312 C 49.53125 101.375 49.464844 101.039062 49.464844 100.695312 C 49.464844 100.347656 49.53125 100.011719 49.664062 99.691406 C 49.796875 99.371094 49.988281 99.085938 50.230469 98.84375 C 50.476562 98.597656 50.761719 98.40625 51.082031 98.273438 C 51.402344 98.140625 51.738281 98.074219 52.082031 98.074219 C 52.429688 98.074219 52.765625 98.140625 53.085938 98.273438 C 53.40625 98.40625 53.6875 98.597656 53.933594 98.84375 C 54.179688 99.085938 54.371094 99.371094 54.503906 99.691406 C 54.636719 100.011719 54.703125 100.347656 54.703125 100.695312 Z M 54.703125 100.695312 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#9a5a757d7f)">
        <path
          fill="#000000"
          d="M 54.082031 88.023438 C 54.082031 88.371094 54.015625 88.707031 53.882812 89.027344 C 53.75 89.347656 53.5625 89.628906 53.316406 89.875 C 53.070312 90.121094 52.789062 90.308594 52.464844 90.441406 C 52.144531 90.574219 51.8125 90.640625 51.464844 90.640625 C 51.117188 90.640625 50.785156 90.574219 50.460938 90.441406 C 50.140625 90.308594 49.859375 90.121094 49.613281 89.875 C 49.367188 89.628906 49.179688 89.347656 49.046875 89.027344 C 48.914062 88.707031 48.847656 88.371094 48.847656 88.023438 C 48.847656 87.675781 48.914062 87.34375 49.046875 87.023438 C 49.179688 86.703125 49.367188 86.417969 49.613281 86.171875 C 49.859375 85.925781 50.140625 85.738281 50.460938 85.605469 C 50.785156 85.472656 51.117188 85.40625 51.464844 85.40625 C 51.8125 85.40625 52.144531 85.472656 52.464844 85.605469 C 52.789062 85.738281 53.070312 85.925781 53.316406 86.171875 C 53.5625 86.417969 53.75 86.703125 53.882812 87.023438 C 54.015625 87.34375 54.082031 87.675781 54.082031 88.023438 Z M 54.082031 88.023438 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#d2dc3cd24a)">
        <path
          fill="#000000"
          d="M 55.320312 129.53125 C 55.320312 129.878906 55.253906 130.214844 55.121094 130.535156 C 54.988281 130.855469 54.800781 131.136719 54.554688 131.382812 C 54.308594 131.628906 54.023438 131.820312 53.703125 131.953125 C 53.382812 132.085938 53.050781 132.152344 52.703125 132.152344 C 52.355469 132.152344 52.023438 132.085938 51.699219 131.953125 C 51.378906 131.820312 51.097656 131.628906 50.851562 131.382812 C 50.605469 131.136719 50.417969 130.855469 50.285156 130.535156 C 50.152344 130.214844 50.085938 129.878906 50.085938 129.53125 C 50.085938 129.1875 50.152344 128.851562 50.285156 128.53125 C 50.417969 128.210938 50.605469 127.925781 50.851562 127.679688 C 51.097656 127.4375 51.378906 127.246094 51.699219 127.113281 C 52.023438 126.980469 52.355469 126.914062 52.703125 126.914062 C 53.050781 126.914062 53.382812 126.980469 53.703125 127.113281 C 54.023438 127.246094 54.308594 127.4375 54.554688 127.679688 C 54.800781 127.925781 54.988281 128.210938 55.121094 128.53125 C 55.253906 128.851562 55.320312 129.1875 55.320312 129.53125 Z M 55.320312 129.53125 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#45e12981c7)">
        <path
          fill="#000000"
          d="M 54.703125 116.863281 C 54.703125 117.210938 54.636719 117.542969 54.503906 117.867188 C 54.371094 118.1875 54.179688 118.46875 53.933594 118.714844 C 53.6875 118.960938 53.40625 119.148438 53.085938 119.28125 C 52.765625 119.414062 52.429688 119.480469 52.082031 119.480469 C 51.738281 119.480469 51.402344 119.414062 51.082031 119.28125 C 50.761719 119.148438 50.476562 118.960938 50.230469 118.714844 C 49.988281 118.46875 49.796875 118.1875 49.664062 117.867188 C 49.53125 117.542969 49.464844 117.210938 49.464844 116.863281 C 49.464844 116.515625 49.53125 116.183594 49.664062 115.863281 C 49.796875 115.539062 49.988281 115.257812 50.230469 115.011719 C 50.476562 114.765625 50.761719 114.578125 51.082031 114.445312 C 51.402344 114.3125 51.738281 114.246094 52.082031 114.246094 C 52.429688 114.246094 52.765625 114.3125 53.085938 114.445312 C 53.40625 114.578125 53.6875 114.765625 53.933594 115.011719 C 54.179688 115.257812 54.371094 115.539062 54.503906 115.863281 C 54.636719 116.183594 54.703125 116.515625 54.703125 116.863281 Z M 54.703125 116.863281 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#cf2b1f534e)">
        <path
          fill="#000000"
          d="M 70.613281 60.625 C 70.613281 60.972656 70.546875 61.304688 70.414062 61.625 C 70.28125 61.945312 70.09375 62.230469 69.847656 62.476562 C 69.601562 62.71875 69.320312 62.910156 68.996094 63.042969 C 68.675781 63.175781 68.34375 63.242188 67.996094 63.242188 C 67.648438 63.242188 67.316406 63.175781 66.992188 63.042969 C 66.671875 62.910156 66.390625 62.71875 66.144531 62.476562 C 65.898438 62.230469 65.710938 61.945312 65.578125 61.625 C 65.445312 61.304688 65.378906 60.972656 65.378906 60.625 C 65.378906 60.277344 65.445312 59.941406 65.578125 59.621094 C 65.710938 59.300781 65.898438 59.019531 66.144531 58.773438 C 66.390625 58.527344 66.671875 58.335938 66.992188 58.203125 C 67.316406 58.070312 67.648438 58.003906 67.996094 58.003906 C 68.34375 58.003906 68.675781 58.070312 68.996094 58.203125 C 69.320312 58.335938 69.601562 58.527344 69.847656 58.773438 C 70.09375 59.019531 70.28125 59.300781 70.414062 59.621094 C 70.546875 59.941406 70.613281 60.277344 70.613281 60.625 Z M 70.613281 60.625 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#76d566bac3)">
        <path
          fill="#000000"
          d="M 69.996094 47.953125 C 69.996094 48.300781 69.929688 48.636719 69.796875 48.957031 C 69.664062 49.277344 69.472656 49.558594 69.226562 49.804688 C 68.984375 50.050781 68.699219 50.242188 68.378906 50.375 C 68.058594 50.507812 67.722656 50.574219 67.375 50.574219 C 67.03125 50.574219 66.695312 50.507812 66.375 50.375 C 66.054688 50.242188 65.769531 50.050781 65.527344 49.804688 C 65.28125 49.558594 65.089844 49.277344 64.957031 48.957031 C 64.824219 48.636719 64.757812 48.300781 64.757812 47.953125 C 64.757812 47.605469 64.824219 47.273438 64.957031 46.953125 C 65.089844 46.632812 65.28125 46.347656 65.527344 46.101562 C 65.769531 45.859375 66.054688 45.667969 66.375 45.535156 C 66.695312 45.402344 67.03125 45.335938 67.375 45.335938 C 67.722656 45.335938 68.058594 45.402344 68.378906 45.535156 C 68.699219 45.667969 68.984375 45.859375 69.226562 46.101562 C 69.472656 46.347656 69.664062 46.632812 69.796875 46.953125 C 69.929688 47.273438 69.996094 47.605469 69.996094 47.953125 Z M 69.996094 47.953125 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#e1a575e633)">
        <path
          fill="#000000"
          d="M 120.523438 60.625 C 120.523438 60.972656 120.457031 61.304688 120.320312 61.625 C 120.1875 61.945312 120 62.230469 119.753906 62.476562 C 119.507812 62.71875 119.226562 62.910156 118.90625 63.042969 C 118.585938 63.175781 118.25 63.242188 117.902344 63.242188 C 117.554688 63.242188 117.222656 63.175781 116.902344 63.042969 C 116.582031 62.910156 116.296875 62.71875 116.050781 62.476562 C 115.808594 62.230469 115.617188 61.945312 115.484375 61.625 C 115.351562 61.304688 115.285156 60.972656 115.285156 60.625 C 115.285156 60.277344 115.351562 59.941406 115.484375 59.621094 C 115.617188 59.300781 115.808594 59.019531 116.050781 58.773438 C 116.296875 58.527344 116.582031 58.335938 116.902344 58.203125 C 117.222656 58.070312 117.554688 58.003906 117.902344 58.003906 C 118.25 58.003906 118.585938 58.070312 118.90625 58.203125 C 119.226562 58.335938 119.507812 58.527344 119.753906 58.773438 C 120 59.019531 120.1875 59.300781 120.320312 59.621094 C 120.457031 59.941406 120.523438 60.277344 120.523438 60.625 Z M 120.523438 60.625 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#07b81400d9)">
        <path
          fill="#000000"
          d="M 120.523438 47.953125 C 120.523438 48.300781 120.457031 48.636719 120.320312 48.957031 C 120.1875 49.277344 120 49.558594 119.753906 49.804688 C 119.507812 50.050781 119.226562 50.242188 118.90625 50.375 C 118.585938 50.507812 118.25 50.574219 117.902344 50.574219 C 117.554688 50.574219 117.222656 50.507812 116.902344 50.375 C 116.582031 50.242188 116.296875 50.050781 116.050781 49.804688 C 115.808594 49.558594 115.617188 49.277344 115.484375 48.957031 C 115.351562 48.636719 115.285156 48.300781 115.285156 47.953125 C 115.285156 47.605469 115.351562 47.273438 115.484375 46.953125 C 115.617188 46.632812 115.808594 46.347656 116.050781 46.101562 C 116.296875 45.859375 116.582031 45.667969 116.902344 45.535156 C 117.222656 45.402344 117.554688 45.335938 117.902344 45.335938 C 118.25 45.335938 118.585938 45.402344 118.90625 45.535156 C 119.226562 45.667969 119.507812 45.859375 119.753906 46.101562 C 120 46.347656 120.1875 46.632812 120.320312 46.953125 C 120.457031 47.273438 120.523438 47.605469 120.523438 47.953125 Z M 120.523438 47.953125 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#f37ad73549)">
        <path
          fill="#000000"
          d="M 136.324219 73.632812 C 136.324219 73.980469 136.257812 74.3125 136.125 74.632812 C 135.992188 74.953125 135.804688 75.238281 135.558594 75.484375 C 135.3125 75.726562 135.03125 75.917969 134.710938 76.050781 C 134.390625 76.183594 134.054688 76.25 133.707031 76.25 C 133.359375 76.25 133.027344 76.183594 132.707031 76.050781 C 132.386719 75.917969 132.101562 75.726562 131.855469 75.484375 C 131.609375 75.238281 131.421875 74.953125 131.289062 74.632812 C 131.15625 74.3125 131.089844 73.980469 131.089844 73.632812 C 131.089844 73.285156 131.15625 72.949219 131.289062 72.628906 C 131.421875 72.308594 131.609375 72.027344 131.855469 71.78125 C 132.101562 71.535156 132.386719 71.347656 132.707031 71.214844 C 133.027344 71.082031 133.359375 71.011719 133.707031 71.011719 C 134.054688 71.011719 134.390625 71.082031 134.710938 71.214844 C 135.03125 71.347656 135.3125 71.535156 135.558594 71.78125 C 135.804688 72.027344 135.992188 72.308594 136.125 72.628906 C 136.257812 72.949219 136.324219 73.285156 136.324219 73.632812 Z M 136.324219 73.632812 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#79aef9edc3)">
        <path
          fill="#000000"
          d="M 136.945312 100.695312 C 136.945312 101.039062 136.878906 101.375 136.746094 101.695312 C 136.613281 102.015625 136.421875 102.300781 136.179688 102.542969 C 135.933594 102.789062 135.648438 102.980469 135.328125 103.113281 C 135.007812 103.246094 134.675781 103.3125 134.328125 103.3125 C 133.980469 103.3125 133.644531 103.246094 133.324219 103.113281 C 133.003906 102.980469 132.722656 102.789062 132.476562 102.542969 C 132.230469 102.300781 132.039062 102.015625 131.90625 101.695312 C 131.773438 101.375 131.707031 101.039062 131.707031 100.695312 C 131.707031 100.347656 131.773438 100.011719 131.90625 99.691406 C 132.039062 99.371094 132.230469 99.085938 132.476562 98.84375 C 132.722656 98.597656 133.003906 98.40625 133.324219 98.273438 C 133.644531 98.140625 133.980469 98.074219 134.328125 98.074219 C 134.675781 98.074219 135.007812 98.140625 135.328125 98.273438 C 135.648438 98.40625 135.933594 98.597656 136.179688 98.84375 C 136.421875 99.085938 136.613281 99.371094 136.746094 99.691406 C 136.878906 100.011719 136.945312 100.347656 136.945312 100.695312 Z M 136.945312 100.695312 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#e752260a6b)">
        <path
          fill="#000000"
          d="M 136.324219 88.023438 C 136.324219 88.371094 136.257812 88.707031 136.125 89.027344 C 135.992188 89.347656 135.804688 89.628906 135.558594 89.875 C 135.3125 90.121094 135.03125 90.308594 134.710938 90.441406 C 134.390625 90.574219 134.054688 90.640625 133.707031 90.640625 C 133.359375 90.640625 133.027344 90.574219 132.707031 90.441406 C 132.386719 90.308594 132.101562 90.121094 131.855469 89.875 C 131.609375 89.628906 131.421875 89.347656 131.289062 89.027344 C 131.15625 88.707031 131.089844 88.371094 131.089844 88.023438 C 131.089844 87.675781 131.15625 87.34375 131.289062 87.023438 C 131.421875 86.703125 131.609375 86.417969 131.855469 86.171875 C 132.101562 85.925781 132.386719 85.738281 132.707031 85.605469 C 133.027344 85.472656 133.359375 85.40625 133.707031 85.40625 C 134.054688 85.40625 134.390625 85.472656 134.710938 85.605469 C 135.03125 85.738281 135.3125 85.925781 135.558594 86.171875 C 135.804688 86.417969 135.992188 86.703125 136.125 87.023438 C 136.257812 87.34375 136.324219 87.675781 136.324219 88.023438 Z M 136.324219 88.023438 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#9e1e2a2e56)">
        <path
          fill="#000000"
          d="M 137.5625 129.53125 C 137.5625 129.878906 137.496094 130.214844 137.363281 130.535156 C 137.230469 130.855469 137.042969 131.136719 136.796875 131.382812 C 136.550781 131.628906 136.269531 131.820312 135.949219 131.953125 C 135.628906 132.085938 135.292969 132.152344 134.945312 132.152344 C 134.597656 132.152344 134.265625 132.085938 133.945312 131.953125 C 133.625 131.820312 133.339844 131.628906 133.09375 131.382812 C 132.847656 131.136719 132.660156 130.855469 132.527344 130.535156 C 132.394531 130.214844 132.328125 129.878906 132.328125 129.53125 C 132.328125 129.1875 132.394531 128.851562 132.527344 128.53125 C 132.660156 128.210938 132.847656 127.925781 133.09375 127.679688 C 133.339844 127.4375 133.625 127.246094 133.945312 127.113281 C 134.265625 126.980469 134.597656 126.914062 134.945312 126.914062 C 135.292969 126.914062 135.628906 126.980469 135.949219 127.113281 C 136.269531 127.246094 136.550781 127.4375 136.796875 127.679688 C 137.042969 127.925781 137.230469 128.210938 137.363281 128.53125 C 137.496094 128.851562 137.5625 129.1875 137.5625 129.53125 Z M 137.5625 129.53125 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#6394793c06)">
        <path
          fill="#000000"
          d="M 136.945312 116.863281 C 136.945312 117.210938 136.878906 117.542969 136.746094 117.867188 C 136.613281 118.1875 136.421875 118.46875 136.179688 118.714844 C 135.933594 118.960938 135.648438 119.148438 135.328125 119.28125 C 135.007812 119.414062 134.675781 119.480469 134.328125 119.480469 C 133.980469 119.480469 133.644531 119.414062 133.324219 119.28125 C 133.003906 119.148438 132.722656 118.960938 132.476562 118.714844 C 132.230469 118.46875 132.039062 118.1875 131.90625 117.867188 C 131.773438 117.542969 131.707031 117.210938 131.707031 116.863281 C 131.707031 116.515625 131.773438 116.183594 131.90625 115.863281 C 132.039062 115.539062 132.230469 115.257812 132.476562 115.011719 C 132.722656 114.765625 133.003906 114.578125 133.324219 114.445312 C 133.644531 114.3125 133.980469 114.246094 134.328125 114.246094 C 134.675781 114.246094 135.007812 114.3125 135.328125 114.445312 C 135.648438 114.578125 135.933594 114.765625 136.179688 115.011719 C 136.421875 115.257812 136.613281 115.539062 136.746094 115.863281 C 136.878906 116.183594 136.945312 116.515625 136.945312 116.863281 Z M 136.945312 116.863281 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#f78a53e89e)">
        <path
          fill="#000000"
          d="M 38.789062 80.113281 C 38.789062 80.460938 38.722656 80.792969 38.589844 81.113281 C 38.457031 81.433594 38.269531 81.71875 38.023438 81.964844 C 37.777344 82.210938 37.492188 82.398438 37.171875 82.53125 C 36.851562 82.664062 36.519531 82.730469 36.171875 82.730469 C 35.824219 82.730469 35.488281 82.664062 35.167969 82.53125 C 34.847656 82.398438 34.566406 82.210938 34.320312 81.964844 C 34.074219 81.71875 33.886719 81.433594 33.753906 81.113281 C 33.621094 80.792969 33.554688 80.460938 33.554688 80.113281 C 33.554688 79.765625 33.621094 79.433594 33.753906 79.109375 C 33.886719 78.789062 34.074219 78.507812 34.320312 78.261719 C 34.566406 78.015625 34.847656 77.828125 35.167969 77.695312 C 35.488281 77.5625 35.824219 77.496094 36.171875 77.496094 C 36.519531 77.496094 36.851562 77.5625 37.171875 77.695312 C 37.492188 77.828125 37.777344 78.015625 38.023438 78.261719 C 38.269531 78.507812 38.457031 78.789062 38.589844 79.109375 C 38.722656 79.433594 38.789062 79.765625 38.789062 80.113281 Z M 38.789062 80.113281 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#802b9a4f9e)">
        <path
          fill="#000000"
          d="M 38.789062 107.921875 C 38.789062 108.269531 38.722656 108.605469 38.589844 108.925781 C 38.457031 109.246094 38.269531 109.527344 38.023438 109.773438 C 37.777344 110.019531 37.492188 110.207031 37.171875 110.339844 C 36.851562 110.472656 36.519531 110.539062 36.171875 110.539062 C 35.824219 110.539062 35.488281 110.472656 35.167969 110.339844 C 34.847656 110.207031 34.566406 110.019531 34.320312 109.773438 C 34.074219 109.527344 33.886719 109.246094 33.753906 108.925781 C 33.621094 108.605469 33.554688 108.269531 33.554688 107.921875 C 33.554688 107.574219 33.621094 107.242188 33.753906 106.921875 C 33.886719 106.601562 34.074219 106.316406 34.320312 106.070312 C 34.566406 105.824219 34.847656 105.636719 35.167969 105.503906 C 35.488281 105.371094 35.824219 105.304688 36.171875 105.304688 C 36.519531 105.304688 36.851562 105.371094 37.171875 105.503906 C 37.492188 105.636719 37.777344 105.824219 38.023438 106.070312 C 38.269531 106.316406 38.457031 106.601562 38.589844 106.921875 C 38.722656 107.242188 38.789062 107.574219 38.789062 107.921875 Z M 38.789062 107.921875 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#4c99623174)">
        <path
          fill="#000000"
          d="M 38.789062 94.503906 C 38.789062 94.851562 38.722656 95.1875 38.589844 95.507812 C 38.457031 95.828125 38.269531 96.109375 38.023438 96.355469 C 37.777344 96.601562 37.492188 96.792969 37.171875 96.925781 C 36.851562 97.058594 36.519531 97.125 36.171875 97.125 C 35.824219 97.125 35.488281 97.058594 35.167969 96.925781 C 34.847656 96.792969 34.566406 96.601562 34.320312 96.355469 C 34.074219 96.109375 33.886719 95.828125 33.753906 95.507812 C 33.621094 95.1875 33.554688 94.851562 33.554688 94.503906 C 33.554688 94.15625 33.621094 93.824219 33.753906 93.503906 C 33.886719 93.183594 34.074219 92.898438 34.320312 92.652344 C 34.566406 92.410156 34.847656 92.21875 35.167969 92.085938 C 35.488281 91.953125 35.824219 91.886719 36.171875 91.886719 C 36.519531 91.886719 36.851562 91.953125 37.171875 92.085938 C 37.492188 92.21875 37.777344 92.410156 38.023438 92.652344 C 38.269531 92.898438 38.457031 93.183594 38.589844 93.503906 C 38.722656 93.824219 38.789062 94.15625 38.789062 94.503906 Z M 38.789062 94.503906 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#9b6ce5a150)">
        <path
          fill="#000000"
          d="M 153.113281 80.113281 C 153.113281 80.460938 153.046875 80.792969 152.914062 81.113281 C 152.78125 81.433594 152.59375 81.71875 152.347656 81.964844 C 152.101562 82.210938 151.820312 82.398438 151.5 82.53125 C 151.179688 82.664062 150.84375 82.730469 150.496094 82.730469 C 150.148438 82.730469 149.816406 82.664062 149.496094 82.53125 C 149.175781 82.398438 148.890625 82.210938 148.644531 81.964844 C 148.398438 81.71875 148.210938 81.433594 148.078125 81.113281 C 147.945312 80.792969 147.878906 80.460938 147.878906 80.113281 C 147.878906 79.765625 147.945312 79.433594 148.078125 79.109375 C 148.210938 78.789062 148.398438 78.507812 148.644531 78.261719 C 148.890625 78.015625 149.175781 77.828125 149.496094 77.695312 C 149.816406 77.5625 150.148438 77.496094 150.496094 77.496094 C 150.84375 77.496094 151.179688 77.5625 151.5 77.695312 C 151.820312 77.828125 152.101562 78.015625 152.347656 78.261719 C 152.59375 78.507812 152.78125 78.789062 152.914062 79.109375 C 153.046875 79.433594 153.113281 79.765625 153.113281 80.113281 Z M 153.113281 80.113281 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#0d9a91ce09)">
        <path
          fill="#000000"
          d="M 152.910156 107.175781 C 152.910156 107.523438 152.84375 107.855469 152.710938 108.175781 C 152.578125 108.496094 152.390625 108.78125 152.144531 109.027344 C 151.898438 109.269531 151.617188 109.460938 151.292969 109.59375 C 150.972656 109.726562 150.640625 109.792969 150.292969 109.792969 C 149.945312 109.792969 149.613281 109.726562 149.289062 109.59375 C 148.96875 109.460938 148.6875 109.269531 148.441406 109.027344 C 148.195312 108.78125 148.007812 108.496094 147.875 108.175781 C 147.742188 107.855469 147.675781 107.523438 147.675781 107.175781 C 147.675781 106.828125 147.742188 106.492188 147.875 106.171875 C 148.007812 105.851562 148.195312 105.570312 148.441406 105.324219 C 148.6875 105.078125 148.96875 104.886719 149.289062 104.753906 C 149.613281 104.621094 149.945312 104.554688 150.292969 104.554688 C 150.640625 104.554688 150.972656 104.621094 151.292969 104.753906 C 151.617188 104.886719 151.898438 105.078125 152.144531 105.324219 C 152.390625 105.570312 152.578125 105.851562 152.710938 106.171875 C 152.84375 106.492188 152.910156 106.828125 152.910156 107.175781 Z M 152.910156 107.175781 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#63647218ae)">
        <path
          fill="#000000"
          d="M 153.113281 94.503906 C 153.113281 94.851562 153.046875 95.1875 152.914062 95.507812 C 152.78125 95.828125 152.59375 96.109375 152.347656 96.355469 C 152.101562 96.601562 151.820312 96.792969 151.5 96.925781 C 151.179688 97.058594 150.84375 97.125 150.496094 97.125 C 150.148438 97.125 149.816406 97.058594 149.496094 96.925781 C 149.175781 96.792969 148.890625 96.601562 148.644531 96.355469 C 148.398438 96.109375 148.210938 95.828125 148.078125 95.507812 C 147.945312 95.1875 147.878906 94.851562 147.878906 94.503906 C 147.878906 94.15625 147.945312 93.824219 148.078125 93.503906 C 148.210938 93.183594 148.398438 92.898438 148.644531 92.652344 C 148.890625 92.410156 149.175781 92.21875 149.496094 92.085938 C 149.816406 91.953125 150.148438 91.886719 150.496094 91.886719 C 150.84375 91.886719 151.179688 91.953125 151.5 92.085938 C 151.820312 92.21875 152.101562 92.410156 152.347656 92.652344 C 152.59375 92.898438 152.78125 93.183594 152.914062 93.503906 C 153.046875 93.824219 153.113281 94.15625 153.113281 94.503906 Z M 153.113281 94.503906 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#e57c933132)">
        <path
          fill="#000000"
          d="M 87.785156 159.632812 C 87.785156 159.980469 87.71875 160.316406 87.585938 160.636719 C 87.453125 160.957031 87.265625 161.242188 87.019531 161.484375 C 86.773438 161.730469 86.488281 161.921875 86.167969 162.054688 C 85.847656 162.1875 85.515625 162.253906 85.167969 162.253906 C 84.820312 162.253906 84.484375 162.1875 84.164062 162.054688 C 83.84375 161.921875 83.5625 161.730469 83.316406 161.484375 C 83.070312 161.242188 82.882812 160.957031 82.75 160.636719 C 82.617188 160.316406 82.550781 159.980469 82.550781 159.632812 C 82.550781 159.289062 82.617188 158.953125 82.75 158.632812 C 82.882812 158.3125 83.070312 158.027344 83.316406 157.785156 C 83.5625 157.539062 83.84375 157.347656 84.164062 157.214844 C 84.484375 157.082031 84.820312 157.015625 85.167969 157.015625 C 85.515625 157.015625 85.847656 157.082031 86.167969 157.214844 C 86.488281 157.347656 86.773438 157.539062 87.019531 157.785156 C 87.265625 158.027344 87.453125 158.3125 87.585938 158.632812 C 87.71875 158.953125 87.785156 159.289062 87.785156 159.632812 Z M 87.785156 159.632812 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#5ae710362b)">
        <path
          fill="#000000"
          d="M 103.988281 159.632812 C 103.988281 159.980469 103.921875 160.316406 103.789062 160.636719 C 103.65625 160.957031 103.464844 161.242188 103.222656 161.484375 C 102.976562 161.730469 102.691406 161.921875 102.371094 162.054688 C 102.050781 162.1875 101.71875 162.253906 101.371094 162.253906 C 101.023438 162.253906 100.6875 162.1875 100.367188 162.054688 C 100.046875 161.921875 99.765625 161.730469 99.519531 161.484375 C 99.273438 161.242188 99.082031 160.957031 98.949219 160.636719 C 98.816406 160.316406 98.75 159.980469 98.75 159.632812 C 98.75 159.289062 98.816406 158.953125 98.949219 158.632812 C 99.082031 158.3125 99.273438 158.027344 99.519531 157.785156 C 99.765625 157.539062 100.046875 157.347656 100.367188 157.214844 C 100.6875 157.082031 101.023438 157.015625 101.371094 157.015625 C 101.71875 157.015625 102.050781 157.082031 102.371094 157.214844 C 102.691406 157.347656 102.976562 157.539062 103.222656 157.785156 C 103.464844 158.027344 103.65625 158.3125 103.789062 158.632812 C 103.921875 158.953125 103.988281 159.289062 103.988281 159.632812 Z M 103.988281 159.632812 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#4630d94b1c)">
        <path
          fill="#000000"
          d="M 88.167969 146.964844 C 88.167969 147.3125 88.101562 147.644531 87.96875 147.96875 C 87.835938 148.289062 87.648438 148.570312 87.402344 148.816406 C 87.15625 149.0625 86.875 149.25 86.554688 149.382812 C 86.234375 149.515625 85.898438 149.582031 85.550781 149.582031 C 85.203125 149.582031 84.871094 149.515625 84.550781 149.382812 C 84.230469 149.25 83.945312 149.0625 83.699219 148.816406 C 83.453125 148.570312 83.265625 148.289062 83.132812 147.96875 C 83 147.644531 82.933594 147.3125 82.933594 146.964844 C 82.933594 146.617188 83 146.285156 83.132812 145.964844 C 83.265625 145.644531 83.453125 145.359375 83.699219 145.113281 C 83.945312 144.867188 84.230469 144.679688 84.550781 144.546875 C 84.871094 144.414062 85.203125 144.347656 85.550781 144.347656 C 85.898438 144.347656 86.234375 144.414062 86.554688 144.546875 C 86.875 144.679688 87.15625 144.867188 87.402344 145.113281 C 87.648438 145.359375 87.835938 145.644531 87.96875 145.964844 C 88.101562 146.285156 88.167969 146.617188 88.167969 146.964844 Z M 88.167969 146.964844 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#18fb8eb9de)">
        <path
          fill="#000000"
          d="M 103.367188 146.964844 C 103.367188 147.3125 103.300781 147.644531 103.167969 147.96875 C 103.035156 148.289062 102.847656 148.570312 102.601562 148.816406 C 102.355469 149.0625 102.074219 149.25 101.753906 149.382812 C 101.433594 149.515625 101.097656 149.582031 100.75 149.582031 C 100.402344 149.582031 100.070312 149.515625 99.75 149.382812 C 99.429688 149.25 99.144531 149.0625 98.898438 148.816406 C 98.652344 148.570312 98.464844 148.289062 98.332031 147.96875 C 98.199219 147.644531 98.132812 147.3125 98.132812 146.964844 C 98.132812 146.617188 98.199219 146.285156 98.332031 145.964844 C 98.464844 145.644531 98.652344 145.359375 98.898438 145.113281 C 99.144531 144.867188 99.429688 144.679688 99.75 144.546875 C 100.070312 144.414062 100.402344 144.347656 100.75 144.347656 C 101.097656 144.347656 101.433594 144.414062 101.753906 144.546875 C 102.074219 144.679688 102.355469 144.867188 102.601562 145.113281 C 102.847656 145.359375 103.035156 145.644531 103.167969 145.964844 C 103.300781 146.285156 103.367188 146.617188 103.367188 146.964844 Z M 103.367188 146.964844 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#0f4437048e)">
        <path
          fill="#000000"
          d="M 74.945312 157.160156 C 74.945312 157.507812 74.878906 157.839844 74.746094 158.160156 C 74.613281 158.480469 74.421875 158.765625 74.179688 159.011719 C 73.933594 159.257812 73.648438 159.445312 73.328125 159.578125 C 73.007812 159.710938 72.675781 159.777344 72.328125 159.777344 C 71.980469 159.777344 71.644531 159.710938 71.324219 159.578125 C 71.003906 159.445312 70.722656 159.257812 70.476562 159.011719 C 70.230469 158.765625 70.039062 158.480469 69.90625 158.160156 C 69.773438 157.839844 69.707031 157.507812 69.707031 157.160156 C 69.707031 156.8125 69.773438 156.476562 69.90625 156.15625 C 70.039062 155.835938 70.230469 155.554688 70.476562 155.308594 C 70.722656 155.0625 71.003906 154.875 71.324219 154.742188 C 71.644531 154.609375 71.980469 154.542969 72.328125 154.542969 C 72.675781 154.542969 73.007812 154.609375 73.328125 154.742188 C 73.648438 154.875 73.933594 155.0625 74.179688 155.308594 C 74.421875 155.554688 74.613281 155.835938 74.746094 156.15625 C 74.878906 156.476562 74.945312 156.8125 74.945312 157.160156 Z M 74.945312 157.160156 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#8d641af51b)">
        <path
          fill="#000000"
          d="M 121.160156 157.160156 C 121.160156 157.507812 121.09375 157.839844 120.960938 158.160156 C 120.828125 158.480469 120.640625 158.765625 120.394531 159.011719 C 120.148438 159.257812 119.863281 159.445312 119.542969 159.578125 C 119.222656 159.710938 118.890625 159.777344 118.542969 159.777344 C 118.195312 159.777344 117.863281 159.710938 117.539062 159.578125 C 117.21875 159.445312 116.9375 159.257812 116.691406 159.011719 C 116.445312 158.765625 116.257812 158.480469 116.125 158.160156 C 115.992188 157.839844 115.925781 157.507812 115.925781 157.160156 C 115.925781 156.8125 115.992188 156.476562 116.125 156.15625 C 116.257812 155.835938 116.445312 155.554688 116.691406 155.308594 C 116.9375 155.0625 117.21875 154.875 117.539062 154.742188 C 117.863281 154.609375 118.195312 154.542969 118.542969 154.542969 C 118.890625 154.542969 119.222656 154.609375 119.542969 154.742188 C 119.863281 154.875 120.148438 155.0625 120.394531 155.308594 C 120.640625 155.554688 120.828125 155.835938 120.960938 156.15625 C 121.09375 156.476562 121.160156 156.8125 121.160156 157.160156 Z M 121.160156 157.160156 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#25e210d03d)">
        <path
          fill="#000000"
          d="M 65.042969 142.710938 C 65.042969 143.058594 64.976562 143.390625 64.84375 143.710938 C 64.710938 144.035156 64.523438 144.316406 64.277344 144.5625 C 64.03125 144.808594 63.75 144.996094 63.429688 145.128906 C 63.109375 145.261719 62.773438 145.328125 62.425781 145.328125 C 62.078125 145.328125 61.746094 145.261719 61.425781 145.128906 C 61.105469 144.996094 60.820312 144.808594 60.574219 144.5625 C 60.328125 144.316406 60.140625 144.035156 60.007812 143.710938 C 59.875 143.390625 59.808594 143.058594 59.808594 142.710938 C 59.808594 142.363281 59.875 142.03125 60.007812 141.710938 C 60.140625 141.386719 60.328125 141.105469 60.574219 140.859375 C 60.820312 140.613281 61.105469 140.425781 61.425781 140.292969 C 61.746094 140.160156 62.078125 140.09375 62.425781 140.09375 C 62.773438 140.09375 63.109375 140.160156 63.429688 140.292969 C 63.75 140.425781 64.03125 140.613281 64.277344 140.859375 C 64.523438 141.105469 64.710938 141.386719 64.84375 141.710938 C 64.976562 142.03125 65.042969 142.363281 65.042969 142.710938 Z M 65.042969 142.710938 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#405f1b622a)">
        <path
          fill="#000000"
          d="M 132.875 142.710938 C 132.875 143.058594 132.808594 143.390625 132.675781 143.710938 C 132.542969 144.035156 132.351562 144.316406 132.105469 144.5625 C 131.863281 144.808594 131.578125 144.996094 131.257812 145.128906 C 130.9375 145.261719 130.601562 145.328125 130.257812 145.328125 C 129.910156 145.328125 129.574219 145.261719 129.253906 145.128906 C 128.933594 144.996094 128.648438 144.808594 128.40625 144.5625 C 128.160156 144.316406 127.96875 144.035156 127.835938 143.710938 C 127.703125 143.390625 127.636719 143.058594 127.636719 142.710938 C 127.636719 142.363281 127.703125 142.03125 127.835938 141.710938 C 127.96875 141.386719 128.160156 141.105469 128.40625 140.859375 C 128.648438 140.613281 128.933594 140.425781 129.253906 140.292969 C 129.574219 140.160156 129.910156 140.09375 130.257812 140.09375 C 130.601562 140.09375 130.9375 140.160156 131.257812 140.292969 C 131.578125 140.425781 131.863281 140.613281 132.105469 140.859375 C 132.351562 141.105469 132.542969 141.386719 132.675781 141.710938 C 132.808594 142.03125 132.875 142.363281 132.875 142.710938 Z M 132.875 142.710938 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#765cb47f71)">
        <path
          fill="#000000"
          d="M 63.261719 153.300781 C 63.261719 153.648438 63.195312 153.980469 63.0625 154.300781 C 62.929688 154.621094 62.742188 154.90625 62.496094 155.152344 C 62.25 155.398438 61.96875 155.585938 61.648438 155.71875 C 61.324219 155.851562 60.992188 155.917969 60.644531 155.917969 C 60.296875 155.917969 59.964844 155.851562 59.644531 155.71875 C 59.320312 155.585938 59.039062 155.398438 58.792969 155.152344 C 58.546875 154.90625 58.359375 154.621094 58.226562 154.300781 C 58.09375 153.980469 58.027344 153.648438 58.027344 153.300781 C 58.027344 152.953125 58.09375 152.617188 58.226562 152.296875 C 58.359375 151.976562 58.546875 151.695312 58.792969 151.449219 C 59.039062 151.203125 59.320312 151.015625 59.644531 150.882812 C 59.964844 150.75 60.296875 150.683594 60.644531 150.683594 C 60.992188 150.683594 61.324219 150.75 61.648438 150.882812 C 61.96875 151.015625 62.25 151.203125 62.496094 151.449219 C 62.742188 151.695312 62.929688 151.976562 63.0625 152.296875 C 63.195312 152.617188 63.261719 152.953125 63.261719 153.300781 Z M 63.261719 153.300781 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#c3c813092f)">
        <path
          fill="#000000"
          d="M 153.113281 122.109375 C 153.113281 122.453125 153.046875 122.789062 152.914062 123.109375 C 152.78125 123.429688 152.59375 123.714844 152.347656 123.957031 C 152.101562 124.203125 151.820312 124.394531 151.5 124.527344 C 151.179688 124.660156 150.84375 124.726562 150.496094 124.726562 C 150.148438 124.726562 149.816406 124.660156 149.496094 124.527344 C 149.175781 124.394531 148.890625 124.203125 148.644531 123.957031 C 148.398438 123.714844 148.210938 123.429688 148.078125 123.109375 C 147.945312 122.789062 147.878906 122.453125 147.878906 122.109375 C 147.878906 121.761719 147.945312 121.425781 148.078125 121.105469 C 148.210938 120.785156 148.398438 120.503906 148.644531 120.257812 C 148.890625 120.011719 149.175781 119.820312 149.496094 119.6875 C 149.816406 119.554688 150.148438 119.488281 150.496094 119.488281 C 150.84375 119.488281 151.179688 119.554688 151.5 119.6875 C 151.820312 119.820312 152.101562 120.011719 152.347656 120.257812 C 152.59375 120.503906 152.78125 120.785156 152.914062 121.105469 C 153.046875 121.425781 153.113281 121.761719 153.113281 122.109375 Z M 153.113281 122.109375 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#ed26406703)">
        <path
          fill="#000000"
          d="M 39.410156 119.632812 C 39.410156 119.980469 39.34375 120.3125 39.210938 120.632812 C 39.078125 120.957031 38.886719 121.238281 38.640625 121.484375 C 38.394531 121.730469 38.113281 121.917969 37.792969 122.050781 C 37.472656 122.183594 37.136719 122.25 36.789062 122.25 C 36.441406 122.25 36.109375 122.183594 35.789062 122.050781 C 35.46875 121.917969 35.183594 121.730469 34.9375 121.484375 C 34.695312 121.238281 34.503906 120.957031 34.371094 120.632812 C 34.238281 120.3125 34.171875 119.980469 34.171875 119.632812 C 34.171875 119.285156 34.238281 118.953125 34.371094 118.628906 C 34.503906 118.308594 34.695312 118.027344 34.9375 117.78125 C 35.183594 117.535156 35.46875 117.347656 35.789062 117.214844 C 36.109375 117.082031 36.441406 117.015625 36.789062 117.015625 C 37.136719 117.015625 37.472656 117.082031 37.792969 117.214844 C 38.113281 117.347656 38.394531 117.535156 38.640625 117.78125 C 38.886719 118.027344 39.078125 118.308594 39.210938 118.628906 C 39.34375 118.953125 39.410156 119.285156 39.410156 119.632812 Z M 39.410156 119.632812 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#d2525bef14)">
        <path
          fill="#000000"
          d="M 119.773438 145.1875 C 119.773438 145.53125 119.707031 145.867188 119.574219 146.1875 C 119.441406 146.507812 119.253906 146.792969 119.007812 147.039062 C 118.761719 147.28125 118.476562 147.472656 118.15625 147.605469 C 117.835938 147.738281 117.503906 147.804688 117.15625 147.804688 C 116.808594 147.804688 116.472656 147.738281 116.152344 147.605469 C 115.832031 147.472656 115.550781 147.28125 115.304688 147.039062 C 115.058594 146.792969 114.871094 146.507812 114.738281 146.1875 C 114.605469 145.867188 114.539062 145.53125 114.539062 145.1875 C 114.539062 144.839844 114.605469 144.503906 114.738281 144.183594 C 114.871094 143.863281 115.058594 143.582031 115.304688 143.335938 C 115.550781 143.089844 115.832031 142.898438 116.152344 142.765625 C 116.472656 142.632812 116.808594 142.566406 117.15625 142.566406 C 117.503906 142.566406 117.835938 142.632812 118.15625 142.765625 C 118.476562 142.898438 118.761719 143.089844 119.007812 143.335938 C 119.253906 143.582031 119.441406 143.863281 119.574219 144.183594 C 119.707031 144.503906 119.773438 144.839844 119.773438 145.1875 Z M 119.773438 145.1875 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#c866a5a194)">
        <path
          fill="#000000"
          d="M 132.875 153.300781 C 132.875 153.648438 132.808594 153.980469 132.675781 154.300781 C 132.542969 154.621094 132.351562 154.90625 132.105469 155.152344 C 131.863281 155.398438 131.578125 155.585938 131.257812 155.71875 C 130.9375 155.851562 130.601562 155.917969 130.257812 155.917969 C 129.910156 155.917969 129.574219 155.851562 129.253906 155.71875 C 128.933594 155.585938 128.648438 155.398438 128.40625 155.152344 C 128.160156 154.90625 127.96875 154.621094 127.835938 154.300781 C 127.703125 153.980469 127.636719 153.648438 127.636719 153.300781 C 127.636719 152.953125 127.703125 152.617188 127.835938 152.296875 C 127.96875 151.976562 128.160156 151.695312 128.40625 151.449219 C 128.648438 151.203125 128.933594 151.015625 129.253906 150.882812 C 129.574219 150.75 129.910156 150.683594 130.257812 150.683594 C 130.601562 150.683594 130.9375 150.75 131.257812 150.882812 C 131.578125 151.015625 131.863281 151.203125 132.105469 151.449219 C 132.351562 151.695312 132.542969 151.976562 132.675781 152.296875 C 132.808594 152.617188 132.875 152.953125 132.875 153.300781 Z M 132.875 153.300781 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#6eacb13695)">
        <path
          fill="#000000"
          d="M 76.605469 145.1875 C 76.605469 145.53125 76.539062 145.867188 76.40625 146.1875 C 76.273438 146.507812 76.085938 146.792969 75.839844 147.039062 C 75.59375 147.28125 75.3125 147.472656 74.992188 147.605469 C 74.671875 147.738281 74.335938 147.804688 73.988281 147.804688 C 73.640625 147.804688 73.308594 147.738281 72.988281 147.605469 C 72.667969 147.472656 72.382812 147.28125 72.136719 147.039062 C 71.890625 146.792969 71.703125 146.507812 71.570312 146.1875 C 71.4375 145.867188 71.371094 145.53125 71.371094 145.1875 C 71.371094 144.839844 71.4375 144.503906 71.570312 144.183594 C 71.703125 143.863281 71.890625 143.582031 72.136719 143.335938 C 72.382812 143.089844 72.667969 142.898438 72.988281 142.765625 C 73.308594 142.632812 73.640625 142.566406 73.988281 142.566406 C 74.335938 142.566406 74.671875 142.632812 74.992188 142.765625 C 75.3125 142.898438 75.59375 143.089844 75.839844 143.335938 C 76.085938 143.582031 76.273438 143.863281 76.40625 144.183594 C 76.539062 144.503906 76.605469 144.839844 76.605469 145.1875 Z M 76.605469 145.1875 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  );
}

export default BluringTwoSvg;
