import React, { useState } from "react";
import {
  BoxTooltipTitle,
  TooltipMobileAction,
} from "../../ToolTipsFolder/ToolTips";
import ToolSvg from "../../Svgs/ToolTipSvg";
import { randomPassword } from "../../../RandomID";

import PlusIcon from "../../../assits/Add_Donation_Tier_Icon.svg";

function DonationBox() {
  const [enableDonations, setEnableDonations] = useState(false);
  const [enableCustom, setEnableCustom] = useState(false);
  const [dataSelected, setDataSelected] = useState("$ US");
  const [clickAdd, setClickAdd] = useState(false);
  const [tiers, setTiers] = useState([]);
  const [stateEdit, setStateEdit] = useState("");
  const [currentTier, setCurrentTier] = useState({
    title: "",
    description: "",
    amount: 1,
    id: randomPassword(2),
  });
  const [error, setError] = useState(false);

  const [customAmount, setCustomAmount] = useState(1);

  const handleAddTier = () => {
    setClickAdd(true);
    setCurrentTier({
      title: "",
      description: "",
      amount: 1,
      id: randomPassword(2),
    });
    setError(false);
  };

  const handleTierChange = (field, value) => {
    setCurrentTier({ ...currentTier, [field]: value });
    setError(false);
  };

  const saveTier = () => {
    if (
      currentTier.title.trim() === "" ||
      currentTier.description.trim() === "" ||
      currentTier.amount === 1 ||
      isNaN(currentTier.amount) ||
      currentTier.amount == ""
    ) {
      setError(true);
      return;
    }

    if (stateEdit) {
      setTiers(
        tiers.map((tier) => (tier.id === stateEdit ? currentTier : tier))
      );
      setStateEdit("");
    } else {
      setTiers([...tiers, currentTier]);
    }

    setClickAdd(false);
    setCurrentTier({
      title: "",
      description: "",
      amount: 1,
      id: randomPassword(2),
    });
    setError(false);
  };

  const incrementAmount = () => {
    setCurrentTier({ ...currentTier, amount: currentTier.amount + 1 });
    setError(false);
  };

  const decrementAmount = () => {
    if (currentTier.amount > 1) {
      setCurrentTier({ ...currentTier, amount: currentTier.amount - 1 });
    }
    setError(false);
  };

  const handleEditTier = (tier) => {
    setCurrentTier(tier);
    setStateEdit(tier.id);
    setError(false);
  };

  const handleDeleteTier = (id) => {
    setTiers(tiers.filter((tier) => tier.id !== id));
  };

  const incrementCustomAmount = () => {
    setCustomAmount(customAmount + 1);
  };

  const decrementCustomAmount = () => {
    if (customAmount > 1) {
      setCustomAmount(customAmount - 1);
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Initial width

  console.log("tierstiers", tiers);
  return (
    <>
      <div className="quality-record">
        <div className="box-chack">
          <div className="wraper-check">
            <input
              type="checkbox"
              id="Donations"
              className="form-check"
              checked={enableDonations}
              onChange={() => setEnableDonations(!enableDonations)}
            />
            <label htmlFor="Donations">Enable Donations</label>
            {windowWidth <= 575 ? (
              <TooltipMobileAction
                backgroundColor={"rgba(27, 31, 41)"}
                title="Allow users to make in-stream donations"
                placement="top"
              >
                <ToolSvg />
              </TooltipMobileAction>
            ) : (
              <BoxTooltipTitle
                title="Allow users to make in-stream donations"
                arrow
                placement="top"
              >
                <ToolSvg />
              </BoxTooltipTitle>
            )}
          </div>
        </div>

        {enableDonations ? (
          <>
            <div
              className="wraper"
              style={{
                width: "100%",
                marginTop: "20px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <div className="box-label">
                <label>Currency</label>
              </div>

              <div className="d-flex gap-5 justify-content-start box-with-icon">
                <select
                  className="select-control form-select"
                  style={{
                    width: "100px",
                    borderRadius: "8px",
                    fontSize: "1.3rem",
                  }}
                  value={dataSelected}
                  onChange={(e) => setDataSelected(e.target.value)}
                >
                  <option value="$ US" key="one">
                    $ US
                  </option>
                  <option value="€ Euro" key="two">
                    € Euro
                  </option>
                </select>
              </div>
            </div>
            <div className="Add-donation">
              <div className="header">
                <p>Add up to three donation tiers</p>
                {tiers.length >= 3 ? (
                  ""
                ) : (
                  <div className="Add-Icon" onClick={handleAddTier}>
                    <img
                      src={PlusIcon}
                      alt="..."
                      width={"100%"}
                      height={"100%"}
                    />
                  </div>
                )}
              </div>

              {clickAdd && (
                <div className="box-add">
                  <input
                    name="title"
                    placeholder="Title"
                    className="form-control"
                    value={currentTier.title}
                    onChange={(e) => handleTierChange("title", e.target.value)}
                    style={{
                      border:
                        error && currentTier.title.trim() === ""
                          ? "1px solid red"
                          : "1px solid #ced4da",
                    }}
                    onFocus={(e) =>
                      (e.target.style.border = "1px solid #ced4da")
                    }
                  />
                  <input
                    name="description"
                    placeholder="Description"
                    className="form-control"
                    value={currentTier.description}
                    onChange={(e) =>
                      handleTierChange("description", e.target.value)
                    }
                    style={{
                      border:
                        error && currentTier.description.trim() === ""
                          ? "1px solid red"
                          : "1px solid #ced4da",
                    }}
                    onFocus={(e) =>
                      (e.target.style.border = "1px solid #ced4da")
                    }
                  />
                  <div className="footer-add">
                    <div className="increseBox">
                      <span className="title">Amount</span>
                      <div
                        className="wrapperMore"
                        style={{
                          border:
                            error &&
                            (currentTier.amount === 1 ||
                              isNaN(currentTier.amount) ||
                              currentTier.amount === null)
                              ? "1px solid red"
                              : "1px solid #ced4da",
                        }}
                        onFocus={(e) =>
                          (e.target.style.border = "1px solid #ced4da")
                        }
                      >
                        <input
                          value={currentTier.amount}
                          type="number"
                          required
                          onChange={(e) =>
                            handleTierChange("amount", parseInt(e.target.value))
                          }
                        />
                        <div className="icon-add">
                          <i
                            className="fa-solid fa-angle-up"
                            onClick={incrementAmount}
                          />
                          <i
                            className="fa-solid fa-angle-down"
                            onClick={decrementAmount}
                          />
                        </div>
                      </div>
                      <span className="data-selected">{dataSelected}</span>
                    </div>
                    <div className="boxs-btns">
                      <div className="header">
                        <button className="btn btn-primary" onClick={saveTier}>
                          <text>Save</text>
                        </button>
                      </div>

                      <div className="header">
                        <button
                          className="btn btn-outline-primary"
                          onClick={() => {
                            setError(false);
                            setClickAdd(false);
                          }}
                        >
                          <text>Cancel</text>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {tiers.map((tier) =>
                stateEdit === tier.id ? (
                  <div key={tier.id} className="box-add">
                    <input
                      name="title"
                      placeholder="Title"
                      className="form-control"
                      value={currentTier.title}
                      onChange={(e) =>
                        handleTierChange("title", e.target.value)
                      }
                      style={{
                        border:
                          error && currentTier.title.trim() === ""
                            ? "1px solid red"
                            : "1px solid #ced4da",
                      }}
                      onFocus={(e) =>
                        (e.target.style.border = "1px solid #ced4da")
                      }
                    />
                    <input
                      name="description"
                      placeholder="Description"
                      className="form-control"
                      value={currentTier.description}
                      onChange={(e) =>
                        handleTierChange("description", e.target.value)
                      }
                      style={{
                        border:
                          error && currentTier.description.trim() === ""
                            ? "1px solid red"
                            : "1px solid #ced4da",
                      }}
                      onFocus={(e) =>
                        (e.target.style.border = "1px solid #ced4da")
                      }
                    />
                    <div className="footer-add">
                      <div className="increseBox">
                        <span className="title">Amount</span>
                        <div
                          className="wrapperMore"
                          style={{
                            border:
                              error &&
                              (currentTier.amount === 0 ||
                                isNaN(currentTier.amount) ||
                                currentTier.amount === null)
                                ? "1px solid red"
                                : "1px solid #ced4da",
                          }}
                          onFocus={(e) =>
                            (e.target.style.border = "1px solid #ced4da")
                          }
                        >
                          <input
                            value={currentTier.amount}
                            type="number"
                            required
                            onChange={(e) =>
                              handleTierChange(
                                "amount",
                                parseInt(e.target.value)
                              )
                            }
                          />
                          <div className="icon-add">
                            <i
                              className="fa-solid fa-angle-up"
                              onClick={incrementAmount}
                            />
                            <i
                              className="fa-solid fa-angle-down"
                              onClick={decrementAmount}
                            />
                          </div>
                        </div>
                        <span className="data-selected">{dataSelected}</span>
                      </div>
                      <div className="boxs-btns">
                        <div className="header">
                          <button
                            className="btn btn-primary"
                            onClick={saveTier}
                          >
                            <text>Save</text>
                          </button>
                        </div>

                        <div className="header">
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => setStateEdit("")}
                          >
                            <text>Cancel</text>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div key={tier.id} className="box-tier">
                    <div className="box-money">
                      {tier.amount} {dataSelected}
                    </div>
                    <div className="title">
                      {tier.title} - {tier.description}
                    </div>
                    <div className="icons-box">
                      <div
                        className="edit-icon"
                        onClick={() => handleEditTier(tier)}
                      >
                        <i className="fa-solid fa-pen"></i>
                      </div>
                      <div
                        className="remove-icon"
                        onClick={() => handleDeleteTier(tier.id)}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>

            <div className="box-chack">
              <div className="wraper-check">
                <input
                  type="checkbox"
                  id="Shift"
                  className="form-check"
                  checked={enableCustom}
                  onChange={() => setEnableCustom(!enableCustom)}
                />
                <label htmlFor="Shift">Allow custom donation</label>
                {windowWidth <= 575 ? (
                  <TooltipMobileAction
                    backgroundColor={"rgba(27, 31, 41)"}
                    title="The user can enter a custom amount to donate"
                    placement="top"
                  >
                    <ToolSvg />
                  </TooltipMobileAction>
                ) : (
                  <BoxTooltipTitle
                    title="The user can enter a custom amount to donate"
                    arrow
                    placement="top"
                  >
                    <ToolSvg />
                  </BoxTooltipTitle>
                )}
              </div>
            </div>
            {enableCustom && (
              <>
                <div
                  className="footer-add mt-4 mb-5"
                  style={{ width: "80%", marginLeft: "27px" }}
                >
                  <div className="increseBox">
                    <span className="title">Minimum amount</span>
                    <div className="wrapperMore">
                      <input
                        type="number"
                        required
                        value={customAmount}
                        onChange={(e) =>
                          setCustomAmount(parseInt(e.target.value))
                        }
                      />
                      <div className="icon-add">
                        <i
                          className="fa-solid fa-angle-up"
                          onClick={incrementCustomAmount}
                        />
                        <i
                          className="fa-solid fa-angle-down"
                          onClick={decrementCustomAmount}
                        />
                      </div>
                    </div>
                    <span className="data-selected">{dataSelected}</span>
                  </div>
                </div>
              </>
            )}

            {error && (
              <p className="Error mt-4">
                The tier you are trying to add has missing information.
              </p>
            )}
          </>
        ) : (
          <>
            <div
              className="wraper"
              style={{
                width: "100%",
                marginTop: "20px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <div className="box-label">
                <label style={{ color: "lightgray" }}>Currency</label>
              </div>

              <div className="d-flex gap-5 justify-content-start box-with-icon">
                <select
                  className="select-control form-select"
                  style={{
                    width: "100px",
                    borderRadius: "8px",
                    fontSize: "1.3rem",
                    color: "lightgray",
                  }}
                  value={dataSelected}
                  onChange={(e) => setDataSelected(e.target.value)}
                >
                  <option value="$ US" key="one">
                    $ US
                  </option>
                  <option value="€ Euro" key="two">
                    € Euro
                  </option>
                </select>
              </div>
            </div>
            <div className="Add-donation">
              <div className="header">
                <p style={{ color: "lightgray" }}>
                  Add up to three donation tiers
                </p>

                <div
                  className="Add-Icon"
                  style={{ background: "lightgray" }}
                  onClick={handleAddTier}
                >
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                      height: "100%",
                      width: "fit-content",
                    }}
                  >
                    +
                  </span>
                </div>
              </div>
            </div>
            <div className="box-chack">
              <div className="wraper-check">
                <input
                  type="checkbox"
                  id="Shift"
                  className="form-check"
                  checked={enableDonations}
                  disabled={true}
                  onChange={() => setEnableCustom(!enableDonations)}
                />
                <label htmlFor="Shift" style={{ color: "lightgray" }}>
                  Allow custom donation
                </label>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default DonationBox;
