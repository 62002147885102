import React from "react";

const BannerSvg = ({ backgroundColor, sidebar, current }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      zoomAndPan="magnify"
      viewBox="0 0 67.5 56.249996"
      height="75"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="34c5f8c73a">
          <path
            d="M 0.199219 0 L 66.808594 0 L 66.808594 55.511719 L 0.199219 55.511719 Z M 0.199219 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="9c0676d488">
          <path
            d="M 7.597656 0 L 59.40625 0 C 63.496094 0 66.808594 3.3125 66.808594 7.402344 L 66.808594 48.109375 C 66.808594 52.195312 63.496094 55.511719 59.40625 55.511719 L 7.597656 55.511719 C 3.511719 55.511719 0.199219 52.195312 0.199219 48.109375 L 0.199219 7.402344 C 0.199219 3.3125 3.511719 0 7.597656 0 Z M 7.597656 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="8146739632">
          <path
            d="M 0.199219 0 L 66.316406 0 L 66.316406 55.511719 L 0.199219 55.511719 Z M 0.199219 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="bb0d624c0d">
          <path
            d="M 7.601562 0 L 59.402344 0 C 63.492188 0 66.804688 3.3125 66.804688 7.402344 L 66.804688 48.101562 C 66.804688 52.191406 63.492188 55.503906 59.402344 55.503906 L 7.601562 55.503906 C 3.511719 55.503906 0.199219 52.191406 0.199219 48.101562 L 0.199219 7.402344 C 0.199219 3.3125 3.511719 0 7.601562 0 Z M 7.601562 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="e394544bbe">
          <path
            d="M 4.96875 40.441406 L 62.035156 40.441406 L 62.035156 47.375 L 4.96875 47.375 Z M 4.96875 40.441406 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#34c5f8c73a)">
        <g clip-path="url(#9c0676d488)">
          <path
            fill={backgroundColor}
            d="M 0.199219 0 L 66.765625 0 L 66.765625 55.511719 L 0.199219 55.511719 Z M 0.199219 0 "
            fill-opacity="1"
            fill-rule="nonzero"
          />
        </g>
      </g>
      <g clip-path="url(#8146739632)">
        <g clip-path="url(#bb0d624c0d)">
          <path
            stroke-linecap="butt"
            transform="matrix(1.652975, 0, 0, 1.652975, 0.199953, -0.00000463386)"
            fill="none"
            stroke-linejoin="miter"
            d="M 4.477751 0.00000280334 L 35.815663 0.00000280334 C 38.289895 0.00000280334 40.293858 2.003966 40.293858 4.478198 L 40.293858 29.099999 C 40.293858 31.574231 38.289895 33.578194 35.815663 33.578194 L 4.477751 33.578194 C 2.003519 33.578194 -0.000443905 31.574231 -0.000443905 29.099999 L -0.000443905 4.478198 C -0.000443905 2.003966 2.003519 0.00000280334 4.477751 0.00000280334 Z M 4.477751 0.00000280334 "
            stroke={backgroundColor}
            stroke-width="2.686543"
            stroke-opacity="1"
            stroke-miterlimit="4"
          />
        </g>
      </g>
      <g clip-path="url(#e394544bbe)">
        <path
          fill={"#fff"}
          d="M 4.96875 40.441406 L 62.046875 40.441406 L 62.046875 47.375 L 4.96875 47.375 Z M 4.96875 40.441406 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  );
};

export default BannerSvg;
