import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: [
    {
      title: `In my opionion, this is a great platform for
      live streaming and interadtions, it makes
      the relationship between the streamer
      and their public very close without barrier`,
      main: true,
      username: "hittheroadJack",
      time: "07:16 PM",
    },
    {
      title: `Hi, this is my first time here, how does
      this work?
      `,
      main: false,
      username: "Zaher Hinbarji",
      time: "07:16 PM",
    },
    {
      title: `صباح الخير
      `,
      main: false,
      username: "محمد الحنبرجي",
      time: "07:16 PM",
    },
    {
      title: `Hi, this is my first time here, wow!`,
      main: true,
      username: "Owen_23",
      time: "07:16 PM",
    },
  ],
  loading: false,
  error: null, // Initialize error as null or an Error object
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addMessage(state, action) {
      state.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {},
});

export default chatSlice.reducer;

export let { addMessage } = chatSlice.actions;
