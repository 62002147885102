import React from "react";

function BluringOneSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="250"
      zoomAndPan="magnify"
      viewBox="0 0 187.5 187.499992"
      height="250"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="96bf3b87be">
          <path
            d="M 0 0 L 187 0 L 187 187 L 0 187 Z M 0 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="fb33f08e27">
          <path
            d="M 29.921875 0 L 157.082031 0 C 165.015625 0 172.625 3.152344 178.238281 8.761719 C 183.847656 14.375 187 21.984375 187 29.921875 L 187 157.082031 C 187 165.015625 183.847656 172.625 178.238281 178.238281 C 172.625 183.847656 165.015625 187 157.082031 187 L 29.921875 187 C 21.984375 187 14.375 183.847656 8.761719 178.238281 C 3.152344 172.625 0 165.015625 0 157.082031 L 0 29.921875 C 0 21.984375 3.152344 14.375 8.761719 8.761719 C 14.375 3.152344 21.984375 0 29.921875 0 Z M 29.921875 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="b7f124f335">
          <path
            d="M 82.550781 51.671875 L 87.785156 51.671875 L 87.785156 56.90625 L 82.550781 56.90625 Z M 82.550781 51.671875 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="504c26057e">
          <path
            d="M 97.75 51.671875 L 102.984375 51.671875 L 102.984375 56.90625 L 97.75 56.90625 Z M 97.75 51.671875 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="f6a48beb4a">
          <path
            d="M 77 65.125 L 110 65.125 L 110 100 L 77 100 Z M 77 65.125 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="34ee4daf3c">
          <path
            d="M 63.261719 99 L 123.851562 99 L 123.851562 131.695312 L 63.261719 131.695312 Z M 63.261719 99 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="9980e18154">
          <path
            d="M 81.929688 39 L 87.167969 39 L 87.167969 44.238281 L 81.929688 44.238281 Z M 81.929688 39 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="c908f87b3f">
          <path
            d="M 97.128906 39 L 102.367188 39 L 102.367188 44.238281 L 97.128906 44.238281 Z M 97.128906 39 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="14c071a743">
          <path
            d="M 48.847656 69.523438 L 54.082031 69.523438 L 54.082031 74.757812 L 48.847656 74.757812 Z M 48.847656 69.523438 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="dee12e8c66">
          <path
            d="M 48.226562 55.128906 L 53.464844 55.128906 L 53.464844 60.367188 L 48.226562 60.367188 Z M 48.226562 55.128906 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="32e26c3b82">
          <path
            d="M 49.464844 96.585938 L 54.703125 96.585938 L 54.703125 101.820312 L 49.464844 101.820312 Z M 49.464844 96.585938 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="ce0204f12d">
          <path
            d="M 48.847656 83.914062 L 54.082031 83.914062 L 54.082031 89.152344 L 48.847656 89.152344 Z M 48.847656 83.914062 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="5c517937a5">
          <path
            d="M 50.085938 125.425781 L 55.320312 125.425781 L 55.320312 130.660156 L 50.085938 130.660156 Z M 50.085938 125.425781 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="783e9066aa">
          <path
            d="M 49.464844 112.753906 L 54.703125 112.753906 L 54.703125 117.992188 L 49.464844 117.992188 Z M 49.464844 112.753906 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="c428eb5883">
          <path
            d="M 65.378906 58.003906 L 70.613281 58.003906 L 70.613281 63.242188 L 65.378906 63.242188 Z M 65.378906 58.003906 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="27141fbb31">
          <path
            d="M 64.757812 45.335938 L 69.996094 45.335938 L 69.996094 50.574219 L 64.757812 50.574219 Z M 64.757812 45.335938 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="f58e5b4ac9">
          <path
            d="M 115.285156 58.003906 L 120.523438 58.003906 L 120.523438 63.242188 L 115.285156 63.242188 Z M 115.285156 58.003906 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="d13cdc563b">
          <path
            d="M 114.667969 45.335938 L 119.902344 45.335938 L 119.902344 50.574219 L 114.667969 50.574219 Z M 114.667969 45.335938 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="fd68c38272">
          <path
            d="M 131.089844 71.011719 L 136.324219 71.011719 L 136.324219 76.25 L 131.089844 76.25 Z M 131.089844 71.011719 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="7a7e3c8a7b">
          <path
            d="M 130.46875 56.621094 L 135.707031 56.621094 L 135.707031 61.855469 L 130.46875 61.855469 Z M 130.46875 56.621094 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="d9c7acd0f6">
          <path
            d="M 131.707031 98.074219 L 136.945312 98.074219 L 136.945312 103.3125 L 131.707031 103.3125 Z M 131.707031 98.074219 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="a967de1404">
          <path
            d="M 131.089844 85.40625 L 136.324219 85.40625 L 136.324219 90.640625 L 131.089844 90.640625 Z M 131.089844 85.40625 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="02cac6f777">
          <path
            d="M 132.328125 126.914062 L 137.5625 126.914062 L 137.5625 132.152344 L 132.328125 132.152344 Z M 132.328125 126.914062 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="c74c68d8e5">
          <path
            d="M 131.707031 114.246094 L 136.945312 114.246094 L 136.945312 119.480469 L 131.707031 119.480469 Z M 131.707031 114.246094 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="fc3d68bb45">
          <path
            d="M 33.554688 77.496094 L 38.789062 77.496094 L 38.789062 82.730469 L 33.554688 82.730469 Z M 33.554688 77.496094 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="ac7ebb36b7">
          <path
            d="M 34.171875 104.554688 L 39.410156 104.554688 L 39.410156 109.792969 L 34.171875 109.792969 Z M 34.171875 104.554688 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="204e49c3a4">
          <path
            d="M 33.554688 91.886719 L 38.789062 91.886719 L 38.789062 97.125 L 33.554688 97.125 Z M 33.554688 91.886719 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="6bfdfc0af7">
          <path
            d="M 147.878906 77.496094 L 153.113281 77.496094 L 153.113281 82.730469 L 147.878906 82.730469 Z M 147.878906 77.496094 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="1be0ddd418">
          <path
            d="M 148.496094 104.554688 L 153.734375 104.554688 L 153.734375 109.792969 L 148.496094 109.792969 Z M 148.496094 104.554688 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="803d0df4d8">
          <path
            d="M 147.878906 91.886719 L 153.113281 91.886719 L 153.113281 97.125 L 147.878906 97.125 Z M 147.878906 91.886719 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="cce4627918">
          <path
            d="M 161.34375 95.832031 L 166.578125 95.832031 L 166.578125 101.066406 L 161.34375 101.066406 Z M 161.34375 95.832031 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="0daf05f731">
          <path
            d="M 19.628906 80.457031 L 24.863281 80.457031 L 24.863281 85.691406 L 19.628906 85.691406 Z M 19.628906 80.457031 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="61c7c2d8b4">
          <path
            d="M 82.933594 155.632812 L 88.167969 155.632812 L 88.167969 160.867188 L 82.933594 160.867188 Z M 82.933594 155.632812 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="d033154610">
          <path
            d="M 98.132812 155.925781 L 103.367188 155.925781 L 103.367188 161.160156 L 98.132812 161.160156 Z M 98.132812 155.925781 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="1c20ef240e">
          <path
            d="M 82.933594 144.347656 L 88.167969 144.347656 L 88.167969 149.582031 L 82.933594 149.582031 Z M 82.933594 144.347656 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="7f7748c618">
          <path
            d="M 98.132812 144.347656 L 103.367188 144.347656 L 103.367188 149.582031 L 98.132812 149.582031 Z M 98.132812 144.347656 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="effb1b7180">
          <path
            d="M 67.851562 150.683594 L 73.089844 150.683594 L 73.089844 155.917969 L 67.851562 155.917969 Z M 67.851562 150.683594 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="94c59ccdd7">
          <path
            d="M 90.359375 29.101562 L 95.59375 29.101562 L 95.59375 34.335938 L 90.359375 34.335938 Z M 90.359375 29.101562 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="8c21d2f6af">
          <path
            d="M 112.191406 153.15625 L 117.425781 153.15625 L 117.425781 158.394531 L 112.191406 158.394531 Z M 112.191406 153.15625 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="1424f33d08">
          <path
            d="M 59.808594 138.648438 L 65.042969 138.648438 L 65.042969 143.886719 L 59.808594 143.886719 Z M 59.808594 138.648438 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="9e025c47fd">
          <path
            d="M 127.636719 140.09375 L 132.875 140.09375 L 132.875 145.328125 L 127.636719 145.328125 Z M 127.636719 140.09375 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="02ad979297">
          <path
            d="M 125.164062 158.402344 L 130.398438 158.402344 L 130.398438 163.636719 L 125.164062 163.636719 Z M 125.164062 158.402344 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="196b705a6c">
          <path
            d="M 55.035156 148.207031 L 60.269531 148.207031 L 60.269531 153.441406 L 55.035156 153.441406 Z M 55.035156 148.207031 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="7b7600d96e">
          <path
            d="M 43.894531 136.921875 L 49.132812 136.921875 L 49.132812 142.160156 L 43.894531 142.160156 Z M 43.894531 136.921875 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="f2144e24c2">
          <path
            d="M 143.546875 121.964844 L 148.785156 121.964844 L 148.785156 127.199219 L 143.546875 127.199219 Z M 143.546875 121.964844 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="0cc974f2ae">
          <path
            d="M 16.226562 98.304688 L 21.460938 98.304688 L 21.460938 103.542969 L 16.226562 103.542969 Z M 16.226562 98.304688 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="eb6644c922">
          <path
            d="M 28.191406 63.703125 L 33.429688 63.703125 L 33.429688 68.941406 L 28.191406 68.941406 Z M 28.191406 63.703125 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="2563a17b90">
          <path
            d="M 161.34375 111.027344 L 166.578125 111.027344 L 166.578125 116.265625 L 161.34375 116.265625 Z M 161.34375 111.027344 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="11c7c7ee1c">
          <path
            d="M 34.171875 117.015625 L 39.410156 117.015625 L 39.410156 122.25 L 34.171875 122.25 Z M 34.171875 117.015625 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="90aa684249">
          <path
            d="M 23.242188 122.949219 L 28.480469 122.949219 L 28.480469 128.183594 L 23.242188 128.183594 Z M 23.242188 122.949219 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="8152c22afa">
          <path
            d="M 156.394531 126.914062 L 161.628906 126.914062 L 161.628906 132.152344 L 156.394531 132.152344 Z M 156.394531 126.914062 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="555d1a855a">
          <path
            d="M 142.929688 137.617188 L 148.164062 137.617188 L 148.164062 142.855469 L 142.929688 142.855469 Z M 142.929688 137.617188 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="424a9d1243">
          <path
            d="M 63.261719 163.113281 L 68.5 163.113281 L 68.5 168.347656 L 63.261719 168.347656 Z M 63.261719 163.113281 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="5c82a40235">
          <path
            d="M 77.730469 164.847656 L 82.964844 164.847656 L 82.964844 170.082031 L 77.730469 170.082031 Z M 77.730469 164.847656 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="20c2034c70">
          <path
            d="M 28.601562 134.445312 L 33.839844 134.445312 L 33.839844 139.683594 L 28.601562 139.683594 Z M 28.601562 134.445312 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="9e9b179727">
          <path
            d="M 115.285156 141.871094 L 120.523438 141.871094 L 120.523438 147.109375 L 115.285156 147.109375 Z M 115.285156 141.871094 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="399800d150">
          <path
            d="M 135.421875 148.207031 L 140.65625 148.207031 L 140.65625 153.441406 L 135.421875 153.441406 Z M 135.421875 148.207031 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="c2354a4865">
          <path
            d="M 106.023438 165.824219 L 111.261719 165.824219 L 111.261719 171.0625 L 106.023438 171.0625 Z M 106.023438 165.824219 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="321eddb893">
          <path
            d="M 90.359375 168.300781 L 95.59375 168.300781 L 95.59375 173.539062 L 90.359375 173.539062 Z M 90.359375 168.300781 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="07a31d7983">
          <path
            d="M 37.394531 144.347656 L 42.632812 144.347656 L 42.632812 149.582031 L 37.394531 149.582031 Z M 37.394531 144.347656 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="98c24e6c88">
          <path
            d="M 124.488281 42.113281 L 129.722656 42.113281 L 129.722656 47.347656 L 124.488281 47.347656 Z M 124.488281 42.113281 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="7e87e0c27e">
          <path
            d="M 108.5 33.304688 L 113.734375 33.304688 L 113.734375 38.539062 L 108.5 38.539062 Z M 108.5 33.304688 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="d1614c3dfb">
          <path
            d="M 70.710938 34.050781 L 75.949219 34.050781 L 75.949219 39.289062 L 70.710938 39.289062 Z M 70.710938 34.050781 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="af0dbac329">
          <path
            d="M 54.414062 36.527344 L 59.652344 36.527344 L 59.652344 41.761719 L 54.414062 41.761719 Z M 54.414062 36.527344 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="ee3e77e27e">
          <path
            d="M 142.929688 60.082031 L 148.164062 60.082031 L 148.164062 65.316406 L 142.929688 65.316406 Z M 142.929688 60.082031 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="427bb38dd6">
          <path
            d="M 38.503906 49.195312 L 43.738281 49.195312 L 43.738281 54.433594 L 38.503906 54.433594 Z M 38.503906 49.195312 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="66f6a20ecf">
          <path
            d="M 154.664062 67.046875 L 159.902344 67.046875 L 159.902344 72.285156 L 154.664062 72.285156 Z M 154.664062 67.046875 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="e1b078085c">
          <path
            d="M 139.621094 48.449219 L 144.859375 48.449219 L 144.859375 53.683594 L 139.621094 53.683594 Z M 139.621094 48.449219 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="24282cd448">
          <path
            d="M 18.292969 110.28125 L 23.527344 110.28125 L 23.527344 115.515625 L 18.292969 115.515625 Z M 18.292969 110.28125 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="b1be62dd83">
          <path
            d="M 161.34375 81.441406 L 166.578125 81.441406 L 166.578125 86.675781 L 161.34375 86.675781 Z M 161.34375 81.441406 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="e751de5255">
          <path
            d="M 48.226562 156.90625 L 53.464844 156.90625 L 53.464844 162.140625 L 48.226562 162.140625 Z M 48.226562 156.90625 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#96bf3b87be)">
        <g clip-path="url(#fb33f08e27)">
          <path
            fill="#d4e3fd"
            d="M 0 0 L 187 0 L 187 187 L 0 187 Z M 0 0 "
            fill-opacity="1"
            fill-rule="nonzero"
          />
        </g>
      </g>
      <g clip-path="url(#b7f124f335)">
        <path
          fill="#000000"
          d="M 87.785156 54.289062 C 87.785156 54.636719 87.71875 54.96875 87.585938 55.292969 C 87.453125 55.613281 87.265625 55.894531 87.019531 56.140625 C 86.773438 56.386719 86.488281 56.574219 86.167969 56.707031 C 85.847656 56.839844 85.515625 56.90625 85.167969 56.90625 C 84.820312 56.90625 84.484375 56.839844 84.164062 56.707031 C 83.84375 56.574219 83.5625 56.386719 83.316406 56.140625 C 83.070312 55.894531 82.882812 55.613281 82.75 55.292969 C 82.617188 54.96875 82.550781 54.636719 82.550781 54.289062 C 82.550781 53.941406 82.617188 53.609375 82.75 53.289062 C 82.882812 52.964844 83.070312 52.683594 83.316406 52.4375 C 83.5625 52.191406 83.84375 52.003906 84.164062 51.871094 C 84.484375 51.738281 84.820312 51.671875 85.167969 51.671875 C 85.515625 51.671875 85.847656 51.738281 86.167969 51.871094 C 86.488281 52.003906 86.773438 52.191406 87.019531 52.4375 C 87.265625 52.683594 87.453125 52.964844 87.585938 53.289062 C 87.71875 53.609375 87.785156 53.941406 87.785156 54.289062 Z M 87.785156 54.289062 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#504c26057e)">
        <path
          fill="#000000"
          d="M 102.984375 54.289062 C 102.984375 54.636719 102.917969 54.96875 102.785156 55.292969 C 102.652344 55.613281 102.464844 55.894531 102.21875 56.140625 C 101.972656 56.386719 101.6875 56.574219 101.367188 56.707031 C 101.046875 56.839844 100.714844 56.90625 100.367188 56.90625 C 100.019531 56.90625 99.683594 56.839844 99.363281 56.707031 C 99.042969 56.574219 98.761719 56.386719 98.515625 56.140625 C 98.269531 55.894531 98.082031 55.613281 97.949219 55.292969 C 97.816406 54.96875 97.75 54.636719 97.75 54.289062 C 97.75 53.941406 97.816406 53.609375 97.949219 53.289062 C 98.082031 52.964844 98.269531 52.683594 98.515625 52.4375 C 98.761719 52.191406 99.042969 52.003906 99.363281 51.871094 C 99.683594 51.738281 100.019531 51.671875 100.367188 51.671875 C 100.714844 51.671875 101.046875 51.738281 101.367188 51.871094 C 101.6875 52.003906 101.972656 52.191406 102.21875 52.4375 C 102.464844 52.683594 102.652344 52.964844 102.785156 53.289062 C 102.917969 53.609375 102.984375 53.941406 102.984375 54.289062 Z M 102.984375 54.289062 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#f6a48beb4a)">
        <path
          fill="#000000"
          d="M 93.558594 99.65625 C 102.457031 99.65625 109.699219 90.195312 109.699219 81.285156 C 109.699219 72.375 102.457031 65.125 93.558594 65.125 C 84.65625 65.125 77.414062 72.375 77.414062 81.285156 C 77.417969 90.195312 84.65625 99.65625 93.558594 99.65625 Z M 93.558594 99.65625 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#34ee4daf3c)">
        <path
          fill="#000000"
          d="M 105.675781 99.820312 C 102.542969 102.210938 98.636719 103.628906 94.40625 103.628906 L 92.707031 103.628906 C 88.476562 103.628906 84.570312 102.207031 81.4375 99.820312 C 71.214844 101.476562 63.410156 110.347656 63.410156 121.050781 C 63.410156 126.929688 76.90625 131.695312 93.558594 131.695312 C 110.207031 131.695312 123.707031 126.929688 123.707031 121.050781 C 123.707031 110.347656 115.898438 101.476562 105.675781 99.820312 Z M 105.675781 99.820312 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#9980e18154)">
        <path
          fill="#000000"
          d="M 87.167969 41.621094 C 87.167969 41.96875 87.101562 42.300781 86.964844 42.621094 C 86.832031 42.941406 86.644531 43.226562 86.398438 43.472656 C 86.152344 43.714844 85.871094 43.90625 85.550781 44.039062 C 85.230469 44.171875 84.894531 44.238281 84.546875 44.238281 C 84.199219 44.238281 83.867188 44.171875 83.546875 44.039062 C 83.226562 43.90625 82.941406 43.714844 82.695312 43.472656 C 82.453125 43.226562 82.261719 42.941406 82.128906 42.621094 C 81.996094 42.300781 81.929688 41.96875 81.929688 41.621094 C 81.929688 41.273438 81.996094 40.9375 82.128906 40.617188 C 82.261719 40.296875 82.453125 40.015625 82.695312 39.769531 C 82.941406 39.523438 83.226562 39.335938 83.546875 39.203125 C 83.867188 39.066406 84.199219 39 84.546875 39 C 84.894531 39 85.230469 39.066406 85.550781 39.203125 C 85.871094 39.335938 86.152344 39.523438 86.398438 39.769531 C 86.644531 40.015625 86.832031 40.296875 86.964844 40.617188 C 87.101562 40.9375 87.167969 41.273438 87.167969 41.621094 Z M 87.167969 41.621094 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#c908f87b3f)">
        <path
          fill="#000000"
          d="M 102.367188 41.621094 C 102.367188 41.96875 102.300781 42.300781 102.164062 42.621094 C 102.03125 42.941406 101.84375 43.226562 101.597656 43.472656 C 101.351562 43.714844 101.070312 43.90625 100.75 44.039062 C 100.429688 44.171875 100.09375 44.238281 99.746094 44.238281 C 99.398438 44.238281 99.066406 44.171875 98.746094 44.039062 C 98.425781 43.90625 98.140625 43.714844 97.894531 43.472656 C 97.652344 43.226562 97.460938 42.941406 97.328125 42.621094 C 97.195312 42.300781 97.128906 41.96875 97.128906 41.621094 C 97.128906 41.273438 97.195312 40.9375 97.328125 40.617188 C 97.460938 40.296875 97.652344 40.015625 97.894531 39.769531 C 98.140625 39.523438 98.425781 39.335938 98.746094 39.203125 C 99.066406 39.066406 99.398438 39 99.746094 39 C 100.09375 39 100.429688 39.066406 100.75 39.203125 C 101.070312 39.335938 101.351562 39.523438 101.597656 39.769531 C 101.84375 40.015625 102.03125 40.296875 102.164062 40.617188 C 102.300781 40.9375 102.367188 41.273438 102.367188 41.621094 Z M 102.367188 41.621094 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#14c071a743)">
        <path
          fill="#000000"
          d="M 54.082031 72.140625 C 54.082031 72.488281 54.015625 72.824219 53.882812 73.144531 C 53.75 73.464844 53.5625 73.746094 53.316406 73.992188 C 53.070312 74.238281 52.789062 74.425781 52.464844 74.558594 C 52.144531 74.691406 51.8125 74.757812 51.464844 74.757812 C 51.117188 74.757812 50.785156 74.691406 50.460938 74.558594 C 50.140625 74.425781 49.859375 74.238281 49.613281 73.992188 C 49.367188 73.746094 49.179688 73.464844 49.046875 73.144531 C 48.914062 72.824219 48.847656 72.488281 48.847656 72.140625 C 48.847656 71.792969 48.914062 71.460938 49.046875 71.140625 C 49.179688 70.820312 49.367188 70.535156 49.613281 70.289062 C 49.859375 70.042969 50.140625 69.855469 50.460938 69.722656 C 50.785156 69.589844 51.117188 69.523438 51.464844 69.523438 C 51.8125 69.523438 52.144531 69.589844 52.464844 69.722656 C 52.789062 69.855469 53.070312 70.042969 53.316406 70.289062 C 53.5625 70.535156 53.75 70.820312 53.882812 71.140625 C 54.015625 71.460938 54.082031 71.792969 54.082031 72.140625 Z M 54.082031 72.140625 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#dee12e8c66)">
        <path
          fill="#000000"
          d="M 53.464844 57.75 C 53.464844 58.097656 53.398438 58.429688 53.265625 58.75 C 53.132812 59.070312 52.941406 59.355469 52.695312 59.601562 C 52.453125 59.84375 52.167969 60.035156 51.847656 60.167969 C 51.527344 60.300781 51.191406 60.367188 50.84375 60.367188 C 50.5 60.367188 50.164062 60.300781 49.84375 60.167969 C 49.523438 60.035156 49.238281 59.84375 48.996094 59.601562 C 48.75 59.355469 48.558594 59.070312 48.425781 58.75 C 48.292969 58.429688 48.226562 58.097656 48.226562 57.75 C 48.226562 57.402344 48.292969 57.066406 48.425781 56.746094 C 48.558594 56.425781 48.75 56.144531 48.996094 55.898438 C 49.238281 55.652344 49.523438 55.464844 49.84375 55.332031 C 50.164062 55.195312 50.5 55.128906 50.84375 55.128906 C 51.191406 55.128906 51.527344 55.195312 51.847656 55.332031 C 52.167969 55.464844 52.453125 55.652344 52.695312 55.898438 C 52.941406 56.144531 53.132812 56.425781 53.265625 56.746094 C 53.398438 57.066406 53.464844 57.402344 53.464844 57.75 Z M 53.464844 57.75 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#32e26c3b82)">
        <path
          fill="#000000"
          d="M 54.703125 99.203125 C 54.703125 99.550781 54.636719 99.882812 54.503906 100.203125 C 54.371094 100.527344 54.179688 100.808594 53.933594 101.054688 C 53.6875 101.300781 53.40625 101.488281 53.085938 101.621094 C 52.765625 101.753906 52.429688 101.820312 52.082031 101.820312 C 51.738281 101.820312 51.402344 101.753906 51.082031 101.621094 C 50.761719 101.488281 50.476562 101.300781 50.230469 101.054688 C 49.988281 100.808594 49.796875 100.527344 49.664062 100.203125 C 49.53125 99.882812 49.464844 99.550781 49.464844 99.203125 C 49.464844 98.855469 49.53125 98.523438 49.664062 98.203125 C 49.796875 97.878906 49.988281 97.597656 50.230469 97.351562 C 50.476562 97.105469 50.761719 96.917969 51.082031 96.785156 C 51.402344 96.652344 51.738281 96.585938 52.082031 96.585938 C 52.429688 96.585938 52.765625 96.652344 53.085938 96.785156 C 53.40625 96.917969 53.6875 97.105469 53.933594 97.351562 C 54.179688 97.597656 54.371094 97.878906 54.503906 98.203125 C 54.636719 98.523438 54.703125 98.855469 54.703125 99.203125 Z M 54.703125 99.203125 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#ce0204f12d)">
        <path
          fill="#000000"
          d="M 54.082031 86.535156 C 54.082031 86.882812 54.015625 87.214844 53.882812 87.535156 C 53.75 87.855469 53.5625 88.140625 53.316406 88.386719 C 53.070312 88.628906 52.789062 88.820312 52.464844 88.953125 C 52.144531 89.085938 51.8125 89.152344 51.464844 89.152344 C 51.117188 89.152344 50.785156 89.085938 50.460938 88.953125 C 50.140625 88.820312 49.859375 88.628906 49.613281 88.386719 C 49.367188 88.140625 49.179688 87.855469 49.046875 87.535156 C 48.914062 87.214844 48.847656 86.882812 48.847656 86.535156 C 48.847656 86.1875 48.914062 85.851562 49.046875 85.53125 C 49.179688 85.210938 49.367188 84.929688 49.613281 84.683594 C 49.859375 84.4375 50.140625 84.246094 50.460938 84.113281 C 50.785156 83.980469 51.117188 83.914062 51.464844 83.914062 C 51.8125 83.914062 52.144531 83.980469 52.464844 84.113281 C 52.789062 84.246094 53.070312 84.4375 53.316406 84.683594 C 53.5625 84.929688 53.75 85.210938 53.882812 85.53125 C 54.015625 85.851562 54.082031 86.1875 54.082031 86.535156 Z M 54.082031 86.535156 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#5c517937a5)">
        <path
          fill="#000000"
          d="M 55.320312 128.042969 C 55.320312 128.390625 55.253906 128.722656 55.121094 129.042969 C 54.988281 129.363281 54.800781 129.648438 54.554688 129.894531 C 54.308594 130.140625 54.023438 130.328125 53.703125 130.460938 C 53.382812 130.59375 53.050781 130.660156 52.703125 130.660156 C 52.355469 130.660156 52.023438 130.59375 51.699219 130.460938 C 51.378906 130.328125 51.097656 130.140625 50.851562 129.894531 C 50.605469 129.648438 50.417969 129.363281 50.285156 129.042969 C 50.152344 128.722656 50.085938 128.390625 50.085938 128.042969 C 50.085938 127.695312 50.152344 127.359375 50.285156 127.039062 C 50.417969 126.71875 50.605469 126.4375 50.851562 126.191406 C 51.097656 125.945312 51.378906 125.757812 51.699219 125.625 C 52.023438 125.492188 52.355469 125.425781 52.703125 125.425781 C 53.050781 125.425781 53.382812 125.492188 53.703125 125.625 C 54.023438 125.757812 54.308594 125.945312 54.554688 126.191406 C 54.800781 126.4375 54.988281 126.71875 55.121094 127.039062 C 55.253906 127.359375 55.320312 127.695312 55.320312 128.042969 Z M 55.320312 128.042969 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#783e9066aa)">
        <path
          fill="#000000"
          d="M 54.703125 115.375 C 54.703125 115.71875 54.636719 116.054688 54.503906 116.375 C 54.371094 116.695312 54.179688 116.980469 53.933594 117.222656 C 53.6875 117.46875 53.40625 117.660156 53.085938 117.792969 C 52.765625 117.925781 52.429688 117.992188 52.082031 117.992188 C 51.738281 117.992188 51.402344 117.925781 51.082031 117.792969 C 50.761719 117.660156 50.476562 117.46875 50.230469 117.222656 C 49.988281 116.980469 49.796875 116.695312 49.664062 116.375 C 49.53125 116.054688 49.464844 115.71875 49.464844 115.375 C 49.464844 115.027344 49.53125 114.691406 49.664062 114.371094 C 49.796875 114.050781 49.988281 113.765625 50.230469 113.523438 C 50.476562 113.277344 50.761719 113.085938 51.082031 112.953125 C 51.402344 112.820312 51.738281 112.753906 52.082031 112.753906 C 52.429688 112.753906 52.765625 112.820312 53.085938 112.953125 C 53.40625 113.085938 53.6875 113.277344 53.933594 113.523438 C 54.179688 113.765625 54.371094 114.050781 54.503906 114.371094 C 54.636719 114.691406 54.703125 115.027344 54.703125 115.375 Z M 54.703125 115.375 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#c428eb5883)">
        <path
          fill="#000000"
          d="M 70.613281 60.625 C 70.613281 60.972656 70.546875 61.304688 70.414062 61.625 C 70.28125 61.945312 70.09375 62.230469 69.847656 62.476562 C 69.601562 62.71875 69.320312 62.910156 68.996094 63.042969 C 68.675781 63.175781 68.34375 63.242188 67.996094 63.242188 C 67.648438 63.242188 67.316406 63.175781 66.992188 63.042969 C 66.671875 62.910156 66.390625 62.71875 66.144531 62.476562 C 65.898438 62.230469 65.710938 61.945312 65.578125 61.625 C 65.445312 61.304688 65.378906 60.972656 65.378906 60.625 C 65.378906 60.277344 65.445312 59.941406 65.578125 59.621094 C 65.710938 59.300781 65.898438 59.019531 66.144531 58.773438 C 66.390625 58.527344 66.671875 58.335938 66.992188 58.203125 C 67.316406 58.070312 67.648438 58.003906 67.996094 58.003906 C 68.34375 58.003906 68.675781 58.070312 68.996094 58.203125 C 69.320312 58.335938 69.601562 58.527344 69.847656 58.773438 C 70.09375 59.019531 70.28125 59.300781 70.414062 59.621094 C 70.546875 59.941406 70.613281 60.277344 70.613281 60.625 Z M 70.613281 60.625 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#27141fbb31)">
        <path
          fill="#000000"
          d="M 69.996094 47.953125 C 69.996094 48.300781 69.929688 48.636719 69.796875 48.957031 C 69.664062 49.277344 69.472656 49.558594 69.226562 49.804688 C 68.984375 50.050781 68.699219 50.242188 68.378906 50.375 C 68.058594 50.507812 67.722656 50.574219 67.375 50.574219 C 67.03125 50.574219 66.695312 50.507812 66.375 50.375 C 66.054688 50.242188 65.769531 50.050781 65.527344 49.804688 C 65.28125 49.558594 65.089844 49.277344 64.957031 48.957031 C 64.824219 48.636719 64.757812 48.300781 64.757812 47.953125 C 64.757812 47.605469 64.824219 47.273438 64.957031 46.953125 C 65.089844 46.632812 65.28125 46.347656 65.527344 46.101562 C 65.769531 45.859375 66.054688 45.667969 66.375 45.535156 C 66.695312 45.402344 67.03125 45.335938 67.375 45.335938 C 67.722656 45.335938 68.058594 45.402344 68.378906 45.535156 C 68.699219 45.667969 68.984375 45.859375 69.226562 46.101562 C 69.472656 46.347656 69.664062 46.632812 69.796875 46.953125 C 69.929688 47.273438 69.996094 47.605469 69.996094 47.953125 Z M 69.996094 47.953125 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#f58e5b4ac9)">
        <path
          fill="#000000"
          d="M 120.523438 60.625 C 120.523438 60.972656 120.457031 61.304688 120.320312 61.625 C 120.1875 61.945312 120 62.230469 119.753906 62.476562 C 119.507812 62.71875 119.226562 62.910156 118.90625 63.042969 C 118.585938 63.175781 118.25 63.242188 117.902344 63.242188 C 117.554688 63.242188 117.222656 63.175781 116.902344 63.042969 C 116.582031 62.910156 116.296875 62.71875 116.050781 62.476562 C 115.808594 62.230469 115.617188 61.945312 115.484375 61.625 C 115.351562 61.304688 115.285156 60.972656 115.285156 60.625 C 115.285156 60.277344 115.351562 59.941406 115.484375 59.621094 C 115.617188 59.300781 115.808594 59.019531 116.050781 58.773438 C 116.296875 58.527344 116.582031 58.335938 116.902344 58.203125 C 117.222656 58.070312 117.554688 58.003906 117.902344 58.003906 C 118.25 58.003906 118.585938 58.070312 118.90625 58.203125 C 119.226562 58.335938 119.507812 58.527344 119.753906 58.773438 C 120 59.019531 120.1875 59.300781 120.320312 59.621094 C 120.457031 59.941406 120.523438 60.277344 120.523438 60.625 Z M 120.523438 60.625 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#d13cdc563b)">
        <path
          fill="#000000"
          d="M 119.902344 47.953125 C 119.902344 48.300781 119.835938 48.636719 119.703125 48.957031 C 119.570312 49.277344 119.382812 49.558594 119.136719 49.804688 C 118.890625 50.050781 118.605469 50.242188 118.285156 50.375 C 117.964844 50.507812 117.632812 50.574219 117.285156 50.574219 C 116.9375 50.574219 116.601562 50.507812 116.28125 50.375 C 115.960938 50.242188 115.679688 50.050781 115.433594 49.804688 C 115.1875 49.558594 115 49.277344 114.867188 48.957031 C 114.734375 48.636719 114.667969 48.300781 114.667969 47.953125 C 114.667969 47.605469 114.734375 47.273438 114.867188 46.953125 C 115 46.632812 115.1875 46.347656 115.433594 46.101562 C 115.679688 45.859375 115.960938 45.667969 116.28125 45.535156 C 116.601562 45.402344 116.9375 45.335938 117.285156 45.335938 C 117.632812 45.335938 117.964844 45.402344 118.285156 45.535156 C 118.605469 45.667969 118.890625 45.859375 119.136719 46.101562 C 119.382812 46.347656 119.570312 46.632812 119.703125 46.953125 C 119.835938 47.273438 119.902344 47.605469 119.902344 47.953125 Z M 119.902344 47.953125 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#fd68c38272)">
        <path
          fill="#000000"
          d="M 136.324219 73.632812 C 136.324219 73.980469 136.257812 74.3125 136.125 74.632812 C 135.992188 74.953125 135.804688 75.238281 135.558594 75.484375 C 135.3125 75.726562 135.03125 75.917969 134.710938 76.050781 C 134.390625 76.183594 134.054688 76.25 133.707031 76.25 C 133.359375 76.25 133.027344 76.183594 132.707031 76.050781 C 132.386719 75.917969 132.101562 75.726562 131.855469 75.484375 C 131.609375 75.238281 131.421875 74.953125 131.289062 74.632812 C 131.15625 74.3125 131.089844 73.980469 131.089844 73.632812 C 131.089844 73.285156 131.15625 72.949219 131.289062 72.628906 C 131.421875 72.308594 131.609375 72.027344 131.855469 71.78125 C 132.101562 71.535156 132.386719 71.347656 132.707031 71.214844 C 133.027344 71.082031 133.359375 71.011719 133.707031 71.011719 C 134.054688 71.011719 134.390625 71.082031 134.710938 71.214844 C 135.03125 71.347656 135.3125 71.535156 135.558594 71.78125 C 135.804688 72.027344 135.992188 72.308594 136.125 72.628906 C 136.257812 72.949219 136.324219 73.285156 136.324219 73.632812 Z M 136.324219 73.632812 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#7a7e3c8a7b)">
        <path
          fill="#000000"
          d="M 135.707031 59.238281 C 135.707031 59.585938 135.640625 59.921875 135.507812 60.242188 C 135.375 60.5625 135.183594 60.84375 134.941406 61.089844 C 134.695312 61.335938 134.410156 61.523438 134.089844 61.65625 C 133.769531 61.789062 133.4375 61.855469 133.089844 61.855469 C 132.742188 61.855469 132.40625 61.789062 132.085938 61.65625 C 131.765625 61.523438 131.484375 61.335938 131.238281 61.089844 C 130.992188 60.84375 130.804688 60.5625 130.667969 60.242188 C 130.535156 59.921875 130.46875 59.585938 130.46875 59.238281 C 130.46875 58.890625 130.535156 58.558594 130.667969 58.238281 C 130.804688 57.917969 130.992188 57.632812 131.238281 57.386719 C 131.484375 57.140625 131.765625 56.953125 132.085938 56.820312 C 132.40625 56.6875 132.742188 56.621094 133.089844 56.621094 C 133.4375 56.621094 133.769531 56.6875 134.089844 56.820312 C 134.410156 56.953125 134.695312 57.140625 134.941406 57.386719 C 135.183594 57.632812 135.375 57.917969 135.507812 58.238281 C 135.640625 58.558594 135.707031 58.890625 135.707031 59.238281 Z M 135.707031 59.238281 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#d9c7acd0f6)">
        <path
          fill="#000000"
          d="M 136.945312 100.695312 C 136.945312 101.039062 136.878906 101.375 136.746094 101.695312 C 136.613281 102.015625 136.421875 102.300781 136.179688 102.542969 C 135.933594 102.789062 135.648438 102.980469 135.328125 103.113281 C 135.007812 103.246094 134.675781 103.3125 134.328125 103.3125 C 133.980469 103.3125 133.644531 103.246094 133.324219 103.113281 C 133.003906 102.980469 132.722656 102.789062 132.476562 102.542969 C 132.230469 102.300781 132.039062 102.015625 131.90625 101.695312 C 131.773438 101.375 131.707031 101.039062 131.707031 100.695312 C 131.707031 100.347656 131.773438 100.011719 131.90625 99.691406 C 132.039062 99.371094 132.230469 99.085938 132.476562 98.84375 C 132.722656 98.597656 133.003906 98.40625 133.324219 98.273438 C 133.644531 98.140625 133.980469 98.074219 134.328125 98.074219 C 134.675781 98.074219 135.007812 98.140625 135.328125 98.273438 C 135.648438 98.40625 135.933594 98.597656 136.179688 98.84375 C 136.421875 99.085938 136.613281 99.371094 136.746094 99.691406 C 136.878906 100.011719 136.945312 100.347656 136.945312 100.695312 Z M 136.945312 100.695312 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#a967de1404)">
        <path
          fill="#000000"
          d="M 136.324219 88.023438 C 136.324219 88.371094 136.257812 88.707031 136.125 89.027344 C 135.992188 89.347656 135.804688 89.628906 135.558594 89.875 C 135.3125 90.121094 135.03125 90.308594 134.710938 90.441406 C 134.390625 90.574219 134.054688 90.640625 133.707031 90.640625 C 133.359375 90.640625 133.027344 90.574219 132.707031 90.441406 C 132.386719 90.308594 132.101562 90.121094 131.855469 89.875 C 131.609375 89.628906 131.421875 89.347656 131.289062 89.027344 C 131.15625 88.707031 131.089844 88.371094 131.089844 88.023438 C 131.089844 87.675781 131.15625 87.34375 131.289062 87.023438 C 131.421875 86.703125 131.609375 86.417969 131.855469 86.171875 C 132.101562 85.925781 132.386719 85.738281 132.707031 85.605469 C 133.027344 85.472656 133.359375 85.40625 133.707031 85.40625 C 134.054688 85.40625 134.390625 85.472656 134.710938 85.605469 C 135.03125 85.738281 135.3125 85.925781 135.558594 86.171875 C 135.804688 86.417969 135.992188 86.703125 136.125 87.023438 C 136.257812 87.34375 136.324219 87.675781 136.324219 88.023438 Z M 136.324219 88.023438 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#02cac6f777)">
        <path
          fill="#000000"
          d="M 137.5625 129.53125 C 137.5625 129.878906 137.496094 130.214844 137.363281 130.535156 C 137.230469 130.855469 137.042969 131.136719 136.796875 131.382812 C 136.550781 131.628906 136.269531 131.820312 135.949219 131.953125 C 135.628906 132.085938 135.292969 132.152344 134.945312 132.152344 C 134.597656 132.152344 134.265625 132.085938 133.945312 131.953125 C 133.625 131.820312 133.339844 131.628906 133.09375 131.382812 C 132.847656 131.136719 132.660156 130.855469 132.527344 130.535156 C 132.394531 130.214844 132.328125 129.878906 132.328125 129.53125 C 132.328125 129.1875 132.394531 128.851562 132.527344 128.53125 C 132.660156 128.210938 132.847656 127.925781 133.09375 127.679688 C 133.339844 127.4375 133.625 127.246094 133.945312 127.113281 C 134.265625 126.980469 134.597656 126.914062 134.945312 126.914062 C 135.292969 126.914062 135.628906 126.980469 135.949219 127.113281 C 136.269531 127.246094 136.550781 127.4375 136.796875 127.679688 C 137.042969 127.925781 137.230469 128.210938 137.363281 128.53125 C 137.496094 128.851562 137.5625 129.1875 137.5625 129.53125 Z M 137.5625 129.53125 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#c74c68d8e5)">
        <path
          fill="#000000"
          d="M 136.945312 116.863281 C 136.945312 117.210938 136.878906 117.542969 136.746094 117.867188 C 136.613281 118.1875 136.421875 118.46875 136.179688 118.714844 C 135.933594 118.960938 135.648438 119.148438 135.328125 119.28125 C 135.007812 119.414062 134.675781 119.480469 134.328125 119.480469 C 133.980469 119.480469 133.644531 119.414062 133.324219 119.28125 C 133.003906 119.148438 132.722656 118.960938 132.476562 118.714844 C 132.230469 118.46875 132.039062 118.1875 131.90625 117.867188 C 131.773438 117.542969 131.707031 117.210938 131.707031 116.863281 C 131.707031 116.515625 131.773438 116.183594 131.90625 115.863281 C 132.039062 115.539062 132.230469 115.257812 132.476562 115.011719 C 132.722656 114.765625 133.003906 114.578125 133.324219 114.445312 C 133.644531 114.3125 133.980469 114.246094 134.328125 114.246094 C 134.675781 114.246094 135.007812 114.3125 135.328125 114.445312 C 135.648438 114.578125 135.933594 114.765625 136.179688 115.011719 C 136.421875 115.257812 136.613281 115.539062 136.746094 115.863281 C 136.878906 116.183594 136.945312 116.515625 136.945312 116.863281 Z M 136.945312 116.863281 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#fc3d68bb45)">
        <path
          fill="#000000"
          d="M 38.789062 80.113281 C 38.789062 80.460938 38.722656 80.792969 38.589844 81.113281 C 38.457031 81.433594 38.269531 81.71875 38.023438 81.964844 C 37.777344 82.210938 37.492188 82.398438 37.171875 82.53125 C 36.851562 82.664062 36.519531 82.730469 36.171875 82.730469 C 35.824219 82.730469 35.488281 82.664062 35.167969 82.53125 C 34.847656 82.398438 34.566406 82.210938 34.320312 81.964844 C 34.074219 81.71875 33.886719 81.433594 33.753906 81.113281 C 33.621094 80.792969 33.554688 80.460938 33.554688 80.113281 C 33.554688 79.765625 33.621094 79.433594 33.753906 79.109375 C 33.886719 78.789062 34.074219 78.507812 34.320312 78.261719 C 34.566406 78.015625 34.847656 77.828125 35.167969 77.695312 C 35.488281 77.5625 35.824219 77.496094 36.171875 77.496094 C 36.519531 77.496094 36.851562 77.5625 37.171875 77.695312 C 37.492188 77.828125 37.777344 78.015625 38.023438 78.261719 C 38.269531 78.507812 38.457031 78.789062 38.589844 79.109375 C 38.722656 79.433594 38.789062 79.765625 38.789062 80.113281 Z M 38.789062 80.113281 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#ac7ebb36b7)">
        <path
          fill="#000000"
          d="M 39.410156 107.175781 C 39.410156 107.523438 39.34375 107.855469 39.210938 108.175781 C 39.078125 108.496094 38.886719 108.78125 38.640625 109.027344 C 38.394531 109.269531 38.113281 109.460938 37.792969 109.59375 C 37.472656 109.726562 37.136719 109.792969 36.789062 109.792969 C 36.441406 109.792969 36.109375 109.726562 35.789062 109.59375 C 35.46875 109.460938 35.183594 109.269531 34.9375 109.027344 C 34.695312 108.78125 34.503906 108.496094 34.371094 108.175781 C 34.238281 107.855469 34.171875 107.523438 34.171875 107.175781 C 34.171875 106.828125 34.238281 106.492188 34.371094 106.171875 C 34.503906 105.851562 34.695312 105.570312 34.9375 105.324219 C 35.183594 105.078125 35.46875 104.886719 35.789062 104.753906 C 36.109375 104.621094 36.441406 104.554688 36.789062 104.554688 C 37.136719 104.554688 37.472656 104.621094 37.792969 104.753906 C 38.113281 104.886719 38.394531 105.078125 38.640625 105.324219 C 38.886719 105.570312 39.078125 105.851562 39.210938 106.171875 C 39.34375 106.492188 39.410156 106.828125 39.410156 107.175781 Z M 39.410156 107.175781 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#204e49c3a4)">
        <path
          fill="#000000"
          d="M 38.789062 94.503906 C 38.789062 94.851562 38.722656 95.1875 38.589844 95.507812 C 38.457031 95.828125 38.269531 96.109375 38.023438 96.355469 C 37.777344 96.601562 37.492188 96.792969 37.171875 96.925781 C 36.851562 97.058594 36.519531 97.125 36.171875 97.125 C 35.824219 97.125 35.488281 97.058594 35.167969 96.925781 C 34.847656 96.792969 34.566406 96.601562 34.320312 96.355469 C 34.074219 96.109375 33.886719 95.828125 33.753906 95.507812 C 33.621094 95.1875 33.554688 94.851562 33.554688 94.503906 C 33.554688 94.15625 33.621094 93.824219 33.753906 93.503906 C 33.886719 93.183594 34.074219 92.898438 34.320312 92.652344 C 34.566406 92.410156 34.847656 92.21875 35.167969 92.085938 C 35.488281 91.953125 35.824219 91.886719 36.171875 91.886719 C 36.519531 91.886719 36.851562 91.953125 37.171875 92.085938 C 37.492188 92.21875 37.777344 92.410156 38.023438 92.652344 C 38.269531 92.898438 38.457031 93.183594 38.589844 93.503906 C 38.722656 93.824219 38.789062 94.15625 38.789062 94.503906 Z M 38.789062 94.503906 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#6bfdfc0af7)">
        <path
          fill="#000000"
          d="M 153.113281 80.113281 C 153.113281 80.460938 153.046875 80.792969 152.914062 81.113281 C 152.78125 81.433594 152.59375 81.71875 152.347656 81.964844 C 152.101562 82.210938 151.820312 82.398438 151.5 82.53125 C 151.179688 82.664062 150.84375 82.730469 150.496094 82.730469 C 150.148438 82.730469 149.816406 82.664062 149.496094 82.53125 C 149.175781 82.398438 148.890625 82.210938 148.644531 81.964844 C 148.398438 81.71875 148.210938 81.433594 148.078125 81.113281 C 147.945312 80.792969 147.878906 80.460938 147.878906 80.113281 C 147.878906 79.765625 147.945312 79.433594 148.078125 79.109375 C 148.210938 78.789062 148.398438 78.507812 148.644531 78.261719 C 148.890625 78.015625 149.175781 77.828125 149.496094 77.695312 C 149.816406 77.5625 150.148438 77.496094 150.496094 77.496094 C 150.84375 77.496094 151.179688 77.5625 151.5 77.695312 C 151.820312 77.828125 152.101562 78.015625 152.347656 78.261719 C 152.59375 78.507812 152.78125 78.789062 152.914062 79.109375 C 153.046875 79.433594 153.113281 79.765625 153.113281 80.113281 Z M 153.113281 80.113281 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#1be0ddd418)">
        <path
          fill="#000000"
          d="M 153.734375 107.175781 C 153.734375 107.523438 153.667969 107.855469 153.535156 108.175781 C 153.402344 108.496094 153.210938 108.78125 152.96875 109.027344 C 152.722656 109.269531 152.4375 109.460938 152.117188 109.59375 C 151.796875 109.726562 151.464844 109.792969 151.117188 109.792969 C 150.769531 109.792969 150.433594 109.726562 150.113281 109.59375 C 149.792969 109.460938 149.511719 109.269531 149.265625 109.027344 C 149.019531 108.78125 148.832031 108.496094 148.695312 108.175781 C 148.5625 107.855469 148.496094 107.523438 148.496094 107.175781 C 148.496094 106.828125 148.5625 106.492188 148.695312 106.171875 C 148.832031 105.851562 149.019531 105.570312 149.265625 105.324219 C 149.511719 105.078125 149.792969 104.886719 150.113281 104.753906 C 150.433594 104.621094 150.769531 104.554688 151.117188 104.554688 C 151.464844 104.554688 151.796875 104.621094 152.117188 104.753906 C 152.4375 104.886719 152.722656 105.078125 152.96875 105.324219 C 153.210938 105.570312 153.402344 105.851562 153.535156 106.171875 C 153.667969 106.492188 153.734375 106.828125 153.734375 107.175781 Z M 153.734375 107.175781 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#803d0df4d8)">
        <path
          fill="#000000"
          d="M 153.113281 94.503906 C 153.113281 94.851562 153.046875 95.1875 152.914062 95.507812 C 152.78125 95.828125 152.59375 96.109375 152.347656 96.355469 C 152.101562 96.601562 151.820312 96.792969 151.5 96.925781 C 151.179688 97.058594 150.84375 97.125 150.496094 97.125 C 150.148438 97.125 149.816406 97.058594 149.496094 96.925781 C 149.175781 96.792969 148.890625 96.601562 148.644531 96.355469 C 148.398438 96.109375 148.210938 95.828125 148.078125 95.507812 C 147.945312 95.1875 147.878906 94.851562 147.878906 94.503906 C 147.878906 94.15625 147.945312 93.824219 148.078125 93.503906 C 148.210938 93.183594 148.398438 92.898438 148.644531 92.652344 C 148.890625 92.410156 149.175781 92.21875 149.496094 92.085938 C 149.816406 91.953125 150.148438 91.886719 150.496094 91.886719 C 150.84375 91.886719 151.179688 91.953125 151.5 92.085938 C 151.820312 92.21875 152.101562 92.410156 152.347656 92.652344 C 152.59375 92.898438 152.78125 93.183594 152.914062 93.503906 C 153.046875 93.824219 153.113281 94.15625 153.113281 94.503906 Z M 153.113281 94.503906 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#cce4627918)">
        <path
          fill="#000000"
          d="M 166.578125 98.449219 C 166.578125 98.796875 166.511719 99.128906 166.378906 99.453125 C 166.246094 99.773438 166.058594 100.054688 165.8125 100.300781 C 165.566406 100.546875 165.285156 100.734375 164.960938 100.867188 C 164.640625 101 164.308594 101.066406 163.960938 101.066406 C 163.613281 101.066406 163.28125 101 162.960938 100.867188 C 162.636719 100.734375 162.355469 100.546875 162.109375 100.300781 C 161.863281 100.054688 161.675781 99.773438 161.542969 99.453125 C 161.410156 99.128906 161.34375 98.796875 161.34375 98.449219 C 161.34375 98.101562 161.410156 97.769531 161.542969 97.449219 C 161.675781 97.125 161.863281 96.84375 162.109375 96.597656 C 162.355469 96.351562 162.636719 96.164062 162.960938 96.03125 C 163.28125 95.898438 163.613281 95.832031 163.960938 95.832031 C 164.308594 95.832031 164.640625 95.898438 164.960938 96.03125 C 165.285156 96.164062 165.566406 96.351562 165.8125 96.597656 C 166.058594 96.84375 166.246094 97.125 166.378906 97.449219 C 166.511719 97.769531 166.578125 98.101562 166.578125 98.449219 Z M 166.578125 98.449219 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#0daf05f731)">
        <path
          fill="#000000"
          d="M 24.863281 83.074219 C 24.863281 83.421875 24.796875 83.753906 24.664062 84.074219 C 24.53125 84.398438 24.34375 84.679688 24.097656 84.925781 C 23.851562 85.171875 23.566406 85.359375 23.246094 85.492188 C 22.925781 85.625 22.59375 85.691406 22.246094 85.691406 C 21.898438 85.691406 21.5625 85.625 21.242188 85.492188 C 20.921875 85.359375 20.640625 85.171875 20.394531 84.925781 C 20.148438 84.679688 19.960938 84.398438 19.828125 84.074219 C 19.695312 83.753906 19.628906 83.421875 19.628906 83.074219 C 19.628906 82.726562 19.695312 82.394531 19.828125 82.070312 C 19.960938 81.75 20.148438 81.46875 20.394531 81.222656 C 20.640625 80.976562 20.921875 80.789062 21.242188 80.65625 C 21.5625 80.523438 21.898438 80.457031 22.246094 80.457031 C 22.59375 80.457031 22.925781 80.523438 23.246094 80.65625 C 23.566406 80.789062 23.851562 80.976562 24.097656 81.222656 C 24.34375 81.46875 24.53125 81.75 24.664062 82.070312 C 24.796875 82.394531 24.863281 82.726562 24.863281 83.074219 Z M 24.863281 83.074219 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#61c7c2d8b4)">
        <path
          fill="#000000"
          d="M 88.167969 158.25 C 88.167969 158.597656 88.101562 158.929688 87.96875 159.25 C 87.835938 159.574219 87.648438 159.855469 87.402344 160.101562 C 87.15625 160.347656 86.875 160.535156 86.554688 160.667969 C 86.234375 160.800781 85.898438 160.867188 85.550781 160.867188 C 85.203125 160.867188 84.871094 160.800781 84.550781 160.667969 C 84.230469 160.535156 83.945312 160.347656 83.699219 160.101562 C 83.453125 159.855469 83.265625 159.574219 83.132812 159.25 C 83 158.929688 82.933594 158.597656 82.933594 158.25 C 82.933594 157.902344 83 157.570312 83.132812 157.25 C 83.265625 156.925781 83.453125 156.644531 83.699219 156.398438 C 83.945312 156.152344 84.230469 155.964844 84.550781 155.832031 C 84.871094 155.699219 85.203125 155.632812 85.550781 155.632812 C 85.898438 155.632812 86.234375 155.699219 86.554688 155.832031 C 86.875 155.964844 87.15625 156.152344 87.402344 156.398438 C 87.648438 156.644531 87.835938 156.925781 87.96875 157.25 C 88.101562 157.570312 88.167969 157.902344 88.167969 158.25 Z M 88.167969 158.25 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#d033154610)">
        <path
          fill="#000000"
          d="M 103.367188 158.542969 C 103.367188 158.890625 103.300781 159.226562 103.167969 159.546875 C 103.035156 159.867188 102.847656 160.148438 102.601562 160.394531 C 102.355469 160.640625 102.074219 160.828125 101.753906 160.960938 C 101.433594 161.09375 101.097656 161.160156 100.75 161.160156 C 100.402344 161.160156 100.070312 161.09375 99.75 160.960938 C 99.429688 160.828125 99.144531 160.640625 98.898438 160.394531 C 98.652344 160.148438 98.464844 159.867188 98.332031 159.546875 C 98.199219 159.226562 98.132812 158.890625 98.132812 158.542969 C 98.132812 158.195312 98.199219 157.863281 98.332031 157.542969 C 98.464844 157.222656 98.652344 156.9375 98.898438 156.691406 C 99.144531 156.449219 99.429688 156.257812 99.75 156.125 C 100.070312 155.992188 100.402344 155.925781 100.75 155.925781 C 101.097656 155.925781 101.433594 155.992188 101.753906 156.125 C 102.074219 156.257812 102.355469 156.449219 102.601562 156.691406 C 102.847656 156.9375 103.035156 157.222656 103.167969 157.542969 C 103.300781 157.863281 103.367188 158.195312 103.367188 158.542969 Z M 103.367188 158.542969 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#1c20ef240e)">
        <path
          fill="#000000"
          d="M 88.167969 146.964844 C 88.167969 147.3125 88.101562 147.644531 87.96875 147.96875 C 87.835938 148.289062 87.648438 148.570312 87.402344 148.816406 C 87.15625 149.0625 86.875 149.25 86.554688 149.382812 C 86.234375 149.515625 85.898438 149.582031 85.550781 149.582031 C 85.203125 149.582031 84.871094 149.515625 84.550781 149.382812 C 84.230469 149.25 83.945312 149.0625 83.699219 148.816406 C 83.453125 148.570312 83.265625 148.289062 83.132812 147.96875 C 83 147.644531 82.933594 147.3125 82.933594 146.964844 C 82.933594 146.617188 83 146.285156 83.132812 145.964844 C 83.265625 145.644531 83.453125 145.359375 83.699219 145.113281 C 83.945312 144.867188 84.230469 144.679688 84.550781 144.546875 C 84.871094 144.414062 85.203125 144.347656 85.550781 144.347656 C 85.898438 144.347656 86.234375 144.414062 86.554688 144.546875 C 86.875 144.679688 87.15625 144.867188 87.402344 145.113281 C 87.648438 145.359375 87.835938 145.644531 87.96875 145.964844 C 88.101562 146.285156 88.167969 146.617188 88.167969 146.964844 Z M 88.167969 146.964844 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#7f7748c618)">
        <path
          fill="#000000"
          d="M 103.367188 146.964844 C 103.367188 147.3125 103.300781 147.644531 103.167969 147.96875 C 103.035156 148.289062 102.847656 148.570312 102.601562 148.816406 C 102.355469 149.0625 102.074219 149.25 101.753906 149.382812 C 101.433594 149.515625 101.097656 149.582031 100.75 149.582031 C 100.402344 149.582031 100.070312 149.515625 99.75 149.382812 C 99.429688 149.25 99.144531 149.0625 98.898438 148.816406 C 98.652344 148.570312 98.464844 148.289062 98.332031 147.96875 C 98.199219 147.644531 98.132812 147.3125 98.132812 146.964844 C 98.132812 146.617188 98.199219 146.285156 98.332031 145.964844 C 98.464844 145.644531 98.652344 145.359375 98.898438 145.113281 C 99.144531 144.867188 99.429688 144.679688 99.75 144.546875 C 100.070312 144.414062 100.402344 144.347656 100.75 144.347656 C 101.097656 144.347656 101.433594 144.414062 101.753906 144.546875 C 102.074219 144.679688 102.355469 144.867188 102.601562 145.113281 C 102.847656 145.359375 103.035156 145.644531 103.167969 145.964844 C 103.300781 146.285156 103.367188 146.617188 103.367188 146.964844 Z M 103.367188 146.964844 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#effb1b7180)">
        <path
          fill="#000000"
          d="M 73.089844 153.300781 C 73.089844 153.648438 73.023438 153.980469 72.890625 154.300781 C 72.757812 154.621094 72.566406 154.90625 72.320312 155.152344 C 72.078125 155.398438 71.792969 155.585938 71.472656 155.71875 C 71.152344 155.851562 70.816406 155.917969 70.472656 155.917969 C 70.125 155.917969 69.789062 155.851562 69.46875 155.71875 C 69.148438 155.585938 68.863281 155.398438 68.621094 155.152344 C 68.375 154.90625 68.183594 154.621094 68.050781 154.300781 C 67.917969 153.980469 67.851562 153.648438 67.851562 153.300781 C 67.851562 152.953125 67.917969 152.617188 68.050781 152.296875 C 68.183594 151.976562 68.375 151.695312 68.621094 151.449219 C 68.863281 151.203125 69.148438 151.015625 69.46875 150.882812 C 69.789062 150.75 70.125 150.683594 70.472656 150.683594 C 70.816406 150.683594 71.152344 150.75 71.472656 150.882812 C 71.792969 151.015625 72.078125 151.203125 72.320312 151.449219 C 72.566406 151.695312 72.757812 151.976562 72.890625 152.296875 C 73.023438 152.617188 73.089844 152.953125 73.089844 153.300781 Z M 73.089844 153.300781 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#94c59ccdd7)">
        <path
          fill="#000000"
          d="M 95.59375 31.71875 C 95.59375 32.066406 95.527344 32.402344 95.394531 32.722656 C 95.261719 33.042969 95.074219 33.324219 94.828125 33.570312 C 94.582031 33.816406 94.300781 34.003906 93.976562 34.136719 C 93.65625 34.269531 93.324219 34.335938 92.976562 34.335938 C 92.628906 34.335938 92.296875 34.269531 91.976562 34.136719 C 91.652344 34.003906 91.371094 33.816406 91.125 33.570312 C 90.878906 33.324219 90.691406 33.042969 90.558594 32.722656 C 90.425781 32.402344 90.359375 32.066406 90.359375 31.71875 C 90.359375 31.371094 90.425781 31.039062 90.558594 30.71875 C 90.691406 30.398438 90.878906 30.113281 91.125 29.867188 C 91.371094 29.625 91.652344 29.433594 91.976562 29.300781 C 92.296875 29.167969 92.628906 29.101562 92.976562 29.101562 C 93.324219 29.101562 93.65625 29.167969 93.976562 29.300781 C 94.300781 29.433594 94.582031 29.625 94.828125 29.867188 C 95.074219 30.113281 95.261719 30.398438 95.394531 30.71875 C 95.527344 31.039062 95.59375 31.371094 95.59375 31.71875 Z M 95.59375 31.71875 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#8c21d2f6af)">
        <path
          fill="#000000"
          d="M 117.425781 155.773438 C 117.425781 156.121094 117.359375 156.457031 117.226562 156.777344 C 117.09375 157.097656 116.90625 157.378906 116.660156 157.625 C 116.414062 157.871094 116.132812 158.0625 115.8125 158.195312 C 115.492188 158.328125 115.15625 158.394531 114.808594 158.394531 C 114.460938 158.394531 114.128906 158.328125 113.808594 158.195312 C 113.488281 158.0625 113.203125 157.871094 112.957031 157.625 C 112.710938 157.378906 112.523438 157.097656 112.390625 156.777344 C 112.257812 156.457031 112.191406 156.121094 112.191406 155.773438 C 112.191406 155.429688 112.257812 155.09375 112.390625 154.773438 C 112.523438 154.453125 112.710938 154.167969 112.957031 153.921875 C 113.203125 153.679688 113.488281 153.488281 113.808594 153.355469 C 114.128906 153.222656 114.460938 153.15625 114.808594 153.15625 C 115.15625 153.15625 115.492188 153.222656 115.8125 153.355469 C 116.132812 153.488281 116.414062 153.679688 116.660156 153.921875 C 116.90625 154.167969 117.09375 154.453125 117.226562 154.773438 C 117.359375 155.09375 117.425781 155.429688 117.425781 155.773438 Z M 117.425781 155.773438 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#1424f33d08)">
        <path
          fill="#000000"
          d="M 65.042969 141.265625 C 65.042969 141.613281 64.976562 141.949219 64.84375 142.269531 C 64.710938 142.589844 64.523438 142.871094 64.277344 143.117188 C 64.03125 143.363281 63.75 143.554688 63.429688 143.6875 C 63.109375 143.820312 62.773438 143.886719 62.425781 143.886719 C 62.078125 143.886719 61.746094 143.820312 61.425781 143.6875 C 61.105469 143.554688 60.820312 143.363281 60.574219 143.117188 C 60.328125 142.871094 60.140625 142.589844 60.007812 142.269531 C 59.875 141.949219 59.808594 141.613281 59.808594 141.265625 C 59.808594 140.921875 59.875 140.585938 60.007812 140.265625 C 60.140625 139.945312 60.328125 139.660156 60.574219 139.414062 C 60.820312 139.171875 61.105469 138.980469 61.425781 138.847656 C 61.746094 138.714844 62.078125 138.648438 62.425781 138.648438 C 62.773438 138.648438 63.109375 138.714844 63.429688 138.847656 C 63.75 138.980469 64.03125 139.171875 64.277344 139.414062 C 64.523438 139.660156 64.710938 139.945312 64.84375 140.265625 C 64.976562 140.585938 65.042969 140.921875 65.042969 141.265625 Z M 65.042969 141.265625 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#9e025c47fd)">
        <path
          fill="#000000"
          d="M 132.875 142.710938 C 132.875 143.058594 132.808594 143.390625 132.675781 143.710938 C 132.542969 144.035156 132.351562 144.316406 132.105469 144.5625 C 131.863281 144.808594 131.578125 144.996094 131.257812 145.128906 C 130.9375 145.261719 130.601562 145.328125 130.257812 145.328125 C 129.910156 145.328125 129.574219 145.261719 129.253906 145.128906 C 128.933594 144.996094 128.648438 144.808594 128.40625 144.5625 C 128.160156 144.316406 127.96875 144.035156 127.835938 143.710938 C 127.703125 143.390625 127.636719 143.058594 127.636719 142.710938 C 127.636719 142.363281 127.703125 142.03125 127.835938 141.710938 C 127.96875 141.386719 128.160156 141.105469 128.40625 140.859375 C 128.648438 140.613281 128.933594 140.425781 129.253906 140.292969 C 129.574219 140.160156 129.910156 140.09375 130.257812 140.09375 C 130.601562 140.09375 130.9375 140.160156 131.257812 140.292969 C 131.578125 140.425781 131.863281 140.613281 132.105469 140.859375 C 132.351562 141.105469 132.542969 141.386719 132.675781 141.710938 C 132.808594 142.03125 132.875 142.363281 132.875 142.710938 Z M 132.875 142.710938 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#02ad979297)">
        <path
          fill="#000000"
          d="M 130.398438 161.019531 C 130.398438 161.367188 130.332031 161.699219 130.199219 162.019531 C 130.066406 162.339844 129.878906 162.625 129.632812 162.871094 C 129.386719 163.117188 129.105469 163.304688 128.78125 163.4375 C 128.460938 163.570312 128.128906 163.636719 127.78125 163.636719 C 127.433594 163.636719 127.101562 163.570312 126.777344 163.4375 C 126.457031 163.304688 126.175781 163.117188 125.929688 162.871094 C 125.683594 162.625 125.496094 162.339844 125.363281 162.019531 C 125.230469 161.699219 125.164062 161.367188 125.164062 161.019531 C 125.164062 160.671875 125.230469 160.339844 125.363281 160.015625 C 125.496094 159.695312 125.683594 159.414062 125.929688 159.167969 C 126.175781 158.921875 126.457031 158.734375 126.777344 158.601562 C 127.101562 158.46875 127.433594 158.402344 127.78125 158.402344 C 128.128906 158.402344 128.460938 158.46875 128.78125 158.601562 C 129.105469 158.734375 129.386719 158.921875 129.632812 159.167969 C 129.878906 159.414062 130.066406 159.695312 130.199219 160.015625 C 130.332031 160.339844 130.398438 160.671875 130.398438 161.019531 Z M 130.398438 161.019531 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#196b705a6c)">
        <path
          fill="#000000"
          d="M 60.269531 150.824219 C 60.269531 151.171875 60.203125 151.507812 60.070312 151.828125 C 59.9375 152.148438 59.75 152.429688 59.503906 152.675781 C 59.257812 152.921875 58.976562 153.109375 58.65625 153.242188 C 58.332031 153.375 58 153.441406 57.652344 153.441406 C 57.304688 153.441406 56.972656 153.375 56.652344 153.242188 C 56.332031 153.109375 56.046875 152.921875 55.800781 152.675781 C 55.554688 152.429688 55.367188 152.148438 55.234375 151.828125 C 55.101562 151.507812 55.035156 151.171875 55.035156 150.824219 C 55.035156 150.476562 55.101562 150.144531 55.234375 149.824219 C 55.367188 149.503906 55.554688 149.21875 55.800781 148.972656 C 56.046875 148.726562 56.332031 148.539062 56.652344 148.40625 C 56.972656 148.273438 57.304688 148.207031 57.652344 148.207031 C 58 148.207031 58.332031 148.273438 58.65625 148.40625 C 58.976562 148.539062 59.257812 148.726562 59.503906 148.972656 C 59.75 149.21875 59.9375 149.503906 60.070312 149.824219 C 60.203125 150.144531 60.269531 150.476562 60.269531 150.824219 Z M 60.269531 150.824219 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#7b7600d96e)">
        <path
          fill="#000000"
          d="M 49.132812 139.539062 C 49.132812 139.886719 49.066406 140.222656 48.933594 140.542969 C 48.800781 140.863281 48.609375 141.144531 48.367188 141.390625 C 48.121094 141.636719 47.835938 141.824219 47.515625 141.957031 C 47.195312 142.09375 46.863281 142.160156 46.515625 142.160156 C 46.167969 142.160156 45.832031 142.09375 45.511719 141.957031 C 45.191406 141.824219 44.910156 141.636719 44.664062 141.390625 C 44.417969 141.144531 44.230469 140.863281 44.097656 140.542969 C 43.960938 140.222656 43.894531 139.886719 43.894531 139.539062 C 43.894531 139.191406 43.960938 138.859375 44.097656 138.539062 C 44.230469 138.21875 44.417969 137.933594 44.664062 137.6875 C 44.910156 137.445312 45.191406 137.253906 45.511719 137.121094 C 45.832031 136.988281 46.167969 136.921875 46.515625 136.921875 C 46.863281 136.921875 47.195312 136.988281 47.515625 137.121094 C 47.835938 137.253906 48.121094 137.445312 48.367188 137.6875 C 48.609375 137.933594 48.800781 138.21875 48.933594 138.539062 C 49.066406 138.859375 49.132812 139.191406 49.132812 139.539062 Z M 49.132812 139.539062 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#f2144e24c2)">
        <path
          fill="#000000"
          d="M 148.785156 124.582031 C 148.785156 124.929688 148.71875 125.265625 148.585938 125.585938 C 148.453125 125.90625 148.261719 126.1875 148.015625 126.433594 C 147.773438 126.679688 147.488281 126.867188 147.167969 127 C 146.847656 127.132812 146.511719 127.199219 146.164062 127.199219 C 145.820312 127.199219 145.484375 127.132812 145.164062 127 C 144.84375 126.867188 144.558594 126.679688 144.316406 126.433594 C 144.070312 126.1875 143.878906 125.90625 143.746094 125.585938 C 143.613281 125.265625 143.546875 124.929688 143.546875 124.582031 C 143.546875 124.234375 143.613281 123.902344 143.746094 123.582031 C 143.878906 123.261719 144.070312 122.976562 144.316406 122.730469 C 144.558594 122.484375 144.84375 122.296875 145.164062 122.164062 C 145.484375 122.03125 145.820312 121.964844 146.164062 121.964844 C 146.511719 121.964844 146.847656 122.03125 147.167969 122.164062 C 147.488281 122.296875 147.773438 122.484375 148.015625 122.730469 C 148.261719 122.976562 148.453125 123.261719 148.585938 123.582031 C 148.71875 123.902344 148.785156 124.234375 148.785156 124.582031 Z M 148.785156 124.582031 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#0cc974f2ae)">
        <path
          fill="#000000"
          d="M 21.460938 100.925781 C 21.460938 101.273438 21.394531 101.605469 21.261719 101.925781 C 21.128906 102.246094 20.941406 102.53125 20.695312 102.777344 C 20.449219 103.019531 20.164062 103.210938 19.84375 103.34375 C 19.523438 103.476562 19.191406 103.542969 18.84375 103.542969 C 18.496094 103.542969 18.160156 103.476562 17.839844 103.34375 C 17.519531 103.210938 17.238281 103.019531 16.992188 102.777344 C 16.746094 102.53125 16.558594 102.246094 16.425781 101.925781 C 16.292969 101.605469 16.226562 101.273438 16.226562 100.925781 C 16.226562 100.578125 16.292969 100.242188 16.425781 99.921875 C 16.558594 99.601562 16.746094 99.320312 16.992188 99.074219 C 17.238281 98.828125 17.519531 98.636719 17.839844 98.503906 C 18.160156 98.371094 18.496094 98.304688 18.84375 98.304688 C 19.191406 98.304688 19.523438 98.371094 19.84375 98.503906 C 20.164062 98.636719 20.449219 98.828125 20.695312 99.074219 C 20.941406 99.320312 21.128906 99.601562 21.261719 99.921875 C 21.394531 100.242188 21.460938 100.578125 21.460938 100.925781 Z M 21.460938 100.925781 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#eb6644c922)">
        <path
          fill="#000000"
          d="M 33.429688 66.320312 C 33.429688 66.667969 33.363281 67.003906 33.230469 67.324219 C 33.097656 67.644531 32.90625 67.925781 32.664062 68.171875 C 32.417969 68.417969 32.132812 68.609375 31.8125 68.742188 C 31.492188 68.875 31.160156 68.941406 30.8125 68.941406 C 30.464844 68.941406 30.128906 68.875 29.808594 68.742188 C 29.488281 68.609375 29.207031 68.417969 28.960938 68.171875 C 28.714844 67.925781 28.523438 67.644531 28.390625 67.324219 C 28.257812 67.003906 28.191406 66.667969 28.191406 66.320312 C 28.191406 65.976562 28.257812 65.640625 28.390625 65.320312 C 28.523438 65 28.714844 64.714844 28.960938 64.46875 C 29.207031 64.226562 29.488281 64.035156 29.808594 63.902344 C 30.128906 63.769531 30.464844 63.703125 30.8125 63.703125 C 31.160156 63.703125 31.492188 63.769531 31.8125 63.902344 C 32.132812 64.035156 32.417969 64.226562 32.664062 64.46875 C 32.90625 64.714844 33.097656 65 33.230469 65.320312 C 33.363281 65.640625 33.429688 65.976562 33.429688 66.320312 Z M 33.429688 66.320312 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#2563a17b90)">
        <path
          fill="#000000"
          d="M 166.578125 113.644531 C 166.578125 113.992188 166.511719 114.328125 166.378906 114.648438 C 166.246094 114.96875 166.058594 115.25 165.8125 115.496094 C 165.566406 115.742188 165.285156 115.933594 164.960938 116.066406 C 164.640625 116.199219 164.308594 116.265625 163.960938 116.265625 C 163.613281 116.265625 163.28125 116.199219 162.960938 116.066406 C 162.636719 115.933594 162.355469 115.742188 162.109375 115.496094 C 161.863281 115.25 161.675781 114.96875 161.542969 114.648438 C 161.410156 114.328125 161.34375 113.992188 161.34375 113.644531 C 161.34375 113.300781 161.410156 112.964844 161.542969 112.644531 C 161.675781 112.324219 161.863281 112.039062 162.109375 111.796875 C 162.355469 111.550781 162.636719 111.359375 162.960938 111.226562 C 163.28125 111.09375 163.613281 111.027344 163.960938 111.027344 C 164.308594 111.027344 164.640625 111.09375 164.960938 111.226562 C 165.285156 111.359375 165.566406 111.550781 165.8125 111.796875 C 166.058594 112.039062 166.246094 112.324219 166.378906 112.644531 C 166.511719 112.964844 166.578125 113.300781 166.578125 113.644531 Z M 166.578125 113.644531 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#11c7c7ee1c)">
        <path
          fill="#000000"
          d="M 39.410156 119.632812 C 39.410156 119.980469 39.34375 120.3125 39.210938 120.632812 C 39.078125 120.957031 38.886719 121.238281 38.640625 121.484375 C 38.394531 121.730469 38.113281 121.917969 37.792969 122.050781 C 37.472656 122.183594 37.136719 122.25 36.789062 122.25 C 36.441406 122.25 36.109375 122.183594 35.789062 122.050781 C 35.46875 121.917969 35.183594 121.730469 34.9375 121.484375 C 34.695312 121.238281 34.503906 120.957031 34.371094 120.632812 C 34.238281 120.3125 34.171875 119.980469 34.171875 119.632812 C 34.171875 119.285156 34.238281 118.953125 34.371094 118.628906 C 34.503906 118.308594 34.695312 118.027344 34.9375 117.78125 C 35.183594 117.535156 35.46875 117.347656 35.789062 117.214844 C 36.109375 117.082031 36.441406 117.015625 36.789062 117.015625 C 37.136719 117.015625 37.472656 117.082031 37.792969 117.214844 C 38.113281 117.347656 38.394531 117.535156 38.640625 117.78125 C 38.886719 118.027344 39.078125 118.308594 39.210938 118.628906 C 39.34375 118.953125 39.410156 119.285156 39.410156 119.632812 Z M 39.410156 119.632812 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#90aa684249)">
        <path
          fill="#000000"
          d="M 28.480469 125.566406 C 28.480469 125.914062 28.414062 126.25 28.28125 126.570312 C 28.148438 126.890625 27.957031 127.171875 27.710938 127.417969 C 27.46875 127.664062 27.183594 127.851562 26.863281 127.984375 C 26.542969 128.117188 26.207031 128.183594 25.859375 128.183594 C 25.515625 128.183594 25.179688 128.117188 24.859375 127.984375 C 24.539062 127.851562 24.253906 127.664062 24.011719 127.417969 C 23.765625 127.171875 23.574219 126.890625 23.441406 126.570312 C 23.308594 126.25 23.242188 125.914062 23.242188 125.566406 C 23.242188 125.21875 23.308594 124.886719 23.441406 124.566406 C 23.574219 124.246094 23.765625 123.960938 24.011719 123.714844 C 24.253906 123.46875 24.539062 123.28125 24.859375 123.148438 C 25.179688 123.015625 25.515625 122.949219 25.859375 122.949219 C 26.207031 122.949219 26.542969 123.015625 26.863281 123.148438 C 27.183594 123.28125 27.46875 123.46875 27.710938 123.714844 C 27.957031 123.960938 28.148438 124.246094 28.28125 124.566406 C 28.414062 124.886719 28.480469 125.21875 28.480469 125.566406 Z M 28.480469 125.566406 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#8152c22afa)">
        <path
          fill="#000000"
          d="M 161.628906 129.53125 C 161.628906 129.878906 161.5625 130.214844 161.429688 130.535156 C 161.296875 130.855469 161.109375 131.136719 160.863281 131.382812 C 160.617188 131.628906 160.332031 131.820312 160.011719 131.953125 C 159.691406 132.085938 159.359375 132.152344 159.011719 132.152344 C 158.664062 132.152344 158.328125 132.085938 158.007812 131.953125 C 157.6875 131.820312 157.40625 131.628906 157.160156 131.382812 C 156.914062 131.136719 156.726562 130.855469 156.59375 130.535156 C 156.460938 130.214844 156.394531 129.878906 156.394531 129.53125 C 156.394531 129.1875 156.460938 128.851562 156.59375 128.53125 C 156.726562 128.210938 156.914062 127.925781 157.160156 127.679688 C 157.40625 127.4375 157.6875 127.246094 158.007812 127.113281 C 158.328125 126.980469 158.664062 126.914062 159.011719 126.914062 C 159.359375 126.914062 159.691406 126.980469 160.011719 127.113281 C 160.332031 127.246094 160.617188 127.4375 160.863281 127.679688 C 161.109375 127.925781 161.296875 128.210938 161.429688 128.53125 C 161.5625 128.851562 161.628906 129.1875 161.628906 129.53125 Z M 161.628906 129.53125 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#555d1a855a)">
        <path
          fill="#000000"
          d="M 148.164062 140.234375 C 148.164062 140.582031 148.097656 140.917969 147.964844 141.238281 C 147.832031 141.558594 147.644531 141.839844 147.398438 142.085938 C 147.152344 142.332031 146.871094 142.523438 146.546875 142.65625 C 146.226562 142.789062 145.894531 142.855469 145.546875 142.855469 C 145.199219 142.855469 144.867188 142.789062 144.546875 142.65625 C 144.222656 142.523438 143.941406 142.332031 143.695312 142.085938 C 143.449219 141.839844 143.261719 141.558594 143.128906 141.238281 C 142.996094 140.917969 142.929688 140.582031 142.929688 140.234375 C 142.929688 139.890625 142.996094 139.554688 143.128906 139.234375 C 143.261719 138.914062 143.449219 138.628906 143.695312 138.382812 C 143.941406 138.140625 144.222656 137.949219 144.546875 137.816406 C 144.867188 137.683594 145.199219 137.617188 145.546875 137.617188 C 145.894531 137.617188 146.226562 137.683594 146.546875 137.816406 C 146.871094 137.949219 147.152344 138.140625 147.398438 138.382812 C 147.644531 138.628906 147.832031 138.914062 147.964844 139.234375 C 148.097656 139.554688 148.164062 139.890625 148.164062 140.234375 Z M 148.164062 140.234375 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#424a9d1243)">
        <path
          fill="#000000"
          d="M 68.5 165.730469 C 68.5 166.078125 68.433594 166.410156 68.300781 166.730469 C 68.167969 167.050781 67.976562 167.335938 67.730469 167.582031 C 67.488281 167.828125 67.203125 168.015625 66.882812 168.148438 C 66.5625 168.28125 66.226562 168.347656 65.878906 168.347656 C 65.535156 168.347656 65.199219 168.28125 64.878906 168.148438 C 64.558594 168.015625 64.273438 167.828125 64.03125 167.582031 C 63.785156 167.335938 63.59375 167.050781 63.460938 166.730469 C 63.328125 166.410156 63.261719 166.078125 63.261719 165.730469 C 63.261719 165.382812 63.328125 165.050781 63.460938 164.726562 C 63.59375 164.40625 63.785156 164.125 64.03125 163.878906 C 64.273438 163.632812 64.558594 163.445312 64.878906 163.3125 C 65.199219 163.179688 65.535156 163.113281 65.878906 163.113281 C 66.226562 163.113281 66.5625 163.179688 66.882812 163.3125 C 67.203125 163.445312 67.488281 163.632812 67.730469 163.878906 C 67.976562 164.125 68.167969 164.40625 68.300781 164.726562 C 68.433594 165.050781 68.5 165.382812 68.5 165.730469 Z M 68.5 165.730469 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#5c82a40235)">
        <path
          fill="#000000"
          d="M 82.964844 167.464844 C 82.964844 167.8125 82.898438 168.144531 82.765625 168.46875 C 82.632812 168.789062 82.445312 169.070312 82.199219 169.316406 C 81.953125 169.5625 81.671875 169.75 81.351562 169.882812 C 81.03125 170.015625 80.695312 170.082031 80.347656 170.082031 C 80 170.082031 79.667969 170.015625 79.347656 169.882812 C 79.027344 169.75 78.742188 169.5625 78.496094 169.316406 C 78.25 169.070312 78.0625 168.789062 77.929688 168.46875 C 77.796875 168.144531 77.730469 167.8125 77.730469 167.464844 C 77.730469 167.117188 77.796875 166.785156 77.929688 166.464844 C 78.0625 166.140625 78.25 165.859375 78.496094 165.613281 C 78.742188 165.367188 79.027344 165.179688 79.347656 165.046875 C 79.667969 164.914062 80 164.847656 80.347656 164.847656 C 80.695312 164.847656 81.03125 164.914062 81.351562 165.046875 C 81.671875 165.179688 81.953125 165.367188 82.199219 165.613281 C 82.445312 165.859375 82.632812 166.140625 82.765625 166.464844 C 82.898438 166.785156 82.964844 167.117188 82.964844 167.464844 Z M 82.964844 167.464844 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#20c2034c70)">
        <path
          fill="#000000"
          d="M 33.839844 137.066406 C 33.839844 137.414062 33.773438 137.746094 33.640625 138.066406 C 33.507812 138.386719 33.316406 138.671875 33.074219 138.917969 C 32.828125 139.160156 32.542969 139.351562 32.222656 139.484375 C 31.902344 139.617188 31.570312 139.683594 31.222656 139.683594 C 30.875 139.683594 30.539062 139.617188 30.21875 139.484375 C 29.898438 139.351562 29.617188 139.160156 29.371094 138.917969 C 29.125 138.671875 28.933594 138.386719 28.800781 138.066406 C 28.667969 137.746094 28.601562 137.414062 28.601562 137.066406 C 28.601562 136.71875 28.667969 136.382812 28.800781 136.0625 C 28.933594 135.742188 29.125 135.460938 29.371094 135.214844 C 29.617188 134.96875 29.898438 134.777344 30.21875 134.644531 C 30.539062 134.511719 30.875 134.445312 31.222656 134.445312 C 31.570312 134.445312 31.902344 134.511719 32.222656 134.644531 C 32.542969 134.777344 32.828125 134.96875 33.074219 135.214844 C 33.316406 135.460938 33.507812 135.742188 33.640625 136.0625 C 33.773438 136.382812 33.839844 136.71875 33.839844 137.066406 Z M 33.839844 137.066406 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#9e9b179727)">
        <path
          fill="#000000"
          d="M 120.523438 144.492188 C 120.523438 144.835938 120.457031 145.171875 120.320312 145.492188 C 120.1875 145.8125 120 146.097656 119.753906 146.339844 C 119.507812 146.585938 119.226562 146.777344 118.90625 146.910156 C 118.585938 147.042969 118.25 147.109375 117.902344 147.109375 C 117.554688 147.109375 117.222656 147.042969 116.902344 146.910156 C 116.582031 146.777344 116.296875 146.585938 116.050781 146.339844 C 115.808594 146.097656 115.617188 145.8125 115.484375 145.492188 C 115.351562 145.171875 115.285156 144.835938 115.285156 144.492188 C 115.285156 144.144531 115.351562 143.808594 115.484375 143.488281 C 115.617188 143.167969 115.808594 142.882812 116.050781 142.640625 C 116.296875 142.394531 116.582031 142.203125 116.902344 142.070312 C 117.222656 141.9375 117.554688 141.871094 117.902344 141.871094 C 118.25 141.871094 118.585938 141.9375 118.90625 142.070312 C 119.226562 142.203125 119.507812 142.394531 119.753906 142.640625 C 120 142.882812 120.1875 143.167969 120.320312 143.488281 C 120.457031 143.808594 120.523438 144.144531 120.523438 144.492188 Z M 120.523438 144.492188 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#399800d150)">
        <path
          fill="#000000"
          d="M 140.65625 150.824219 C 140.65625 151.171875 140.589844 151.507812 140.457031 151.828125 C 140.324219 152.148438 140.136719 152.429688 139.890625 152.675781 C 139.644531 152.921875 139.359375 153.109375 139.039062 153.242188 C 138.71875 153.375 138.386719 153.441406 138.039062 153.441406 C 137.691406 153.441406 137.359375 153.375 137.035156 153.242188 C 136.714844 153.109375 136.433594 152.921875 136.1875 152.675781 C 135.941406 152.429688 135.753906 152.148438 135.621094 151.828125 C 135.488281 151.507812 135.421875 151.171875 135.421875 150.824219 C 135.421875 150.476562 135.488281 150.144531 135.621094 149.824219 C 135.753906 149.503906 135.941406 149.21875 136.1875 148.972656 C 136.433594 148.726562 136.714844 148.539062 137.035156 148.40625 C 137.359375 148.273438 137.691406 148.207031 138.039062 148.207031 C 138.386719 148.207031 138.71875 148.273438 139.039062 148.40625 C 139.359375 148.539062 139.644531 148.726562 139.890625 148.972656 C 140.136719 149.21875 140.324219 149.503906 140.457031 149.824219 C 140.589844 150.144531 140.65625 150.476562 140.65625 150.824219 Z M 140.65625 150.824219 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#c2354a4865)">
        <path
          fill="#000000"
          d="M 111.261719 168.445312 C 111.261719 168.792969 111.195312 169.125 111.0625 169.445312 C 110.929688 169.765625 110.738281 170.050781 110.492188 170.296875 C 110.25 170.539062 109.964844 170.730469 109.644531 170.863281 C 109.324219 170.996094 108.988281 171.0625 108.640625 171.0625 C 108.296875 171.0625 107.960938 170.996094 107.640625 170.863281 C 107.320312 170.730469 107.035156 170.539062 106.792969 170.296875 C 106.546875 170.050781 106.355469 169.765625 106.222656 169.445312 C 106.089844 169.125 106.023438 168.792969 106.023438 168.445312 C 106.023438 168.097656 106.089844 167.761719 106.222656 167.441406 C 106.355469 167.121094 106.546875 166.839844 106.792969 166.59375 C 107.035156 166.347656 107.320312 166.160156 107.640625 166.027344 C 107.960938 165.894531 108.296875 165.824219 108.640625 165.824219 C 108.988281 165.824219 109.324219 165.894531 109.644531 166.027344 C 109.964844 166.160156 110.25 166.347656 110.492188 166.59375 C 110.738281 166.839844 110.929688 167.121094 111.0625 167.441406 C 111.195312 167.761719 111.261719 168.097656 111.261719 168.445312 Z M 111.261719 168.445312 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#321eddb893)">
        <path
          fill="#000000"
          d="M 95.59375 170.917969 C 95.59375 171.265625 95.527344 171.601562 95.394531 171.921875 C 95.261719 172.242188 95.074219 172.523438 94.828125 172.769531 C 94.582031 173.015625 94.300781 173.207031 93.976562 173.339844 C 93.65625 173.472656 93.324219 173.539062 92.976562 173.539062 C 92.628906 173.539062 92.296875 173.472656 91.976562 173.339844 C 91.652344 173.207031 91.371094 173.015625 91.125 172.769531 C 90.878906 172.523438 90.691406 172.242188 90.558594 171.921875 C 90.425781 171.601562 90.359375 171.265625 90.359375 170.917969 C 90.359375 170.570312 90.425781 170.238281 90.558594 169.917969 C 90.691406 169.597656 90.878906 169.3125 91.125 169.066406 C 91.371094 168.824219 91.652344 168.632812 91.976562 168.5 C 92.296875 168.367188 92.628906 168.300781 92.976562 168.300781 C 93.324219 168.300781 93.65625 168.367188 93.976562 168.5 C 94.300781 168.632812 94.582031 168.824219 94.828125 169.066406 C 95.074219 169.3125 95.261719 169.597656 95.394531 169.917969 C 95.527344 170.238281 95.59375 170.570312 95.59375 170.917969 Z M 95.59375 170.917969 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#07a31d7983)">
        <path
          fill="#000000"
          d="M 42.632812 146.964844 C 42.632812 147.3125 42.566406 147.644531 42.433594 147.96875 C 42.300781 148.289062 42.109375 148.570312 41.863281 148.816406 C 41.621094 149.0625 41.335938 149.25 41.015625 149.382812 C 40.695312 149.515625 40.359375 149.582031 40.011719 149.582031 C 39.667969 149.582031 39.332031 149.515625 39.011719 149.382812 C 38.691406 149.25 38.40625 149.0625 38.164062 148.816406 C 37.917969 148.570312 37.726562 148.289062 37.59375 147.96875 C 37.460938 147.644531 37.394531 147.3125 37.394531 146.964844 C 37.394531 146.617188 37.460938 146.285156 37.59375 145.964844 C 37.726562 145.644531 37.917969 145.359375 38.164062 145.113281 C 38.40625 144.867188 38.691406 144.679688 39.011719 144.546875 C 39.332031 144.414062 39.667969 144.347656 40.011719 144.347656 C 40.359375 144.347656 40.695312 144.414062 41.015625 144.546875 C 41.335938 144.679688 41.621094 144.867188 41.863281 145.113281 C 42.109375 145.359375 42.300781 145.644531 42.433594 145.964844 C 42.566406 146.285156 42.632812 146.617188 42.632812 146.964844 Z M 42.632812 146.964844 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#98c24e6c88)">
        <path
          fill="#000000"
          d="M 129.722656 44.730469 C 129.722656 45.078125 129.65625 45.414062 129.523438 45.734375 C 129.390625 46.054688 129.203125 46.335938 128.957031 46.582031 C 128.710938 46.828125 128.425781 47.015625 128.105469 47.148438 C 127.785156 47.28125 127.453125 47.347656 127.105469 47.347656 C 126.757812 47.347656 126.421875 47.28125 126.101562 47.148438 C 125.78125 47.015625 125.5 46.828125 125.253906 46.582031 C 125.007812 46.335938 124.820312 46.054688 124.6875 45.734375 C 124.554688 45.414062 124.488281 45.078125 124.488281 44.730469 C 124.488281 44.382812 124.554688 44.050781 124.6875 43.730469 C 124.820312 43.410156 125.007812 43.125 125.253906 42.878906 C 125.5 42.636719 125.78125 42.445312 126.101562 42.3125 C 126.421875 42.179688 126.757812 42.113281 127.105469 42.113281 C 127.453125 42.113281 127.785156 42.179688 128.105469 42.3125 C 128.425781 42.445312 128.710938 42.636719 128.957031 42.878906 C 129.203125 43.125 129.390625 43.410156 129.523438 43.730469 C 129.65625 44.050781 129.722656 44.382812 129.722656 44.730469 Z M 129.722656 44.730469 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#7e87e0c27e)">
        <path
          fill="#000000"
          d="M 113.734375 35.921875 C 113.734375 36.269531 113.667969 36.601562 113.535156 36.921875 C 113.402344 37.246094 113.214844 37.527344 112.96875 37.773438 C 112.722656 38.019531 112.441406 38.207031 112.121094 38.339844 C 111.796875 38.472656 111.464844 38.539062 111.117188 38.539062 C 110.769531 38.539062 110.4375 38.472656 110.117188 38.339844 C 109.792969 38.207031 109.511719 38.019531 109.265625 37.773438 C 109.019531 37.527344 108.832031 37.246094 108.699219 36.921875 C 108.566406 36.601562 108.5 36.269531 108.5 35.921875 C 108.5 35.574219 108.566406 35.242188 108.699219 34.921875 C 108.832031 34.597656 109.019531 34.316406 109.265625 34.070312 C 109.511719 33.824219 109.792969 33.636719 110.117188 33.503906 C 110.4375 33.371094 110.769531 33.304688 111.117188 33.304688 C 111.464844 33.304688 111.796875 33.371094 112.121094 33.503906 C 112.441406 33.636719 112.722656 33.824219 112.96875 34.070312 C 113.214844 34.316406 113.402344 34.597656 113.535156 34.921875 C 113.667969 35.242188 113.734375 35.574219 113.734375 35.921875 Z M 113.734375 35.921875 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#d1614c3dfb)">
        <path
          fill="#000000"
          d="M 75.949219 36.667969 C 75.949219 37.015625 75.882812 37.351562 75.75 37.671875 C 75.617188 37.992188 75.425781 38.277344 75.179688 38.519531 C 74.9375 38.765625 74.652344 38.957031 74.332031 39.089844 C 74.011719 39.222656 73.675781 39.289062 73.332031 39.289062 C 72.984375 39.289062 72.648438 39.222656 72.328125 39.089844 C 72.007812 38.957031 71.722656 38.765625 71.480469 38.519531 C 71.234375 38.277344 71.042969 37.992188 70.910156 37.671875 C 70.777344 37.351562 70.710938 37.015625 70.710938 36.667969 C 70.710938 36.324219 70.777344 35.988281 70.910156 35.667969 C 71.042969 35.347656 71.234375 35.0625 71.480469 34.820312 C 71.722656 34.574219 72.007812 34.382812 72.328125 34.25 C 72.648438 34.117188 72.984375 34.050781 73.332031 34.050781 C 73.675781 34.050781 74.011719 34.117188 74.332031 34.25 C 74.652344 34.382812 74.9375 34.574219 75.179688 34.820312 C 75.425781 35.0625 75.617188 35.347656 75.75 35.667969 C 75.882812 35.988281 75.949219 36.324219 75.949219 36.667969 Z M 75.949219 36.667969 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#af0dbac329)">
        <path
          fill="#000000"
          d="M 59.652344 39.144531 C 59.652344 39.492188 59.585938 39.824219 59.453125 40.148438 C 59.320312 40.46875 59.128906 40.75 58.886719 40.996094 C 58.640625 41.242188 58.355469 41.429688 58.035156 41.5625 C 57.714844 41.695312 57.378906 41.761719 57.035156 41.761719 C 56.6875 41.761719 56.351562 41.695312 56.03125 41.5625 C 55.710938 41.429688 55.429688 41.242188 55.183594 40.996094 C 54.9375 40.75 54.746094 40.46875 54.613281 40.148438 C 54.480469 39.824219 54.414062 39.492188 54.414062 39.144531 C 54.414062 38.796875 54.480469 38.464844 54.613281 38.144531 C 54.746094 37.820312 54.9375 37.539062 55.183594 37.292969 C 55.429688 37.046875 55.710938 36.859375 56.03125 36.726562 C 56.351562 36.59375 56.6875 36.527344 57.035156 36.527344 C 57.378906 36.527344 57.714844 36.59375 58.035156 36.726562 C 58.355469 36.859375 58.640625 37.046875 58.886719 37.292969 C 59.128906 37.539062 59.320312 37.820312 59.453125 38.144531 C 59.585938 38.464844 59.652344 38.796875 59.652344 39.144531 Z M 59.652344 39.144531 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#ee3e77e27e)">
        <path
          fill="#000000"
          d="M 148.164062 62.699219 C 148.164062 63.046875 148.097656 63.378906 147.964844 63.699219 C 147.832031 64.023438 147.644531 64.304688 147.398438 64.550781 C 147.152344 64.796875 146.871094 64.984375 146.546875 65.117188 C 146.226562 65.25 145.894531 65.316406 145.546875 65.316406 C 145.199219 65.316406 144.867188 65.25 144.546875 65.117188 C 144.222656 64.984375 143.941406 64.796875 143.695312 64.550781 C 143.449219 64.304688 143.261719 64.023438 143.128906 63.699219 C 142.996094 63.378906 142.929688 63.046875 142.929688 62.699219 C 142.929688 62.351562 142.996094 62.019531 143.128906 61.695312 C 143.261719 61.375 143.449219 61.09375 143.695312 60.847656 C 143.941406 60.601562 144.222656 60.414062 144.546875 60.28125 C 144.867188 60.148438 145.199219 60.082031 145.546875 60.082031 C 145.894531 60.082031 146.226562 60.148438 146.546875 60.28125 C 146.871094 60.414062 147.152344 60.601562 147.398438 60.847656 C 147.644531 61.09375 147.832031 61.375 147.964844 61.695312 C 148.097656 62.019531 148.164062 62.351562 148.164062 62.699219 Z M 148.164062 62.699219 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#427bb38dd6)">
        <path
          fill="#000000"
          d="M 43.738281 51.8125 C 43.738281 52.160156 43.671875 52.496094 43.539062 52.816406 C 43.40625 53.136719 43.21875 53.417969 42.972656 53.664062 C 42.726562 53.910156 42.445312 54.101562 42.125 54.234375 C 41.800781 54.367188 41.46875 54.433594 41.121094 54.433594 C 40.773438 54.433594 40.441406 54.367188 40.121094 54.234375 C 39.796875 54.101562 39.515625 53.910156 39.269531 53.664062 C 39.023438 53.417969 38.835938 53.136719 38.703125 52.816406 C 38.570312 52.496094 38.503906 52.160156 38.503906 51.8125 C 38.503906 51.46875 38.570312 51.132812 38.703125 50.8125 C 38.835938 50.492188 39.023438 50.207031 39.269531 49.964844 C 39.515625 49.71875 39.796875 49.527344 40.121094 49.394531 C 40.441406 49.261719 40.773438 49.195312 41.121094 49.195312 C 41.46875 49.195312 41.800781 49.261719 42.125 49.394531 C 42.445312 49.527344 42.726562 49.71875 42.972656 49.964844 C 43.21875 50.207031 43.40625 50.492188 43.539062 50.8125 C 43.671875 51.132812 43.738281 51.46875 43.738281 51.8125 Z M 43.738281 51.8125 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#66f6a20ecf)">
        <path
          fill="#000000"
          d="M 159.902344 69.667969 C 159.902344 70.011719 159.835938 70.347656 159.703125 70.667969 C 159.570312 70.988281 159.378906 71.273438 159.136719 71.515625 C 158.890625 71.761719 158.605469 71.953125 158.285156 72.085938 C 157.964844 72.21875 157.632812 72.285156 157.285156 72.285156 C 156.9375 72.285156 156.601562 72.21875 156.28125 72.085938 C 155.960938 71.953125 155.679688 71.761719 155.433594 71.515625 C 155.1875 71.273438 155 70.988281 154.863281 70.667969 C 154.730469 70.347656 154.664062 70.011719 154.664062 69.667969 C 154.664062 69.320312 154.730469 68.984375 154.863281 68.664062 C 155 68.34375 155.1875 68.0625 155.433594 67.816406 C 155.679688 67.570312 155.960938 67.378906 156.28125 67.246094 C 156.601562 67.113281 156.9375 67.046875 157.285156 67.046875 C 157.632812 67.046875 157.964844 67.113281 158.285156 67.246094 C 158.605469 67.378906 158.890625 67.570312 159.136719 67.816406 C 159.378906 68.0625 159.570312 68.34375 159.703125 68.664062 C 159.835938 68.984375 159.902344 69.320312 159.902344 69.667969 Z M 159.902344 69.667969 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#e1b078085c)">
        <path
          fill="#000000"
          d="M 144.859375 51.066406 C 144.859375 51.414062 144.792969 51.746094 144.660156 52.066406 C 144.527344 52.390625 144.335938 52.671875 144.09375 52.917969 C 143.847656 53.164062 143.5625 53.351562 143.242188 53.484375 C 142.921875 53.617188 142.589844 53.683594 142.242188 53.683594 C 141.894531 53.683594 141.558594 53.617188 141.238281 53.484375 C 140.917969 53.351562 140.636719 53.164062 140.390625 52.917969 C 140.144531 52.671875 139.953125 52.390625 139.820312 52.066406 C 139.6875 51.746094 139.621094 51.414062 139.621094 51.066406 C 139.621094 50.71875 139.6875 50.386719 139.820312 50.0625 C 139.953125 49.742188 140.144531 49.460938 140.390625 49.214844 C 140.636719 48.96875 140.917969 48.78125 141.238281 48.648438 C 141.558594 48.515625 141.894531 48.449219 142.242188 48.449219 C 142.589844 48.449219 142.921875 48.515625 143.242188 48.648438 C 143.5625 48.78125 143.847656 48.96875 144.09375 49.214844 C 144.335938 49.460938 144.527344 49.742188 144.660156 50.0625 C 144.792969 50.386719 144.859375 50.71875 144.859375 51.066406 Z M 144.859375 51.066406 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#24282cd448)">
        <path
          fill="#000000"
          d="M 23.527344 112.898438 C 23.527344 113.246094 23.460938 113.578125 23.328125 113.898438 C 23.195312 114.222656 23.007812 114.503906 22.761719 114.75 C 22.515625 114.996094 22.234375 115.183594 21.914062 115.316406 C 21.59375 115.449219 21.257812 115.515625 20.910156 115.515625 C 20.5625 115.515625 20.230469 115.449219 19.910156 115.316406 C 19.589844 115.183594 19.304688 114.996094 19.058594 114.75 C 18.8125 114.503906 18.625 114.222656 18.492188 113.898438 C 18.359375 113.578125 18.292969 113.246094 18.292969 112.898438 C 18.292969 112.550781 18.359375 112.21875 18.492188 111.894531 C 18.625 111.574219 18.8125 111.292969 19.058594 111.046875 C 19.304688 110.800781 19.589844 110.613281 19.910156 110.480469 C 20.230469 110.347656 20.5625 110.28125 20.910156 110.28125 C 21.257812 110.28125 21.59375 110.347656 21.914062 110.480469 C 22.234375 110.613281 22.515625 110.800781 22.761719 111.046875 C 23.007812 111.292969 23.195312 111.574219 23.328125 111.894531 C 23.460938 112.21875 23.527344 112.550781 23.527344 112.898438 Z M 23.527344 112.898438 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#b1be62dd83)">
        <path
          fill="#000000"
          d="M 166.578125 84.058594 C 166.578125 84.40625 166.511719 84.738281 166.378906 85.0625 C 166.246094 85.382812 166.058594 85.664062 165.8125 85.910156 C 165.566406 86.15625 165.285156 86.34375 164.960938 86.476562 C 164.640625 86.609375 164.308594 86.675781 163.960938 86.675781 C 163.613281 86.675781 163.28125 86.609375 162.960938 86.476562 C 162.636719 86.34375 162.355469 86.15625 162.109375 85.910156 C 161.863281 85.664062 161.675781 85.382812 161.542969 85.0625 C 161.410156 84.738281 161.34375 84.40625 161.34375 84.058594 C 161.34375 83.710938 161.410156 83.378906 161.542969 83.058594 C 161.675781 82.734375 161.863281 82.453125 162.109375 82.207031 C 162.355469 81.960938 162.636719 81.773438 162.960938 81.640625 C 163.28125 81.507812 163.613281 81.441406 163.960938 81.441406 C 164.308594 81.441406 164.640625 81.507812 164.960938 81.640625 C 165.285156 81.773438 165.566406 81.960938 165.8125 82.207031 C 166.058594 82.453125 166.246094 82.734375 166.378906 83.058594 C 166.511719 83.378906 166.578125 83.710938 166.578125 84.058594 Z M 166.578125 84.058594 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#e751de5255)">
        <path
          fill="#000000"
          d="M 53.464844 159.523438 C 53.464844 159.871094 53.398438 160.203125 53.265625 160.523438 C 53.132812 160.84375 52.941406 161.128906 52.695312 161.375 C 52.453125 161.621094 52.167969 161.808594 51.847656 161.941406 C 51.527344 162.074219 51.191406 162.140625 50.84375 162.140625 C 50.5 162.140625 50.164062 162.074219 49.84375 161.941406 C 49.523438 161.808594 49.238281 161.621094 48.996094 161.375 C 48.75 161.128906 48.558594 160.84375 48.425781 160.523438 C 48.292969 160.203125 48.226562 159.871094 48.226562 159.523438 C 48.226562 159.175781 48.292969 158.84375 48.425781 158.519531 C 48.558594 158.199219 48.75 157.917969 48.996094 157.671875 C 49.238281 157.425781 49.523438 157.238281 49.84375 157.105469 C 50.164062 156.972656 50.5 156.90625 50.84375 156.90625 C 51.191406 156.90625 51.527344 156.972656 51.847656 157.105469 C 52.167969 157.238281 52.453125 157.425781 52.695312 157.671875 C 52.941406 157.917969 53.132812 158.199219 53.265625 158.519531 C 53.398438 158.84375 53.464844 159.175781 53.464844 159.523438 Z M 53.464844 159.523438 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  );
}

export default BluringOneSvg;
