import React from "react";

function ImageSvg({ backgroundColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      zoomAndPan="magnify"
      viewBox="0 0 67.5 56.249996"
      height="75"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="c2041e7f59">
          <path
            d="M 0.199219 0 L 66.808594 0 L 66.808594 55.511719 L 0.199219 55.511719 Z M 0.199219 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="6e67844089">
          <path
            d="M 7.597656 0 L 59.40625 0 C 63.496094 0 66.808594 3.3125 66.808594 7.402344 L 66.808594 48.109375 C 66.808594 52.195312 63.496094 55.511719 59.40625 55.511719 L 7.597656 55.511719 C 3.511719 55.511719 0.199219 52.195312 0.199219 48.109375 L 0.199219 7.402344 C 0.199219 3.3125 3.511719 0 7.597656 0 Z M 7.597656 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="1050a23bd2">
          <path
            d="M 8.96875 7.214844 L 21.625 7.214844 L 21.625 19.871094 L 8.96875 19.871094 Z M 8.96875 7.214844 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="3d0da4ffc7">
          <path
            d="M 15.296875 7.214844 C 11.800781 7.214844 8.96875 10.050781 8.96875 13.542969 C 8.96875 17.039062 11.800781 19.871094 15.296875 19.871094 C 18.789062 19.871094 21.625 17.039062 21.625 13.542969 C 21.625 10.050781 18.789062 7.214844 15.296875 7.214844 Z M 15.296875 7.214844 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="e096be6f34">
          <path
            d="M 22.609375 19.488281 L 59.246094 19.488281 L 59.246094 46.46875 L 22.609375 46.46875 Z M 22.609375 19.488281 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="f524b3bac6">
          <path
            d="M 42.183594 20.222656 L 58.96875 44.640625 C 59.214844 45 59.242188 45.460938 59.039062 45.847656 C 58.839844 46.230469 58.441406 46.46875 58.007812 46.46875 L 23.84375 46.46875 C 23.410156 46.46875 23.011719 46.230469 22.8125 45.847656 C 22.609375 45.460938 22.636719 45 22.882812 44.640625 L 39.667969 20.222656 C 39.953125 19.808594 40.421875 19.558594 40.925781 19.558594 C 41.429688 19.558594 41.898438 19.808594 42.183594 20.222656 Z M 42.183594 20.222656 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="398b7f0581">
          <path
            d="M 10 29.0625 L 33.484375 29.0625 L 33.484375 46.46875 L 10 46.46875 Z M 10 29.0625 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="86e380698a">
          <path
            d="M 22.992188 29.730469 L 33.238281 44.640625 C 33.484375 45 33.511719 45.460938 33.308594 45.847656 C 33.109375 46.230469 32.710938 46.46875 32.277344 46.46875 L 11.1875 46.46875 C 10.753906 46.46875 10.355469 46.230469 10.15625 45.847656 C 9.953125 45.460938 9.980469 45 10.226562 44.640625 L 20.476562 29.730469 C 20.757812 29.316406 21.230469 29.070312 21.734375 29.070312 C 22.234375 29.070312 22.707031 29.316406 22.992188 29.730469 Z M 22.992188 29.730469 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#c2041e7f59)">
        <g clip-path="url(#6e67844089)">
          <path
            fill={backgroundColor}
            d="M 0.199219 0 L 66.765625 0 L 66.765625 55.511719 L 0.199219 55.511719 Z M 0.199219 0 "
            fill-opacity="1"
            fill-rule="nonzero"
          />
          <path
            stroke-linecap="butt"
            transform="matrix(0.740132, 0, 0, 0.740132, 0.197371, -0.00000123359)"
            fill="none"
            stroke-linejoin="miter"
            d="M 9.998609 0.00000166672 L 79.997778 0.00000166672 C 85.523612 0.00000166672 89.999168 4.475557 89.999168 10.001391 L 89.999168 65.001116 C 89.999168 70.521671 85.523612 75.002505 79.997778 75.002505 L 9.998609 75.002505 C 4.478053 75.002505 0.00249702 70.521671 0.00249702 65.001116 L 0.00249702 10.001391 C 0.00249702 4.475557 4.478053 0.00000166672 9.998609 0.00000166672 Z M 9.998609 0.00000166672 "
            stroke={backgroundColor}
            stroke-width="6"
            stroke-opacity="1"
            stroke-miterlimit="4"
          />
        </g>
      </g>
      <g clip-path="url(#1050a23bd2)">
        <g clip-path="url(#3d0da4ffc7)">
          <path
            fill="#ffffff"
            d="M 8.96875 7.214844 L 21.625 7.214844 L 21.625 19.871094 L 8.96875 19.871094 Z M 8.96875 7.214844 "
            fill-opacity="1"
            fill-rule="nonzero"
          />
        </g>
      </g>
      <g clip-path="url(#e096be6f34)">
        <g clip-path="url(#f524b3bac6)">
          <path
            fill="#ffffff"
            d="M 21.625 18.390625 L 60.230469 18.390625 L 60.230469 46.46875 L 21.625 46.46875 Z M 21.625 18.390625 "
            fill-opacity="1"
            fill-rule="nonzero"
          />
        </g>
      </g>
      <g clip-path="url(#398b7f0581)">
        <g clip-path="url(#86e380698a)">
          <path
            fill="#ffffff"
            d="M 8.96875 27.902344 L 34.5 27.902344 L 34.5 46.46875 L 8.96875 46.46875 Z M 8.96875 27.902344 "
            fill-opacity="1"
            fill-rule="nonzero"
          />
        </g>
      </g>
    </svg>
  );
}

export default ImageSvg;
