import React, { useEffect, useRef, useState } from "react";
import "./conins.scss";
import { randomPassword } from "../../RandomID";

function CoinsPopUp({ Close }) {
  const [amount, setAmount] = useState(5);

  const handleIncrement = () => {
    setAmount((prevAmount) => prevAmount + 1);
  };

  const handleDecrement = () => {
    setAmount((prevAmount) => (prevAmount > 0 ? prevAmount - 1 : 0));
  };

  const handleChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 0) {
      setAmount(value);
    }
  };

  let [Check, setTap] = useState("0");

  let [contentOther, setContentOther] = useState({
    header: "Other amount!",
    desc: "Alternatively you can enter the amount you would like to donate,thank you!",
  });

  let [content, setContent] = useState([
    {
      header: "Title of the tier, very cool!",
      desc: "Get a T-shirt with this very nice donation tier, thank you!",
    },
    {
      header: "Title of the tier, very cool!",
      desc: "Get a T-shirt with this very nice donation tier, thank you!",
    },
    {
      header: "Title of the tier, very cool!",
      desc: "Get a T-shirt with this very nice donation tier, thank you!",
    },
  ]);

  return (
    <div className="modal-control coinsss">
      <div
        className="form-wrap"
        style={{ padding: "12px 12px", maxWidth: "315px" }}
      >
        <h2 style={{ color: "rgb(87 93 104)", fontSize: "1.9rem" }}>
          Choose your donation amount
        </h2>
        <div className="boxes">
          <div
            onClick={() => setTap("0")}
            className={`box-number ${Check === "0" ? "active" : ""}`}
            style={{
              border:
                Check === "0"
                  ? "3px solid rgb(17, 81, 188)"
                  : "3px solid rgb(87 93 104)",
            }}
          >
            <span
              style={{
                color: Check === "0" ? "rgb(17, 81, 188)" : "rgb(143 148 162)",
              }}
            >
              $10
            </span>
          </div>
          <div
            className={`box-number ${Check === "1" ? "active" : ""}`}
            onClick={() => setTap("1")}
            style={{
              border:
                Check === "1"
                  ? "3px solid rgb(17, 81, 188)"
                  : "3px solid rgb(87 93 104)",
            }}
          >
            <span
              style={{
                color: Check === "1" ? "rgb(17, 81, 188)" : "rgb(143 148 162)",
              }}
            >
              $50
            </span>
          </div>
          <div
            className={`box-number ${Check === "2" ? "active" : ""}`}
            onClick={() => setTap("2")}
            style={{
              border:
                Check === "2"
                  ? "3px solid rgb(17, 81, 188)"
                  : "3px solid rgb(87 93 104)",
            }}
          >
            <span
              style={{
                color: Check === "2" ? "rgb(17, 81, 188)" : "rgb(143 148 162)",
              }}
            >
              $100
            </span>
          </div>
        </div>

        <div className="box-title">
          {Check === "four" ? (
            <>
              <span className="header">{contentOther?.header}</span>
              <span
                className="desc"
                style={{
                  minHeight: "41px",
                  maxHeight: "41px",
                  fontSize:
                    contentOther?.desc.length <= 75
                      ? "1.5rem"
                      : contentOther?.desc.length > 75 &&
                        contentOther?.desc.length <= 90
                      ? "1.3rem"
                      : contentOther?.desc.length > 90 &&
                        contentOther?.desc.length <= 96
                      ? "1.2rem"
                      : "1.1rem",
                }}
              >
                {contentOther?.desc}
              </span>
            </>
          ) : (
            <>
              <span className="header">{content[Check]?.header}</span>
              <span
                className="desc"
                style={{
                  minHeight: "41px",
                  maxHeight: "41px",
                  fontSize:
                    content[Check]?.desc.length <= 75
                      ? "1.5rem"
                      : content[Check]?.desc.length > 75 &&
                        content[Check]?.desc.length <= 90
                      ? "1.3rem"
                      : content[Check]?.desc.length > 90 &&
                        content[Check]?.desc.length <= 96
                      ? "1.2rem"
                      : "1.1rem",
                }}
              >
                {content[Check]?.desc}
              </span>
            </>
          )}
        </div>

        <div className="box-input">
          <div>
            <div className="box-label">
              <label htmlFor="amount">Other amount</label>
            </div>
            <div
              className={`wrapperMore ${Check === "four" ? "active" : ""}`}
              style={{
                border: "1px solid #ced4da",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                placeholder="$5"
                type="number"
                value={amount}
                onFocus={() => setTap("four")}
                onChange={handleChange}
                min="0"
                style={{ flex: 1 }}
              />
              <div className="icon-add">
                <i
                  className="fa-solid fa-angle-up"
                  onClick={() => {
                    handleIncrement();
                    setTap("four");
                  }}
                />
                <i
                  className="fa-solid fa-angle-down"
                  onClick={() => {
                    handleDecrement();
                    setTap("four");
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="d-flex "
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <button className="sucsessww">
            <span>Next</span>
          </button>
          <button className="cancle" onClick={Close}>
            <span>Cancel</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CoinsPopUp;
