import { Fragment } from "react";
import TitleInsideBox from "./TitleInsideBox";
import { Draggable } from "react-beautiful-dnd";
import { manageUsers } from "../../store/usersSlice";
import { useDispatch } from "react-redux";

export const renderVideos = (
  videoList,
  videoDimensions,
  childRef,
  theme,
  generateSacleChild,
  fontColor,
  layout,
  color,
  screenMode,
  ShowNameUser,
  ticker,
  status,
  videoRef,
  screenWidth,
  vi1,
  usersInStage,
  title,
  isMuted,
  SortableVideoItem,
  CustomDragPreview,
  dispatch,
  moveVideo
) => {
  const { rows, columns } = videoDimensions;
  const getTopLeftPercentages = (index, columns, rows) => {
    if (layout === "Grid") {
      const rowIndex = Math.floor(index / columns);
      const colIndex = index % columns;
      if (
        screenMode === "portrait" ||
        (status === "Viewer" && screenWidth <= 576)
      ) {
        if (videoList.length === 1) {
          const topPercent = 2; // 2% padding from top
          const leftPercent = 2; // 2% padding from left
          const widthPercent = 96 / columns; // Subtracting padding
          const heightPercent = 100 / 3 - 3; // Subtracting padding

          return { topPercent, leftPercent, widthPercent, heightPercent };
        } else if (videoList.length === 2) {
          const topPercent = rowIndex * (100 / 3) + 2; // 2% padding
          const leftPercent = 2; // 2% padding from left
          const widthPercent = 96 / 1; // Subtracting padding
          const heightPercent = 100 / 3 - 3; // Subtracting padding

          return { topPercent, leftPercent, widthPercent, heightPercent };
        } else if (videoList.length === 3) {
          if (index < 2) {
            // First two boxes in the first row
            const topPercent = 2; // 2% padding from top
            const leftPercent = index * 47 + 2 * (index + 1); // 2% padding from left, 47% width of each box
            const widthPercent = 47; // 45% width for each box
            const heightPercent = 47; // 47% height for each box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else {
            // Third box in the second row
            const topPercent = 53; // 50% from top for the second row
            const leftPercent = 2; // 2% padding from left
            const widthPercent = 96; // 96% width for the third box
            const heightPercent = 100 / 3 - 3; // 47% height for the third box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          }
        } else if (videoList.length === 4) {
          if (index < 2) {
            // First two boxes in the first row
            const topPercent = 2; // 2% padding from top
            const leftPercent = index * 47 + 2 * (index + 1) + 1; // 2% padding from left, 47% width of each box
            const widthPercent = 46; // 45% width for each box
            const heightPercent = 47; // 47% height for each box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === 3) {
            const topPercent = 53; // 50% from top for the second row
            const leftPercent = 3; // 2% padding from left, 47% width of each box
            const widthPercent = 46; // 45% width for each box
            const heightPercent = 47; // 47% height for each box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else {
            // Third box in the second row
            const topPercent = 53; // 50% from top for the second row
            const leftPercent = 52; // 2% padding from left, 47% width of each box
            const widthPercent = 46; // 45% width for each box
            const heightPercent = 47; // 47% height for each box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          }
        } else if (videoList.length === 5) {
          if (index < 3) {
            // First three boxes in the first row
            const topPercent = 2; // 2% padding from top
            const leftPercent = index * 30 + 2 * (index + 1) + 1; // 2% padding from left, 30% width of each box
            const widthPercent = 30; // 30% width for each box
            const heightPercent = 30; // 30% height for each box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === 3) {
            // Fourth box in the second row
            const topPercent = 35; // 35% from top for the second row
            const leftPercent = 2; // 2% padding from left
            const widthPercent = 96; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else {
            // Fifth box in the third row
            const topPercent = 68; // 68% from top for the third row
            const leftPercent = 2; // 2% padding from left
            const widthPercent = 96; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          }
        } else if (videoList.length === 6) {
          if (index < 3) {
            // First three boxes in the first row
            const topPercent = 2; // 2% padding from top
            const leftPercent = index * 30 + 2 * (index + 1) + 1; // 2% padding from left, 30% width of each box
            const widthPercent = 30; // 30% width for each box
            const heightPercent = 30; // 30% height for each box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === 3) {
            // Fourth box in the second row
            const topPercent = 35; // 35% from top for the second row
            const leftPercent = 3; // 2% padding from left
            const widthPercent = 43.5; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === 4) {
            // Fourth box in the second row
            const topPercent = 35; // 35% from top for the second row
            const leftPercent = 52; // 2% padding from left
            const widthPercent = 43.5; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else {
            // Fifth box in the third row
            const topPercent = 68; // 68% from top for the third row
            const leftPercent = 2; // 2% padding from left
            const widthPercent = 96; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          }
        } else if (videoList.length === 7) {
          if (index < 3) {
            // First three boxes in the first row
            const topPercent = 2; // 2% padding from top
            const leftPercent = index * 30 + 2 * (index + 1) + 1; // 2% padding from left, 30% width of each box
            const widthPercent = 30; // 30% width for each box
            const heightPercent = 30; // 30% height for each box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === 3) {
            // Fourth box in the second row
            const topPercent = 35; // 35% from top for the second row
            const leftPercent = 3; // 2% padding from left
            const widthPercent = 43.5; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === 4) {
            // Fourth box in the second row
            const topPercent = 35; // 35% from top for the second row
            const leftPercent = 52; // 2% padding from left
            const widthPercent = 43.5; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === 5) {
            // Fourth box in the second row
            const topPercent = 68; // 68% from top for the third row
            const leftPercent = 3; // 2% padding from left
            const widthPercent = 43.5; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else {
            // Fifth box in the third row
            const topPercent = 68; // 68% from top for the third row
            const leftPercent = 52; // 2% padding from left
            const widthPercent = 43.5; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          }
        } else if (videoList.length === 8) {
          if (index < 3) {
            // First three boxes in the first row
            const topPercent = 2; // 2% padding from top
            const leftPercent = index * 30 + 2 * (index + 1) + 1; // 2% padding from left, 30% width of each box
            const widthPercent = 30; // 30% width for each box
            const heightPercent = 30; // 30% height for each box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === 3) {
            // Fourth box in the second row
            const topPercent = 35; // 35% from top for the second row
            const leftPercent = 3; // 2% padding from left
            const widthPercent = 30; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === 4) {
            // Fourth box in the second row
            const topPercent = 35; // 35% from top for the second row
            const leftPercent = 35; // 2% padding from left
            const widthPercent = 30; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === 5) {
            // Fourth box in the second row
            const topPercent = 35; // 35% from top for the second row
            const leftPercent = 67; // 2% padding from left
            const widthPercent = 30; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === 6) {
            const topPercent = 68; // 68% from top for the third row
            const leftPercent = 3; // 2% padding from left
            const widthPercent = 43.5; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else {
            // Fifth box in the third row
            const topPercent = 68; // 68% from top for the third row
            const leftPercent = 52; // 2% padding from left
            const widthPercent = 43.5; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          }
        } else if (videoList.length === 9) {
          if (index < 3) {
            // First three boxes in the first row
            const topPercent = 2; // 2% padding from top
            const leftPercent = index * 30 + 2 * (index + 1) + 1; // 2% padding from left, 30% width of each box
            const widthPercent = 30; // 30% width for each box
            const heightPercent = 30; // 30% height for each box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === 3) {
            // Fourth box in the second row
            const topPercent = 35; // 35% from top for the second row
            const leftPercent = 3; // 2% padding from left
            const widthPercent = 30; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === 4) {
            // Fourth box in the second row
            const topPercent = 35; // 35% from top for the second row
            const leftPercent = 35; // 2% padding from left
            const widthPercent = 30; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === 5) {
            // Fourth box in the second row
            const topPercent = 35; // 35% from top for the second row
            const leftPercent = 67; // 2% padding from left
            const widthPercent = 30; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === 6) {
            // Fourth box in the second row
            const topPercent = 68; // 35% from top for the second row
            const leftPercent = 3; // 2% padding from left
            const widthPercent = 30; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === 7) {
            // Fourth box in the second row
            const topPercent = 68; // 35% from top for the second row
            const leftPercent = 35; // 2% padding from left
            const widthPercent = 30; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else {
            // Fifth box in the third row
            const topPercent = 68; // 68% from top for the third row
            const leftPercent = 67; // 2% padding from left
            const widthPercent = 30; // 96% width for the box
            const heightPercent = 30; // 30% height for the box

            return { topPercent, leftPercent, widthPercent, heightPercent };
          }
        }
      } else {
        if (videoList.length === 1) {
          const topPercent = rowIndex * (100 / rows); // 2% padding
          const leftPercent = colIndex * (100 / columns); // 2% padding
          const widthPercent = 100 / columns; // Subtracting padding
          const heightPercent = 100 / rows; // Subtracting padding
          return { topPercent, leftPercent, widthPercent, heightPercent };
        } else if (videoList.length === 2) {
          const topPercent = 26; // 26% padding
          const leftPercent = colIndex * (100 / columns) + 2; // 2% padding
          const widthPercent = 100 / columns - 4; // Subtracting padding
          const heightPercent = 46; // Subtracting padding
          return { topPercent, leftPercent, widthPercent, heightPercent };
        } else if (videoList.length === 3 || videoList.length === 4) {
          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;
          let widthPercent = 100 / columns - 4; // Subtracting padding
          let heightPercent = 100 / rows - 4; // Subtracting padding

          // Determine the number of boxes in the current row
          const boxesInRow = videoList.length - rowIndex * columns;
          const boxesInCurrentRow = boxesInRow < columns ? boxesInRow : columns;

          let leftOffset = 0;

          if (boxesInCurrentRow % 2 !== 0 && boxesInCurrentRow !== 3) {
            // Odd number of boxes in the current row: center the boxes
            leftOffset = 100 / columns / 2;
          }

          const topPercent = rowIndex * (100 / rows) + 2; // 2% padding
          const leftPercent = colIndex * (100 / columns) + 2 + leftOffset; // 2% padding and offset

          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
          };
        } else if (videoList.length === 5) {
          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;
          let widthPercent = 100 / columns - 4; // Subtracting padding
          let heightPercent = 100 / rows - 4; // Subtracting padding

          let topPercent; // 2% padding
          let leftPercent; // 2% padding and offset

          // Determine the number of boxes in the current row
          const boxesInRow = videoList.length - rowIndex * columns;
          const boxesInCurrentRow = boxesInRow < columns ? boxesInRow : columns;

          let leftOffset = 0;

          if (boxesInCurrentRow % 2 === 0 && boxesInCurrentRow !== 3) {
            // Even number of boxes in the current row: center the boxes
            leftOffset = 100 / columns / 2;
          }

          // Adjust the size of boxes in the second row
          if (rowIndex === 1) {
            // This condition targets the second row
            widthPercent *= 1.5;
            heightPercent *= 1.5;

            // Move the top position up by 8% for the second row
            topPercent = rowIndex * (100 / rows) - 8; // Subtracting 8%

            // Set the left position for the first box in the second row to 2%
            if (colIndex === 0) {
              leftPercent = 2;
            } else {
              leftPercent = colIndex * (100 / columns) + 2 + leftOffset; // 2% padding and offset
            }
          } else {
            // For the first row
            topPercent = rowIndex * (100 / rows) + 2; // 2% padding
            leftPercent = colIndex * (100 / columns) + 2 + leftOffset; // 2% padding and offset
          }

          return {
            topPercent: topPercent + 8,
            leftPercent,
            widthPercent,
            heightPercent: widthPercent,
          };
        } else if (videoList.length === 6) {
          const topPercent = rowIndex * (100 / rows) + 2; // 2% padding
          const leftPercent = colIndex * (100 / columns) + 2; // 2% padding
          const widthPercent = 100 / columns - 4; // Subtracting padding
          const heightPercent = 100 / rows - 4; // Subtracting padding
          return {
            topPercent: topPercent + 8,
            leftPercent,
            widthPercent,
            heightPercent: widthPercent,
          };
        } else if (videoList.length === 7 || videoList.length === 8) {
          let widthPercent = 100 / columns - 4; // Subtracting padding

          // Determine the number of boxes in the current row
          const boxesInRow = videoList.length - rowIndex * columns;
          const boxesInCurrentRow = boxesInRow < columns ? boxesInRow : columns;

          let leftOffset = 0;

          if (boxesInCurrentRow % 2 === 0 && boxesInCurrentRow !== 3) {
            // Odd number of boxes in the current row: center the boxes
            leftOffset = 100 / columns / 2;
          } else if (boxesInCurrentRow % 2 !== 0 && boxesInCurrentRow !== 3) {
            leftOffset = 100 / columns;
          }

          const topPercent = rowIndex * (100 / rows) + 2; // 2% padding
          const leftPercent = colIndex * (100 / columns) + 2 + leftOffset; // 2% padding and offset

          return {
            topPercent: topPercent + (videoList.length === 5 && 8),
            leftPercent,
            widthPercent,
            heightPercent: widthPercent,
          };
        } else if (videoList.length >= 10 && videoList.length <= 15) {
          const heightPercent = 100 / rows - 4; // Subtracting padding
          // Determine the number of boxes in the current row
          const boxesInRow = videoList.length - rowIndex * columns;
          const boxesInCurrentRow = boxesInRow < columns ? boxesInRow : columns;

          let leftOffset = 0;

          if (boxesInCurrentRow % 2 === 0 && boxesInCurrentRow !== 3) {
            // Odd number of boxes in the current row: center the boxes
            leftOffset = 100 / columns / 2;
          } else if (boxesInCurrentRow % 2 !== 0 && boxesInCurrentRow !== 3) {
            leftOffset = 100 / columns;
          }

          const topPercent = rowIndex * (100 / rows) + 2; // 2% padding
          const leftPercent = colIndex * (100 / columns) + 2 + leftOffset; // 2% padding and offset

          return {
            topPercent,
            leftPercent: leftPercent + (videoList.length > 12 ? 6 : 4),
            widthPercent: heightPercent,
            heightPercent,
          };
        } else {
          const topPercent = rowIndex * (100 / rows) + 2; // 2% padding
          const leftPercent = colIndex * (100 / columns) + 2; // 2% padding
          const widthPercent = 100 / columns - 4; // Subtracting padding
          const heightPercent = 100 / rows - 4; // Subtracting padding
          return { topPercent, leftPercent, widthPercent, heightPercent };
        }
      }
    } else if (layout === "Fill") {
      let rowIndex = Math.floor(index / columns);
      const colIndex = index % columns;
      if (
        screenMode === "portrait" ||
        (status === "Viewer" && screenWidth <= 576)
      ) {
        if (videoList.length === 1) {
          const topPercent = rowIndex * (100 / rows); // 2% padding
          const leftPercent = colIndex * (100 / columns); // 2% padding
          const widthPercent = 100 / columns; // Subtracting padding
          const heightPercent = 100 / rows; // Subtracting padding
          return { topPercent, leftPercent, widthPercent, heightPercent };
        } else if (videoList.length === 2) {
          const topPercent = rowIndex * (100 / rows); // Position videos in separate rows
          const leftPercent = colIndex * (100 / columns); // 2% padding from left
          const widthPercent = 100 / columns; // Subtracting padding
          const heightPercent = 100 / rows; // Each video occupies its own row

          return { topPercent, leftPercent, widthPercent, heightPercent };
        } else if (videoList.length === 3) {
          // console.log("hello trash rows", rows);
          rowIndex = Math.floor(index / 2);
          if (rowIndex === 0) {
            // First row with two boxes
            const topPercent = rowIndex * (100 / 2); // Position in the first row
            const leftPercent = colIndex * (100 / 2); // Each box takes up 50% of the width
            const widthPercent = 100 / 2; // 50% width for each box
            const heightPercent = 100 / 2; // Each box occupies its own row

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else {
            // Second row with one box
            const topPercent = rowIndex * (100 / 2); // Position in the second row
            const leftPercent = 0; // Box starts from the left
            const widthPercent = 100; // Box occupies the entire width
            const heightPercent = 100 / 2; // Each box occupies its own row

            return { topPercent, leftPercent, widthPercent, heightPercent };
          }
        } else if (videoList.length === 4) {
          const columns = 2; // Number of columns for the layout
          const rows = 2; // Number of rows for the layout

          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;

          let topPercent;
          let leftPercent;
          let widthPercent;
          let heightPercent = 100 / rows; // Each box takes up 1/2 of the column height

          // Distribute the videos equally in each row
          topPercent = rowIndex * (100 / rows);
          leftPercent = colIndex * (100 / columns);
          widthPercent = 100 / columns;

          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
          };
        } else if (videoList.length === 5) {
          const columns = 3; // Number of columns for the layout
          const rows = 3; // Number of rows for the layout

          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;

          let topPercent;
          let leftPercent;
          let widthPercent;
          let heightPercent;

          if (index < 3) {
            // First row with 3 boxes
            topPercent = 0; // Position in the first row
            leftPercent = colIndex * (100 / columns); // Distribute boxes equally in the row
            widthPercent = 100 / columns; // Equal width for each box
            heightPercent = 100 / rows; // Each box occupies its own row
          } else {
            // Second and third row with 1 box each
            topPercent = (index - 2) * (100 / rows); // Position in the respective row
            leftPercent = 0; // Box starts from the left
            widthPercent = 100; // Box occupies the entire width
            heightPercent = 100 / rows; // Each box occupies its own row
          }

          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
          };
        } else if (videoList.length === 6) {
          const columns = 3; // Number of columns for the layout
          const rows = 3; // Number of rows for the layout

          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;

          let topPercent;
          let leftPercent;
          let widthPercent;
          let heightPercent;

          if (index <= 2) {
            // First row with 3 boxes
            topPercent = 0; // Position in the first row
            leftPercent = colIndex * (100 / columns); // Distribute boxes equally in the row
            widthPercent = 100 / columns; // Equal width for each box
            heightPercent = 100 / rows; // Each box occupies its own row
          } else if (index <= 4) {
            // Second row with 2 boxes
            topPercent = 100 / rows; // Position in the second row
            leftPercent = (index - 3) * (100 / 2); // Distribute boxes equally in the row
            widthPercent = 100 / 2; // Each box takes up half of the width
            heightPercent = 100 / rows; // Each box occupies its own row
          } else {
            // Third row with 1 box
            topPercent = 2 * (100 / rows); // Position in the third row
            leftPercent = 0; // Box starts from the left
            widthPercent = 100; // Box occupies the entire width
            heightPercent = 100 / rows; // Each box occupies its own row
          }

          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
          };
        } else if (videoList.length === 7) {
          const columns = 3; // Number of columns for the layout
          const rows = 3; // Number of rows for the layout

          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;

          let topPercent;
          let leftPercent;
          let widthPercent;
          let heightPercent;

          if (index <= 2) {
            // First row with 3 boxes
            topPercent = 0; // Position in the first row
            leftPercent = colIndex * (100 / columns); // Distribute boxes equally in the row
            widthPercent = 100 / columns; // Equal width for each box
            heightPercent = 100 / rows; // Each box occupies its own row
          } else if (index <= 4) {
            // Second row with 2 boxes
            topPercent = 100 / rows; // Position in the second row
            leftPercent = (index - 3) * (100 / 2); // Distribute boxes equally in the row
            widthPercent = 100 / 2; // Each box takes up half of the width
            heightPercent = 100 / rows; // Each box occupies its own row
          } else {
            // Third row with 2 boxes
            topPercent = 2 * (100 / rows); // Position in the third row
            leftPercent = (index - 5) * (100 / 2); // Distribute boxes equally in the row
            widthPercent = 100 / 2; // Each box takes up half of the width
            heightPercent = 100 / rows; // Each box occupies its own row
          }

          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
          };
        } else if (videoList.length === 8) {
          const columns = 3; // Number of columns for the layout
          const rows = 3; // Number of rows for the layout

          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;

          let topPercent;
          let leftPercent;
          let widthPercent;
          let heightPercent;

          if (index <= 2) {
            // First row with 3 boxes
            topPercent = 0; // Position in the first row
            leftPercent = colIndex * (100 / columns); // Distribute boxes equally in the row
            widthPercent = 100 / columns; // Equal width for each box
            heightPercent = 100 / rows; // Each box occupies its own row
          } else if (index <= 5) {
            // Second row with 3 boxes
            topPercent = 100 / rows; // Position in the second row
            leftPercent = (index - 3) * (100 / 3); // Distribute boxes equally in the row
            widthPercent = 100 / 3; // Each box takes up one-third of the width
            heightPercent = 100 / rows; // Each box occupies its own row
          } else {
            // Third row with 2 boxes
            topPercent = 2 * (100 / rows); // Position in the third row
            leftPercent = (index - 6) * (100 / 2); // Distribute boxes equally in the row
            widthPercent = 100 / 2; // Each box takes up half of the width
            heightPercent = 100 / rows; // Each box occupies its own row
          }

          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
          };
        } else if (videoList.length === 9) {
          const columns = 3; // Number of columns for the layout
          const rows = 3; // Number of rows for the layout

          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;

          let topPercent;
          let leftPercent;
          let widthPercent;
          let heightPercent;

          // Distribute the videos evenly across the rows and columns
          topPercent = rowIndex * (100 / rows);
          leftPercent = colIndex * (100 / columns);
          widthPercent = 100 / columns;
          heightPercent = 100 / rows;

          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
          };
        } else {
          return {
            topPercent: 1,
            leftPercent: 1,
            widthPercent: 1,
            heightPercent: 1,
          };
        }
      } else {
        if (videoList.length === 1) {
          const topPercent = rowIndex * (100 / rows); // 2% padding
          const leftPercent = colIndex * (100 / columns); // 2% padding
          const widthPercent = 100 / columns; // Subtracting padding
          const heightPercent = 100 / rows; // Subtracting padding
          return { topPercent, leftPercent, widthPercent, heightPercent };
        } else if (videoList.length === 2) {
          const topPercent = 0; // 26% padding
          const leftPercent = colIndex * (100 / columns); // 2% padding
          const rightPercent = colIndex === 0 ? 50 : 0; // 2% padding
          const widthPercent = 50; // Subtracting padding
          const heightPercent = 100; // Subtracting padding
          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
            rightPercent,
          };
        } else if (videoList.length === 3) {
          const topPercent = 0; // 26% padding
          const leftPercent = colIndex * (100 / columns); // 2% padding
          const rightPercent =
            colIndex === 0 ? 66.666 : colIndex === 1 ? 33.333 : 0;
          const widthPercent = 100 / columns; // Subtracting padding
          const heightPercent = 100; // Subtracting padding
          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
            rightPercent,
          };
        } else if (videoList.length === 4) {
          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;
          let widthPercent = 100 / columns; // Subtracting padding
          let heightPercent = 100 / rows; // Subtracting padding

          // Determine the number of boxes in the current row
          const boxesInRow = videoList.length - rowIndex * columns;
          const boxesInCurrentRow = boxesInRow < columns ? boxesInRow : columns;

          let leftOffset = 0;

          if (boxesInCurrentRow % 2 !== 0 && boxesInCurrentRow !== 3) {
            // Odd number of boxes in the current row: center the boxes
            leftOffset = 100 / columns / 2;
          }

          const topPercent = rowIndex * (100 / rows); // 2% padding
          const leftPercent = colIndex * (100 / columns) + leftOffset; // 2% padding and offset

          return { topPercent, leftPercent, widthPercent, heightPercent };
        } else if (videoList.length === 5) {
          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;
          let widthPercent = 100 / columns; // Subtracting padding
          let heightPercent = 100 / rows; // Subtracting padding

          let topPercent; // 2% padding
          let leftPercent; // 2% padding and offset

          // Determine the number of boxes in the current row
          const boxesInRow = videoList.length - rowIndex * columns;
          const boxesInCurrentRow = boxesInRow < columns ? boxesInRow : columns;

          let leftOffset = 0;

          if (boxesInCurrentRow % 2 === 0 && boxesInCurrentRow !== 3) {
            // Even number of boxes in the current row: center the boxes
            leftOffset = 100 / columns / 2;
          }

          // Adjust the size of boxes in the second row
          if (rowIndex === 1) {
            // This condition targets the second row
            widthPercent *= 1.5;

            // Move the top position up by 8% for the second row
            topPercent = rowIndex * (100 / rows); // Subtracting 8%

            // Set the left position for the first box in the second row to 2%
            if (colIndex === 0) {
              leftPercent = 0;
            } else {
              leftPercent = colIndex * (100 / columns) + leftOffset; // 2% padding and offset
            }
          } else {
            // For the first row
            topPercent = rowIndex * (100 / rows); // 2% padding
            leftPercent = colIndex * (100 / columns); // 2% padding and offset
          }

          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent: heightPercent,
          };
        } else if (videoList.length === 6) {
          const topPercent = rowIndex * (100 / rows); // 2% padding
          const leftPercent = colIndex * (100 / columns); // 2% padding
          const widthPercent = 100 / columns; // Subtracting padding
          const heightPercent = 100 / rows; // Subtracting padding
          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
          };
        } else if (videoList.length === 7) {
          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;
          let widthPercent = 100 / columns; // Subtracting padding
          let heightPercent = 100 / rows; // Subtracting padding

          if (rowIndex === 1) {
            // For boxes in the second row
            widthPercent = 33.33; // Each box in the second row takes 1/3 of the width
            const boxesInFirstRow = columns; // Number of boxes in the first row
            const secondRowIndex = index - boxesInFirstRow;
            const secondRowColIndex = secondRowIndex % 3;

            const topPercent = rowIndex * (100 / rows); // Position in the second row
            const leftPercent = secondRowColIndex * 33.33;

            return {
              topPercent,
              leftPercent,
              widthPercent,
              heightPercent,
            };
          } else {
            // For boxes in the first row
            const topPercent = rowIndex * (100 / rows); // 2% padding
            const leftPercent = colIndex * (100 / columns); // 2% padding and offset

            return {
              topPercent,
              leftPercent,
              widthPercent,
              heightPercent,
            };
          }
        } else if (videoList.length === 8) {
          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;

          const widthPercent = 100 / columns; // Each box takes up 1/4th of the row width
          const heightPercent = 100 / rows; // Each box takes up 1/2 of the column height

          const topPercent = rowIndex * (100 / rows); // Position in the row
          const leftPercent = colIndex * (100 / columns); // Position in the column

          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
          };
        } else if (videoList.length === 9) {
          const columns = 3; // Fixed 3 columns for 9 video layout
          const rows = 3; // Fixed 3 rows for 9 video layout

          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;

          const widthPercent = 100 / columns; // Each box takes up 1/3rd of the row width
          const heightPercent = 100 / rows; // Each box takes up 1/3rd of the column height

          const topPercent = rowIndex * (100 / rows); // Position in the row
          const leftPercent = colIndex * (100 / columns); // Position in the column

          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
          };
        } else if (videoList.length === 10) {
          const columns = 3; // Number of columns for the layout
          const rows = 3; // Number of rows for the layout

          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;

          let topPercent;
          let leftPercent;
          let widthPercent;
          let heightPercent = 100 / rows; // Each box takes up 1/3 of the column height

          if (index < 4) {
            // First row: 4 boxes
            topPercent = 0; // Position in the first row
            leftPercent = (index % 4) * 25; // Each box takes up 1/4th of the width
            widthPercent = 25; // 25% width for each box
          } else if (index < 7) {
            // Second row: 3 boxes taking full width
            topPercent = 100 / rows; // Position in the second row
            leftPercent = (index - 4) * (100 / columns); // Each box takes up 1/3rd of the width
            widthPercent = 100 / columns; // 33.33% width for each box
          } else {
            // Third row: 3 boxes taking full width
            topPercent = 2 * (100 / rows); // Position in the third row
            leftPercent = (index - 7) * (100 / columns); // Each box takes up 1/3rd of the width
            widthPercent = 100 / columns; // 33.33% width for each box
          }

          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
          };
        } else if (videoList.length === 11) {
          const columns = 3; // Number of columns for the layout
          const rows = 3; // Number of rows for the layout

          const rowIndex = Math.floor(index / 4); // 4 boxes per row in the first and second rows
          const colIndex = index % 4;

          let topPercent;
          let leftPercent;
          let widthPercent;
          let heightPercent = 100 / rows; // Each box takes up 1/3 of the column height

          if (rowIndex === 0) {
            // First row: 4 boxes
            topPercent = 0; // Position in the first row
            leftPercent = (index % 4) * 25; // Each box takes up 1/4th of the width
            widthPercent = 25; // 25% width for each box
          } else if (rowIndex === 1) {
            // Second row: 4 boxes
            topPercent = 100 / rows; // Position in the second row
            leftPercent = (index % 4) * 25; // Each box takes up 1/4th of the width
            widthPercent = 25; // 25% width for each box
          } else if (rowIndex === 2) {
            // Third row: 3 boxes
            const thirdRowIndex = index - 8; // Adjust index for third row
            topPercent = 2 * (100 / rows); // Position in the third row
            leftPercent = thirdRowIndex * (100 / columns); // Each box takes up 1/3rd of the width
            widthPercent = 100 / columns; // 33.33% width for each box
          }

          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
          };
        } else if (videoList.length === 12) {
          const columns = 4; // Number of columns for the layout
          const rows = 3; // Number of rows for the layout

          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;

          const topPercent = rowIndex * (100 / rows); // Each row takes up 1/3 of the height
          const leftPercent = colIndex * (100 / columns); // Each column takes up 1/4 of the width
          const widthPercent = 100 / columns; // 25% width for each box
          const heightPercent = 100 / rows; // 33.33% height for each box

          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
          };
        } else if (videoList.length === 13) {
          const columns = 5; // Number of columns for the layout
          const rows = 3; // Number of rows for the layout

          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;

          let topPercent;
          let leftPercent;
          let widthPercent;
          let heightPercent = 100 / rows; // Each box takes up 1/3 of the column height

          if (index >= 0 && index <= 4) {
            // First row: 5 boxes
            topPercent = 0; // Position in the first row
            leftPercent = colIndex * (100 / columns); // Each box takes up 1/5th of the width
            widthPercent = 100 / columns; // 20% width for each box
          } else if (index >= 5 && index <= 8) {
            // Second row: 4 boxes
            topPercent = 100 / rows; // Position in the second row
            leftPercent = (index - 5) * (100 / 4); // Each box takes up 1/4th of the width
            widthPercent = 100 / 4; // 25% width for each box
          } else {
            // Third row: Remaining boxes
            topPercent = 2 * (100 / rows); // Position in the third row
            leftPercent = (index - 9) * (100 / 4); // Each box takes up 1/4th of the width
            widthPercent = 100 / 4; // 25% width for each box
          }

          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
          };
        } else if (videoList.length === 14) {
          const columns = 5; // Number of columns for the layout
          const rows = 3; // Number of rows for the layout

          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;

          let topPercent;
          let leftPercent;
          let widthPercent;
          let heightPercent = 100 / rows; // Each box takes up 1/3 of the column height

          if (index >= 0 && index <= 4) {
            // First row: 5 boxes
            topPercent = 0; // Position in the first row
            leftPercent = colIndex * (100 / columns); // Each box takes up 1/5th of the width
            widthPercent = 100 / columns; // 20% width for each box
          } else if (index >= 5 && index <= 9) {
            // Second row: 5 boxes
            topPercent = 100 / rows; // Position in the second row
            leftPercent = (index - 5) * (100 / columns); // Each box takes up 1/5th of the width
            widthPercent = 100 / columns; // 20% width for each box
          } else {
            // Third row: Remaining boxes (4 boxes)
            topPercent = 2 * (100 / rows); // Position in the third row
            leftPercent = (index - 10) * (100 / 4); // Each box takes up 1/4th of the width
            widthPercent = 100 / 4; // 25% width for each box
          }

          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
          };
        } else if (videoList.length === 15) {
          const columns = 5; // Number of columns for the layout
          const rows = 3; // Number of rows for the layout

          const rowIndex = Math.floor(index / columns);
          const colIndex = index % columns;

          let topPercent;
          let leftPercent;
          let widthPercent;
          let heightPercent = 100 / rows; // Each box takes up 1/3 of the column height

          // Distribute the videos equally in each row
          topPercent = rowIndex * (100 / rows);
          leftPercent = colIndex * (100 / columns);
          widthPercent = 100 / columns;

          return {
            topPercent,
            leftPercent,
            widthPercent,
            heightPercent,
          };
        }
      }
    } else if (layout === "mainSup") {
      if (
        screenMode === "portrait" ||
        (status === "Viewer" && screenWidth <= 576)
      ) {
        if (videoList.length === 1) {
          const topPercent = 2; // 2% padding from top
          const leftPercent = 2; // 2% padding from left
          const widthPercent = 96 / columns; // Subtracting padding
          const heightPercent = 100 / 3 - 3; // Subtracting padding

          return { topPercent, leftPercent, widthPercent, heightPercent };
        } else if (videoList.length === 2) {
          const mainVideoIndex = videoList.findIndex((video) => video.main);

          if (index === mainVideoIndex) {
            // Main video dimensions and position
            const mainTopPercent = 2;
            const mainLeftPercent = 2;
            const mainWidthPercent = 96; // Adjusted to leave some padding
            const mainHeightPercent = 30.33; // Adjusted to desired height

            return {
              topPercent: mainTopPercent,
              leftPercent: mainLeftPercent,
              widthPercent: mainWidthPercent,
              heightPercent: mainHeightPercent,
            };
          } else {
            // Position for the second video
            const secondaryIndex = index - (index > mainVideoIndex ? 1 : 0); // Adjust index if after main video

            // Remaining height after main video
            const remainingHeightPercent = 100 - 2 - 30.33; // Subtracting top padding and main video height
            const rowHeightPercent = remainingHeightPercent / 2; // Height for each row

            // Calculate position for the secondary video in the first row
            const secondaryTopPercent = 34.33; // Starting just below the main video + padding
            const secondaryLeftPercent = 25; // Same left padding as the main video
            const secondaryWidthPercent = 48; // Same width as the main video
            const secondaryHeightPercent = rowHeightPercent; // Height for the first row

            return {
              topPercent: secondaryTopPercent,
              leftPercent: secondaryLeftPercent,
              widthPercent: secondaryWidthPercent,
              heightPercent: secondaryHeightPercent,
            };
          }
        } else if (videoList.length === 3) {
          const mainVideoIndex = videoList.findIndex((video) => video.main);

          if (index === mainVideoIndex) {
            // Main video dimensions and position
            const mainTopPercent = 2;
            const mainLeftPercent = 2;
            const mainWidthPercent = 96; // Adjusted to leave some padding
            const mainHeightPercent = 30.33; // Adjusted to desired height

            return {
              topPercent: mainTopPercent,
              leftPercent: mainLeftPercent,
              widthPercent: mainWidthPercent,
              heightPercent: mainHeightPercent,
            };
          } else {
            // Position for the secondary videos
            const secondaryIndex = index - (index > mainVideoIndex ? 1 : 0); // Adjust index if after main video

            // Remaining height after main video
            const remainingHeightPercent = 100 - 2 - 30.33; // Subtracting top padding and main video height
            const rowHeightPercent = remainingHeightPercent / 2; // Height for each row

            if (secondaryIndex === 0 || secondaryIndex === 1) {
              // First row of secondary boxes
              const secondaryTopPercent = 34.33; // Starting just below the main video + padding
              const secondaryLeftPercent = 2 + secondaryIndex * 48; // Positioning the two boxes side by side
              const secondaryWidthPercent = 46; // Adjust width to fit 2 boxes in the row
              const secondaryHeightPercent = 30.33; // Height for the first row

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryIndex === 1 ? 51 : secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: secondaryHeightPercent,
              };
            }
          }
        } else if (videoList.length === 4) {
          const mainVideoIndex = videoList.findIndex((video) => video.main);

          if (index === mainVideoIndex) {
            // Main video dimensions and position
            const mainTopPercent = 2;
            const mainLeftPercent = 2;
            const mainWidthPercent = 96; // Adjusted to leave some padding
            const mainHeightPercent = 30.33; // Adjusted to desired height

            return {
              topPercent: mainTopPercent,
              leftPercent: mainLeftPercent,
              widthPercent: mainWidthPercent,
              heightPercent: mainHeightPercent,
            };
          } else {
            // Position for the secondary videos
            const secondaryIndex = index - (index > mainVideoIndex ? 1 : 0); // Adjust index if after main video

            // Remaining height after main video
            const remainingHeightPercent = 100 - 2 - 30.33; // Subtracting top padding and main video height
            const rowHeightPercent = remainingHeightPercent / 2; // Height for each row

            if (secondaryIndex === 0 || secondaryIndex === 1) {
              // First row of secondary boxes
              const secondaryTopPercent = 34.33; // Starting just below the main video + padding
              const secondaryLeftPercent = 2 + secondaryIndex * 48; // Positioning the two boxes side by side
              const secondaryWidthPercent = 46; // Adjust width to fit 2 boxes in the row
              const secondaryHeightPercent = 30.33; // Height for the first row

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryIndex === 1 ? 51 : secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: secondaryHeightPercent,
              };
            } else if (secondaryIndex === 2) {
              // Second row with one full-width box
              const secondaryTopPercent = 67; // Starting just below the first row
              const secondaryLeftPercent = 25; // Adjusted left padding
              const secondaryWidthPercent = 48; // Full width of the remaining space
              const secondaryHeightPercent = 30.33; // Height for the second row

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: secondaryHeightPercent,
              };
            }
          }
        } else if (videoList.length === 5) {
          const mainVideoIndex = videoList.findIndex((video) => video.main);

          if (index === mainVideoIndex) {
            // Main video dimensions and position
            const mainTopPercent = 2;
            const mainLeftPercent = 2;
            const mainWidthPercent = 96; // Adjusted to leave some padding
            const mainHeightPercent = 30.33; // Adjusted to desired height

            return {
              topPercent: mainTopPercent,
              leftPercent: mainLeftPercent,
              widthPercent: mainWidthPercent,
              heightPercent: mainHeightPercent,
            };
          } else {
            // Position for the secondary videos
            const secondaryIndex = index - (index > mainVideoIndex ? 1 : 0); // Adjust index if after main video

            // Remaining height after main video
            const remainingHeightPercent = 100 - 2 - 30.33; // Subtracting top padding and main video height
            const rowHeightPercent = remainingHeightPercent / 2; // Height for each row

            if (secondaryIndex === 0 || secondaryIndex === 1) {
              // First row of secondary boxes
              const secondaryTopPercent = 34.33; // Starting just below the main video + padding
              const secondaryLeftPercent = 2 + secondaryIndex * 48; // Positioning the two boxes side by side
              const secondaryWidthPercent = 46; // Adjust width to fit 2 boxes in the row
              const secondaryHeightPercent = 30.33; // Height for the first row

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryIndex === 1 ? 51 : secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: secondaryHeightPercent,
              };
            } else if (secondaryIndex === 2 || secondaryIndex === 3) {
              // Second row of secondary boxes
              const secondaryTopPercent = 67; // Starting just below the first row
              const secondaryLeftPercent = 2 + (secondaryIndex - 2) * 48; // Positioning the two boxes side by side
              const secondaryWidthPercent = 46; // Adjust width to fit 2 boxes in the row
              const secondaryHeightPercent = 30.33; // Height for the second row

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryIndex === 3 ? 51 : secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: secondaryHeightPercent,
              };
            }
          }
        } else if (videoList.length === 6) {
          const mainVideoIndex = videoList.findIndex((video) => video.main);

          if (index === mainVideoIndex) {
            // Main video dimensions and position
            const mainTopPercent = 2;
            const mainLeftPercent = 2;
            const mainWidthPercent = 96; // Adjusted to leave some padding
            const mainHeightPercent = 30.33; // Adjusted to desired height

            return {
              topPercent: mainTopPercent,
              leftPercent: mainLeftPercent,
              widthPercent: mainWidthPercent,
              heightPercent: mainHeightPercent,
            };
          } else {
            // Position for the secondary videos
            const secondaryIndex = index - (index > mainVideoIndex ? 1 : 0); // Adjust index if after main video

            // Remaining height after main video
            const remainingHeightPercent = 100 - 2 - 30.33; // Subtracting top padding and main video height
            const rowHeightPercent = remainingHeightPercent / 3; // Height for each row

            if (secondaryIndex === 0 || secondaryIndex === 1) {
              // First row of secondary boxes
              const secondaryTopPercent = 34.33; // Starting just below the main video + padding
              const secondaryLeftPercent = 2 + secondaryIndex * 48; // Positioning the two boxes side by side
              const secondaryWidthPercent = 46; // Adjust width to fit 2 boxes in the row
              const secondaryHeightPercent = rowHeightPercent; // Height for the first row

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryIndex === 1 ? 51 : secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: 20,
              };
            } else if (secondaryIndex === 2 || secondaryIndex === 3) {
              // Second row of secondary boxes
              const secondaryTopPercent = 34.33 + rowHeightPercent; // Starting just below the first row
              const secondaryLeftPercent = 2 + (secondaryIndex - 2) * 48; // Positioning the two boxes side by side
              const secondaryWidthPercent = 46; // Adjust width to fit 2 boxes in the row
              const secondaryHeightPercent = rowHeightPercent; // Height for the second row

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryIndex === 3 ? 51 : secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: 20,
              };
            } else {
              // Third row with one full-width box
              const secondaryTopPercent = 68.66 + 10; // Starting just below the second row
              const secondaryLeftPercent = 25; // Same left padding as other boxes
              const secondaryWidthPercent = 48; // Full width of the remaining space
              const secondaryHeightPercent = 20; // Height for the third row

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: secondaryHeightPercent,
              };
            }
          }
        } else if (videoList.length === 7) {
          const mainVideoIndex = videoList.findIndex((video) => video.main);

          if (index === mainVideoIndex) {
            // Main video dimensions and position
            const mainTopPercent = 2;
            const mainLeftPercent = 2;
            const mainWidthPercent = 96; // Adjusted to leave some padding
            const mainHeightPercent = 30.33; // Adjusted to desired height

            return {
              topPercent: mainTopPercent,
              leftPercent: mainLeftPercent,
              widthPercent: mainWidthPercent,
              heightPercent: mainHeightPercent,
            };
          } else {
            // Position for the secondary videos
            const secondaryIndex = index - (index > mainVideoIndex ? 1 : 0); // Adjust index if after main video

            // Remaining height after main video
            const remainingHeightPercent = 100 - 2 - 30.33; // Subtracting top padding and main video height
            const rowHeightPercent = remainingHeightPercent / 3; // Height for each row

            // Calculate which row the secondary video belongs to
            const row = Math.floor(secondaryIndex / 2);

            // Calculate the position within the row
            const col = secondaryIndex % 2;

            // Calculate top and left percentages based on row and column
            const secondaryTopPercent = 34.33 + row * rowHeightPercent; // Starting just below the main video + padding
            const secondaryLeftPercent = 2 + col * 48; // Positioning the two boxes side by side
            const secondaryWidthPercent = 46; // Adjust width to fit 2 boxes in the row
            const secondaryHeightPercent = rowHeightPercent; // Height for the row

            return {
              topPercent: secondaryTopPercent,
              leftPercent: secondaryLeftPercent + col,
              widthPercent: secondaryWidthPercent,
              heightPercent: 20,
            };
          }
        } else if (videoList.length === 8) {
          const mainVideoIndex = videoList.findIndex((video) => video.main);

          if (index === mainVideoIndex) {
            // Main video dimensions and position
            const mainTopPercent = 2;
            const mainLeftPercent = 2;
            const mainWidthPercent = 96; // Adjusted to leave some padding
            const mainHeightPercent = 30.33; // Adjusted to desired height

            return {
              topPercent: mainTopPercent,
              leftPercent: mainLeftPercent,
              widthPercent: mainWidthPercent,
              heightPercent: mainHeightPercent,
            };
          } else {
            // Position for the secondary videos
            const secondaryIndex = index - (index > mainVideoIndex ? 1 : 0); // Adjust index if after main video

            // Remaining height after main video
            const remainingHeightPercent = 100 - 2 - 30.33; // Subtracting top padding and main video height
            const rowHeightPercent = remainingHeightPercent / 3; // Height for each row

            if (
              secondaryIndex === 0 ||
              secondaryIndex === 1 ||
              secondaryIndex === 2
            ) {
              // First row of secondary boxes
              const secondaryTopPercent = 34.33; // Starting just below the main video + padding
              const secondaryLeftPercent = 2 + secondaryIndex * 32; // Positioning the boxes side by side
              const secondaryWidthPercent = 30; // Adjust width to fit 3 boxes in the row
              const secondaryHeightPercent = rowHeightPercent; // Height for the row

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: 20,
              };
            } else if (secondaryIndex === 3 || secondaryIndex === 4) {
              // Second row of secondary boxes
              const secondaryTopPercent = 34.33 + rowHeightPercent; // Starting just below the first row
              const secondaryLeftPercent = 2 + (secondaryIndex - 3) * 32; // Positioning the boxes side by side
              const secondaryWidthPercent = 46; // Adjust width to fit 2 boxes in the row
              const secondaryHeightPercent = rowHeightPercent; // Height for the row

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryIndex === 4 ? 51 : secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: 20,
              };
            } else {
              // Third row of secondary boxes
              const secondaryTopPercent = 34.33 + 2 * rowHeightPercent; // Starting just below the second row
              const secondaryLeftPercent = 2 + (secondaryIndex - 5) * 32; // Positioning the boxes side by side
              const secondaryWidthPercent = 46; // Adjust width to fit 2 boxes in the row
              const secondaryHeightPercent = rowHeightPercent; // Height for the row

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryIndex === 6 ? 51 : secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: 20,
              };
            }
          }
        } else if (videoList.length === 9) {
          const mainVideoIndex = videoList.findIndex((video) => video.main);

          if (index === mainVideoIndex) {
            // Main video dimensions and position
            const mainTopPercent = 2;
            const mainLeftPercent = 2;
            const mainWidthPercent = 96; // Adjusted to leave some padding
            const mainHeightPercent = 30.33; // Adjusted to desired height

            return {
              topPercent: mainTopPercent,
              leftPercent: mainLeftPercent,
              widthPercent: mainWidthPercent,
              heightPercent: mainHeightPercent,
            };
          } else {
            // Position for the secondary videos
            const secondaryIndex = index - (index > mainVideoIndex ? 1 : 0); // Adjust index if after main video

            // Remaining height after main video
            const remainingHeightPercent = 100 - 2 - 30.33; // Subtracting top padding and main video height
            const rowHeightPercent = remainingHeightPercent / 3; // Height for each row

            if (
              secondaryIndex === 0 ||
              secondaryIndex === 1 ||
              secondaryIndex === 2
            ) {
              // First row of secondary boxes
              const secondaryTopPercent = 34.33; // Starting just below the main video + padding
              const secondaryLeftPercent = 2 + secondaryIndex * 32; // Positioning the boxes side by side
              const secondaryWidthPercent = 30; // Adjust width to fit 3 boxes in the row
              const secondaryHeightPercent = rowHeightPercent; // Height for the row

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: 20,
              };
            } else if (
              secondaryIndex === 3 ||
              secondaryIndex === 4 ||
              secondaryIndex === 5
            ) {
              // Second row of secondary boxes
              const secondaryTopPercent = 34.33 + rowHeightPercent; // Starting just below the first row
              const secondaryLeftPercent = 2 + (secondaryIndex - 3) * 32; // Positioning the boxes side by side
              const secondaryWidthPercent = 30; // Adjust width to fit 3 boxes in the row
              const secondaryHeightPercent = rowHeightPercent; // Height for the row

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: 20,
              };
            } else {
              // Third row of secondary boxes
              const secondaryTopPercent = 34.33 + 2 * rowHeightPercent; // Starting just below the second row
              const secondaryLeftPercent = 2 + (secondaryIndex - 6) * 48; // Positioning the boxes side by side
              const secondaryWidthPercent = 46; // Adjust width to fit 2 boxes in the row
              const secondaryHeightPercent = rowHeightPercent; // Height for the row

              return {
                topPercent: 78.5,
                leftPercent: secondaryIndex === 8 ? 51 : secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: 20,
              };
            }
          }
        }
      } else {
        if (videoList.length === 1) {
          const topPercent = 0; // 2% padding from top
          const leftPercent = 0; // 2% padding from left
          const widthPercent = 100; // Subtracting padding
          const heightPercent = 100; // Subtracting padding

          return { topPercent, leftPercent, widthPercent, heightPercent };
        } else if (videoList.length === 2) {
          const mainVideoIndex = videoList.findIndex((video) => video.main);
          const secondaryVideoIndex = videoList.findIndex(
            (video) => !video.main
          );

          if (index === mainVideoIndex) {
            // Main video dimensions and position
            const topPercent = 12;
            const leftPercent = 2;
            const widthPercent = 74;
            const heightPercent = 74;

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === secondaryVideoIndex) {
            // Secondary video dimensions and position
            const topPercent = 32; // Adjust as needed for spacing
            const leftPercent = 77; // Adjust as needed for spacing
            const widthPercent = 20; // 20% width
            const heightPercent = 40; // 40% height

            return { topPercent, leftPercent, widthPercent, heightPercent };
          }
        } else if (videoList.length === 3) {
          const mainVideoIndex = videoList.findIndex((video) => video.main);
          const secondaryVideoIndices = videoList
            .map((video, idx) => ({ video, idx }))
            .filter(({ video }) => !video.main)
            .map(({ idx }) => idx);

          if (index === mainVideoIndex) {
            // Main video dimensions and position
            const topPercent = 12;
            const leftPercent = 2;
            const widthPercent = 74;
            const heightPercent = 74;

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === secondaryVideoIndices[0]) {
            // First secondary video dimensions and position
            const topPercent = 7; // Adjust as needed for spacing
            const leftPercent = 77; // Adjust as needed for spacing
            const widthPercent = 20; // 20% width
            const heightPercent = 40; // 40% height

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (index === secondaryVideoIndices[1]) {
            // Second secondary video dimensions and position
            const topPercent = 52; // Adjust as needed for spacing
            const leftPercent = 77; // Adjust as needed for spacing
            const widthPercent = 20; // 20% width
            const heightPercent = 40; // 40% height

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else {
            return {
              topPercent: 1,
              leftPercent: 1,
              widthPercent: 100,
              heightPercent: 100,
            };
          }
        } else if (videoList.length === 4) {
          const mainVideoIndex = videoList.findIndex((video) => video.main);
          const secondaryVideoIndices = videoList
            .map((video, idx) => ({ video, idx }))
            .filter(({ video }) => !video.main)
            .map(({ idx }) => idx);

          if (index === mainVideoIndex) {
            // Main video dimensions and position
            const topPercent = 12;
            const leftPercent = 2;
            const widthPercent = 74;
            const heightPercent = 74;

            return { topPercent, leftPercent, widthPercent, heightPercent };
          } else if (secondaryVideoIndices.includes(index)) {
            // Secondary video dimensions and position
            const totalSecondaryVideos = secondaryVideoIndices.length;
            const secondaryIndex = secondaryVideoIndices.indexOf(index);
            const topPercent =
              7 +
              (92.5 / (totalSecondaryVideos + 1)) *
                (secondaryIndex +
                  (secondaryIndex === 0
                    ? 0
                    : secondaryIndex / secondaryVideoIndices.length));
            const leftPercent = 77;
            const widthPercent = 20;
            const heightPercent =
              100 / (totalSecondaryVideos + 1) + secondaryVideoIndices.length;

            return { topPercent, leftPercent, widthPercent, heightPercent };
          }
        } else if (videoList.length === 5) {
          const mainVideoIndex = videoList.findIndex((video) => video.main);

          if (index === mainVideoIndex) {
            // Main video dimensions and position
            const mainTopPercent = 12;
            const mainLeftPercent = 2;
            const mainWidthPercent = 74;
            const mainHeightPercent = 74;

            return {
              topPercent: mainTopPercent,
              leftPercent: mainLeftPercent,
              widthPercent: mainWidthPercent,
              heightPercent: mainHeightPercent,
            };
          } else {
            // Secondary video dimensions and position
            const totalSecondaryVideos = videoList.length - 1;
            const secondaryIndex = index - (index > mainVideoIndex ? 1 : 0); // Adjust index if after main video
            const secondaryTopPercent =
              7 + (74 / totalSecondaryVideos) * secondaryIndex;
            const secondaryLeftPercent = 77;
            const secondaryWidthPercent = 20;
            const secondaryHeightPercent = 74 / totalSecondaryVideos;

            return {
              topPercent: secondaryTopPercent + secondaryIndex * 3,
              leftPercent: secondaryLeftPercent,
              widthPercent: secondaryWidthPercent,
              heightPercent: secondaryHeightPercent,
            };
          }
        } else if (videoList.length === 6) {
          const mainVideoIndex = videoList.findIndex((video) => video.main);

          if (index === mainVideoIndex) {
            // Main video dimensions and position
            const mainTopPercent = 12;
            const mainLeftPercent = 2;
            const mainWidthPercent = 74;
            const mainHeightPercent = 74;

            return {
              topPercent: mainTopPercent,
              leftPercent: mainLeftPercent,
              widthPercent: mainWidthPercent,
              heightPercent: mainHeightPercent,
            };
          } else {
            // Calculate position for secondary boxes
            const remainingVideos = videoList.filter(
              (_, i) => i !== mainVideoIndex
            );
            const secondaryIndex = remainingVideos.findIndex(
              (_, i) => i === index - (index > mainVideoIndex ? 1 : 0)
            );

            if (secondaryIndex === 0 || secondaryIndex === 1) {
              // First row of secondary boxes
              const secondaryTopPercent = 7;
              const secondaryLeftPercent = 77 + secondaryIndex * (10 + 3); // Adjust spacing between videos
              const secondaryWidthPercent = 10;
              const secondaryHeightPercent = 25;

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: secondaryHeightPercent,
              };
            } else if (secondaryIndex === 2 || secondaryIndex === 3) {
              // Second row of secondary boxes
              const secondaryTopPercent = 36;
              const secondaryLeftPercent = 77 + (secondaryIndex - 2) * (10 + 3); // Adjust spacing between videos
              const secondaryWidthPercent = 10;
              const secondaryHeightPercent = 25;

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: secondaryHeightPercent,
              };
            } else if (secondaryIndex === 4) {
              // Third row with one full-width box
              const secondaryTopPercent = 65;
              const secondaryLeftPercent = 77;
              const secondaryWidthPercent = 21;
              const secondaryHeightPercent = 25;

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: secondaryHeightPercent,
              };
            }
          }
        } else if (videoList.length === 7) {
          const mainVideoIndex = videoList.findIndex((video) => video.main);

          if (index === mainVideoIndex) {
            // Main video dimensions and position
            const mainTopPercent = 12;
            const mainLeftPercent = 2;
            const mainWidthPercent = 74;
            const mainHeightPercent = 74;

            return {
              topPercent: mainTopPercent,
              leftPercent: mainLeftPercent,
              widthPercent: mainWidthPercent,
              heightPercent: mainHeightPercent,
            };
            // } else {
            //   // Calculate position for secondary boxes
            //   const totalSecondaryVideos = videoList.length - 1;
            //   const remainingWidthPercent = 100 - (2 + 74); // Remaining width after main box
            //   const boxWidthPercent = remainingWidthPercent / 2; // Width for each box in the remaining space
            //   const secondaryIndex = index - (index > mainVideoIndex ? 1 : 0); // Adjust index if after main video

            //   if (secondaryIndex === 1 || secondaryIndex === 2) {
            //     // First row of secondary boxes
            //     const secondaryTopPercent = 7;
            //     const secondaryLeftPercent =
            //       77.5 + (secondaryIndex - 1) * (boxWidthPercent + 3);
            //     const secondaryWidthPercent = 10;
            //     const secondaryHeightPercent = 25; // Adjust as needed

            //     return {
            //       topPercent: secondaryTopPercent,
            //       leftPercent: secondaryIndex === 2 ? 89 : secondaryLeftPercent,
            //       widthPercent: secondaryWidthPercent,
            //       heightPercent: secondaryHeightPercent,
            //     };
            //   } else if (secondaryIndex === 3 || secondaryIndex === 4) {
            //     // Second row of secondary boxes
            //     const secondaryTopPercent = 36;
            //     const secondaryLeftPercent =
            //       77.5 + (secondaryIndex - 3) * (boxWidthPercent + 3);
            //     const secondaryWidthPercent = 10;
            //     const secondaryHeightPercent = 25; // Adjust as needed

            //     return {
            //       topPercent: secondaryTopPercent,
            //       leftPercent: secondaryIndex === 4 ? 89 : secondaryLeftPercent,
            //       widthPercent: secondaryWidthPercent,
            //       heightPercent: secondaryHeightPercent,
            //     };
            //   } else {
            //     // Third row with one full-width box
            //     // Second row of secondary boxes
            //     // const secondaryTopPercent = 65;
            //     // const secondaryLeftPercent =
            //     //   77.5 + (secondaryIndex - 3) * (boxWidthPercent + 3);
            //     // const secondaryWidthPercent = 10;
            //     // const secondaryHeightPercent = 25; // Adjust as needed

            //     // return {
            //     //   topPercent: secondaryTopPercent,
            //     //   leftPercent: secondaryIndex === 6 ? 89 : secondaryLeftPercent,
            //     //   widthPercent: secondaryWidthPercent,
            //     //   heightPercent: secondaryHeightPercent,
            //     // };

            //     // Fourth row with one full-width box
            //     const secondaryTopPercent = 84;
            //     const secondaryLeftPercent = 77.5;
            //     const secondaryWidthPercent = 21;
            //     const secondaryHeightPercent = 20; // Adjust as needed

            //     return {
            //       topPercent: secondaryTopPercent,
            //       leftPercent: secondaryLeftPercent,
            //       widthPercent: secondaryWidthPercent,
            //       heightPercent: secondaryHeightPercent,
            //     };
            //   }
            // }
          } else {
            // Calculate position for secondary boxes
            const totalSecondaryVideos = videoList.length - 1;
            const remainingWidthPercent = 100 - (2 + 74); // Remaining width after main box
            const boxWidthPercent = remainingWidthPercent / 2; // Width for each box in the remaining space
            const secondaryIndex = index - (index > mainVideoIndex ? 1 : 0); // Adjust index if after main video

            if (secondaryIndex === 0 || secondaryIndex === 1) {
              // First row of secondary boxes
              const secondaryTopPercent = 12;
              const secondaryLeftPercent =
                77 + secondaryIndex * (boxWidthPercent + 3);
              const secondaryWidthPercent = 10;
              const secondaryHeightPercent = 22; // Adjust as needed

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryIndex === 1 ? 88 : secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: secondaryHeightPercent,
              };
            } else if (secondaryIndex === 2 || secondaryIndex === 3) {
              // Second row of secondary boxes
              const secondaryTopPercent = 38;
              const secondaryLeftPercent =
                77 + (secondaryIndex - 2) * (boxWidthPercent + 3);
              const secondaryWidthPercent = 10;
              const secondaryHeightPercent = 22; // Adjust as needed

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryIndex === 3 ? 88 : secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: secondaryHeightPercent,
              };
            } else if (secondaryIndex === 4 || secondaryIndex === 5) {
              // Third row with one full-width box
              const secondaryTopPercent = 64;
              const secondaryLeftPercent =
                77 + (secondaryIndex - 4) * (boxWidthPercent + 3);
              const secondaryWidthPercent = 10;
              const secondaryHeightPercent = 22; // Adjust as needed

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryIndex === 5 ? 88 : secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: secondaryHeightPercent,
              };
            }
          }
        } else if (videoList.length === 8) {
          const mainVideoIndex = videoList.findIndex((video) => video.main);

          if (index === mainVideoIndex) {
            // Main video dimensions and position
            const mainTopPercent = 12;
            const mainLeftPercent = 2;
            const mainWidthPercent = 74;
            const mainHeightPercent = 74;

            return {
              topPercent: mainTopPercent,
              leftPercent: mainLeftPercent,
              widthPercent: mainWidthPercent,
              heightPercent: mainHeightPercent,
            };
          } else {
            // Calculate position for secondary boxes
            const totalSecondaryVideos = videoList.length - 1;
            const remainingWidthPercent = 100 - (2 + 74); // Remaining width after main box
            const boxWidthPercent = remainingWidthPercent / 2; // Width for each box in the remaining space
            const secondaryIndex = index - (index > mainVideoIndex ? 1 : 0); // Adjust index if after main video

            if (secondaryIndex === 0 || secondaryIndex === 1) {
              // First row of secondary boxes
              const secondaryTopPercent = 7;
              const secondaryLeftPercent =
                77 + secondaryIndex * (boxWidthPercent + 3);
              const secondaryWidthPercent = 10;
              const secondaryHeightPercent = 20; // Adjust as needed

              return {
                topPercent: secondaryTopPercent,
                leftPercent: secondaryIndex === 1 ? 88 : secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: secondaryHeightPercent,
              };
            } else if (secondaryIndex === 2 || secondaryIndex === 3) {
              // Second row of secondary boxes
              const secondaryTopPercent = 31;
              const secondaryLeftPercent =
                77 + (secondaryIndex - 2) * (boxWidthPercent + 3);
              const secondaryWidthPercent = 10;
              const secondaryHeightPercent = 20; // Adjust as needed

              return {
                topPercent: secondaryTopPercent - 2,
                leftPercent: secondaryIndex === 3 ? 88 : secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: secondaryHeightPercent,
              };
            } else if (secondaryIndex === 4 || secondaryIndex === 5) {
              // Third row of secondary boxes
              const secondaryTopPercent = 50;
              const secondaryLeftPercent =
                77 + (secondaryIndex - 4) * (boxWidthPercent + 3);
              const secondaryWidthPercent = 10;
              const secondaryHeightPercent = 20; // Adjust as needed

              return {
                topPercent: secondaryTopPercent + 1,
                leftPercent: secondaryIndex === 5 ? 88 : secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: secondaryHeightPercent,
              };
            } else {
              // Fourth row with one full-width box
              const secondaryTopPercent = 69;
              const secondaryLeftPercent = 77.5;
              const secondaryWidthPercent = 21;
              const secondaryHeightPercent = 20; // Adjust as needed

              return {
                topPercent: secondaryTopPercent + 4,
                leftPercent: secondaryLeftPercent,
                widthPercent: secondaryWidthPercent,
                heightPercent: secondaryHeightPercent,
              };
            }
          }
        } else if (videoList.length === 9) {
          const mainVideoIndex = videoList.findIndex((video) => video.main);

          if (index === mainVideoIndex) {
            // Main video dimensions and position
            const mainTopPercent = 12;
            const mainLeftPercent = 2;
            const mainWidthPercent = 74;
            const mainHeightPercent = 74;

            return {
              topPercent: mainTopPercent,
              leftPercent: mainLeftPercent,
              widthPercent: mainWidthPercent,
              heightPercent: mainHeightPercent,
            };
          } else {
            // Calculate position for secondary boxes
            const remainingWidthPercent = 100 - (2 + 74); // Remaining width after main box
            const boxWidthPercent = remainingWidthPercent / 2; // Width for each box in the remaining space
            const secondaryIndex = index - (index > mainVideoIndex ? 1 : 0); // Adjust index if after main video

            // Calculate row and column for secondary box
            const row = Math.floor(secondaryIndex / 2);
            const column = secondaryIndex % 2;

            // Calculate top and left percentage based on row and column
            const secondaryTopPercent = 7 + row * 20; // Adjust as needed
            const secondaryLeftPercent = 77 + column * (boxWidthPercent + 3); // Adjust as needed
            const secondaryWidthPercent = 10;
            const secondaryHeightPercent = 20; // Adjust as needed

            return {
              topPercent: secondaryTopPercent + row * 2,
              leftPercent: column === 1 ? 88 : secondaryLeftPercent,
              widthPercent: secondaryWidthPercent,
              heightPercent: secondaryHeightPercent,
            };
          }
        } else {
          return {
            topPercent: 1,
            leftPercent: 1,
            widthPercent: 100,
            heightPercent: 100,
          };
        }
      }
    } else {
      return {
        topPercent: 1,
        leftPercent: 1,
        widthPercent: 100,
        heightPercent: 100,
      };
    }
  };

  return (
    <div
      className="wrapper-camera"
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      {videoList.map((ele, index) => {
        const {
          topPercent,
          leftPercent,
          widthPercent,
          heightPercent,
          rightPercent,
        } = getTopLeftPercentages(index, columns, rows);
        const CamStyle =
          layout === "Grid" || layout === "mainSup"
            ? {
                position: "absolute",
                top: `${topPercent}%`,
                left: `${leftPercent}%`,
                width: `${widthPercent}%`,
                height: `${heightPercent}%`,
                transition: "all 0.3s ease 0s",
                borderRadius:
                  layout === "Grid" || layout === "mainSup"
                    ? videoList.length <= 1
                      ? screenMode === "portrait" || status === "Viewer"
                        ? "10px"
                        : "0"
                      : "10px"
                    : "0",
                overflow: "hidden",
              }
            : layout === "Fill"
            ? rightPercent
              ? {
                  position: "absolute",
                  top: `${topPercent}%`,
                  right: `${rightPercent}%`,
                  width: `${widthPercent}%`,
                  height: `${heightPercent}%`,
                  transition: "all 0.25s ease 0s",
                  overflow: "hidden",
                }
              : {
                  position: "absolute",
                  top: `${topPercent}%`,
                  left: `${leftPercent}%`,
                  width: `${widthPercent}%`,
                  height: `${heightPercent}%`,
                  transition: "all 0.25s ease 0s",
                  overflow: "hidden",
                }
            : layout === "Single"
            ? {
                width: `${100}%`,
                height: `${100}%`,
                position: "relative",
                overflow: "hidden",
              }
            : {
                width: `${100}%`,
                height: `${100}%`,
                position: "relative",
                overflow: "hidden",
                transition: "all 0.3s ease 0s",
              };

        return (
          <SortableVideoItem
            key={`item-${index}`}
            index={index}
            ele={ele}
            moveVideo={moveVideo} // Pass moveVideo function
            style={CamStyle}
          >
            <CustomDragPreview width={widthPercent} height={heightPercent} />
            {ele.activeCam ? (
              ele.type === "img" || ele.type === "ImgList" ? (
                <img
                  src={ele.src ? ele.src : vi1}
                  alt="..."
                  style={{
                    background: "black",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <video
                  src={ele.src}
                  autoPlay
                  loop={ele.loop}
                  ref={videoRef}
                  muted={!ele.activeMic ? !ele.activeMic : isMuted}
                  playsInline
                  style={{
                    background: "black",
                    objectFit: "cover",
                  }}
                />
              )
            ) : (
              <div className="box-avatar">
                <div className="wraper-avatar">
                  <svg
                    focusable={false}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="#4F5461"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
                  </svg>
                </div>
              </div>
            )}
            {!title.title && !ticker && (
              <TitleInsideBox
                title={ShowNameUser ? { title: ele.name } : ""}
                theme={theme}
                ticker={ticker}
                color={color}
                fontColor={fontColor}
                sacle={generateSacleChild(heightPercent, widthPercent)}
                screenMode={screenMode}
                widthBox={childRef.current?.clientWidth}
                userName
              />
            )}
          </SortableVideoItem>
        );
      })}
    </div>
  );
};
