import React, { useState } from "react";
import "./AddimgModal.scss";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Switch from "react-switch";

import mainsub from "../../assits/mainsub.svg";
import single from "../../assits/single.svg";
import grid from "../../assits/grid.svg";
import { useDispatch } from "react-redux";
import { getLayout } from "../../store/theme";

function SeetingAddImage({
  Close,
  setHandleData,
  setIsLoop,
  isLoop,
  saveData,
}) {
  const [selectedValue, setSelectedValue] = useState("mainSup");
  let dispatch = useDispatch();

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSave = () => {
    console.log("Selected Value:", selectedValue);
    dispatch(getLayout(selectedValue));
    setHandleData({ true: true });
    Close();
  };

  console.log("=========saveData", saveData);
  return (
    <div className="modal-Box">
      <div className="box-icon" onClick={() => Close()}>
        <h2>Display Settings</h2>
        <i className="fa-solid fa-xmark" />
      </div>
      <div
        className="form-wrap"
        style={{
          width: "100%",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="wraper-iconxx">
          <FormControl>
            <RadioGroup
              row
              value={selectedValue}
              onChange={handleChange}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
                marginTop: "8px",
                ".MuiFormControlLabel-root": { width: "100%" },
              }}
            >
              {saveData?.type === "video" && (
                <div
                  className="video-box"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "8px",
                    marginBottom: "13px",
                    marginTop: "10px",
                  }}
                >
                  <i
                    class="fa-solid fa-repeat"
                    style={{ fontSize: "1.5rem", marginLeft: "-2px" }}
                  ></i>
                  <span
                    className="label"
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "500",
                      marginRight: "23px",
                    }}
                  >
                    Play in loop
                  </span>
                  <Switch
                    onColor="#4285C5"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={18}
                    width={40}
                    handleDiameter={15}
                    checked={isLoop}
                    onChange={(e) => setIsLoop(e)}
                  />
                </div>
              )}

              <FormControlLabel
                sx={{
                  ".MuiTypography-root": { fontSize: "1.5rem", width: "100%" },
                  ".MuiFormControlLabel-root": { width: "100%" },
                }}
                value="mainSup" // Value for audio & video selection
                control={<Radio />}
                label={
                  <div
                    className="box-title d-flex justify-content-center align-items-center gap-4"
                    style={{ width: "100%" }}
                  >
                    <span
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "500",
                        flex: "1",
                      }}
                    >
                      Main Screen
                    </span>
                    <div>
                      <img
                        width={"50px"}
                        height={"50px"}
                        style={{ flex: "1" }}
                        src={mainsub}
                        alt="..."
                      />
                    </div>
                  </div>
                }
              />
              <FormControlLabel
                sx={{
                  ".MuiTypography-root": { fontSize: "1.5rem", width: "100%" },
                  ".MuiFormControlLabel-root": { width: "100%" },
                }}
                style={{ width: "100%" }}
                value="Single" // Value for video only selection
                control={<Radio color="primary" />}
                label={
                  <div
                    style={{ width: "100%" }}
                    className="box-title d-flex justify-content-center align-items-center gap-4"
                  >
                    <span
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "500",
                        flex: "1",
                      }}
                    >
                      Full Screen
                    </span>
                    <div>
                      <img
                        width={"50px"}
                        height={"50px"}
                        style={{ flex: "1" }}
                        src={single}
                        alt="..."
                      />
                    </div>
                  </div>
                }
              />
              <FormControlLabel
                sx={{
                  label: { width: "100%" },
                  ".MuiTypography-root": { fontSize: "1.5rem", width: "100%" },
                  ".MuiFormControlLabel-root": { width: "100%" },
                }}
                value="Grid"
                control={<Radio />}
                label={
                  <div
                    style={{ width: "100%" }}
                    className="box-title d-flex justify-content-center align-items-center gap-4"
                  >
                    <span
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "500",
                        flex: "1",
                      }}
                    >
                      Guest
                    </span>
                    <div>
                      <img
                        style={{ flex: "1" }}
                        width={"50px"}
                        height={"50px"}
                        src={grid}
                        alt="..."
                      />
                    </div>
                  </div>
                }
              />
            </RadioGroup>
          </FormControl>
        </div>
        <button
          className="sucsess"
          style={{ marginTop: "10px", marginBottom: "10px" }}
          onClick={() => {
            handleSave();
          }}
        >
          <span
            style={{
              fontSize: "1.6rem",
              background: "#1461e1",
              color: "#fff",
              padding: "6px 19px",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            Show
          </span>
        </button>
      </div>
    </div>
  );
}

export default SeetingAddImage;
