import React, { Fragment, useEffect, useRef, useState } from "react";
import "./UploadVideo.scss";

import {
  BoxTooltipTitle,
  TooltipBoxAction,
  TooltipMobileAction,
} from "../ToolTipsFolder/ToolTips";
import { useDispatch, useSelector } from "react-redux";
import { CloseSide } from "../../store/theme";
import {
  ManageAddVideo,
  ManageDeleteVideo,
  ManageReorderVideo,
} from "../../store/videosSlice.js";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { randomPassword } from "../../RandomID";
import VideoSvg from "../Svgs/VideoSvg";
import { addAllAction, deleteAllAction } from "../../store/allActionsSlice.js";
import { manageUsers } from "../../store/usersSlice.js";
import ToolSvg from "../Svgs/ToolTipSvg.jsx";
import { Dialog } from "@mui/material";
import SeetingAddImage from "../GraphicsTap/SeetingAddImage.jsx";
function UploadVideos() {
  let { Users } = useSelector((state) => state.users);
  let { videos } = useSelector((state) => state.persistData.videos);
  let [openPop, setOpenPop] = useState(false);
  let [handleData, setHandleData] = useState("");
  let [saveData, setSaveData] = useState("");
  let [isLoop, setIsLoop] = useState("");
  console.log("isLoopisLoop", isLoop);
  let [File, setFile] = useState("");
  let dispatch = useDispatch();

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "video/mp4") {
      setFile(event.target.files[0]);

      const reader = new FileReader();
      reader.onload = (e) => {
        let value = {
          id: `${randomPassword(2)}${videos.length + 1}}`,
          name: file.name,
          duration: "0:30", // You can update this with the actual video length
          src: e.target.result,
          type: "video",
        };
        dispatch(ManageAddVideo(value));
        dispatch(addAllAction(value));
      };
      reader.readAsDataURL(file);
      setFile("");
    }
  };

  let [showCreate, setShowCreate] = useState(true);

  let onDragStart = (e) => {
    setShowCreate(false);
  };

  const onDragEnd = (result) => {
    setShowCreate(true);
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    if (source.index === destination.index) {
      return; // Item dropped in the same position
    }
    const updatedList = reorder(videos, source.index, destination.index);
    dispatch(ManageReorderVideo(updatedList));
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  useEffect(() => {
    if (handleData) {
      const addVideoToStage = (loop, video) => {
        const videoIndex = Users.findIndex((ele) => ele.id === video.id);
        if (videoIndex !== -1) {
          // Video found, toggle inStage property
          let updateValue = Users.filter((ele) => ele.id !== video.id);

          // Get the first item that has inStage: true
          const firstInStageIndex = updateValue.findIndex(
            (ele) => ele.inStage === true
          );

          if (firstInStageIndex !== -1) {
            // Create a new array with the updated main property for the first inStage item
            updateValue = updateValue.map((user, index) =>
              index === firstInStageIndex ? { ...user, main: true } : user
            );
          }

          // Dispatch the updated Users array
          dispatch(manageUsers(updateValue));
        } else {
          // Video not found, add a new video object
          // let newData = Users.filter((e) => e.type !== "videoList");
          // const updatedData = newData.map((item) => {
          //   return { ...item, main: false }; // Toggle inStage
          // });
          let newData = Users.filter((e) => e.type !== "videoList");
          const updatedData = newData.map((item) => ({ ...item, main: false })); // Set main to false for all items

          dispatch(
            manageUsers([
              {
                id: video.id,
                name: video.name,
                type: "videoList",
                main: true,
                src: video.src,
                loop: isLoop,
                inStage: true,
                activeCam: true,
                activeMic: true,
                isAssits: true,
              },
              ...updatedData,
            ])
          );
        }
      };
      setIsLoop("");
      addVideoToStage("", saveData);
    }
  }, [handleData]);

  const addVideoToStage = (loop, video) => {
    const videoIndex = Users.findIndex((ele) => ele.id === video.id);
    if (videoIndex !== -1) {
      // Video found, toggle inStage property
      let updateValue = Users.filter((ele) => ele.id !== video.id);

      // Get the first item that has inStage: true
      const firstInStageIndex = updateValue.findIndex(
        (ele) => ele.inStage === true
      );

      if (firstInStageIndex !== -1) {
        // Create a new array with the updated main property for the first inStage item
        updateValue = updateValue.map((user, index) =>
          index === firstInStageIndex ? { ...user, main: true } : user
        );
      }

      // Dispatch the updated Users array
      dispatch(manageUsers(updateValue));
    } else {
      // Video not found, add a new video object
      let newData = Users.filter((e) => e.type !== "videoList");
      const updatedData = newData.map((item) => ({ ...item, main: false })); // Set main to false for all items

      dispatch(
        manageUsers([
          ...updatedData,
          {
            id: video.id,
            title: video.name,
            type: "videoList",
            main: true,
            src: video.src,
            loop: isLoop,
            inStage: true,
            activeCam: true,
            activeMic: true,
            isAssits: true,
          },
        ])
      );
    }
  };

  let DeleteFn = (ee) => {
    const videoIndex = Users.findIndex((ele) => ele.id === ee);
    if (videoIndex !== -1) {
      // Video found, toggle inStage property
      let updateValue = Users.filter((ele) => ele.id !== ee);
      dispatch(manageUsers(updateValue));
    }
    dispatch(ManageDeleteVideo(ee));
    dispatch(deleteAllAction(ee));
  };

  let checkActive = (id) => {
    const videoIndex = Users.findIndex((ele) => ele.id === id);
    return videoIndex !== -1 ? true : false;
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Initial width
  const handleResize = () => {
    setTimeout(() => {
      setWindowWidth(window.innerWidth);
    }, 100); // 100ms delay, you can adjust this as needed
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <div className="wrapper-side all-action">
        <div className="Details p-2">
          <div className="header">
            <text className="text-acco">Videos</text>
            {windowWidth ? (
              <TooltipMobileAction
                backgroundColor={"rgba(27, 31, 41)"}
                title={
                  <div className="toltip-title-line">
                    <span>Upload short video clips</span> <br />{" "}
                    <span>File type: mp4</span> <br />{" "}
                    <span>Recommended dimensions: 1280 x 720 pixels</span>{" "}
                    <br />{" "}
                    <span>Recommended duration: from 10 secs to 15 mins</span>{" "}
                    <br />
                  </div>
                }
                placement="top"
              >
                <ToolSvg />
              </TooltipMobileAction>
            ) : (
              <BoxTooltipTitle
                bigWidth={true}
                backgroundColor={"rgba(27, 31, 41)"}
                title={
                  <div className="toltip-title-line">
                    <span>Upload short video clips</span> <br />{" "}
                    <span>File type: mp4</span> <br />{" "}
                    <span>Recommended dimensions: 1280 x 720 pixels</span>{" "}
                    <br />{" "}
                    <span>Recommended duration: from 10 secs to 15 mins</span>{" "}
                    <br />
                  </div>
                }
                placement="top"
              >
                <ToolSvg />
              </BoxTooltipTitle>
            )}
            <div className="close" onClick={() => dispatch(CloseSide(false))}>
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
          {/**
                  <div className="loop d-flex">
            <input
              id="loop"
              type="checkbox"
              className="check-form"
              value={loop}
              onChange={(e) => {
                setLoop(!loop);
                dispatch(ManageCover({ ...cover, ["loop"]: !loop }));
              }}
            />
            <label htmlFor="loop">Loop</label>
            <BoxTooltipTitle
              backgroundColor={"rgba(27, 31, 41)"}
              title={"Enable loop to repeat video clips"}
              placement="top"
            >
              <ToolSvg />
            </BoxTooltipTitle>
          </div>
    */}

          <Droppable droppableId="draggable">
            {(provided, snapshot) => (
              <ul
                className="list-unstyled mb-2"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {videos &&
                  videos.map((video, index) => (
                    <Draggable
                      key={video.id}
                      draggableId={video.id.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={index}
                          className={`notAdded draggable-item ${
                            snapshot.isDragging ? "dragging" : ""
                          } ${checkActive(video.id) && "active"}`}
                        >
                          <Fragment>
                            <div className="box-title">
                              <div className="box-icons video-active">
                                <VideoSvg
                                  miancolor={
                                    checkActive(video.id)
                                      ? "#ffffff"
                                      : "rgb(79, 84, 97)"
                                  }
                                  backgroundColor={
                                    checkActive(video.id)
                                      ? "rgb(10, 76, 199)"
                                      : "rgb(79, 84, 97)"
                                  }
                                />
                              </div>
                              <div className="title">
                                <div
                                  className="element"
                                  style={{ width: " 82px", minWidth: "82px" }}
                                >
                                  <video src={video.src} alt=".." />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignContent: "flex-start",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "1.3rem",
                                      WebkitLineClamp: "1",
                                    }}
                                  >
                                    {video.name}
                                  </span>
                                  <span>{video.duration || "0:30"}</span>
                                </div>
                              </div>
                            </div>

                            <div
                              className="box-action"
                              onClick={() => {
                                !checkActive(video.id)
                                  ? console.log("")
                                  : addVideoToStage("", video);
                                setSaveData(video);
                                setOpenPop(
                                  !checkActive(video.id) ? true : false
                                );
                              }}
                            >
                              <div className="Title">
                                {!checkActive(video.id) ? (
                                  <div className="Title">
                                    <i className="fa-solid fa-circle-plus" />
                                    <text>Show</text>
                                  </div>
                                ) : (
                                  <div className="Title">
                                    <i className="fa-solid fa-circle-plus" />
                                    <text>Hide</text>
                                  </div>
                                )}
                              </div>
                              <div className="icon-edit">
                                <BoxTooltipTitle placement="top" title="Edit">
                                  <div
                                    className="edit"
                                    style={{
                                      margin: "0",
                                      marginRight: "-5px",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }}
                                  >
                                    <i
                                      className="fa-solid fa-pen"
                                      style={{
                                        color: checkActive(video.id) && "#fff",
                                      }}
                                    />
                                  </div>
                                </BoxTooltipTitle>
                                <BoxTooltipTitle placement="top" title="Delete">
                                  <div
                                    className="edit"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      DeleteFn(video.id);
                                    }}
                                  >
                                    <i
                                      className="fa-solid fa-trash"
                                      style={{
                                        color: checkActive(video.id) && "#fff",
                                      }}
                                    />
                                  </div>
                                </BoxTooltipTitle>
                              </div>
                            </div>
                          </Fragment>
                        </li>
                      )}
                    </Draggable>
                  ))}
                {showCreate && (
                  <div className="box-add-video">
                    <div className="wrapper">
                      <label htmlFor="upload">
                        <input
                          type="file"
                          id="upload"
                          value={File}
                          className="d-none"
                          onChange={handleVideoUpload}
                        />
                        <div className="box-plus">
                          <span>+</span>
                        </div>
                        <div className="title">
                          <span>Add Video</span>
                        </div>
                      </label>
                    </div>
                  </div>
                )}
              </ul>
            )}
          </Droppable>
        </div>
      </div>
      <Dialog maxWidth="xs" open={openPop} onClose={() => setOpenPop(false)}>
        <SeetingAddImage
          Close={() => setOpenPop(false)}
          setHandleData={setHandleData}
          setIsLoop={setIsLoop}
          isLoop={isLoop}
          saveData={{ type: "video" }}
        />
      </Dialog>
    </DragDropContext>
  );
}

export default UploadVideos;
