import React, { Fragment, useState, useEffect, useRef } from "react";
import "./BoxVideo.scss";
import vi1 from "../../assits/presenter.jpg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ChangeScrennMode } from "../../store/theme";
import TitleInsideBox from "./TitleInsideBox";
import BoxQuestion from "../QuestionTap/BoxQuestion";
import ViwerMobileCover from "../ViewerMobileScreen/ViwerMobileCover";
import BoxCreateQuestion from "../QuestionTap/BoxCreateQuestion";
import BoxPall from "../PollsTap/BoxPall";
import BoxCreatePolls from "../PollsTap/BoxCreatePolls";
import BoxAutoMute from "./BoxAutoMute";
import SendMessageBox from "../SendMessageBox/SendMessageBox";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import zIndex from "@mui/material/styles/zIndex";
import { renderVideos } from "./getTopLeftPercentages";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { manageUsers } from "../../store/usersSlice";
import { DndProvider, useDrag, useDragLayer, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { isMobile } from "react-device-detect";
import { TouchBackend } from "react-dnd-touch-backend";
import { usePreview } from "react-dnd-preview";

const SortableVideoContainer = SortableContainer(({ children }) => {
  return <div className="box-container2">{children}</div>;
});

// const SortableVideoItem = ({ ele, style, children }) => {
//   return (
//     <div className="video-container" style={style}>
//       {children}
//     </div>
//   );
// };

const ItemType = "VIDEO_ITEM";

const SortableVideoItem = ({ index, ele, moveVideo, children, style }) => {
  // Configure the drag logic
  const [{ isDragging }, ref, preview] = useDrag({
    type: ItemType,
    item: { index, src: ele.src, data: ele },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    options: {
      preview: true, // Ensure that the preview is enabled for touch devices
    },
  });

  // Configure the drop logic
  const [{ isOver }, drop] = useDrop({
    accept: ItemType,
    drop: (item) => {
      if (item.index !== index) {
        moveVideo(item.index, index, item);
        item.index = index; // Update the dragged item's index
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  // Combine refs for drag and drop
  const combinedRef = (node) => ref(drop(node));

  return (
    <div
      className={`video-container ${isDragging ? "dragging" : ""} ${
        isOver ? "drag-over" : ""
      }`} // Add class based on isOver
      ref={combinedRef} // Combine drag and drop refs
      style={{
        ...style,
        opacity: isDragging ? 0.5 : 1, // Visual feedback for drag effect
        cursor: isDragging ? "grabbing" : "grab", // Change the cursor when dragging
        border: isOver ? "2px solid blue" : "none", // Add border when dragging over
      }}
    >
      {children}
    </div>
  );
};
function BoxVideo() {
  const [sacle, setSacle] = useState(0); // Default width
  const [sacleChild, setSacleChild] = useState(0);
  const divRef = useRef(null);
  const childRef = useRef(null);
  const [videoDimensions, setVideoDimensions] = useState({});
  const screenWidth = window.innerWidth;
  const [screenInnerWidth, setScrecinnetWidth] = useState(window.innerWidth);
  const [checkState, setCheckState] = useState(true);
  const [ChckeStart, setChckeStart] = useState(false);
  const dispatch = useDispatch();
  let {
    theme,
    color,
    fontColor,
    sideBarStatus,
    status,
    cover,
    ShowNameUser,
    screenMode,
    layout,
    statusTapViewer,
    autoMuteStatus,
  } = useSelector((state) => state.themeslice);

  let { Users } = useSelector((state) => state.users);

  let { currentSelected, stateCreateQues } = useSelector(
    (state) => state.persistData.questions
  );

  let { currentSelectedPoll, stateCreatePoll } = useSelector(
    (state) => state.persistData.polls
  );

  let { title, ticker } = useSelector((state) => state.persistData.banners);
  let { currentBackground: background } = useSelector(
    (state) => state.backgrounds
  );

  let generateSacle = (currentHight, currentWidth) => {
    let numberSacel = 0.0784882557356767;
    let scale = (numberSacel * currentWidth) / 100;
    return scale;
  };
  let generateSacleChild = (currentHight, currentWidth) => {
    // let numberSacel = 0.984882557356767;
    let numberSacel =
      screenWidth <= 776
        ? screenMode === "portrait"
          ? 0.1994882557356767
          : 0.5594882557356767
        : screenMode === "portrait"
        ? 0.2894882557356767
        : 0.984882557356767;
    let scale = (numberSacel * currentWidth) / 100;
    if (screenMode === "portrait" || screenWidth <= 776) {
      scale =
        scale <= 0.1949608524522557
          ? screenWidth <= 576 &&
            screenMode === "portrait" &&
            status !== "Viewer"
            ? 0.1649608524522557
            : 0.2199608524522557
          : scale;
    } else {
      scale =
        scale <= 0.4051624183541654
          ? usersInStage.length >= 9
            ? screenWidth <= 1076
              ? 0.2951624183541654
              : 0.3551624183541654
            : 0.3551624183541654
          : scale;
    }
    return scale;
  };

  //useeffcet for handle portrait mode
  useEffect(() => {
    if (ChckeStart === true) {
      if (screenMode === "portrait") {
        let parentWidth = divRef.current.clientWidth;
        let parentHeight = divRef.current.clientHeight;
        let newWidth, newHeight;
        if (parentWidth && parentHeight) {
          if (parentWidth / parentHeight > 16 / 9) {
            newWidth = (parentHeight * 9) / 16;
            newHeight = parentHeight;
          } else {
            newWidth = (parentHeight * 9) / 16;
            newHeight = parentHeight;
          }
        }
        childRef.current.style.width = `${newWidth}px`;
        childRef.current.style.height = `${newHeight}px`;
        let scaleValue = generateSacle(newHeight, newWidth);
        setSacle(scaleValue);
      } else {
        let parentWidth = divRef.current.clientWidth;
        let parentHeight = divRef.current.clientHeight;
        let newWidth, newHeight;
        if (parentWidth && parentHeight) {
          if (parentWidth / parentHeight > 16 / 9) {
            newWidth = (parentHeight * 16) / 9;
            newHeight = parentHeight;
          } else {
            newWidth = parentWidth;
            newHeight = (parentWidth * 9) / 16;
          }
        }
        childRef.current.style.width = `${newWidth}px`;
        childRef.current.style.height = `${newHeight}px`;
        let scaleValue = generateSacle(newHeight, newWidth);
        setSacle(scaleValue);
      }
    }
  }, [screenMode]);

  const handleResize = () => {
    setTimeout(() => {
      setScrecinnetWidth(window.innerWidth);
      // console.log("window.innerWidthe", window.innerWidth);
    }, 200);
    if (divRef.current) {
      const screenWidth = window.innerWidth;
      if (screenMode === "portrait") {
        setTimeout(() => {
          let parentWidth = divRef.current.clientWidth;
          let parentHeight = divRef.current.clientHeight;
          let newWidth, newHeight;
          if (parentWidth && parentHeight) {
            if (parentWidth / parentHeight > 16 / 9) {
              newWidth = (parentHeight * 9) / 16;
              newHeight = parentHeight;
            } else {
              newWidth = (parentHeight * 9) / 16;
              newHeight = parentHeight;
            }
          }
          childRef.current.style.width = `${newWidth}px`;
          childRef.current.style.height = `${newHeight}px`;
          let scaleValue = generateSacle(newHeight, newWidth);
          setSacle(scaleValue);
        }, 300);
      } else if (screenWidth <= 576 && status === "Viewer") {
        setTimeout(() => {
          let parentWidth = divRef.current.clientWidth;
          let parentHeight = divRef.current.clientHeight;
          childRef.current.style.width = `${parentWidth}px`;
          childRef.current.style.height = `${parentHeight}px`;
          let scaleValue = generateSacle(parentHeight, parentWidth);
          setSacle(scaleValue);
        }, 300);
      } else if (childRef.current && screenMode !== "portrait") {
        setTimeout(() => {
          let parentWidth = divRef.current.clientWidth;
          let parentHeight = divRef.current.clientHeight;
          let newWidth, newHeight;
          if (parentWidth && parentHeight) {
            if (parentWidth / parentHeight > 16 / 9) {
              newWidth = (parentHeight * 16) / 9;
              newHeight = parentHeight;
            } else {
              newWidth = parentWidth;
              newHeight = (parentWidth * 9) / 16;
            }
          }
          childRef.current.style.width = `${newWidth}px`;
          childRef.current.style.height = `${newHeight}px`;
          let scaleValue = generateSacle(newHeight, newWidth);
          setSacle(scaleValue);
        }, 300);
      }
    }
  };

  //useEffcet for screen Resize

  useEffect(() => {
    if (ChckeStart === true) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [status, screenMode]);

  //use effect to mange box from start
  useEffect(() => {
    let parentWidth = divRef.current.clientWidth;
    let parentHeight = divRef.current.clientHeight;

    let newWidth, newHeight;
    if (parentWidth / parentHeight > 16 / 9) {
      newWidth = (parentHeight * 16) / 9;
      newHeight = parentHeight;
    } else {
      newWidth = parentWidth;
      newHeight = (parentWidth * 9) / 16;
    }

    // Apply dimensions
    if (childRef.current) {
      setTimeout(() => {
        setChckeStart(true);
        childRef.current.style.width = `${newWidth}px`;
        childRef.current.style.height = `${newHeight}px`;
        let scaleValue = generateSacle(newHeight, newWidth);
        setSacle(scaleValue);
      }, 500);
    }
  }, []);

  // const reorderUsersInStage = (users) => {
  //   const mainUserIndex = users.findIndex((user) => user.main === true);

  //   if (mainUserIndex > -1) {
  //     const mainUser = users.splice(mainUserIndex, 1)[0]; // Remove the main user from the array
  //     users.unshift(mainUser); // Add the main user to the beginning of the array
  //   }

  //   return users;
  // };

  let usersInStage = Users.filter((e) => e.inStage === true);
  // usersInStage = reorderUsersInStage(usersInStage);

  //useEffcet for Sidbar status
  useEffect(() => {
    const ResizeBySideBar = () => {
      const sidebarWidth = status === "Viewer" ? 470 : 330; // Width of the sidebar
      let parentWidth = divRef.current.clientWidth;
      if (status === "Viewer") {
        setCheckState(false);
      }
      // Adjust parent width if sidebar is open
      if (sideBarStatus) {
        parentWidth += sidebarWidth;
      } else {
        parentWidth -= sidebarWidth;
      }

      const parentHeight = divRef.current.clientHeight;

      // Calculate aspect ratio dimensions
      let newWidth, newHeight;
      if (parentWidth / parentHeight > 16 / 9) {
        newWidth = (parentHeight * 16) / 9;
        newHeight = parentHeight;
      } else {
        newWidth = parentWidth;
        newHeight = (parentWidth * 9) / 16;
      }

      // Apply dimensions
      setTimeout(
        () => {
          if (screenMode === "portrait") {
            setTimeout(() => {
              let parentWidth = divRef.current.clientWidth;
              let parentHeight = divRef.current.clientHeight;
              let newWidth, newHeight;
              if (parentWidth && parentHeight) {
                if (parentWidth / parentHeight > 16 / 9) {
                  newWidth = (parentHeight * 9) / 16;
                  newHeight = parentHeight;
                } else {
                  newWidth = (parentHeight * 9) / 16;
                  newHeight = parentHeight;
                }
              }
              childRef.current.style.width = `${newWidth}px`;
              childRef.current.style.height = `${newHeight}px`;
              let scaleValue = generateSacle(newHeight, newWidth);
              setSacle(scaleValue);
            }, 500);
          } else if (childRef.current) {
            childRef.current.style.width = `${newWidth}px`;
            childRef.current.style.height = `${newHeight}px`;
            let scaleValue = generateSacle(newHeight, newWidth);
            setSacle(scaleValue);
          }
        },
        checkState ? 100 : 100
      );
      // setTimeout(() => {
      //   const newHeightChild = childRef.current.clientHeight; // Adjust based on your needs
      //   const newWidthChild = childRef.current.clientWidth; // Adjust based on your need

      // }, 300);
    };
    ResizeBySideBar();
  }, [sideBarStatus, status]); // Only called when sideBarStatus changes

  //useEffect for Boxes camera
  useEffect(() => {
    const screenWidth = childRef.current?.clientWidth;
    const screenHeight = childRef.current?.clientHeight;
    // Calculate number of rows and columns based on video count
    let rows = 1;
    let columns = 1;
    const streamsCount = usersInStage.length;

    if (streamsCount === 1) {
      // Single layout

      columns = 1;
      rows = 1;
    } else if (layout === "Grid" && usersInStage.length > 1) {
      // Group layout (same as existing logic)
      if (
        (streamsCount > 2 && screenWidth > screenHeight) ||
        (streamsCount < 3 && screenWidth > screenHeight + 25)
      ) {
        // Landscape
        switch (streamsCount) {
          case 2:
            columns = 2;
            rows = 1;
            break;
          case 3:
            columns = 3;
            rows = 1;
            break;
          case 4:
            columns = 2;
            rows = 2;
            break;
          case 5:
            columns = 3;
            rows = 2;
            break;
          case 6:
            columns = 3;
            rows = 2;
            break;
          case 7:
            columns = 3;
            rows = 3;
            break;
          case 8:
            columns = 3;
            rows = 3;
            break;
          case 9:
            columns = 3;
            rows = 3;
            break;
          case 10:
            columns = 3;
            rows = 4;
            break;
          case 11:
            columns = 3;
            rows = 4;
            break;
          case 12:
            columns = 3;
            rows = 4;
            break;
          case 13:
            columns = 3;
            rows = 5;
            break;
          case 14:
            columns = 3;
            rows = 5;
            break;
          case 15:
            columns = 3;
            rows = 5;
            break;
          // ... (remaining cases for landscape layout)
          default:
            columns = 3;
            rows = 5;
        }
      } else {
        // Portrait
        switch (streamsCount) {
          case 2:
            columns = 1;
            rows = 3;
            break;
          case 3:
            columns = 2;
            rows = 3;
            break;
          case 4:
            columns = 2;
            rows = 3;
            break;
          case 5:
            columns = 3;
            rows = 3;
            break;
          case 6:
            columns = 3;
            rows = 3;
            break;
          case 7:
            columns = 3;
            rows = 3;
            break;
          case 8:
            columns = 3;
            rows = 3;
            break;
          case 9:
            columns = 3;
            rows = 3;
            break;
          case 10:
          case 11:
          case 12:
            columns = 3;
            rows = 4;
            break;
          default:
            columns = 3;
            rows = 5;
        }
      }
    } else if (layout === "Fill" && usersInStage.length > 1) {
      // Group layout (same as existing logic)
      const parentHeight = divRef.current.clientHeight;
      const parentWidth = divRef.current.clientWidth;

      if (
        (streamsCount > 2 && screenWidth > screenHeight) ||
        (streamsCount < 3 && screenWidth > screenHeight + 25)
      ) {
        // Landscape
        switch (streamsCount) {
          case 2:
            columns = 2;
            rows = 1;
            break;
          case 3:
            columns = 3;
            rows = 1;
            break;
          case 4:
            columns = 2;
            rows = 2;
            break;
          case 5:
            columns = 3;
            rows = 2;
            break;
          case 6:
            columns = 3;
            rows = 2;
            break;
          case 7:
            columns = 4;
            rows = 2;
            break;
          case 8:
            columns = 4;
            rows = 2;
            break;
          case 9:
            columns = 3;
            rows = 3;
            break;
          case 10:
            columns = 4;
            rows = 3;
            break;
          case 11:
            columns = 4;
            rows = 3;
            break;
          case 12:
            columns = 4;
            rows = 3;
            break;
          case 13:
            columns = 4;
            rows = 3;
            break;
          case 14:
            columns = 4;
            rows = 3;
            break;
          case 15:
            columns = 4;
            rows = 3;
            break;
          // ... (remaining cases for landscape layout)
          default:
            columns = 5;
            rows = 3;
        }
      } else {
        // Portrait
        switch (streamsCount) {
          case 2:
            columns = 1;
            rows = 2;
            break;
          case 3:
            columns = 2;
            rows = 2;
            break;
          case 4:
            columns = 2;
            rows = 2;
            break;
          case 5:
            columns = 3;
            rows = 3;
            break;
          case 6:
            columns = 3;
            rows = 3;
            break;
          case 7:
            columns = 3;
            rows = 3;
            break;
          case 8:
            columns = 3;
            rows = 3;
            break;
          case 9:
            columns = 3;
            rows = 3;
            break;
          case 10:
            columns = 3;
            rows = 4;
            break;
          case 11:
            columns = 3;
            rows = 4;
            break;
          case 12:
            columns = 3;
            rows = 4;
            break;
          case 13:
            columns = 3;
            rows = 5;
            break;
          case 14:
            columns = 3;
            rows = 5;
            break;
          case 15:
            columns = 3;
            rows = 5;
            break;
          // ... (remaining cases for portrait layout)
          default:
            columns = 3;
            rows = 8;
        }
      }
    } else {
      // Default layout (same as existing logic)
      // ... (your existing logic for calculating rows and columns)
    }

    const timeoutId = setTimeout(() => {
      setVideoDimensions({ rows, columns });
      dispatch(ChangeScrennMode(screenMode));
    }, 0);
    return () => clearTimeout(timeoutId);
  }, [
    layout,
    sideBarStatus,
    usersInStage.length,
    status,
    Users,
    childRef.current?.clientWidth,
    childRef.current?.clientHeight,
  ]);

  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    // Unmute the video after a short delay
    const unmuteTimeout = setTimeout(() => {
      setIsMuted(false);
      if (videoRef.current) {
        videoRef.current.muted = false;
      }
    }, 700);

    // Cleanup timeout on component unmount
    return () => clearTimeout(unmuteTimeout);
  }, []);

  const filteredUsersInStage =
    screenMode === "portrait" ||
    (status === "Viewer" && screenWidth <= 576) ||
    layout === "mainSup"
      ? usersInStage.slice(0, 9)
      : usersInStage.slice(0, 15);

  //layout = "Single" || "Fill" || "Grid" || "mainSup"

  // const moveVideo = (fromIndex, toIndex) => {
  //   if (fromIndex === toIndex) return;

  //   // Clone the current Users array
  //   const updatedUsers = Array.from(Users);

  //   // Find the user IDs at fromIndex and toIndex in usersInStage
  //   const fromUserId = usersInStage[fromIndex].id;
  //   const toUserId = usersInStage[toIndex].id;

  //   // Find the corresponding indexes in the main Users array by comparing ID
  //   const fromUserIndex = updatedUsers.findIndex(
  //     (user) => user.id === fromUserId
  //   );
  //   const toUserIndex = updatedUsers.findIndex((user) => user.id === toUserId);

  //   // Swap the items in the main Users array if both indexes are valid
  //   if (fromUserIndex !== -1 && toUserIndex !== -1) {
  //     [updatedUsers[fromUserIndex], updatedUsers[toUserIndex]] = [
  //       updatedUsers[toUserIndex],
  //       updatedUsers[fromUserIndex],
  //     ];

  //     // Dispatch only the updated list
  //     dispatch(manageUsers(updatedUsers));
  //   }
  // };

  const moveVideo = (fromIndex, toIndex, data) => {
    console.log("datadatadata1212", data);
    if (fromIndex === toIndex) return;

    // Clone the current Users array
    const updatedUsers = Array.from(Users);

    // Find the user IDs at fromIndex and toIndex in usersInStage
    const fromUserId = usersInStage[fromIndex].id;
    const toUserId = usersInStage[toIndex].id;

    console.log("fromUserIdfromUserId", fromUserId);

    console.log("toUserIdtoUserId", toUserId);

    // Find the corresponding indexes in the main Users array by comparing ID
    const fromUserIndex = updatedUsers.findIndex(
      (user) => user.id === fromUserId
    );

    const toUserIndex = updatedUsers.findIndex((user) => user.id === toUserId);

    // Swap the items in the main Users array if both indexes are valid
    if (fromUserIndex !== -1 && toUserIndex !== -1) {
      // Create new objects for the users being swapped to avoid mutation
      const fromUser = { ...updatedUsers[fromUserIndex] };
      const toUser = { ...updatedUsers[toUserIndex] };

      // Check if the first item has `main: true`
      const isMain = fromUser.main;
      const IsMain2 = toUser.main;
      console.log("isMainisMain", isMain);

      // Swap the users
      updatedUsers[fromUserIndex] = toUser;
      updatedUsers[toUserIndex] = fromUser;

      // If the first item had `main: true`, give it to the second item
      if (isMain) {
        updatedUsers[toUserIndex].main = false;
        updatedUsers[fromUserIndex].main = true;
      }
      if (IsMain2) {
        updatedUsers[toUserIndex].main = true;
        updatedUsers[fromUserIndex].main = false;
      }

      // Dispatch only the updated list
      dispatch(manageUsers(updatedUsers));
    }
  };

  const CustomDragPreview = ({ width, height }) => {
    const { display, itemType, item, style } = usePreview();
    if (!display) {
      return null;
    }
    return (
      <div
        style={{
          ...style,
          backgroundColor: "rgba( 0,0, 0, 0.1)",
          opacity: 0.4,
          zIndex: 9999999,
          width: width,
          height: height,
        }}
      >
        {/* Custom preview content goes here */}
        <img
          src={item.src}
          style={{
            width: width,
            height: height,
            opacity: 0.2,
            backgroundColor: "rgba( 0,0, 0, 0.1)",
            zIndex: -1,
          }}
          alt="Preview"
        />
      </div>
    );
  };

  return (
    <Fragment>
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <div
          className="bibibig"
          ref={divRef}
          style={{
            background: status === "Viewer" ? "rgb(19, 19, 21)" : "",
            flex: "25 auto",
            overflow: "hidden",
            height:
              status === "Viewer"
                ? "100%"
                : screenWidth <= 576
                ? (screenWidth / 16) * 9
                : "TEST",
          }}
        >
          <div
            className="box-conatiner"
            ref={childRef}
            style={{
              transition: "width 300ms ease 0s, height 300ms ease 0s",
            }}
          >
            <div
              className="box-conatiner1"
              style={{
                backgroundImage: `url(${
                  status === "Viewer"
                    ? screenWidth <= 576
                      ? background.portrait
                        ? background.portrait && background.portrait.src
                        : background.landscape && background.landscape.src
                      : background.landscape
                      ? background.landscape && background.landscape.src
                      : background.portrait && background.portrait.src
                    : screenMode === "portrait"
                    ? background.portrait
                      ? background.portrait && background.portrait.src
                      : background.landscape && background.landscape.src
                    : background.landscape
                    ? background.landscape && background.landscape.src
                    : background.portrait && background.portrait.src
                }) `,
              }}
            >
              <div className="box-container2">
                {layout === "Single" || usersInStage.length === 1
                  ? renderVideos(
                      usersInStage.filter((video) => video.main),
                      videoDimensions,
                      childRef,
                      theme,
                      generateSacleChild,
                      fontColor,
                      layout,
                      color,
                      screenMode,
                      ShowNameUser,
                      ticker,
                      status,
                      videoRef,
                      screenWidth,
                      vi1,
                      usersInStage,
                      title,
                      isMuted,
                      SortableVideoItem, // Pass the SortableVideoItem here
                      CustomDragPreview,
                      dispatch,
                      moveVideo
                    ) // Filter for main video in Single layout and another layout
                  : renderVideos(
                      filteredUsersInStage,
                      videoDimensions,
                      childRef,
                      theme,
                      generateSacleChild,
                      fontColor,
                      layout,
                      color,
                      screenMode,
                      ShowNameUser,
                      ticker,
                      status,
                      videoRef,
                      screenWidth,
                      vi1,
                      usersInStage,
                      title,
                      isMuted,
                      SortableVideoItem, // Pass the SortableVideoItem here,
                      CustomDragPreview,
                      dispatch,
                      moveVideo
                    )}
              </div>
              <TitleInsideBox
                title={title}
                theme={theme}
                ticker={ticker}
                fontColor={fontColor}
                color={color}
                sacle={sacle}
                screenMode={screenMode}
                status={status}
                widthBox={childRef.current?.clientWidth}
              />
              <div className="d-none d-sm-flex">
                {currentSelected &&
                  (currentSelected.type === "question" ? (
                    <BoxQuestion value={currentSelected.value} />
                  ) : (
                    ""
                  ))}
                {currentSelectedPoll && (
                  <BoxPall value={currentSelectedPoll.value} />
                )}

                {stateCreateQues && stateCreateQues.show === "question" && (
                  <BoxCreateQuestion
                    valueData={stateCreateQues.value}
                    type={stateCreateQues.type}
                  />
                )}

                {stateCreatePoll && (
                  <BoxCreatePolls
                    valueData={stateCreatePoll.value}
                    type={stateCreatePoll.type}
                  />
                )}
              </div>
              <div className="d-none d-sm-flex">
                {autoMuteStatus && <BoxAutoMute />}
              </div>

              <div className="d-block d-sm-none mobile-pop-box">
                {status === "Viewer" && <ViwerMobileCover />}
              </div>
            </div>
          </div>
          {status === "Viewer" && (
            <div
              className="d-block d-sm-none"
              style={{ width: "100%", padding: "0 10px" }}
            >
              <SendMessageBox />
            </div>
          )}
        </div>

        <div className="d-block d-sm-none ">
          {currentSelected &&
            (currentSelected.type === "question" ? (
              <BoxQuestion value={currentSelected.value} />
            ) : (
              ""
            ))}
          {currentSelectedPoll && <BoxPall value={currentSelectedPoll.value} />}
          {stateCreateQues && stateCreateQues.show === "question" && (
            <BoxCreateQuestion
              valueData={stateCreateQues.value}
              type={stateCreateQues.type}
            />
          )}

          {stateCreatePoll && (
            <BoxCreatePolls
              valueData={stateCreatePoll.value}
              type={stateCreatePoll.type}
            />
          )}

          {autoMuteStatus && <BoxAutoMute />}
        </div>
        {/*Button actions foe edit on video box */}
        {/**  {status !== "Viewer" && (
        <div className="box-btns d-none d-sm-flex">
       <Layout />
        </div>
      )} */}
      </DndProvider>
    </Fragment>
  );
}

export default BoxVideo;
