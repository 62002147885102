import React from "react";

const VideoSvg = ({ className, color, miancolor, backgroundColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      zoomAndPan="magnify"
      viewBox="0 0 67.5 56.249996"
      height="75"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="c546a448c8">
          <path
            d="M 14.261719 0 L 66.808594 0 L 66.808594 55.511719 L 14.261719 55.511719 Z M 14.261719 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="90dd226050">
          <path
            d="M 21.660156 0 L 59.40625 0 C 61.371094 0 63.253906 0.78125 64.640625 2.167969 C 66.03125 3.554688 66.808594 5.4375 66.808594 7.402344 L 66.808594 48.109375 C 66.808594 50.070312 66.03125 51.953125 64.640625 53.34375 C 63.253906 54.730469 61.371094 55.511719 59.40625 55.511719 L 21.660156 55.511719 C 19.699219 55.511719 17.816406 54.730469 16.425781 53.34375 C 15.039062 51.953125 14.261719 50.070312 14.261719 48.109375 L 14.261719 7.402344 C 14.261719 5.4375 15.039062 3.554688 16.425781 2.167969 C 17.816406 0.78125 19.699219 0 21.660156 0 Z M 21.660156 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="f1589a0f9c">
          <path
            d="M 0.199219 16.648438 L 28.324219 16.648438 L 28.324219 32.1875 L 0.199219 32.1875 Z M 0.199219 16.648438 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="d3622bd7ff">
          <path
            d="M 0.199219 26.050781 L 28.324219 26.050781 L 28.324219 55.511719 L 0.199219 55.511719 Z M 0.199219 26.050781 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="c145243d67">
          <path
            d="M 7.597656 26.050781 L 20.921875 26.050781 C 22.882812 26.050781 24.765625 26.832031 26.15625 28.21875 C 27.542969 29.609375 28.324219 31.492188 28.324219 33.453125 L 28.324219 48.109375 C 28.324219 50.070312 27.542969 51.953125 26.15625 53.34375 C 24.765625 54.730469 22.882812 55.511719 20.921875 55.511719 L 7.597656 55.511719 C 5.636719 55.511719 3.753906 54.730469 2.363281 53.34375 C 0.976562 51.953125 0.199219 50.070312 0.199219 48.109375 L 0.199219 33.453125 C 0.199219 31.492188 0.976562 29.609375 2.363281 28.21875 C 3.753906 26.832031 5.636719 26.050781 7.597656 26.050781 Z M 7.597656 26.050781 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="fa1f6935fc">
          <path
            d="M 13.074219 21.34375 L 58.964844 21.34375 L 58.964844 46.507812 L 13.074219 46.507812 Z M 13.074219 21.34375 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="86ea2b3296">
          <path
            d="M 0.199219 0 L 15 0 L 15 14 L 0.199219 14 Z M 0.199219 0 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="1ab0a23b86">
          <path
            d="M 21.117188 7 L 7.191406 20.925781 L 0.1875 13.921875 L 14.113281 0 Z M 21.117188 7 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="f1d829d9f7">
          <path
            d="M 14.152344 13.964844 L 0.1875 13.921875 L 14.113281 0 Z M 14.152344 13.964844 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="ff2a3f750c">
          <path
            d="M 0.199219 14 L 18 14 L 18 17 L 0.199219 17 Z M 0.199219 14 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="c146abbbc0">
          <path
            d="M 16.480469 0 L 33.132812 0 L 33.132812 16.652344 L 16.480469 16.652344 Z M 16.480469 0 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#c546a448c8)">
        <g clip-path="url(#90dd226050)">
          <path
            fill={backgroundColor}
            d="M 14.261719 0 L 66.808594 0 L 66.808594 55.421875 L 14.261719 55.421875 Z M 14.261719 0 "
            fill-opacity="1"
            fill-rule="nonzero"
          />
        </g>
      </g>
      <g clip-path="url(#f1589a0f9c)">
        <path
          fill={backgroundColor}
          d="M 0.199219 16.648438 L 28.308594 16.648438 L 28.308594 32.1875 L 0.199219 32.1875 Z M 0.199219 16.648438 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#d3622bd7ff)">
        <g clip-path="url(#c145243d67)">
          <path
            fill={backgroundColor}
            d="M 0.199219 26.050781 L 28.324219 26.050781 L 28.324219 55.496094 L 0.199219 55.496094 Z M 0.199219 26.050781 "
            fill-opacity="1"
            fill-rule="nonzero"
          />
        </g>
      </g>
      <g clip-path="url(#fa1f6935fc)">
        <path
          fill="#ffffff"
          d="M 2.578125 70.261719 C 2.578125 70.816406 3.027344 71.269531 3.585938 71.269531 L 68.167969 71.269531 C 68.722656 71.269531 69.175781 70.816406 69.175781 70.261719 L 69.175781 -14.503906 C 69.175781 -15.058594 68.722656 -15.511719 68.167969 -15.511719 L 20.738281 -15.511719 C 20.46875 -15.511719 20.210938 -15.40625 20.023438 -15.214844 L 2.867188 1.9375 C 2.683594 2.128906 2.578125 2.382812 2.578125 2.652344 Z M 21.75 -13.492188 L 21.75 2.652344 C 21.75 3.207031 21.296875 3.660156 20.738281 3.660156 L 4.59375 3.660156 L 4.59375 69.25 L 67.15625 69.25 L 67.15625 -13.492188 Z M 42.9375 35.984375 L 54.429688 44.824219 C 54.738281 45.058594 55.148438 45.101562 55.492188 44.929688 C 55.835938 44.757812 56.058594 44.40625 56.058594 44.023438 L 56.058594 23.84375 C 56.058594 23.460938 55.835938 23.109375 55.492188 22.9375 C 55.148438 22.765625 54.738281 22.808594 54.429688 23.042969 L 42.9375 31.882812 L 42.9375 25.859375 C 42.9375 24.1875 41.578125 22.832031 39.910156 22.832031 L 18.722656 22.832031 C 17.050781 22.832031 15.695312 24.1875 15.695312 25.859375 L 15.695312 42.007812 C 15.695312 43.675781 17.050781 45.03125 18.722656 45.03125 L 39.910156 45.03125 C 41.578125 45.03125 42.9375 43.675781 42.9375 42.007812 Z M 19.730469 -12.066406 L 6.015625 1.644531 L 19.730469 1.644531 L 19.730469 -12.066406 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <path
        stroke-linecap="butt"
        transform="matrix(0, 0.740131, -0.740131, 0, 16.477647, 0.00261451)"
        fill="none"
        stroke-linejoin="miter"
        d="M 0.00174529 1.500355 L 22.490359 1.500355 "
        stroke="#ffffff"
        stroke-width="3"
        stroke-opacity="1"
        stroke-miterlimit="4"
      />
      <g clip-path="url(#86ea2b3296)">
        <g clip-path="url(#1ab0a23b86)">
          <g clip-path="url(#f1d829d9f7)">
            <path
              fill={backgroundColor}
              d="M 21.117188 7 L 7.191406 20.925781 L 0.1875 13.921875 L 14.113281 0 Z M 21.117188 7 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
          </g>
        </g>
      </g>
      <g clip-path="url(#ff2a3f750c)">
        <path
          stroke-linecap="butt"
          transform="matrix(0.740131, 0, 0, 0.740131, 0.197371, 14.426231)"
          fill="none"
          stroke-linejoin="miter"
          d="M 0.00249702 1.498282 L 22.000277 1.498282 "
          stroke="#ffffff"
          stroke-width="3"
          stroke-opacity="1"
          stroke-miterlimit="4"
        />
      </g>
      <g clip-path="url(#c146abbbc0)">
        <path
          fill={backgroundColor}
          d="M 16.480469 0 L 33.132812 0 L 33.132812 16.652344 L 16.480469 16.652344 Z M 16.480469 0 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  );
};

export default VideoSvg;
