import React, { Fragment } from "react";
import img1 from "../../../assits/camera-placeholder.jpg";
import backGround_vr_1 from "../../../assits/backGround_vr_1.jpg";
import backGround_vr_2 from "../../../assits/backGround_vr_2.jpg";
import backGround_vr_3 from "../../../assits/backGround_vr_3.jpg";
import backGround_vr_4 from "../../../assits/backGround_vr_4.jpg";
// import bluring_1 from "../../../assits/Bluring_1.svg";
// import Bluring_2 from "../../../assits/Bluring_2.svg";
// import Bluring_3 from "../../../assits/Bluring_3.svg";
import BluringOneSvg from "../../Svgs/BluringOneSvg";
import BluringTwoSvg from "../../Svgs/BluringTwoSvg";
import BluringThreeSvg from "../../Svgs/BluringThreeSvg";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
function CamerBox() {
  return (
    <Fragment>
      <div className="box-camera">
        <img src={img1} alt="..." />
      </div>
      <div className="camera-setting">
        <div className="box-content">
          <div className="box-label">
            <label>Camera</label>
          </div>
          <select className="select-control form-select">
            <option value="EasyCamera (0bda:58ea)" key="one">
              EasyCamera (0bda:58ea)
            </option>
          </select>
        </div>
        <div className="box-content">
          <div className="box-label">
            <label>Camera resolution </label>
          </div>
          <select className="select-control form-select">
            <option value="Full High Definition (1080p)" key="one">
              Full High Definition (1080p)
            </option>
            <option value="High Definition (720p)" key="one">
              High Definition (720p)
            </option>
            <option value="Standard Definition (480p)" key="one">
              Standard Definition (480p)
            </option>
            <option value="Low Definition (360p)" key="one">
              Low Definition (360p)
            </option>
          </select>
        </div>
      </div>
      {/* <div className="box-chack">
        <div className="wraper-check">
          <input type="checkbox" id="meror" className="form-check" />
          <label htmlFor="meror">Mirror my camera</label>
        </div>
      </div>
      <div className="box-chack">
        <div className="wraper-check">
          <input type="checkbox" id="meror" className="form-check" />
          <label htmlFor="meror">Touch up my appearance</label>
        </div>
      </div> */}

      <div className="box-background">
        <div className="title">
          <span>Blur Backgrounds</span>
          {/* <span>Backgrounds</span> */}
        </div>
        <div className="box-items">
          <div className="item">
            <i
              class="fa-solid fa-ban"
              style={{ fontSize: "28px", color: "rgb(79 84 97 / 88%)" }}
            ></i>
          </div>
          <div className="item">
            <BluringOneSvg />
          </div>
          <div className="item">
            <BluringTwoSvg />
          </div>
          <div className="item">
            <BluringThreeSvg />
          </div>
        </div>
      </div>

      <div className="box-background">
        <div className="title">
          <span>Virtual Backgrounds</span>
          {/* <span>Backgrounds</span> */}
        </div>
        <div className="box-items">
          <div className="item">
            <i
              class="fa-solid fa-ban"
              style={{ fontSize: "28px", color: "rgb(79 84 97 / 88%)" }}
            ></i>
          </div>

          <div className="item" style={{ cursor: "pointer" }}>
            <AddPhotoAlternateIcon
              style={{ fontSize: "35px", cursor: "pointer" }}
            />
          </div>

          <div className="item">
            <img src={backGround_vr_1} alt="..." />
          </div>
          <div className="item">
            <img src={backGround_vr_2} alt="..." />
          </div>
          <div className="item">
            <img src={backGround_vr_3} alt="..." />
          </div>
          <div className="item">
            <img src={backGround_vr_4} alt="..." />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default CamerBox;
